import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellWalktheWorld = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Walk the World
			<div className="spacer" />
			<span className="right">Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Duration:</b> 8 hours</li>
		</ul>
		<hr />
		<p className="no-indent">You gain a +40-foot circumstance bonus to your travel Speed.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> The bonus to your travel Speed increases by 20 feet.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>