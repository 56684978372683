import React from 'react';
import { Spell } from '../../extra/linkables';

export const TraitRush = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Rush</h2>
		<p className="no-indent">Rush actions allow characters to move and act supernaturally fast. They typically allow
			actions to be performed as a free action, or reduce the number of actions required for larger actions.
			Like <a href="https://2e.aonprd.com/Traits.aspx?ID=73">flourish</a> actions, you can use only 1 action with
			the rush trait per turn.</p>
		<br />
		<br />
		<h3 className="headerbar sub-header-bg">Spells</h3>
		<Spell name="Burst of Speed" title={true} />, <Spell name="Mythic Quicken Spell" title={true} />,&nbsp;
		<Spell name="Sudden Strike" title={true} />
	</div>
	<div className="spacer" />
</div>