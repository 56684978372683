import React from 'react';
import { Link } from 'react-router-dom'
export const UniversalMythicFeats = () =>
<div>
	<h2 className="header-bg">Universal Mythic Feats</h2>
	<p className="no-indent">Every mythic rank at which you gain an Universal feat, select one of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm full-width">
		<thead>
			<tr>
				<th className="col-3">Name</th>
				<th className="col-1">Rank</th>
				<th className="col-3">Traits</th>
				<th className="col-3">Prerequisites</th>
				<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/feats/aptitude">Aptitude</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain many exceptional skills.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/incredibleclimber">Incredible Climber</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>[Dragon] copper form or white form; [Fey] woodland form</td>
				<td>You gain a climb speed.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/incredibleswimmer">Incredible Swimmer</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>[Dragon] black form, bronze form, green form or gold form; [Fey] aquatic form</td>
				<td>You gain a swim speed.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/infinitevariety">Infinite Variety</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Gain perplexing abilities from countless sources.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/legendaryitem">Legendary Item</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain a legendary item.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/manifestpower">Manifest Power</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You can create dramatic effects by expending mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You can survive an attack that would have killed you with 1 hit point.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicbond">Mythic Bond</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>Animal Companion, Familiar or Eidolon</td>
				<td>You grant your animal companion, familiar or eidolon mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythiccraft">Mythic Craft</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You can craft mythic items, and items with a higher item level.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicsustenance">Mythic Sustenance</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You no longer need to eat, drink, sleep or breathe.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/piercethedarkness">Pierce the Darkness</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain greater darkvision.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/telepathy">Telepathy</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain the ability to communicate telepathically.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/communewithpower">Commune with Power</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You can seek answers from the source of your power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mysticsight">Mystic Sight</Link></td>
				<td className="center">2</td>
				<td>Detection, Divination, Mythic</td>
				<td>—</td>
				<td>You can see magic.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicmetamagic">Mythic Metamagic</Link></td>
				<td className="center">2</td>
				<td>Mythic, Metamagic</td>
				<td>Ability to cast spells</td>
				<td>You manipulate your spells in powerful and unexpected ways.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/mythicritualist">Mythic Ritualist</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>expert in Arcana, Nature, Occultism, or Religion</td>
				<td>You perform rituals with great ease.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/amazingreflexes">Amazing Reflexes</Link></td>
				<td className="center">3</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain an additional reaction each round.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/ascendantspell">Ascendant Spell</Link></td>
				<td className="center">3</td>
				<td>Mythic, Metamagic</td>
				<td>—</td>
				<td>You can infuse a spell with mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/extramythicpower">Extra Mythic Power</Link></td>
				<td className="center">3</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You can access a deep and enduring wellspring of power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/magicsurge">Magic Surge</Link></td>
				<td className="center">3</td>
				<td>Mythic</td>
				<td>Ability to cast spells</td>
				<td>You can cast a powerful version of any spell you know.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicanimalcompanion">Mythic Animal Companion</Link></td>
				<td className="center">3</td>
				<td>Mythic</td>
				<td>Animal Companion, <Link to="/mythic/feats/mythicbond">Mythic Bond</Link></td>
				<td>Your animal companion becomes stronger and gains it's own pool of mythic power points.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythiccohort">Mythic Cohort</Link></td>
				<td className="center">3</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You grant a trusted individual access to your mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicteamwork">Mythic Teamwork</Link></td>
				<td className="center">3</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/mythicbond">Mythic Bond</Link> or <Link to="/mythic/feats/mythiccohort">Mythic Cohort</Link></td>
				<td>You grant your animal companion, familiar, eidolon or mythic cohort access to your mythic feats.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td>You gain more innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/advancedlegendaryitem">Advanced Legendary Item</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/legendaryitem">Legendary Item</Link></td>
				<td>Your legendary item becomes an artifact and can have up to 8 abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/advancedmanifestations">Advanced Manifestations</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/manifestpower">Manifest Power</Link></td>
				<td>You can create even more incredible effects by expending mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/impossiblespeed">Impossible Speed</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Elemental] air element, fire element or water element</td>
				<td>Your Speed increases by 20 feet.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/signaturespell">Signature Spell</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>Ability to cast a non-cantrip spell</td>
				<td>You can cast a chosen spell regularly.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/beyondmorality">Beyond Morality</Link></td>
				<td className="center">5</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You have no alignment and can ignore alignment-based effects.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/persistentmagic">Persistent Magic</Link></td>
				<td className="center">5</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/mythicmetamagic">Mythic Metamagic</Link>, <Link to="/mythic/spells/mythicextendspell">Mythic Extend Spell</Link></td>
				<td>You can anchor a spell with mythic power to make it last indefinitely.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/truesight">True Sight</Link></td>
				<td className="center">6</td>
				<td>Divination, Mythic, Revelation</td>
				<td><Link to="/mythic/feats/mysticsight">Mystic Sight</Link></td>
				<td>You see things as they actually are.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/greaterinnatemagic">Greater Innate Magic</Link></td>
				<td className="center">7</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td>You gain even more innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/greaterlegendaryitem">Greater Legendary Item</Link></td>
				<td className="center">7</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/legendaryitem">Legendary Item</Link>, <Link to="/mythic/feats/advancedlegendaryitem">Advanced Legendary Item</Link></td>
				<td>Your legendary item becomes even stronger, and can have up to 9 abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/greatermanifestations">Greater Manifestations</Link></td>
				<td className="center">7</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/manifestpower">Manifest Power</Link>, <Link to="/mythic/feats/advancedmanifestations">Advanced Manifestations</Link></td>
				<td>You can create reality-bending effects by expending mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/peerlessinnatemagic">Peerless Innate Magic</Link></td>
				<td className="center">10</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td>You gain even more innate spellcasting abilities.</td>
			</tr>
		</tbody>
	</table>
</div>
