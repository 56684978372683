import React from 'react';

export const FeatMythicCohort = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Cohort
			<div className="spacer" />
			<span className="right">Feat Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Angel</div>
			<div className="trait">Archon</div>
			<div className="trait">Devil</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">With a special ritual that requires 1 day to complete, you can choose another person or creature who you know, that is your level or lower, to be your mythic cohort and grant them access to your mythic power. You may only grant mythic power in this way to one other creature at a time.</p>
		<p className="no-indent">Your cohort gains a mythic rank equal to your mythic rank &ndash; 2. They gains all of the abilities of a mythic character except for a mythic path and mythic feats. They do not gain their own pool of Mythic Power points, but can activate mythic spells and abilities by spending points from your mythic power pool if you allow it. They gain 8 additional hit points per mythic rank, in place of the normal amount granted by a mythic path.</p>
		<p className="no-indent">The two of you always know the direction to each other, the approximate distance from each other and are immediately aware if the other is dying.</p>
		<p className="no-indent">If one of you dies, or is otherwise permanently lost, the strain of the loss causes the other to become clumsy 2, drained 2, and enfeebled 2 for one week. These conditions can't be removed or reduced by any means until the week has passed. If your cohort dies, the size of your pool of Mythic Power Points is reduced by 3 for one week, during which time you cannot gain another mythic cohort. If you die, the other person stops being your mythic cohort.</p>
	</div>
	<div className="spacer" />
</div>