import React from 'react';
import { Link } from 'react-router-dom'
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellRayofAnnihilation = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Ray of Annihilation
			<div className="spacer" />
			<span className="right">Mythic 8</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Abjuration" /></div>
			<div className="trait"><Trait name="Evocation" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Area:</b> 500-foot line</li>
			<li><b>Saving Throw:</b> basic Reflex</li>
		</ul>
		<hr />
		<p className="no-indent">You concentrate an incredible amount of power in your hands and release it as a white-hot beam of reality-warping light. Targets in the area take 24d10 damage. A creature or object reduced to 0 HP by <i>ray of annihilation</i> fades away and is utterly destroyed, along with any gear it is wearing (excluding artifacts and objects that are similarly hard to destroy). A non-magical object you hit is destroyed. Attempt to counteract a magical item you hit. If you succeed, it is destroyed, except for artifacts and items of similar power.</p>
		<p className="no-indent">A creature or object destroyed by ray of annihilation is erased from time and any actions it has taken in the last 24 hours are retroactively undone. All creatures, except for mythic creatures present at the time of the creature or object's destruction, forget that the creature or object ever existed.</p>
		<p className="no-indent">Anything destroyed by <i>ray of annihilation</i> can be brought back only by a deity's direct intervention or by a powerful mythic spell, such as <Link to="/mythic/spells/recreation">recreation</Link>.</p>
		<p className="no-indent">This spell requires 3 Mythic Power Points to cast, instead of 1.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> The damage increases by 3d10.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>