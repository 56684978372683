import React from 'react';
import { Link } from 'react-router-dom'

export const FeatSoldierWeaponExpertise = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Weapon Expertise
		<div className="spacer" />
		<span className="right">Feat 5</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link>, at least 4 total Soldier feats</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Youâ€™ve dedicated yourself to learning the intricacies of your weapons. Your proficiency ranks for melee weapons, long arms, heavy weapons, unarmed attacks, and any exotic weapons you are trained in increase to expert.</p><br />
</div>