import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellProjectForce = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Project Force
			<div className="spacer" />
			<span className="right">Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Attack" /></div>
			<div className="trait"><Trait name="Flourish" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Evocation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Area:</b> 30-foot cone</li>
		</ul>
		<hr />
		<p className="no-indent">Make a melee Strike, Shove or Trip attack against each creature in the area. This counts as one attack when calculating your multiple attack penalty. Apply the effects of any runes on the weapon used for the attack, but ignore the effects of any special materials the weapon is made of.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (5th)</b> The area increases to a 60-foot cone</li>
			<li><b>Heightened (8th)</b> The area increases to a 120-foot cone</li>
		</ul>
	</div>
	<div className="spacer" />
</div>