import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellDevestatingSmash = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Devestating Smash
			<div className="spacer" />
			<span className="right">Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Attack" /></div>
			<div className="trait"><Trait name="Flourish" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Evocation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
		</ul>
		<hr />
		<p className="no-indent">Make a melee Strike against the target. This Strike ignores any hardness or resistance to physical damage the target might possess. If you hit a creature, you deal 4d6 additional points of damage. If you hit an object, you deal 40 additional damage.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> The damage against creatures increases by 2d6, and the damage against objects increases by 20.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>