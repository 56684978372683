import React from 'react';

export const SpellInfoSculptSpell = () =>
<div className="columns">
	<div className="col-20">
		<h3>Table: Sculpt Spell Sizes</h3>
		<table>
			<thead>
				<tr>
					<th className="col-7">Burst, cylinder or emanation radius</th>
					<th className="col-7">Cone length</th>
					<th className="col-7">Line length</th>
					<th className="col-4">Number of cubes</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>5-foot</td>
					<td>10 to 15-foot</td>
					<td>10 to 20-foot</td>
					<td><b>4 cubes</b></td>
				</tr>
				<tr>
					<td>10-foot</td>
					<td>20 to 30-foot</td>
					<td>25 to 60-foot</td>
					<td><b>12 cubes</b></td>
				</tr>
				<tr>
					<td>15 to 20-foot</td>
					<td>35 to 45-foot</td>
					<td>65 to 120-foot</td>
					<td><b>24 cubes</b></td>
				</tr>
				<tr>
					<td>25 to 30-foot</td>
					<td>50 to 60-foot</td>
					<td>125 to 180-foot</td>
					<td><b>36 cubes</b></td>
				</tr>
				<tr>
					<td>35 to 40-foot</td>
					<td>65 to 90-foot</td>
					<td>185 to 250-foot</td>
					<td><b>50 cubes</b></td>
				</tr>
				<tr>
					<td>45 to 60-foot</td>
					<td>95 to 120-foot</td>
					<td>255 to 500-foot</td>
					<td><b>100 cubes</b></td>
				</tr>
				<tr>
					<td>larger than 60-foot</td>
					<td>larger than 120-foot</td>
					<td>larger than 500-foot</td>
					<td><b>200 cubes</b></td>
				</tr>
			</tbody>
		</table>
		<br />
	</div>
</div>