import React from 'react';
import { Link } from 'react-router-dom'

export const FeatAdvancedSkillTraining = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Advanced Skill Training
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">General</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/swe2/feats/improvedskilltraining">Improved Skill Training</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You have trained in a vast array of skills. You become trained in 2 additional skills of your choice.</p><br />
</div>