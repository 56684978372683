import React from 'react';
import { Link } from 'react-router-dom'
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellTouchofUnmaking = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Touch of Unmaking
			<div className="spacer" />
			<span className="right">Mythic 7</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Abjuration" /></div>
			<div className="trait"><Trait name="Evocation" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Range:</b> Touch; <b>Targets:</b> 1 creature or unattended object</li>
			<li><b>Saving Throw:</b> Fortitude</li>
		</ul>
		<hr />
		<p className="no-indent">You touch your target, attempting to erase it from existance. Make an unarmed melee attack or melee spell attack. You deal 30d10 damage, and the target must attempt a basic Fortitude save. On a critical hit, treat the save result as one degree worse. A creature reduced to 0 HP is destroyed utterly along with any gear it is wearing (excluding artifacts and objects that are similarly hard to destroy).</p>
		<p className="no-indent">A non-magical object you hit is destroyed (no save). Attempt to counteract a magical item you hit. If you succeed, it is destroyed. This can destroy even artifacts, and items of similar power. A single casting can destroy no more than a 20-foot cube of matter.</p>
		<p className="no-indent">Anything destroyed by touch of unmaking can be brought back only by a deity's direct intervention or a powerful mythic spell, such as <Link to="/mythic/spells/recreation">recreation</Link>.</p>
		<p className="no-indent">This spell requires 2 Mythic Power Points to cast, instead of 1.</p>
	</div>
	<div className="spacer" />
</div>