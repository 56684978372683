import React from 'react';
import { Switch, Route } from 'react-router';
import { RulesLegendaryItem } from './mythic/legendaryitem';
import { RulesLegendaryItemSpecialAbilities } from './mythic/legendaryitems/specialabilities';

export const RulesMythic = () =>
<div>
    <h1>Mythic Adventures 2e (Beta)</h1>
	<Switch>
		<Route path="/rules/mythic/legendaryitems" component={RulesLegendaryItem}/>
		<Route path="/rules/mythic/legendaryitems/specialabilities" component={RulesLegendaryItemSpecialAbilities}/>
	</Switch>
	<br />
	<div className="spacer" />
    <div className="copyright shadow-sm">
        <h3>Copyright Notice</h3>
        Pathfinder Roleplaying Game Mythic Adventures © 2013, Paizo Publishing, LLC; Authors: Jason Bulmahn, Stephen Radney-MacFarland,
        Sean K Reynolds, Dennis Baker, Jesse Benner, Ben Bruck, Jim Groves, Tim Hitchcock, Tracy Hurley, Jonathan Keith, Jason Nelson,
        Tom Phillips, Ryan Macklin, F. Wesley Schneider, Amber Scott, Tork Shaw, Russ Taylor, and Ray Vallese.
    </div>
</div>;