import React from 'react';
import freetrigger from '../../../resources/free-action.png';

export const FeatForceSensitivity = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Force Sensitivity
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Force</div>
		<div className="trait">General</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> Non-droid</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You are Force-sensitive. You possess a strong connection to the mystical energy field known as the Force. Through it, you can sense danger and subtly influence ther world around you. You gain the Alter Fate action, which you can use once per day.</p><br />
	<p className="all-indent no-indent justify"><div className="info shadow-sm">
  <h3 className="sub-header-bg">Alter Fate <img className="small-icon" alt="Triggered Free Action" src={freetrigger} /></h3>
  <div className="traits">
    <div className="trait">Force</div> <div className="trait">Fortune</div>
  </div>
  <p className="justify"><b>Trigger:</b> You roll any check.</p>
  <hr />
  <p className="no-indent all-indent justify">The subtle influence of the Force guides you towards success. Reroll the triggering check. You must use the second result, even if it's worse. This is a fortune effect.</p>
</div></p><br />
	<p className="all-indent no-indent justify"><br /></p><br />
	<p className="all-indent no-indent justify">In addition, you automatically sense Force events and Force auras if they are close enough. You learn of the existance of a Force event or aura, it's strength and the degree of Dark-side influence, but not it's location or any other information about it.</p><br />
	<p className="all-indent no-indent justify">If a Force-sensitive loved one, close friend or companion is in mortal danger, you sense this as if it were a powerful Force event, and their death is considered an overwhelming Force event for you.</p><br />
	<br />
<div className="info shadow-sm all-indent">
  <h3>Additional Info: Force Events and Force Auras</h3>
  <p className="no-indent justify">The use of the Force creates ripples of energy in the field, which can be sensed by Force-sensitive creatures.
  (Whether they know what they're sensing or not.) These Force events could be short-lived disturbances, or more
  peristent events like an ongoing effect, or a blighted area in the Force.</p>
  <p className="no-indent justify">Force-sensitive creatures and objects also possess a Force aura. These can be sensed like a persistent Force
  event, though the range is much smaller. The strength of a creature or object's aura is related to the highest
  level Force power or effect they are able to use, or that was used in it's creation.</p>
  <br />
  <div className="columns">
    <div className="column-md">
      <h3>Force Event Strength</h3>
      <table className="shadow-sm">
        <tr>
          <th>Power or<br />Effect Level</th>
          <th>Strength</th>
          <th>Range</th>
        </tr>
        <tr>
          <td>0</td>
          <td>Very faint</td>
          <td>5 meters</td>
        </tr>
        <tr>
          <td>1-3</td>
          <td>Faint</td>
          <td>50 meters</td>
        </tr>
        <tr>
          <td>4-6</td>
          <td>Moderate</td>
          <td>500 meters</td>
        </tr>
        <tr>
          <td>7-9</td>
          <td>Powerful</td>
          <td>5 kilometers</td>
        </tr>
        <tr>
          <td>10+</td>
          <td>Overwhelming</td>
          <td>Galaxy-wide</td>
        </tr>
      </table>
    </div>
    <div className="column-md">
      <h3>Creature and Object Force Auras</h3>
      <table className="shadow-sm">
        <tr>
          <th>Highest Level Power or Effect</th>
          <th>Strength</th>
          <th>Range</th>
        </tr>
        <tr>
          <td>0</td>
          <td>Very faint</td>
          <td>Touch</td>
        </tr>
        <tr>
          <td>1-3</td>
          <td>Faint</td>
          <td>5 meters</td>
        </tr>
        <tr>
          <td>4-6</td>
          <td>Moderate</td>
          <td>50 meters</td>
        </tr>
        <tr>
          <td>7-9</td>
          <td>Powerful</td>
          <td>500 meters</td>
        </tr>
        <tr>
          <td>10+</td>
          <td>Overwhelming</td>
          <td>5 kilometers</td>
        </tr>
      </table>
    </div>
  </div>
</div>
<br />
</div>