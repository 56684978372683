import React from 'react';

export const SW2eSpeciesGamorrean = () => 
<div className="columns">
    <div className="column-md">
        <h2 className="header-bg">Gamorrean</h2>
        <h3 className="sub-header-bg">Physical Description</h3>
        <p className="all-indent">Gamorreans are stout, procine humanoids that average about 1.8 meters in height and
        can weigh more than 100 kilograms. They have thick snouts, close set eyes, tusks, and two small horns on their
        heads. Most Gamorreans have a dark greenish coloration over a large portion of their bodies; however skin
        coloration does vary, particularly among females, with light skinned and two-toned pigmentation not uncommon.
        Black, brown, pinkish yellow, and even a rare white pigmentation were possible. Gamorreans have two sexes,
        called sows and boars.</p>
        <p className="all-indent">Gamorreans grow quickly, reaching maturity around age 13. They are capable of living
        well past the age of 45, though few do, due to the violent nature of their society.</p>
        <p className="all-indent"> Their physiology makes it impossible for them to speak Galactic Basic, or any
        language other than their native Gamorrean, which contributes to them being viewed as intellectually inferior
        brutes by the wider galactic community.</p>
        <br />
        <h3 className="sub-header-bg">Society</h3>
        <p className="all-indent">Gamorreans are organized into clans ruled by a male warlord and his wife, a head sow
        who is the most powerful of the clan matrons. While the warlord and his boars are solely concerned with
        preparing and participating in battle with rival clans, the matrons of the clan perform the productive work
        including farming, hunting, manufacturing weapons and running businesses.</p>
        <p className="all-indent">Females within a clan were all related to each other and could trace their lineage
        back to a common matriarch. Boars, however, were exchanged between clans at an early age and some would change
        their allegiance during their adult lives. While boars leaving the clan entirely to wander the galaxy is common,
        sows leaving is much more rare. Clans ranged in size from a few dozen to over a hundred but typically
        a clan constituted about 20 sows, fifty boars and the young.</p>
        <br />
    </div>
    <div className="column-md">
        <h2 className="header-bg">Gamorrean Mechanics</h2>
        <h3 className="sub-header-bg">Hit Points</h3>
        <p>12</p>
        <br />
        <h3 className="sub-header-bg">Size</h3>
        <p>Medium</p>
        <br />
        <h3 className="sub-header-bg">Speed</h3>
        <p>8 meters</p>
        <br />
        <h3 className="sub-header-bg">Ability Boosts</h3>
        <p>Two Strength Boosts</p>
        <p>Constitution</p>
        <p>Free</p>
        <br />
        <h3 className="sub-header-bg">Ability Flaws</h3>
        <p>Intelligence</p>
        <p>Charisma</p>
        <br />
        <h3 className="sub-header-bg">Languages</h3>
        <p>Basic (cannot speak)</p>
        <p>Gamorrean</p>
        <p className="no-indent all-indent">Additional languages equal to your Intelligence (if it's positive).</p>
        <br />
        <h3 className="sub-header-bg">Limited Vocal Range</h3>
        <p className="all-indent">Gamorreans have a limited vocal range. They can learn, but cannot speak
        languages other than their native Gamorrean. Anyone playing a Gamorrean character should ensure they have a
        method to communicate, such as a friend or protocol droid who understands Gamorrean, or a datapad with a
        vocabulator.</p>
        <br />
        <h3 className="sub-header-bg">Natural Weapons</h3>
        <p className="all-indent">Gamorreans have powerful tusks which can be used to make unarmed attacks. These deal
        1d8 damage and have the unarmed trait.</p>
        <br />
        <h3 className="sub-header-bg">Natural Armor</h3>
        <p className="all-indent">Even unarmored, Gamorreans are particularly difficult to harm. while not wearing armor,
        you gain a +2 item bonus to AC.</p>
        <br />
        <h3 className="sub-header-bg">Unusual Anatomy</h3>
        <p className="all-indent">Gamorreans are unusually large, and consume twice as much food as a normal character.
        Most clothing and armor designed for humanoid species does not fit them. Outside of Gamorrean settlements,
        clothing and armor for Gamorrean characters costs twice as much.</p>
        <br />
        <h3 className="sub-header-bg">Stability</h3>
        <p className="all-indent">Gamorreans have a large mass and a low center of gravity, granting them unmatched
        stability. You gain a +2 circumstance bonus to your Fortitude or Reflex DC against attempts to Shove or Trip
        you. This bonus also applies to saving throws against effects that attempt to knock you prone.</p>
        <p className="all-indent">In addition, if any effect would force you to move 10 feet or more, you are moved only
        half the distance. </p>
        <br />
    </div>
    <div className="column-fit">
        <img className="profile-img" src="/profile/gamorrean.jpg" alt="" />
    </div>
</div>