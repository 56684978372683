import React from 'react';
import { Link } from 'react-router-dom'

export const SW2ePowersGeneral = () =>
<div>
	<h3 className="header-bg">General Basic Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 1st-Level Powers</h3>
	<ul className="unstyled">
		<li><Link to="/sw2e/powers/focus/overcharge"><b>Overcharge:</b></Link> Overcharge your weapons to deal more damage for a short time.</li>
		<li><Link to="/sw2e/powers/focus/quickrepair"><b>Quick Repair:</b></Link> Overcharge your weapons to deal more damage for a short time.</li>
	</ul>
	<h3 className="header-bg">General 2nd-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 3rd-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 4th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 5th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 6th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 7th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 8th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 9th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">General 10th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
</div>