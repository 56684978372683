import React from 'react';
import { Link } from 'react-router-dom'

export const FeatInfoLegendaryItem = () =>
<div className="no-indent">
	<h2 className="header-bg shadow-sm">Legendary Items</h2>
	<p>A legendary item is a powerful object and companion brought to life with your mythic power. Your legendary item
		has the <a href="https://2e.aonprd.com/Traits.aspx?ID=109">minion</a> trait, and it gains 2 actions on your turn
		if you use an action to command it. Your legendary item's special abilities and appearance are yours to decide,
		but regardless of it's appearance, it still functions as a normal item of its type.</p>
	<p>You can change any of the decisions related to your legendary item, including its appearance and purpose, with a
		special ritual that requires 8 hours of concentration. This ritual can also be used to change your legendary
		item into a different item (which you must supply) or replace your legendary item if it is lost or destroyed.
		You can only have one legendary item at a time.</p>
	<p>Another creature can use your legendary item if you lend it to them, or if it is lost or stolen. However, if the
		item does not approve of this new partner, it may deny them access to it's magical abilities and legendary item
		special abilites, or even work against them.</p>
	<br />
	<h3 className="sub-header-bg">Proficiencies</h3>
	<p>Your legendary item uses your level to determine its proficiency bonuses. It is trained in unarmored defense, all
		saving throws, Perception, and a number of skills equal to its intelligence modifier. Unless your
		legendary item has the <i>animated</i> special ability, it can't typically use actions that require physical
		activity, such as Leap or Treat Wounds, even if trained in the appropriate skill. Even if it has the <i>animated
		</i> special ability, it may still lack the necessary appendages to perform such actions.</p>
	<br />
	<h3 className="sub-header-bg">Ability Modifiers</h3>
	<p>A legendary item begins with base ability modifiers of <b>Int</b> +0, <b>Wis</b> +0, <b>Cha</b> +0. It has no
		physical ability modifiers and cannot make checks that require them. Choose one of its mental ability modifiers
		to increase by 2 and another to increase by 1.</p>
	<br />
	<h3 className="sub-header-bg">Hardness and Hit Points</h3>
	<p>Your legendary item has a hardness of 5, or the normal Hardness for an object of it's type, whichever is greater.
		Your legendary item has Hit Points equal to 16 plus twice its Constitution modifier (if it has one) for each
		mythic rank you have.</p>
	<br />
	<h3 className="sub-header-bg">Senses</h3>
	<p>Your legendary item gains normal vision and hearing within 30 feet.</p>
	<br />
	<h3 className="sub-header-bg">Communication and Languages</h3>
	<p>Your legendary item can communicate with its partner empathically. It understands a number of languages equal to
		1 plus its Intelligence modifier (if it's positive). These must be languages you know. It can communicate this
		way with any creature who is touching or wearing it.</p>
	<br />
	<h3 className="sub-header-bg">Alignment and Purpose</h3>
	<p>Your legendary item's alignment is the same as your alignment at the time it was created. If your alignment
		changes, you can use the same ritual you use to alter your legendary item to change its alignment to match your
		new alignment.</p>
	<p>You can choose a purpose for the item which it will seek to fulfill regardless of who it's current partner is. It
		may deny its partner access to its abilities or even seek a new partner if it believes they are acting in a way
		which is preventing it from fulfilling its purpose.</p>
	<br />
	<h3 className="sub-header-bg">Special Abilities</h3>
	<p>Legendary items gain a number of special abilities which make them unique. Your item gains two abilities chosen
		from the list below, and gains additional abilities as your mythic rank increases. At 2nd mythic rank, your
		legendary item gains an additional ability, and it gains another at 3rd mythic rank.</p>
	<br />
</div>