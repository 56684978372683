import React from 'react';

export const FeatImprovedSkillTraining = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Improved Skill Training
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">General</div>
	</div>
	<hr />
	<p className="all-indent no-indent justify">You have trained in a wide array of skills. You become trained in 2 skills of your choice.</p><br />
</div>