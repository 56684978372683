import React from 'react';
import { Link } from 'react-router-dom'
export const MythicSpellsAbout = () =>
<div>
	<h3 className="header-bg">Mythic  Cantrips</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/hymnofglory"><b>Hymn of Glory:</b></Link> Bolster your allies with a sacred song.</li>
		<li><Link to="/mythic/spells/lightoftruth"><b>Light of Truth:</b></Link> Glow with a light that prevents lies and reveals illusions.</li>
		<li><Link to="/mythic/spells/shieldoflife"><b>Shield of Life:</b></Link> Enhance effects near you that heal your allies and harm the undead.</li>
		<li><Link to="/mythic/spells/swordofretribution"><b>Sword of Retribution:</b></Link> Slow and cripple creatures who harm your friends.</li>
	</ul>
	<h3 className="header-bg"> 1st-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/blowback"><b>Blowback:</b></Link> You strike a creature, infusing your weapon with mythic power to throw your enemies away from you.</li>
		<li><Link to="/mythic/spells/featofagility"><b>Feat of Agility:</b></Link> Use mythic power to move with unearthly grace.</li>
		<li><Link to="/mythic/spells/featofendurance"><b>Feat of Endurance:</b></Link> Use mythic power to harden your body against harsh conditions.</li>
		<li><Link to="/mythic/spells/featofstrength"><b>Feat of Strength:</b></Link> Use mythic power to perform incredible feats of strength.</li>
		<li><Link to="/mythic/spells/miraculoussurvival"><b>Miraculous Survival:</b></Link> You can survive hits that would kill a normal person.</li>
		<li><Link to="/mythic/spells/mythicsurge"><b>Mythic Surge:</b></Link> Call upon your mythic power to overcome difficult challenges.</li>
		<li><Link to="/mythic/spells/suddenbrilliance"><b>Sudden Brilliance:</b></Link> Use mythic power to speed thought, improve memory, and make impressive deductions.</li>
		<li><Link to="/mythic/spells/suddengranduer"><b>Sudden Granduer:</b></Link> Use mythic power to improve your presence and make powerful impressions with your words and actions.</li>
		<li><Link to="/mythic/spells/suddeninsight"><b>Sudden Insight:</b></Link> Use mythic power to clear your mind and strengthen your willpower.</li>
	</ul>
	<h3 className="header-bg"> 2nd-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/devestatingsmash"><b>Devestating Smash:</b></Link> You strike creatures and smash objects with incredible force, cutting through their defenses.</li>
		<li><Link to="/mythic/spells/energeticspell"><b>Energetic Spell:</b></Link> Change the elemental damage type of a spell.</li>
		<li><Link to="/mythic/spells/mythicreachspell"><b>Mythic Reach Spell:</b></Link> Use mythic power to cast your spells at long distances.</li>
		<li><Link to="/mythic/spells/mythicwidenspell"><b>Mythic Widen Spell:</b></Link> Use mythic power to affect a larger area with your spells.</li>
		<li><Link to="/mythic/spells/precisespell"><b>Precise Spell:</b></Link> Prevent your spell from affecting unintended targets.</li>
		<li><Link to="/mythic/spells/projectforce"><b>Project Force:</b></Link> Use mythic power to strike at all foes in front of you.</li>
		<li><Link to="/mythic/spells/quickeningpresence"><b>Quickening Presence:</b></Link> Speed up those around you with mythic power.</li>
		<li><Link to="/mythic/spells/shelteringpresence"><b>Sheltering Presence:</b></Link> Shield those around you with mythic power.</li>
		<li><Link to="/mythic/spells/stomp"><b>Stomp:</b></Link> You shake the ground, knocking down those around you.</li>
		<li><Link to="/mythic/spells/walktheworld"><b>Walk the World:</b></Link> Travel impossible distances in a short period of time.</li>
	</ul>
	<h3 className="header-bg"> 3rd-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/ascendspell"><b>Ascend Spell:</b></Link> Use mythic power to cast powerful versions of your spells.</li>
		<li><Link to="/mythic/spells/borrowtime"><b>Borrow Time:</b></Link> You can borrow time from the future, allowing you to take your later actions immediately.</li>
		<li><Link to="/mythic/spells/burstofspeed"><b>Burst of Speed:</b></Link> Infused with mythic power, you can move with impossible bursts of speed.</li>
		<li><Link to="/mythic/spells/commandthemindless"><b>Command the Mindless:</b></Link> Take control of the mindless creatures around you.</li>
		<li><Link to="/mythic/spells/invigoratingpresence"><b>Invigorating Presence:</b></Link> Empower those around you with mythic power.</li>
		<li><Link to="/mythic/spells/magicsurge"><b>Magic Surge:</b></Link> Cast the spell you need at a moment's notice.</li>
		<li><Link to="/mythic/spells/mythicextendspell"><b>Mythic Extend Spell:</b></Link> Extend the duration of a spell significantly.</li>
		<li><Link to="/mythic/spells/sevenleaguesleap"><b>Seven-Leagues Leap:</b></Link> You can make a running leap that carries you for miles, allowing you to cover huge distances in a short time.</li>
		<li><Link to="/mythic/spells/suddenstrike"><b>Sudden Strike:</b></Link> Infused with mythic power, you can strike with blinding speed.</li>
	</ul>
	<h3 className="header-bg"> 4th-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/flawlessstrike"><b>Flawless Strike:</b></Link> You strike with unparalleled precision.</li>
		<li><Link to="/mythic/spells/giftofpower"><b>Gift of Power:</b></Link> You infuse an ally with mythic power</li>
		<li><Link to="/mythic/spells/sculptspell"><b>Sculpt Spell:</b></Link> You shape your spells' effects into whatever shape you desire.</li>
		<li><Link to="/mythic/spells/sealpower"><b>Seal Power:</b></Link> You strike your opponents, and seal their supernatural abilities with mythic power.</li>
		<li><Link to="/mythic/spells/shadowdouble"><b>Shadow Double:</b></Link> You create shadowy duplicates of yourself to aid you.</li>
		<li><Link to="/mythic/spells/titanicrage"><b>Titanic Rage:</b></Link> You grow to a terrifying size, destroying everything around you.</li>
	</ul>
	<h3 className="header-bg"> 5th-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/blastspell"><b>Blast Spell:</b></Link> You can transform single target spells into spells that affect many creatures.</li>
		<li><Link to="/mythic/spells/forceofwill"><b>Force of Will:</b></Link> Call upon your mythic power to influence the fate of others.</li>
		<li><Link to="/mythic/spells/mythicquickenspell"><b>Mythic Quicken Spell:</b></Link> Use mythic power to cast spells quickly.</li>
		<li><Link to="/mythic/spells/tideofundeath"><b>Tide of Undeath:</b></Link> Raise the dead all around you, forming an army to fight for you, for a time.</li>
		<li><Link to="/mythic/spells/unscathed"><b>Unscathed:</b></Link> Shield yourself with mythic power, preventing damage and harmful conditions.</li>
		<li><Link to="/mythic/spells/wordofpower"><b>Word of Power:</b></Link> You speak a word that explodes into a wave of mythic power, destroying everything around you and sweeping creatures off their feet.</li>
	</ul>
	<h3 className="header-bg"> 6th-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/fierceregeneration"><b>Fierce Regeneration:</b></Link> You gain powerful regeneration for a short time.</li>
	</ul>
	<h3 className="header-bg"> 7th-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/finalgift"><b>Final Gift:</b></Link> Sacrifice yourself to heal those around you and return them to life.</li>
		<li><Link to="/mythic/spells/touchofunmaking"><b>Touch of Unmaking:</b></Link> You unravel objects and creatures with a touch, erasing them from existance.</li>
		<li><Link to="/mythic/spells/unstoppableresolve"><b>Unstoppable Resolve:</b></Link> Use mythic power to free yourself from debilitating conditions.</li>
	</ul>
	<h3 className="header-bg"> 8th-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/rayofannihilation"><b>Ray of Annihilation:</b></Link> </li>
	</ul>
	<h3 className="header-bg"> 9th-Level Mythic Spells</h3>
	<ul className="unstyled">
		<li><Link to="/mythic/spells/recreation"><b>Recreation:</b></Link> </li>
	</ul>
	<h3 className="header-bg"> 10th-Level Mythic Spells</h3>
	<ul className="unstyled">
	</ul>
</div>