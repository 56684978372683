import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellMythicExtendSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Extend Spell
			<div className="spacer" />
			<span className="right">Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Metamagic" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> </li>
		</ul>
		<hr />
		<p className="no-indent">You pour your mythic power into your spell, causing its effects to linger for a considerable time. If the next action you use is to Cast a Spell that has a duration longer than 1 round, that duration is increased.</p>
		<p className="no-indent">If the spell had a duration of 1 minute or less, the spell lasts for 10 minutes instead.</p>
		<p className="no-indent">If the spell had a duration of more than 1 minute, but less than 10 minutes, the spell lasts for 1 hour instead.</p>
		<p className="no-indent">If the spell had a duration of more than 10 minutes, but less than 1 hour, the spell lasts for 8 hour instead.</p>
		<p className="no-indent">If the spell had a duration of 1 hour or more, the spell lasts until the next time you make your daily preparations.</p>
	</div>
	<div className="spacer" />
</div>