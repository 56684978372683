import React from 'react';
import { Link } from 'react-router-dom'

export const FeatBreadthofExperience = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Breadth of Experience
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scoundrel</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/advancedskilltraining">Advanced Skill Training</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You rely on your skills, and practice them rigorously, improving quickly.</p><br />
	<p className="all-indent no-indent justify">Instead of the normal skill feats and skill increases granted to heroic characters as they increase in level, you gain a skill feat and a skill increase at every level, including 1st.</p><br />
	<p className="all-indent no-indent justify">Additionally, you can choose either Dexterity or Intelligence as your key ability for determining your ability DC. Once this choice is made, it cannot be changed.</p><br />
	<p className="all-indent no-indent justify"><b>Special:</b> If this feat is taken after 1st level, instead of the normal benefit, you immediately gain 3 skill increases and 3 skill feats of your choice.</p><br />
</div>