import React from 'react';
import { Link } from 'react-router-dom'
import { FeatInfoGreaterLegendaryItem } from './extrainfo/greaterlegendaryitem';
export const FeatGreaterLegendaryItem = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Greater Legendary Item
			<div className="spacer" />
			<span className="right">Feat Mythic 7</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/legendaryitem">Legendary Item</Link>, <Link to="/mythic/feats/advancedlegendaryitem">Advanced Legendary Item</Link></li>
		</ul>
		<hr />
		<p className="no-indent">Your legendary item grows even stronger. Increase its Intelligence, Wisdom, and Charisma by 1. Increase its proficiency rank for Perception, unarmored defense and all saving throws to master. The item gains two skill increases, allowing it to become trained in a skill in which it was untrained, or increase the proficiency rank in a skill in which it was already trained, up to master.</p>
		<p className="no-indent">Your item gains two new special abilities, and it gains an additional ability at 8th, and 9th mythic rank. You can choose from the abilities listed below, in addition to those described in the Legendary Item and Advanced Legendary Item feats.</p>
        <br />
        <FeatInfoGreaterLegendaryItem />

	</div>
	<div className="spacer" />
</div>