import React from 'react';
import { Link } from 'react-router-dom'

export const FeatAdvancedWeaponFocus = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Advanced Weapon Focus
		<div className="spacer" />
		<span className="right">Feat 13</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/improvedweaponfocus">Improved Weapon Focus</Link>, Weapon Mastery (any), at least 11 total Soldier feats</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Choose another type of attack or weapon from the list in Weapon Focus. Your proficiency rank with all three of your chosen weapon types increases to legendary.</p><br />
</div>