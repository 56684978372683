import React from 'react';
import { Link } from 'react-router-dom'

export const FeatWeaponSpecialization = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Weapon Specialization
		<div className="spacer" />
		<span className="right">Feat 7</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/weaponexpertise">Weapon Expertise</Link>, at least 5 total Soldier feats</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Youâ€™ve learned how to inflict greater injuries with the weapons you know best. You deal 2 additional damage with weapons and unarmed attacks in which you are an expert. This damage increases to 3 if youâ€™re a master, and to 4 if youâ€™re legendary.</p><br />
</div>