import React from 'react';

export const FeatDeadlyHerbalism = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Deadly Herbalism
			<div className="spacer" />
			<span className="right">Feat 8</span>
		</h2>
		<div className="traits">
			<div className="trait">Archetype</div>
		</div>
		<ul className="proplist">
			<li><b>Archetype</b> <a href="https://2e.aonprd.com/Archetypes.aspx?ID=62">Herbalist</a></li>
			<li><b>Prerequisites</b> <a href="https://2e.aonprd.com/Feats.aspx?ID=1982">Herbalist Dedication</a>; <a href="https://2e.aonprd.com/Feats.aspx?ID=1986">Expert Herbalism</a>; expert in Nature</li>
		</ul>
		<hr />
		<p className="no-indent">You can use your batches of herbs to create poisons as well as remedies. In addition
			to herbal elixirs, you can create alchemical poisons, excluding poisons with the divine trait and those
			that come from creatures, such as black adder venom or dragon bile. When you select this feat, you
			immediately add the formulas for two common alchemical poisons of your advanced alchemy level or lower
			to your formula book.</p>
	</div>
	<div className="col-md info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
		<p>It really is a pity that the Herbalist doesn't have a way to murder someone with nightshade...</p>
	</div>
	<div className="spacer" />
</div>