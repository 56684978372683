import React from 'react';

export const SW2eSpeciesTwilek = () => 
<div className="columns">
    <div className="column-md">
        <h2 className="header-bg">Twi'lek</h2>
        <h3 className="sub-header-bg">Physical Description</h3>
        <p className="all-indent">Twi'leks, sometimes referred to disparagingly as "Tail-heads," are an omnivorous
        humanoid species originating from the planet Ryloth. Their distinctive features include a complete lack of body
        hair, colorful skin, which varied in pigment from individual to individual, and a pair of shapely prehensile
        tentacles that grow from the base of their skulls. These tentacles, called "brain-tails," "lekku," "tchun-tchin,"
        or "head-tails," are advanced organs used for communication and cognitive functions. Twi'leks vary greatly in
        height, from as short as 1.6 meters, to over 2 meters. They have an average lifespan, reaching maturity around
        age 15 and sometimes living to be over 100 years of age.</p>
        <br />
        <h3 className="sub-header-bg">Society</h3>
        <p className="all-indent">Twi'leks are the second most populous race in the galaxy, behind humans. This is
        largely in part due to female Twi'lek's famed attractiveness, which makes them a popular target among slave
        traders. Slavery is the main currency of Ryloth. Some see it as a chance to make money by kidnapping or selling
        orphaned children, while others see slavery as a way of saving children from growing up in Ryloth's harsh
        environment. A number of Twi'leks believe that slavery is an efficient way to proliferate their species and
        preserve their culture, as the Twi'leks otherwise have few means of inter-planetary travel.</p>
        <p className="all-indent">Many Twi'leks live as slaves or entertainers and are considered status symbols,
        especially the females of rarer skin hues. Twi'leks that manage to escape from captivity usually turn to a life
        of thievery or prostitution, with both genders making use of their powers of seduction. Most Twi'leks prefer to
        "ride the storm" rather than "defeat it," as the proverb goes, and avoid taking a stand on most issues.</p>
        <br />
    </div>
    <div className="column-md">
        <h2 className="header-bg">Twi'lek Mechanics</h2>
        <h3 className="sub-header-bg">Hit Points</h3>
        <p>8</p>
        <br />
        <h3 className="sub-header-bg">Size</h3>
        <p>Medium</p>
        <br />
        <h3 className="sub-header-bg">Speed</h3>
        <p>8 meters</p>
        <br />
        <h3 className="sub-header-bg">Ability Boosts</h3>
        <p>Charisma</p>
        <p>Dexterity</p>
        <p>Free</p>
        <br />
        <h3 className="sub-header-bg">Ability Flaws</h3>
        <p>Wisdom</p>
        <br />
        <h3 className="sub-header-bg">Languages</h3>
        <p>Basic</p>
        <p>Twi'leki</p>
        <p>Lekku sign language</p>
        <p className="no-indent all-indent">Additional languages equal to your Intelligence (if it's positive).</p>
        <br />
        <h3 className="sub-header-bg">Low-Light Vision</h3>
        <p className="all-indent">You can see in dim light as though it were bright light, so you ignore the concealed condition due to dim light.</p>
        <br />
        <h3 className="sub-header-bg">Subtle and Charming</h3>
        <p className="all-indent">You are trained in your choice of Diplomacy or Deception. You gain the Discrete
        Inquiry skill feat, even if you don't meet the prerequisites.</p>
        <br />
    </div>
    <div className="column-fit">
        <img className="profile-img" src="/profile/twilek.png" alt="" />
    </div>
</div>