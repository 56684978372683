import React from 'react';
import { Link } from 'react-router-dom'

export const FeatAbundantMagic = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Abundant Magic
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Azata</div>
			<div className="trait">Devil</div>
			<div className="trait">Elemental</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/innatemagic">Innate Magic</Link></li>
		</ul>
		<hr />
		<p className="no-indent">You have practiced with your magic to produce a wide variety of effects. Each time you choose an innate spell with a mythic feat, choose an additional spell with the same restrictions, and a maximum spell level one level lower than the spell you chose. You gain that spell as an innate spell, with the same benefits and limitations as the first spell.</p>
	</div>
	<div className="spacer" />
</div>