import React from 'react';
import { Link } from 'react-router-dom';

export const SpellTweaksFocus = () =>
<div>
	<h3 className="header-bg">Focus  Cantrips</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 1st-Level Focus Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/dimensionalhop"><b>Dimensional Hop (con):</b></Link> You teleport a short distance.</li>
	</ul>
	<h3 className="header-bg"> 2nd-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 3rd-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 4th-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 5th-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 6th-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 7th-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 8th-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 9th-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 10th-Level Focus Spells</h3>
	<ul className="unstyled">
	</ul>
</div>