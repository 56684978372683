import React from 'react';

export const FeatSkillMastery = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Skill Expertise
			<div className="spacer" />
			<span className="right">Feat 15</span>
		</h2>
		<div className="traits">
			<div className="trait">General</div>
			<div className="trait">Skill</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites</b> Int 14</li>
		</ul>
		<hr />
		<p className="no-indent">You become a master in the skill of your choice in which you are already an expert.</p>

		<p className="no-indent"><b>Special</b> You can select this feat multiple times, choosing a new skill to become a master in each time.</p>
	</div>
	<div className="col-md info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
		<p>There's so many skill feats, and so many of them are so outrageously specific as to be useless, or do things
			that one could reasonably assume could be done without a <i>feat.</i> There's solutions to that, of
			course, but they usually leave you with even more skill feats you don't know how to spend. Something to
			pad out all of those unused skill feat options (especially for rogues) would be nice.</p>
		<p>Restricting additional skill mastery options to level 15 incentivises players to still take a few
			<i>actual</i> skill feats, and prevents someone from simply becoming a jack of all trades, master of
			everything.</p>
	</div>
	<div className="spacer" />
</div>