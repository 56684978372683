import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellBlastSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Blast Spell
			<div className="spacer" />
			<span className="right">Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Metamagic" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> </li>
		</ul>
		<hr />
		<p className="no-indent">If your next action is to Cast a Spell that targets no more than 1 creature or object, that spell gains an area of a 10-foot burst, a 30-foot cone, or a 60-foot line (your choice.) If you choose a burst, the spell gains a range of 30 feet or its range, whichever is greater. The spell targets all creatures or objects (as appropriate) in the area. If the spell required an attack roll, creatures in the area must instead make a Reflex save.</p>
		<p className="no-indent">This spell requires 2 mythic power points to cast, instead of 1.</p><br />
		<p className="no-indent"><b>Critical Success</b> The target is affected as if you had critically failed your attack roll.</p>
		<p className="no-indent"><b>Success</b> The target is affected as if you had failed your attack roll.</p>
		<p className="no-indent"><b>Failure</b> The target is affected as if you had succeeded on your attack roll.</p>
		<p className="no-indent"><b>Critical Failure</b> The target is affected as if you had critically succeeded on your attack roll.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (7th)</b> You can choose a 15-foot burst, a 45-foot cone or a 90-foot line. If you choose a burst, the spell gains a range of 60-feet or its range, whichever is greater.</li>
			<li><b>Heightened (9th)</b> You can choose a 20-foot burst, a 60-foot cone or a 120-foot line. If you choose a burst, the spell gains a range of 120-feet or its range, whichever is greater.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>