import React from 'react';

export const FeatBeyondMorality = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Beyond Morality
			<div className="spacer" />
			<span className="right">Feat Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Your actions are your own and you do not let the universe define you. You have no alignment and are immune to alignment damage (chaotic, evil, good & lawful) and any effects that would change your alignment. For effects with benefits and penalties that depend on your alignment, you are always treated as having the most favorable alignment. For the purpose of effects that would detect or reveal your alignment, you can choose what type of alignment aura you appear to possess (or none at all). You can change this aura as a free action <div className="freeaction" />.</p>
	</div>
	<div className="spacer" />
</div>