import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellWordofPower = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Word of Power
			<div className="spacer" />
			<span className="right">Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Evocation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Area:</b> 60-foot emanation</li>
			<li><b>Saving Throw:</b> Reflex</li>
		</ul>
		<hr />
		<p className="no-indent">You channel your power into a single word and speak it loudly, which explodes in a wave of energy disrupting all it touches. Creatures and objects in the area take 10d6 damage, ignoring any weakness or resistance, and must make a Reflex save.</p>
		<p className="no-indent">This spell requires 2 Mythic Power Points to cast, instead of 1.</p><br />
		<p className="no-indent"><b>Critical Success</b> The target takes no damage and is stunned 1.</p>
		<p className="no-indent"><b>Success</b> The target takes half damage and is stunned 2.</p>
		<p className="no-indent"><b>Failure</b> The target takes full damage, is stunned 3 and is knocked prone.</p>
		<p className="no-indent"><b>Critical Failure</b> The target takes double damage, is stunned 4 and is knocked prone.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> The damage increases by 2d6.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>