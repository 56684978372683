import React from 'react';
import { Link } from 'react-router-dom'
import { FeatInfoManifestationsOfPower } from './extrainfo/manifestationsofpower';
import { FeatInfoAdvancedManifestations } from './extrainfo/advancedmanifestations';
import { FeatInfoGreaterManifestations } from './extrainfo/greatermanifestations';
export const FeatGreaterManifestations = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Greater Manifestations
			<div className="spacer" />
			<span className="right">Feat Mythic 7</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/manifestpower">Manifest Power</Link>, <Link to="/mythic/feats/advancedmanifestations">Advanced Manifestations</Link></li>
		</ul>
		<hr />
		<p className="no-indent">Your displays of power reach incredible heights. The number of spells you can choose with <i>manifest power</i> increases by an additional 1, and you can choose from the following list of greater manifestations in addition to those available with manifest power and advanced manifestations.</p>
        <br />
        <div className="columns">
  <div className="column-md">
    <FeatInfoManifestationsOfPower />
    <FeatInfoAdvancedManifestations />
    <FeatInfoGreaterManifestations />
  </div>
</div>

	</div>
	<div className="spacer" />
</div>