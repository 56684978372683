import React from 'react';

export const GestaltHome = () => 
<div className="text-container">
	<p>A popular and time-tested method of creating characters that have a higher power than normal in tabletop RPGs is to build them
	as "Gestalt" characters, which means choosing two classes and advancing them both simultaneously. This helps gives characters many
	different challenges, without the pitfalls of simply creating higher level charcters</p>

	<h2 className="header-bg">What is Gestalt?</h2>
	<div className="columns">
		<div className="column-md">
			<p>In Golarion, there are certain individuals who are exceptionally talented, naturally gifted or even chosen by powers
			beyond their understanding. These heroes (or villains) are able to pursue many different skill sets simultaneously and
			balance the needs of each in their daily life.</p>
			<p>These gestalts, as they are called, grow in power very quickly, choosing two classes at every level and gaining the
			best aspects of each. The process is similar to multiclassing, except that gestalts gain the full benefits of each class
			at each level. if the two classes have aspects that overlap (such as hit points per level, skill and saving throw
			proficiencies, and class features common to more than one class), they recieve the better version of the two. Gestalts
			retain all class features that don’t overlap.</p>
		</div>
	</div>
	<h2 className="header-bg">Creating a Gestalt Character</h2>
	<p>A gestalt character receives a second class at character creation, henceforth referred to as the "gestalt class". At each
	level, a character with a gestalt class gains all of the class features, spells, proficiences and special abilities of their
	gestalt class in addition to those granted by their base class. For features that overlap, use the following guidelines:</p>
	<div className="columns">
		<div className="column-md">
			<h3>Key Ability</h3>
			<p>At 1st level, a gestalt gains an ability boost of their choice from among the key abilities of either class (but not
			both). For example, a monk with druid as a gestalt class could choose Dexterity, Strength, or Wisdom for their ability
			boost.</p>
			<br />
			<h3>Hit Points</h3>
			<p>A gestalt gains the greater of their two classes hit point increases at each level. A sorcerer/ranger
			gestalt would gain 10 plus their Constitution modifier (the ranger's hit point increase) at each level.</p>
			<br />
			<h3>Proficiencies</h3>
			<p>For each proficiency, a gestalt gain the higher of the two values in each category. For additional skill proficiencies,
			they recieve the larger pool of their two classes.</p>
			<p>For example, a ranger/rogue would be an expert in Perception (as both classes recieve expert proficiency), an expert in
			Fortitude (the ranger's proficiency), an expert in Reflex and an expert in Will (the rogue's proficiencies). They would be
			trained in Nature, Survival and Stealth (plus any additional skills from their rogue racket), plus a number of additional
			skills equal to 7 plus their Intelligence modifier (the rogue's pool of additional skills). They would be trained in
			martial weapons (as they supercede the rogue's special proficiencies) and medium armor and in both the rogue and ranger
			class DCs</p>
			<h3>General and Ancestry feats and Ability Boosts</h3>
			<p>Several features are common to every class, notably general and ancestry feats, and ability boosts. Gestalts gain a
			general feat at 3rd level, and every 4 levels thereafter, just as a single-classed character would. Similarly, they gain
			an ancestry feat at 1st level and every 4 levels thereafter, and ability boosts at every 5th level. A gestalt character
			gains no additional general feats, ancestry feats or ability boosts unless one of their classes specifically grants one.</p>
			<h3>Skill Feats and Skill Increases</h3>
			<p>Much like general and ancestry feats, gestalts gains skill feats and skill increases at regular intervals. They gain a
			skill feat at every odd level, and a skill increase at every even level. Unlike general feats however, some classes,
			notably the rogue, grant additional skill feats and skill increases. In this case, they gain those additional skill feats
			and skill increases as well. And so a rogue/wizard gestalt would gain both a skill increase and a skill feat at each
			level.</p>
			<h3>Class Feats</h3>
			<p>As an important exception, a gestalt does not gain the normal class feats each class gains at even levels. Instead, a
			gestalt gains a single class feat at each level which can be spent on the class feat list of either class. If a class
			gains any additional class feats, such as a fighter or universalist wizard's first-level bonus feat, those are gained as
			well, but must be picked from the class feat list of the class that granted them.</p>
			<h3>Other Class Features</h3>
			<p>A gestalt gains the class features of both classes. A sorcerer/ranger gestalt would gain a bloodline, sorcerer
			spellcasting and spell repetoir, hunt prey and hunter's edge, in addition to the ranger class feat granted at 1st level.</p>
			<h3>Spellcasting</h3>
			<p>If both classes grant spellcasting, you track your spells known and spells per day separately for each class, just as
			if you had taken a multiclass archetype that grants spellcasting.</p>
			<br />
			<h3>Multiclass Archetypes</h3>
			<p>A Gestalt character is free to choose multiclass archetypes, and you may use any of your class feats to do so, but may
			not choose the multiclass archetype for either of your two classes.</p>
		</div>
		<div className="column-md">
			<p>Below are the class features gained at each level by all gestalt characters</p>
			<h3>Table 3-1: Common Gestalt Class Features</h3>
			<table className="shadow-sm">
				<tr>
					<th className="table-col-sm">Your Level</th>
					<th>Class Features</th>
				</tr>
				<tr>
					<td>1</td>
					<td>Ancestry feat, Class Feat</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Skill Feat, Class Feat</td>
				</tr>	
				<tr>
					<td>3</td>
					<td>General Feat, Skill Increase, Class Feat</td>
				</tr>
				<tr>
					<td>4</td>
					<td>Skill Feat, Class Feat</td>
				</tr>
				<tr>
					<td>5</td>
					<td>Ancestry feat, Skill Increase, Class Feat, Ability Boost</td>
				</tr>	
				<tr>
					<td>6</td>
					<td>Skill Feat, Class Feat</td>
				</tr>
				<tr>
					<td>7</td>
					<td>General Feat, Skill Increase, Class Feat</td>
				</tr>
				<tr>
					<td>8</td>
					<td>Skill Feat, Class Feat</td>
				</tr>
				<tr>
					<td>9</td>
					<td>Ancestry feat, Skill Increase, Class Feat</td>
				</tr>
				<tr>
					<td>10</td>
					<td>Skill Feat, Class Feat, Ability Boost</td>
				</tr>
				<tr>
					<td>11</td>
					<td>General Feat, Skill Increase, Class Feat</td>
				</tr>
				<tr>
					<td>12</td>
					<td>Skill Feat, Class Feat</td>
				</tr>	
				<tr>
					<td>13</td>
					<td>Ancestry feat, Skill Increase, Class Feat</td>
				</tr>	
				<tr>
					<td>14</td>
					<td>Skill Feat, Class Feat</td>
				</tr>
				<tr>
					<td>15</td>
					<td>General Feat, Skill Increase, Class Feat, Ability Boost</td>
				</tr>	
				<tr>
					<td>16</td>
					<td>Skill Feat, Class Feat</td>
				</tr>
				<tr>
					<td>17</td>
					<td>Ancestry feat, Skill Increase, Class Feat</td>
				</tr>
				<tr>
					<td>18</td>
					<td>Skill Feat, Class Feat</td>
				</tr>
				<tr>
					<td>19</td>
					<td>General Feat, Skill Increase, Class Feat</td>
				</tr>
				<tr>
					<td>20</td>
					<td>Skill Feat, Class Feat, Ability Boost</td>
				</tr>
			</table>
		</div>
	</div>
</div>