import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellCreateWater = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Create Water&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=53"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Conjuration</div>
			<div className="trait">Water</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, divine, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Range</b> 0 feet</li>
		</ul>
		<hr />
		<p className="no-indent">As you cup your hands, water begins to flow forth from them. You create <span className="tweak-color">up to 4</span> gallons of water. If no one drinks it, it evaporates after 1 day.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+1)</b> You can create 4 additional gallons of water.</span></p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>The demotion from cantrip was already a pretty huge hit to Create Water, though probably a necessary one, to keep some players from solving all their problems by drowning the dungeon. It could stand to have some of it's strength and level scaling back though, if only to make the occasional downtime situation where it's important less annoying. A heightening effect does the job pretty well, though.</p>
	</div>
	<div className="spacer" />
</div>