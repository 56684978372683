import React from 'react';
import freeaction from '../../../resources/free-action.png';
import { Trait } from '../../extra/linkables';

export const SpellBurstofSpeed = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Burst of Speed
			<div className="spacer" />
			<span className="right">Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Rush" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="Free Action" src={freeaction} /> </li>
			<li><b>Frequency:</b> once per turn</li>
		</ul>
		<hr />
		<p className="no-indent">You perform an action which has the <a href="https://2e.aonprd.com/Traits.aspx?ID=114">move</a> trait and costs no more than one action. This action doesn't trigger reactions, such as Attacks of Opportunity, that can be triggered by move actions or upon leaving or entering a square.</p>
	</div>
	<div className="spacer" />
</div>