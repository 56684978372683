import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellIridescentBeam = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Iridescent Beam
			<div className="spacer" />
			<span className="right">Spell 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Evocation</div>
			<div className="trait">Light</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Area</b> 120-foot line</li>
			<li><b>Saving Throw</b> basic Reflex</li>
		</ul>
		<hr />
		<p className="no-indent">A multicolored beam of light shoots from your hand, briefly illuminating the area around it and dealing 10d6 damage of a random type. Each creature in the area must roll 1d8 to determine the type of damage that affects it (fire, acid, electricity, poison, cold, mental, or force), rerolling any results of 8.</p>
		<hr />
			<p className="no-indent"><b>Heightened (+1)</b> The damage increases by 2d6.</p>
	</div>
	<div className="spacer" />
</div>