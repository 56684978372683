import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellShieldofLife = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Shield of Life
			<div className="spacer" />
			<span className="right">Mythic Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Abjuration" /></div>
			<div className="trait"><Trait name="Cantrip" /></div>
			<div className="trait"><Trait name="Good" /></div>
			<div className="trait"><Trait name="Healing" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Necromancy" /></div>
			<div className="trait"><Trait name="Positive" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> somatic</li>
			<li><b>Area:</b> 30-foot emanation centered on you</li>
			<li><b>Duration:</b> 1 round</li>
		</ul>
		<hr />
		<p className="no-indent">You gain an angelic aura to protect and heal those around you. Healing spells gain a +2 status bonus to any fast healing or Hit Points healed to you and your allies in the area and a +2 status bonus to any positive damage dealt to undead creatures in the area.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> The status bonus to healing and damage increases by 2.</li>
			<li><b>Heightened (5th)</b> You can cast this spell as a free action. It retains the somatic component.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>