import React from 'react';

export const FeatAmazingReflexes = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Amazing Reflexes
			<div className="spacer" />
			<span className="right">Feat Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You move with impossible speed and precision, able to react to a variety of different threats. At the start of each of your turns when you regain your actions, you gain an additional reaction.</p>
	</div>
	<div className="spacer" />
</div>