import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellQuickeningPresence = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Quickening Presence
			<div className="spacer" />
			<span className="right">Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Aura" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Radiance" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Area:</b> 60-foot emanation</li>
			<li><b>Duration:</b> 8 hours</li>
		</ul>
		<hr />
		<p className="no-indent">You emanate an aura of mythic power that speeds up your allies. You and all allies in the area gain a +30-foot bonus to your travel Speed, and are quickened. You can use the extra action each round to Step, Stride, or use another form of movement (such as Fly or Swim).</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (4th)</b> Allies who are non-mythic or are below mythic rank 4 can use the extra action for any purpose.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>