import React from 'react';
import { Link } from 'react-router-dom'
import { Spell } from '../../../extra/linkables';

export const FeatInfoAdvancedLegendaryItem = () =>
<div className='no-indent all-indent'>
	<h2 className="header-bg shadow-sm">Advanced Legendary Item Special Abilities</h2>
	<br />

	<p className='hanging-indent'><b><u>Burrowing</u></b>: Your item gains a burrow Speed of 5 feet, allowing it to dig
		tunnels of its size. Your item must have the <i>animated</i> ability to select this.</p>

	<p className='hanging-indent'><b><u>Dedicated Bond</u></b>: Dedicated Bond The item can't be used by anyone not
		bonded with it. You are automatically bonded with it. Other creatures can't access the item's special abilities,
		magical properties and runes or mythic power. Using a special ritual that takes 10 minutes, you can designate a
		mythic creature to transfer the bond to, or specify criteria for a mythic creature who can bond the item. During
		the ritual, you can sequester the item in an object at least twice as large as the item (such as putting a sword
		in a stone). If you do, the item becomes impossible to remove by any creature not bonded to it except with
		exceptionally pwoerful magic, such as a wish spell cast by a creature with a higher mythic rank than you. You
		can perform the ritual again to transfer the bond back to yourself regardless of who it is bonded to.</p>
	<p>When the designated creature or a creature who meets the given criteria touches the item, they bond it
		automatically unless they already possess a legendary item. If the bonded creature is not at least mythic rank
		4, the item does not gain access to the abilities chosen with Advanced Legendary Item. If the bonded creature is
		not at least mythic rank 7, the item does not gain access to any abilities chosen
		with <Link to="/mythic/feats/greaterlegendaryitem">Greater Legendary Item</Link>. For each Legendary Item feat
		the bonded creature qualifies for, but has not taken, their pool of Mythic Power Points is reduced by 2.</p>
	<p>You always know the location of your legendary item (even if it is on another plane), the name of the creature
		bonded to it, and the status of the item and the bonded creature, as the
		spell <i><a href="https://2e.aonprd.com/Spells.aspx?ID=308">status</a></i>.</p>

	<p className='hanging-indent'><b><u>Flying</u></b>: The item gains a fly Speed of 30 feet and can move around
		independent of it's partner. If the item is flying in it's partner's square, it is considered worn by it's
		partner, granting them any appropriate magical effects as
		an <a href="https://2e.aonprd.com/Equipment.aspx?ID=407">aeon stone</a> would. If the item is large enough to
		sit on, it's partner or anyone the item deems appropriate can ride the item, using one hand to guide it.
		Alternatively, the item can guide itself. The item takes a status penalty equal to half its fly Speed if laden
		with more than 20 bulk, and falls to the ground if it carries more than 30 bulk. Your item must have
		the <i>animated</i> ability to select this.</p>

	<p className='hanging-indent'><b><u>Improved Telepathy</u></b>: Your item's telepathic abilities improve, allowing
		it to communicate telepathically with any creature it can see within 100 feet, as long as they share a
		language.</p>

	<p className='hanging-indent'><b><u>Legendary Spell</u></b>: Choose a spell with a spell level equal to your mythic
		rank or less. The item gains the following activation:</p>
	<p><b>Activate</b> Cast a Spell; <b>Frequency</b> varies; <b>Effect</b> You Cast the Spell at
		a spell level equal to your mythic rank.</p>
	<p>If the spell is a cantrip or the spell's original spell level is at least four levels lower
		than your mythic rank, the frequency is at-will. Otherwise, the frequency is three times per day.</p>
	<p>If the item has the Legendary Surge ability, you can spend 1 Mythic Power Point from the item's pool to gain the
		benefits of the <Link to="/mythic/spells/ascendspell">Ascend Spell</Link> Mythic Spell when casting the
		spell.</p>

	<p className='hanging-indent'><b><u>Magical Boon</u></b>: Choose a spell of 3rd level or lower with a duration of 10
		minutes or greater. The item's partner gains the effect of the spell as a constant innate spell as long as they
		are holding or wearing the item. The spell is
		automatically heightened to a spell level equal to your mythic rank. If a heightening benefit would
		reduce the duration of the spell to less than 10 minutes, the item's partner does not gain that benefit, but the
		effective level of the spell still increases. If the spell is counteracted or otherwise ends, the partner must
		reactivate it to gain the benefit again. This is an <a href="https://2e.aonprd.com/Actions.aspx?ID=100">Activate
		an Item</a> activity, which takes a number of actions equal to the number required to cast the spell.</p>
	<p>If you are bonded to the item through the <i>dedicated bond</i> ability, you do not need to hold or wear the item
		to gain the benefits of the spell. You must be able to touch the item to reactivate the effect if it ends or is
		counteracted.</p>

	<p className='hanging-indent'><b><u>Manual Dexterity</u></b>: Your legendary item can form up to two limbs and use
		them as if they were hands to perform manipulate actions. Your item must have the <i>animated</i> ability to
		select this.</p>

	<p className='hanging-indent'><b><u>Rejuvenating</u></b>: As a two action activity that has the concentrate trait,
		you or your legendary item can expend a point from your item's pool of Mythic Power Points to heal and
		rejuvenate you. This restores hit points equal to 10 times your mythic rank, and reduces the value of any
		clumsy, doomed, drained, enfeebled, or stupefied condition by 1.</p>

	<p className='hanging-indent'><b><u>Shapechanging</u></b>: You can use a single action with the manipulate trait to
		change your item into other forms of a similar bulk, allowing it to serve as different types of weapons and
		tools or be worn in different locations. If the item has any magic runes or specific magical abilities, they
		only function if the item's current form meets their requirements. If the item ever loses this ability, it
		reverts back to it's original form.</p>

	<p className='hanging-indent'><b><u>Share Senses</u></b>: You can use a single action with the concentrate trait to
		project your senses into your legendary item. When you do, you lose all sensory information from your own body,
		but can sense through your legendary item's senses for up to 1 minute. You can Dismiss this effect.</p>

	<p className='hanging-indent'><b><u>Independent</u></b>: In an encounter, if you don't Command your item, it still
		gains 1 action each round. Typically, you still decide how it spends that action, but, the GM might determine
		that your item chooses its own tactics rather than performing your preferred action. This doesn't work with
		abilities that require a command, if you're capable of riding your item, or similar situations. Your item must
		have the <i>animated</i> ability to select this.</p>

	<p className='hanging-indent'><b><u>Summon Item</u></b>: You can use a single action with the concentrate trait to
		teleport your legendary item into your hand from its personal pocket dimension or from anywhere in the same
		plane of existence, or to dismiss it, storing it in its personal pocket dimension. The item teleports to you
		even if it is being held or worn by another creature.</p>
	<p>The item's pocket dimension is a small extradminsional space just large enough to accomodate the item. No
		other creature can enter this extradimensional space, and the item can bring along other objects only if it can
		serve as a container and the objects are stored within it. While the item is in this extradimensional space, it
		can see though it's partner's senses and communicate with them telepathically, and the item's partner is
		considered to be wearing the item for the purpose of spells and effects.</p>
	<p>If you have the <Link to="/mythic/feats/greaterlegendaryitem">Greater Legendary Item</Link> feat, you can
		teleport the item into your hand regardless of what plane it is on.</p>

	<p className='hanging-indent'><b><u>Undetectable</u></b>: Your item gains the following activation:</p>
	<p> <b>Activate</b> <div className="twoaction" /> envision, Interact; <b>Effect</b> You gain the effects of
		the <Spell name='invisibility' /> and <Spell name='nondetection' /> spells, cast at a level equal to
		your mythic rank. <i>Invisibility</i> does not gain it's 4th level heightening benefit. These spells have a
		duration of 1 hour, and you can Dismiss both spells. If one spell ends or is counteracted, both spells end. Both
		spells end immediately if you are no longer holding or wearing the item.</p>
	<p>If the item has the <i>legendary surge</i> ability, you or it can spend 1 Mythic Power Point from the item's
		pool when activating the item to grant <i>nondetection</i> it's mythic heightening benefit.</p>
	<p>If you are bonded to the item through the <i>dedicated bond</i> ability, the spells do not end if you are not
		holding or wearing the item.</p>
	<br />
</div>