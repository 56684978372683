import React from 'react';
import freetrigger from '../../../../resources/free-action.png';

export const PowerAlterFate = () =>
<div className="text-container">
	<div className="columns">
		<div className="column-full">
			<h2 className="headerbar header-bg shadow-sm">Alter Fate
				<div className="spacer" />
				<span className="right">Power 1</span>
			</h2>
			<div className="traits">
				<div className="trait">Force</div>
				<div className="trait">Fortune</div>
			</div>
			<ul className="proplist">
				<li><b>Tradition</b> limited</li>
				<li><b>Cast</b> <img className="small-icon" alt="Triggered Free Action" src={freetrigger} /></li>
				<li><b>Trigger</b> You roll any check.</li>
			</ul>
			<hr />
			<p className="no-indent all-indent">Reroll the triggering check. You must use the second result, even if it's worse. This is a fortune effect.</p>
		</div>
	</div>
</div>