import React from 'react';
import action from '../../../../resources/1-action.png';

export const SpellRayofFrost = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Ray of Frost&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=245"> </a>
			<div className="spacer" />
			<span className="right">Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Attack</div>
			<div className="trait">Cantrip</div>
			<div className="trait">Cold</div>
			<div className="trait">Evocation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /> (<span className="tweak-color">somatic</span>)</li>
			<li><b>Range</b> 120 feet; <b>Targets:</b> 1 creature <span className="tweak-color">or object</span></li>
		</ul>
		<hr />
		<p className="no-indent">You blast an icy ray. Make a spell attack roll. The ray deals cold damage equal to 1d4 + your spellcasting ability modifier.</p><br />
		<p className="no-indent"><b>Critical Success</b> The target takes double damage and takes a &ndash;10-foot status penalty to its Speeds for 1 round.</p>
		<p className="no-indent"><b>Success</b> The target takes normal damage.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+2)</b></span> The damage increases by 1d4.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Same treatment as the other attack cantrips, 1 action cast time, lower damage scaling. Its still a bit underwhelming, but the range combined with the slowing effect makes up for it.</p>
	</div>
	<div className="spacer" />
</div>