import React from 'react';
import action from '../../../../resources/1-action.png';

export const SpellTelekineticProjectile = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Telekinetic Projectile&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=334"> </a>
			<div className="spacer" />
			<span className="right">Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Attack</div>
			<div className="trait">Cantrip</div>
			<div className="trait">Evocation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /> (<span className="tweak-color">somatic</span>)</li>
			<li><b>Range</b> 30 feet; <b>Targets:</b> 1 creature <span className="tweak-color">or object</span></li>
		</ul>
		<hr />
		<p className="no-indent">You hurl a loose, unattended object that is within range and that has 1 Bulk or less at the target. Make a spell attack roll against the target. If you hit, you deal bludgeoning, piercing, or slashing damage&mdash;as appropriate for the object you hurled&mdash;equal to 1d6 plus your spellcasting ability modifier <span className="tweak-color">(double damage on a critical hit). Specific traits or magic properties of the hurled item do not affect the attack or the damage, but the special effects of any precious materials the object is made out of apply.</span></p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+2)</b></span> The damage increases by 1d6.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>A couple of things this spell could use. First, the same changes as the other attack cantrips (reduced to one action cast time, reduced damage scaling.) Second, if a player wants to launch silver arrows at the werewolf, that's a very appropriate cost for the benefit, I say. Let them have the bonus damage.</p>
  		<p>Also, no need for a whole separate section to explain that the attack deals double damage on a critical hit, the same note as the other attack spells is fine.</p>
	</div>
	<div className="spacer" />
</div>