import React from 'react';
import { Link } from 'react-router-dom'

export const FeatInfoGreaterManifestations = () =>
<div>
	<h3>Table: Greater Manifestations</h3>
	<table>
		<thead>
			<tr>
				<th>Spell</th>
				<th>Level</th>
				<th>Power Points</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/spells/finalgift">Final Gift</Link></td>
				<td>7</td>
				<td>3</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/touchofunmaking">Touch of Unmaking</Link></td>
				<td>7</td>
				<td>3</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/rayofannihilation">Ray of Annihilation</Link></td>
				<td>8</td>
				<td>5</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/recreation">Recreation</Link></td>
				<td>9</td>
				<td>3</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/realityrevision">Reality Revision</Link></td>
				<td>10</td>
				<td>5</td>
			</tr>
		</tbody>
	</table>
</div>