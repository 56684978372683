import React from 'react';
import freetrigger from '../../../resources/free-action.png';
import { Trait } from '../../extra/linkables';

export const SpellUnscathed = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Unscathed
			<div className="spacer" />
			<span className="right">Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="Triggered Free Action" src={freetrigger} /> </li>
			<li><b>Trigger:</b> You take damage, are attacked or an effect that requires a saving throw would cause damage, or inflict one of the following conditions: confused, controlled, doomed, drained, dying, fascinated, frightened, immobilized, paralyzed, persistent damage, petrified, prone, slowed, stunned or unconscious.</li>
		</ul>
		<hr />
		<p className="no-indent">If the triggering effect originated from a non-mythic source, you treat the result of the attack as a critical failure, or the result of your saving throw as a critical success, and you take no damage.</p>
		<p className="no-indent">If the triggering effect originated from a mythic source, you treat the result of the attack as one degree of success worse or the result of your saving throw as one degree better. If the triggering effect did not include an attack roll or a saving throw, you gain resistance to all damage against the triggering effect equal to four times your mythic rank.</p>
	</div>
	<div className="spacer" />
</div>