import React from 'react';

export const SW2ePowersUniversal = () =>
<div>
	<h3 className="header-bg"> Basic Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 1st-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 2nd-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 3rd-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 4th-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 5th-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 6th-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 7th-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 8th-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 9th-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 10th-Level Universal Powers</h3>
	<ul className="unstyled">
	</ul>
</div>