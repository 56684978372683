import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellMythicWidenSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Widen Spell
			<div className="spacer" />
			<span className="right">Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Metamagic" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> </li>
		</ul>
		<hr />
		<p className="no-indent">You pour your mythic power into your spell, causing it to spread out and affect a wider area. If the next action you use is to Cast a Spell that has an area of a burst, cone, emanation, or line, increase the area of that spell. Add 10 feet to the radius of a burst or emanation that normally has a radius of 10 feet or smaller, and add 20 feet to a larger burst or emanation. Add 20 feet to length of a cone or line that is normally 15 feet long or smaller, and add 40 feet to the length of a larger cone or line.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+2)</b> Add an additional 5 feet to the radius of a burst or emanation spell that normally has a radius of 10 feet or smaller. Add an additional 10 feet to a larger burst or emanation, or a cone or line spell that is normally 15 feet long or smaller. Add an additional 20 feet to the radius of a larger cone or line.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>