import React from 'react';
import { Link } from 'react-router-dom'
export const DemonMythicFeats = () =>
<div>
	<h2 className="header-bg">Demon Mythic Feats</h2>
	<p className="no-indent">Every mythic rank at which you gain a Demon feat, select one of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm full-width">
		<thead>
			<tr>
				<th className="col-3">Name</th>
				<th className="col-1">Rank</th>
				<th className="col-3">Traits</th>
				<th className="col-3">Prerequisites</th>
				<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You can survive an attack that would have killed you with 1 hit point.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/throwrock">Throw Rock</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>[Elemental] earth element</td>
				<td>You can throw rocks with great force.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/changeshape">Change Shape</Link></td>
				<td className="center">2</td>
				<td>Concentrate, Mythic, Polymorph, Transmutation</td>
				<td>—</td>
				<td>Transform into other creatures.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/exoticshape">Exotic Shape</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Transform into several exotic forms.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/stomp">Stomp</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>[Elemental] earth element</td>
				<td>You can shake the ground, knocking down those around you and creating rough terrain.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/auraofdread">Aura of Dread</Link></td>
				<td className="center">4</td>
				<td>Aura, Emotion, Fear, Mental, Mythic</td>
				<td>—</td>
				<td>Your presence frightens those around you.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/imbuedarms">Imbued Arms</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Imbue your weapons with elemental or alignment damage.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicflight">Mythic Flight</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Elemental] air element; [Fey] airborne form</td>
				<td>You can sprout wings and fly.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/shadowdoubling">Shadow Doubling</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Fey] deep form or highland form</td>
				<td>You can create shadowy duplicates of yourself to aid you.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/shadowstep">Shadow Step</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Fey] deep form or highland form</td>
				<td>You can teleport between shadowy areas.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/titanicrage">Titanic Rage</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Fey] deep form</td>
				<td>You can grow to a terrifying size.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/unscathed">Unscathed</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td>You can shrug off blows and ignore effects that would slow you down.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/truesight">True Sight</Link></td>
				<td className="center">6</td>
				<td>Divination, Mythic, Revelation</td>
				<td><Link to="/mythic/feats/mysticsight">Mystic Sight</Link></td>
				<td>You see things as they actually are.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/indomitable">Indomitable</Link></td>
				<td className="center">8</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link>, <Link to="/mythic/feats/unscathed">Unscathed</Link></td>
				<td>You can continue to activate <Link to="/mythic/feats/unscathed">Unscathed</Link> until your next turn at no additional cost.</td>
			</tr>
		</tbody>
	</table>
</div>
