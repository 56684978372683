import React from 'react';
export const PathoftheAzata = () =>
<div>
	<h2 className="header-bg">Path of the Azata</h2>
	<p>(DESCRIPTION)</p>
	<br />
	<p><b>Hit points:</b> 8 plus your Constitution modifier</p>
	<br />
	<div className="columns">
		<div className="column-md">
			<h2 className="header-bg">Path Abilities</h2>
			<h3 className="sub-header-bg">1st Rank - Ray of Light</h3>
      <p>You gain the ability to produce rays of light from your hands. You can make light ray ranged attacks that have a range of 300 feet and deal 1d6 chaotic damage plus 1d6 good damage for each mythic rank you possess. They have the chaotic, good and light traits.</p>
			<br />
			<h3 className="sub-header-bg">4th Rank - (TITLE)</h3>
      <p>(DESCRIPTION)</p>
			<br />
			<h3 className="sub-header-bg">7th Rank - (TITLE)</h3>
      <p>(DESCRIPTION)</p>
			<br />
			<h3 className="sub-header-bg">10th Rank - (TITLE)</h3>
      <p>(DESCRIPTION)</p>
			<br />
		</div>
		<div className="column-md">
			<h2 className="header-bg">Path Feats</h2>
			<p>The following additional Mythic Feats are available to those who walk the Path of the Azata:</p>
			<div className="sidenav-column">
				<ul>
					<li>feat</li>
					<li>feat</li>
					<li>feat</li>
				</ul>
			</div>
		</div>
	</div>
</div>