import React from 'react';

export const FeatMythicBond = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Bond
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> Animal Companion, Familiar or Eidolon</li>
		</ul>
		<hr />
		<p className="no-indent">Your animal companion, familiar or eidolon gains a mythic rank equal to your mythic rank. It gains all of the abilities of a mythic character except for a mythic path, mythic feats and ability boosts. It does not gain it's own pool of Mythic Power points, but can activate mythic spells and abilities by spending points from your mythic power pool if you allow it. Add your mythic rank to your level for the purpose of calculating hit points for your animal companion, familiar or eidolon.</p>
		<p className="no-indent">If you have more than one animal companion, familiar or eidolon, you must choose one to gain this benefit.</p>
		<p className="no-indent">If your animal companion, familiar or eidolon dies or is otherwise permanently lost or replaced, you may choose a new one to gain this benefit, but the strain of the loss causes you to become clumsy 2, drained 2, and enfeebled 2 and reduces the size of your pool of Mythic Power Points by 2 for one week; these conditions can't be removed or reduced by any means until the week has passed.</p>
		<p className="no-indent"><b>Special:</b> You can select this feat more than once. Each time, choose another animal companion, familiar or eidolon to gain this benefit.</p>
	</div>
	<div className="spacer" />
</div>