import React from 'react';
import { Link } from 'react-router-dom'

export const FeatInfoMythicMetamagic = () =>
<div className="columns">
	<div className="col-sm">
		<h3>Table: Mythic Metamagic Spells</h3>
		<table>
			<thead>
				<tr>
					<th className="col-9">Spell</th>
					<th className="col-2">Level</th>
					<th>Prerequisites</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td><Link to="/mythic/spells/mythicreachspell">Mythic Reach Spell</Link></td>
					<td>2</td>
					<td>—</td>
				</tr>
				<tr>
					<td><Link to="/mythic/spells/mythicwidenspell">Mythic Widen Spell</Link></td>
					<td>2</td>
					<td>—</td>
				</tr>
				<tr>
					<td><Link to="/mythic/spells/energeticspell">Energetic Spell</Link></td>
					<td>2</td>
					<td>—</td>
				</tr>
				<tr>
					<td><Link to="/mythic/spells/precisespell">Precise Spell</Link></td>
					<td>2</td>
					<td>—</td>
				</tr>
				<tr>
					<td><Link to="/mythic/spells/mythicextendspell">Mythic Extend Spell</Link></td>
					<td>3</td>
					<td>—</td>
				</tr>
				<tr>
					<td><Link to="/mythic/spells/sculptspell">Sculpt Spell</Link></td>
					<td>4</td>
					<td>Precise Spell</td>
				</tr>
				<tr>
					<td><Link to="/mythic/spells/mythicquickenspell">Mythic Quicken Spell</Link></td>
					<td>5</td>
					<td>—</td>
				</tr>
				<tr>
					<td><Link to="/mythic/spells/blastspell">Blast Spell</Link></td>
					<td>5</td>
					<td>Mythic Widen Spell</td>
				</tr>
			</tbody>
		</table>
		<br />
	</div>
</div>