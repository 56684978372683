import React from 'react';
import { SpellInfoSculptSpell } from './extrainfo/sculptspell';import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellSculptSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Sculpt Spell
			<div className="spacer" />
			<span className="right">Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Metamagic" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> </li>
		</ul>
		<hr />
		<p className="no-indent">You use your mythic power to sculpt your spell into the desired shape. If the next action you use is to Cast a Spell that has an area of a burst, cone, cylinder, emanation or line, you reshape the area of the spell into a number of contiguous 5-foot cubes based on the size of the spell, as shown on the table below.</p>
        <br />
        <SpellInfoSculptSpell />

	</div>
	<div className="spacer" />
</div>