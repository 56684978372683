import React from 'react';
import { Switch, Route } from 'react-router';
import { Link } from 'react-router-dom';
import { FeatDeadlyHerbalism } from './feats/deadlyherbalism';
import { FeatDimensionalAssault } from './feats/dimensionalassault';
import { FeatFamiliarCompanion } from './feats/familiarcompanion';
import { FeatPotentHerbalism } from './feats/potentherbalism';
import { FeatSkillExpertise } from './feats/skillexpertise';
import { FeatSkillMastery } from './feats/skillmastery';
import { FeatWidenSpell } from './feats/widenspell';

export const FeatTweaks = () =><div>
	<h1>Feat Tweaks</h1>
	<Switch>
		<Route exact path="/contenttweaks/feattweaks/feats/dimensionalassault" component={FeatDimensionalAssault} />
		<Route exact path="/contenttweaks/feattweaks/feats/widenspell" component={FeatWidenSpell} />
		<Route exact path="/contenttweaks/feattweaks/feats/deadlyherbalism" component={FeatDeadlyHerbalism} />
		<Route exact path="/contenttweaks/feattweaks/feats/potentherbalism" component={FeatPotentHerbalism} />
		<Route exact path="/contenttweaks/feattweaks/feats/skillexpertise" component={FeatSkillExpertise} />
		<Route exact path="/contenttweaks/feattweaks/feats/skillmastery" component={FeatSkillMastery} />
		<Route exact path="/contenttweaks/feattweaks/feats/familiarcompanion" component={FeatFamiliarCompanion} />
		<Route exact path="/contenttweaks/feattweaks">
			<p>Here you will find a number of tweaks to existing feats and some conversions of feats and abilities from
                1e with the goal of relieving a lot of the frustration caused by finicky restrictions and missing
                utility. Changes to an existing feat, along with new feats and feats converted from other rulesets
				will <span className="tweak-color">be highlighted.</span></p>
			<br />
            <h3 className="header-bg">All Feats</h3>
            <table className="shadow-sm">
                <tr>
                    <th className="table-col-md">Name</th>
                    <th className="table-col-md">Traits</th>
                    <th className="table-col-sm">Level</th>
                    <th className="table-col-md">Prerequisites</th>
                    <th>Benefits</th>
                </tr>
                <tr>
                    <td><Link to="/contenttweaks/feattweaks/feats/deadlyherbalism">Deadly Herbalism</Link></td>
                    <td>Archetype</td>
                    <td>8</td>
                    <td><a href="https://2e.aonprd.com/Feats.aspx?ID=1982">Herbalist Dedication</a>; <a href="https://2e.aonprd.com/Feats.aspx?ID=1986">Expert Herbalism</a>; expert in Nature</td>
                    <td>You can make deadly poisons as well as healing elixirs.</td>
                </tr>
                <tr>
                    <td><Link to="/contenttweaks/feattweaks/feats/dimensionalassault">Dimensional Assault</Link></td>
                    <td>Magus</td>
                    <td>4</td>
                    <td>—</td>
                    <td>You move gracefully through extradimensional spaces, using them to better position yourself for attacks.</td>
                </tr>
                <tr>
                    <td><Link to="/contenttweaks/feattweaks/feats/familiarcompanion">Familiar-Companion</Link></td>
                    <td>Druid, magus, ranger, witch, wizard</td>
                    <td>4</td>
                    <td>—</td>
                    <td>Transform your animal companion into a familiar.</td>
                </tr>
                <tr>
                    <td><Link to="/contenttweaks/feattweaks/feats/potentherbalism">Potent Herbalism</Link></td>
                    <td>Archetype</td>
                    <td>10</td>
                    <td><a href="https://2e.aonprd.com/Feats.aspx?ID=1982">Herbalist Dedication</a>; <a href="https://2e.aonprd.com/Feats.aspx?ID=1986">Expert Herbalism</a>; master in Nature</td>
                    <td>You can make even stronger herbal elixirs.</td>
                </tr>
                <tr>
                    <td><Link to="/contenttweaks/feattweaks/feats/skillexpertise">Skill Expertise</Link></td>
                    <td>General, skill</td>
                    <td>7</td>
                    <td>Int 12</td>
                    <td>Become an expert in the skill of your choice.</td>
                </tr>
                <tr>
                    <td><Link to="/contenttweaks/feattweaks/feats/skillmastery">Skill Mastery</Link></td>
                    <td>General, skill</td>
                    <td>15</td>
                    <td>Int 14</td>
                    <td>Become a master in the skill of your choice.</td>
                </tr>
                <tr>
                    <td><Link to="/contenttweaks/feattweaks/feats/widenspell">Widen Spell</Link></td>
                    <td>Druid, manipulate, metamagic, oracle, sorcerer, witch, wizard</td>
                    <td>1</td>
                    <td>—</td>
                    <td>You manipulate the energy of your spell, causing it to spread out and affect a wider area.</td>
                </tr>
            </table>
		</Route>
	</Switch>
</div>

