import React from 'react';

export const FeatTelepathy = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Telepathy
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You gain telepathy, allowing you to communcate telepathically with creatures you can touch. At 3rd rank, you can communicate telepathically with creatures within 30 feet. At 6th rank, the range increases to 100 feet.</p>
	</div>
	<div className="spacer" />
</div>