import React from 'react';

export const FeatSneakAttack = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Sneak Attack
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scoundrel</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> any Scoundrel specialization feat</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">When your enemy canâ€™t properly defend itself, you take advantage to deal extra damage. If you Strike a creature that has the flat-footed condition with an agile or finesse melee weapon, an agile or finesse unarmed attack, or a ranged weapon attack, you deal an extra 1d6 precision damage. For a ranged attack with a thrown melee weapon, that weapon must also be agile or finesse.</p><br />
</div>