import React from 'react';
import { Link } from 'react-router-dom'
export const SW2eArchTableTechSpecialist = () =>
<div>
	<h2 className="header-bg">Fundamental Tech Specialist Feats</h2>
	<p className="all-indent">Each time you gain a level, you can select one or more of the following feats. You must satisfy any prerequisites before taking the feat. Fundamental feats provide essential abilities and improve your proficiencies. They must often be taken to progress in an archetype.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link></td>
				<td>1</td>
				<td>Archetype, Scoundrel, Tech Specialist</td>
				<td><Link to="/sw2e/feats/advancedskilltraining">Advanced Skill Training</Link></td>
				<td>You gain a skill feat and a skill increase at every level.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/chemicalengineering">Chemical Engineering</Link></td>
				<td>1</td>
				<td>Archetype, Tech Specialist</td>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Will saves, trained in <Link to="/sw2e/skills/crafting">Crafting</Link></td>
				<td>You can create consumable items at a moment's notice to suit your needs.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/custommodifications">Custom Modifications</Link></td>
				<td>1</td>
				<td>Archetype, Tech Specialist</td>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Will saves, trained in <Link to="/sw2e/skills/crafting">Crafting</Link></td>
				<td>You make custom modifications to your equipment, adding upgrades at little to no cost.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/droidcompanion">Droid Companion</Link></td>
				<td>1</td>
				<td>Archetype, Tech Specialist</td>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Will saves, trained in <Link to="/sw2e/skills/crafting">Crafting</Link></td>
				<td>You gain the service of a basic droid companion that travels with you.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/greatfortitude">Great Fortitude</Link></td>
				<td>5</td>
				<td>Archetype, Noble, Scoundrel, Tech Specialist</td>
				<td>—</td>
				<td>Your Fortitude save proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/lightningreflexes">Lightning Reflexes</Link></td>
				<td>5</td>
				<td>Archetype, Noble, Scoundrel, Scout, Soldier, Tech Specialist</td>
				<td>—</td>
				<td>Your Reflex save proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/techspecialistweaponexpertise">Weapon Expertise</Link></td>
				<td>7</td>
				<td>Archetype, Tech Specialist</td>
				<td>Expert Will and Fortitude save proficiency, at least 4 total Tech Specialist feats,</td>
				<td>Your weapon proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/techspecialistabilityexpertise">Ability Expertise</Link></td>
				<td>8</td>
				<td>Archetype, Tech Specialist</td>
				<td>Expert proficiency with any type of weapon, at least 5 total Tech Specialist feats,</td>
				<td>Your ability DC proficiency increases to expert.</td>
			</tr>
		</tbody>
	</table>
	<br />
	<h2 className="header-bg">Tech Specialist Feats</h2>
	<p className="all-indent">Each time you gain a level, you can also select one or more of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/grenadier">Grenadier</Link></td>
				<td>1</td>
				<td>Archetype, Tech Specialist</td>
				<td><Link to="/sw2e/feats/chemicalengineer">Chemical Engineer</Link></td>
				<td>When you throw a grenade, the range increment is increased by 10 feet.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/overcharge">Overcharge</Link></td>
				<td>1</td>
				<td>Archetype, Tech Specialist</td>
				<td><Link to="/sw2e/feats/custommodifications">Custom Modifications</Link></td>
				<td>Overcharge your weapon, causing it to deal additional damage for a short time.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/quickrepairs">Quick Repairs</Link></td>
				<td>1</td>
				<td>Archetype, Tech Specialist</td>
				<td>At least 2 total Tech Specialist feats.</td>
				<td>You make quick repairs to a droid or vehicle in the field.</td>
			</tr>
		</tbody>
	</table>
</div>
