import React from 'react';
import { Trait } from '../../extra/linkables';

export const SpellRecreation = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Recreation
			<div className="spacer" />
			<span className="right">Mythic 9</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Conjuration" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> 1 hour material, somatic, verbal</li>
			<li><b>Range:</b> 5 feet</li>
		</ul>
		<hr />
		<p className="no-indent">You turn back time, to re-create a destroyed object from nothing. This functions like <i><a href="https://2e.aonprd.com/Spells.aspx?ID=249">remake</a></i>, except that it does not require a material component, it can re-create an object as large as a 20-foot cube, and functions regardless of the item's level, or even if the object is an artifact. If the object has a level over 20, you must succeed on a spellcasting check (typically either your spellcasting proficiency + your spellcasting ability modifier, or your class DC - 10) to re-create the object. The DC is usually a standard-difficulty DC of the item's level, but the GM can assign a different DC based on the circumstances. Consumables that were used up rather than destroyed cannot be re-created.</p>
		<p className="no-indent">This spell requires 3 Mythic Power Points to cast, instead of 1.</p>
	</div>
	<div className="spacer" />
</div>