import React from 'react';
import action from '../../../../resources/1-action.png';

export const FeatWidenSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Widen Spell&nbsp;<img className="small-icon" alt="1 Action" src={action} />&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Feats.aspx?ID=315"> </a>
			<div className="spacer" />
			<span className="right">Feat 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Druid</div>
			<div className="trait">Manipulate</div>
			<div className="trait">Metamagic</div>
			<div className="trait">Oracle</div>
			<div className="trait">Sorcerer</div>
			<div className="trait">Witch</div>
			<div className="trait">Wizard</div>
		</div>
		<hr />
		<p className="no-indent">You manipulate the energy of your spell, causing it to spread out and affect a
		wider area. If the next action you use is to Cast a Spell that has an area of a burst, cone<span className="tweak-color">, emanation</span> or line and
		does not have a duration, increase the area of that spell. Add 5 feet to the radius of a burst <span className="tweak-color">or emanation</span> that normally
		has a radius of <span className="tweak-color">10 feet or smaller, and add 10 feet to the radius of a larger
		burst or emanation.</span> Add <span className="tweak-color">10</span> feet to the length of a cone or line that is normally 15 feet long or
		smaller, and add <span className="tweak-color">20</span> feet to the length of a larger cone or line.</p>
	</div>
	<div className="col-md info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
		<p>A lot of the metamagics are disappointing to use, but Widen Spell especially. Situations where widening a
			spell could be worth an action come up so rarely that it starts to feel like a wasted feat. So we've
			increased the effect, and opened it up to those countless (and usually very disappointing) 5-foot radius
			bursts that 2e seems to have.</p>
	</div>
	<div className="spacer" />
</div>