import React from 'react';
import { Route } from 'react-router-dom';
import { SW2eArchTableScoundrel } from './tables/scoundreltable';

export const SW2eArchetypeScoundrel = () =>
<div className="text-container all-indent">
    <h2 className="header-bg">Scoundrel</h2>
    <p>.</p>
    <br />
    <Route component={SW2eArchTableScoundrel} />
    <br />
</div>