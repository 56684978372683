import React from 'react';

export const FeatPotentHerbalism = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Potent Herbalism
			<div className="spacer" />
			<span className="right">Feat 10</span>
		</h2>
		<div className="traits">
			<div className="trait">Archetype</div>
		</div>
		<ul className="proplist">
			<li><b>Archetype</b> <a href="https://2e.aonprd.com/Archetypes.aspx?ID=62">Herbalist</a></li>
			<li><b>Prerequisites</b> <a href="https://2e.aonprd.com/Feats.aspx?ID=1982">Herbalist Dedication</a>; <a href="https://2e.aonprd.com/Feats.aspx?ID=1986">Expert Herbalism</a>; master in Nature</li>
		</ul>
		<hr />
		<p className="no-indent">Your advanced alchemy level for herbalism increases to your level. When you select this feat, you
			immediately add the formulas for two common alchemical items of your new advanced alchemy level or lower
			to your formula book.</p>
	</div>
	<div className="col-md info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
		<p>This is such a cool archetype. It could really use a bit more support for druids and witches who want to go all-in on the herbal remedies.</p>
	</div>
	<div className="spacer" />
</div>