import React from 'react';
import { Link } from 'react-router-dom'

export const FeatTwinDistraction = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Twin Distraction
		<div className="spacer" />
		<span className="right">Feat 5</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scoundrel</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/twinfeint">Twin Feint</Link>, at least 4 total Scoundrel feats</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Your movements with two weapons are so swift and disorienting that they befuddle your opponent. When using <Link to="/sw2e/feats/doublestrike">Double Strike</Link>, if both your Strikes deal damage to a targeted creature, the target must succeed at a Will saving throw against your class DC or become stupefied 1 until the end of your next turn.</p><br />
</div>