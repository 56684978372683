import React from 'react';

export const FeatMythicRitualist = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Ritualist
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> expert in Arcana, Nature, Occultism, or Religion</li>
		</ul>
		<hr />
		<p className="no-indent">Your mythic power allows you to perform difficult rituals with great ease. Add your mythic rank to your level when determining the maximum level of ritual you can perform. You gain a circumstance bonus to all primary checks to perform a ritual equal to half your mythic rank rounded up.</p>
	</div>
	<div className="spacer" />
</div>