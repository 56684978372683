import React from 'react';
import { Link } from 'react-router-dom'
import { Spell } from './linkables';

export const AbilityHeader: React.FC<{name: string, level?: string}> = ({name, level}) =>
<h3 className="headerbar sub-header-bg">{name}<div className="spacer" /><span className="right">{level}</span></h3>

export const HardToKill = () =>
<div>
    <AbilityHeader name="Hard to Kill" level="Rank 1" />
    <p>You become particularly hard to kill. You do not die until your condition reaches dying 6, or dying 7 if you have
        the <a href="https://2e.aonprd.com/Feats.aspx?ID=774">Diehard</a> feat or a similar ability. Your DC
        for <a href="https://2e.aonprd.com/Rules.aspx?ID=375">recovery checks</a> is equal to 5 + your current dying
        value.</p>
</div>

export const MythicPower = () =>
<div>
    <AbilityHeader name="Mythic Power" level="Rank 1" />
    <p>A mythic character has a wellspring of power that they can draw upon to accomplish amazing deeds and cheat
        fate. You gain a pool of 5 Mythic Power Points, which can be used to activate mythic spells in the same
        way Focus Points are used to activate focus spells. This pool increases by 2 points at each mythic rank
        beyond 1st. You replenish all the Mythic Power Points in your pool during your daily preparations.</p>
</div>

export const MythicSurge = () =>
<div>
    <AbilityHeader name="Mythic Surge" level="Rank 1" />
    <p>You can call upon your mythic power to overcome difficult challenges. You gain
        the <Spell name="mythic surge" /> mythic spell.</p>
</div>

export const UniversalMythicFeats = () =>
<div>
    <AbilityHeader name="Universal Mythic Feats" level="Rank 1" />
    <p>Your mythic power manifests in a variety of ways. Each time you gain a mythic rank, you gain
        a <Link to="/mythic/feats/universal">universal mythic feat</Link>.</p>
</div>

export const Recuperation = () =>
<div>
    <AbilityHeader name="Recuperation" level="Rank 2" />
    <p>When you get a full-night's rest, you regain all of your Hit Points, and if a condition, such
        as drained, would decrease in value, it decreases by an additional 1. In addition, by expending 1 Mythic
        Power Point and resting for 1 hour, you regain all of your Hit Points and refresh your spells, Focus
        Points and other abilities as if you had just finished your daily preparations for the first time. You
        do not regain Mythic Power Points or uses of any mythic abilities that are limited to a number of times
        per day.</p>
</div>

export const AbilityBoosts = () =>
<div>
    <AbilityHeader name="Ability Boosts" level="Rank 3" />
    <p>At 3rd rank and every 3 ranks thereafter, you boost four different ability scores. You can use these ability
        boosts to increase your ability scores above 18. Boosting an ability score increases it by 1 if it's already 18
        or above, or by 2 if it starts out below 18.</p>
</div>

export const AmazingSpeed = () =>
<div>
    <AbilityHeader name="Amazing Speed" level="Rank 4" />
    <p>You can move and act supernatually fast. You gain 1 additional action at the start of your turn each round. This
        action stacks with any gained from the quickened condition.</p>
</div>

export const ForceOfWill = () =>
<div>
    <AbilityHeader name="Force of Will" level="Rank 5" />
    <p>You can call upon your mythic power to influence the fate of others. You gain
        the <i><Link to="/mythic/spells/forceofwill">force of will</Link></i> Mythic Spell.</p>
</div>

export const Unstoppable = () =>
<div>
    <AbilityHeader name="Unstoppable" level="Rank 7" />
    <p>What can stop mere mortals in their tracks is only a mild inconvenience to you. You gain
        the <i><Link to="/mythic/spells/unstoppableresolve">unstoppable resolve</Link></i> Mythic Spell.</p>
</div>

export const Immortal = () =>
<div>
    <AbilityHeader name="Immortal" level="Rank 9" />
    <p>If you die, you return to life 24 hours later, regardless of the condition of your body,
        unless you are killed by an artifact, divine being, or something of similar power. When you return to life, you
        have full Hit Points and the same spells prepared and points in your pools as you had when you died. At
        10th rank, you can be permanently killed only by a diety, or an artifact of at least 20th level.</p>
</div>

export const LegendaryHero = () =>
<div>
    <AbilityHeader name="Legendary Hero" level="Rank 10" />
    <p>You have reached the height of mythic power. Manifesting your abilities comes as easily to you as breathing. You
        can spend Focus Points to activate mythic spells and mythic abilities instead of Mythic Power Points. If you
        don't already have one, you gain a focus pool of 1 Focus Point.</p>
</div>