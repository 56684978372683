import React from 'react';
import { Link } from 'react-router-dom'

export const FeatInfoManifestationsOfPower = () =>
<div>
	<h3>Table: Manifestations of Power</h3>
	<table>
		<thead>
			<tr>
				<th>Spell</th>
				<th>Level</th>
				<th>Power Points</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/spells/featofagility">Feat of Agility</Link></td>
				<td>1</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/featofendurance">Feat of Endurance</Link></td>
				<td>1</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/featofstrength">Feat of Strength</Link></td>
				<td>1</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/suddenbrilliance">Sudden Brilliance</Link></td>
				<td>1</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/suddeninsight">Sudden Insight</Link></td>
				<td>1</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/suddengrandeur">Sudden Grandeur</Link></td>
				<td>1</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/blowback">Blowback</Link></td>
				<td>2</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/devestatingsmash">Devestating Smash</Link></td>
				<td>2</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/projectforce">Project Force</Link></td>
				<td>2</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/quickeningpresence">Quickening Presence</Link></td>
				<td>2</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/shelteringpresence">Sheltering Presence</Link></td>
				<td>2</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/commandthemindless">Command the Mindless</Link></td>
				<td>3</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/invigoratingpresence">Invigorating Presence</Link></td>
				<td>3</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/sevenleaguesleap">Seven-Leagues Leap</Link></td>
				<td>3</td>
				<td>1</td>
			</tr>
		</tbody>
	</table>
	<br />
</div>