import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellHymnofGlory = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Hymn of Glory
			<div className="spacer" />
			<span className="right">Mythic Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Cantrip" /></div>
			<div className="trait"><Trait name="Composition" /></div>
			<div className="trait"><Trait name="Emotion" /></div>
			<div className="trait"><Trait name="Enchantment" /></div>
			<div className="trait"><Trait name="Mental" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> verbal</li>
			<li><b>Area:</b> 30-foot emanation centered on you</li>
			<li><b>Duration:</b> 1 round</li>
		</ul>
		<hr />
		<p className="no-indent">You sing a sacred hymn, filling those who hear it with strength. You and all allies in the area gain a +1 status bonus to attack rolls and damage rolls and a +1 status bonus to AC and all saves against sonic and auditory effects. Enemies in the area take a -1 status penalty to AC and all saves against sonic and auditory effects.</p>
		<p className="no-indent">If you are accompanied by at least one allied <a href="https://2e.aonprd.com/Monsters.aspx?ID=14">choir angel</a> or other creature and are affected by their harmonizing aura, a beneficial <a href="https://2e.aonprd.com/Traits.aspx?ID=31">composition</a>, or a similar ability (at GM's discretion), these bonuses increases by +1 and the penalties increase by -1. For each such creature accompanying you, the area of the spell increases by 10-feet.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (5th)</b> You can cast this spell as a free action. It retains the verbal component.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>