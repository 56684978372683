import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Feat, Ritual, Spell } from '../../extra/linkables';
import { PathFeaturesTable, PathTitleDescrip } from '../../extra/pathtemplates';
import {
	AbilityHeader, AmazingSpeed, ForceOfWill, HardToKill, Immortal, LegendaryHero, MythicPower, MythicSurge,
	Recuperation, UniversalMythicFeats, Unstoppable
} from '../../extra/sharedabilities';
import { StarsMythicFeats } from './feats/stars';
import { StarsPathRoute } from './routes/stars';

const starsFeatures: { [index: number]: string[] } = {
	1: ["envisoning", "star magic"],
    2: ["astral resistance (resistance 5)", "stars feat"],
    3: ["stellar hardiness"],
    4: ["stars feat"],
    5: ["astral resistance (resistance 10)", "astral traveler"],
    6: ["stars feat"],
    7: ["fold space"],
    8: ["astral resistance (resistance 15)", "stars feat"],
    9: ["cosmic ascension"],
    10: ["astral resistance (resistance 20)", "stars feat"],
}

const description: string = `Some are compelled always to look upwards, drawn to the infinite beauty of the stars from
	which all life originated. Likewise, in that night sky dwells many creatures who travel this vast expanse, using the
	fillaments of power that weave through the very fabric of reality to create, destroy, and explore. Unfathomable wonders
	and horrors exist within this endless place, but some would trade a lifetime of effort to seek them out.`

export const PathoftheStars = () =>
<div>
	<StarsPathRoute />
	<Switch>
		<Route exact path="/mythic/paths/stars">
	<PathTitleDescrip name="Path of the Stars" description={description} hp={8} />
	
	<h2 className="header-bg">Path Features</h2>
	<br />
	<div className="columns">
		<div className="col-md left-col">
			<PathFeaturesTable pathFeatures={starsFeatures}/>
			<br />
			<AbilityHeader name="Envisioning" level="Rank 1" />
			<p>You gain the <Feat name="telepathy" /> mythic feat. When you communicate telepathically, you do so
				wordlessly through imagery, concepts and psychic impressions, allowing you to communicate with any
				creature regardless of whether or not you share a language. This does not make creatures any more
				friendly or willing to communicate than normal, nor does it allow creatures with limited intelligence to
				understand complex concepts.</p>
			<br />
			<HardToKill />
			<br />
			<AbilityHeader name="Star Magic" level="Rank 1" />
			<p>Your mythic power expresses itself as a variety of innate spells common to creatures who travel the
				stars. You gain the <Feat name="innate magic" /> mythic feat. In addition
				add <Spell name="dancing lights" /> and your choice
				of <Spell name="disrupt undead" />, <Spell name="ray of frost" />,
				or <Spell name="telekinetic projectile" /> to the list of spells you can cast with innate magic.</p>
			<br />
			<MythicPower />
			<br />
			<MythicSurge />
			<br />
			<UniversalMythicFeats />
			<br />
			<AbilityHeader name="Astral Resistance" level="Rank 2" />
			<p>Your body begins to take on some of the qualities of those who travel the stars and planes. You gain a +2
				circumstance bonus on saves against death effects and resistance 5 to cold and mental damage.</p>
			<br />
			<Recuperation />
			<br />
			<AbilityHeader name="Stars Feats" level="Rank 2" />
			<p>At 2nd rank and every even rank, you gain a <Link to="/mythic/paths/stars/feats">stars path feat</Link>.</p>
			<br />
		</div>
		<div className="col-md right-col">
			<AbilityHeader name="Stellar Hardiness" level="Rank 3" />
			<p>Your body becomes attuned to the void of space and the inner planes. You no longer need to breath and
				aren't affected by severe and extreme heat or cold, the vacuum of space, stellar radiation and other
				common hazards found in the interstellar void. If you have the <Feat name="mythic sustenance" /> feat,
				you can immediately swap it out for a universal mythic feat of your choice, as if you had retrained it.</p>
			<br />
			<AmazingSpeed />
			<br />
			<AbilityHeader name="Astral Traveler" level="Rank 5" />
			<p>When you sleep, you dream of distance places and travel there. When you are unconscious and above 0 Hit
				Points, you can project your spiritual essence to other places. This has the effects of
				an <Ritual name="astral projection" /> ritual, but you can choose to project your spiritual essence to
				either the <a href="https://2e.aonprd.com/Planes.aspx?ID=8">Astral Plane</a> or your current location
				and the effect ends immediate if you wake up (as if you had Dismissed it).</p>
			<p>When it is not on the Astral Plane, your astral form is invisible and incorporeal, cannot cast spells
				or use manipulate actions, and moves at half your normal Speeds, but can move in any direction
				(including up and down). When you encounter a creature who is unconscious and dreaming while in your
				astral form, you can enter their dream. While in another person's dream, you can see and communicate
				with one another, but cannot harm each other.</p>
			<br />
			<ForceOfWill />
			<br />
			<AbilityHeader name="Fold Space" level="Rank 7" />
			<p>You become highly sensitive to alternative paths through space that can be used to move around. When you
				Stride, you can choose to teleport up to your Speed instead of moving. When you do, the action does not
				have the move trait and you arrive at your destination instantly and silently. You can use this ability
				when Climbing, Flying, or Swimming instead of Striding if you have the corresponding movement type.</p>
			<br />
			<Unstoppable />
			<br />
			<Immortal />
			<br />
			<AbilityHeader name="Cosmic Ascension" level="Rank 9" />
			<p>You become a being of pure cosmic energy, and your body a window to the vibrant, star-filled tapestry of
				the universe. You cease aging, and gain the <a href="https://2e.aonprd.com/Traits.aspx?ID=14">Astral
				</a> trait, immunity to death effects and
				disease, and the Change Shape ability (detailed below).</p>
				<p>You can cast <Spell name="plane shift" /> as an innate spell at will, heightended to your mythic
				rank. It has the same tradition you chose with <Feat name="innate magic" />. When you travel to
				the <a href="https://2e.aonprd.com/Planes.aspx?ID=8">Astral Plane</a> or
				the <a href="https://2e.aonprd.com/Planes.aspx?ID=1">Material Plane</a>,
				you can act as the spell focus and do not require a tuning fork.</p>
			<div className="indent">
				<br />
				<h3 className="headerbar header-bg short-margin">Change Shape&nbsp;<div className="action" /></h3>
				<div className="traits">
					<div className="trait">Concentrate</div>
					<div className="trait">Mythic</div>
					<div className="trait">Polymorph</div>
					<div className="trait">Transmutation</div>
				</div>
				<hr />
				<p>You transform into a specific alternate form which is your original form, or your cosmic form. Your
					cosmic form appears to be a striking, humanoid cosmic nebula, a piece of the night sky in a humanoid
					shape or something similar. Your cosmic form can be any size from Tiny to Gargantuan, but you gain
					no benefit from changing your size other than changing the space you occupy, unless you change to
					Tiny size, in which case, your reach also changes to 0 feet. You can change to a different form, or
					change your cosmic form to a different size by using this action again.</p>
			</div>
			<br />
			<LegendaryHero />
		</div>
	</div>
		</Route>
		<Route path="/mythic/paths/stars/feats" component={StarsMythicFeats}/>
	</Switch>
</div>