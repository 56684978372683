import React from 'react';
import { Link } from 'react-router-dom'

export const FeatChemicalEngineering = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Chemical Engineering
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Will saves, trained in <Link to="/sw2e/skills/crafting">Crafting</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You understand complex chemical formulas and reactions and can use them to assemble the tools you need at a moment's notice.</p><br />
	<p className="all-indent no-indent justify">You gain the <Link to="/sw2e/feats/chemicalsynthesis">Chemical Synthesis</Link> feat, even if you don't meet that feat's prerequisites, and you gain two common 1st-level consumable item blueprints in addition to those granted by that feat. Consumable Items can be found <Link to="/sw2e/equipment/consumables">here</Link>.</p><br />
	<p className="all-indent no-indent justify">You also maintain a stock of assorted reagents and materials which you can use to create temporary consumable items. You gain the Quick Synthesis action, which you can use a number of times per day equal to your level + your Intelligence.</p><br />
	<br />
<h3 className="sub-header-bg all-indent">Quick Synthesis <div className="threeaction" /></h3> <p className="no-indent all-indent"><b>Requirements:</b> You have the blueprint for the item you're creating, and you're either holding or wearing chemist's tools, or are at an appropriate workstation.</p> <hr /> <p className="no-indent all-indent">You swiftly assemble a single-use device or chemical concoction. You create a single consumable item of your level or lower for which you have a blueprint without having to spend the normal monetary cost in materials or needing to attempt a <Link to="/sw2e/skills/crafting">Crafting</Link> check. This item remains funtional for 24 hours or until your next daily preparations, whichever comes first. The item has a clearly makeshift nature and cannot normally be sold.</p>
<br />
</div>