import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellFierceRegeneration = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Fierce Regeneration
			<div className="spacer" />
			<span className="right">Mythic 6</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Healing" /></div>
			<div className="trait"><Trait name="Necromancy" /></div>
			<div className="trait"><Trait name="Positive" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Duration:</b> 1 minute</li>
		</ul>
		<hr />
		<p className="no-indent">You heal continuously for a short time. You gain regeneration 20, which restores 20 Hit Points to you at the start of each of your turns. While you have regeneration, you can't die from damage or from losing a vital body part (even your head) and your dying condition can't increase to a value that would kill you (this stops most mythic creatures from going beyond dying 5), though this does not prevent you from falling unconscious if your <a href="https://2e.aonprd.com/Conditions.aspx?ID=42">wounded</a> value becomes high enough.</p>
		<p className="no-indent">Each time you regain Hit Points from regeneration, you also regrows one damaged or ruined organ, or missing body part (if any). During the spell's duration, the creature can also reattach severed body parts by spending an Interact action to hold the body part to the area it was severed from.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+2)</b> The regeneration increases by 10.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>