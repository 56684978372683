import React from 'react';

export const FeatToughness = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Toughness
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">General</div>
	</div>
	<hr />
	<p className="all-indent no-indent justify">You can take a hit and keep going. Increase your hitpoints per level to 8 plus your Consitution. If you are above 1st level, you gain these hitpoints retroactively.</p><br />
</div>