import React from 'react';
import { Trait } from '../../extra/linkables';

export const SpellSevenLeaguesLeap = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Seven-Leagues Leap
			<div className="spacer" />
			<span className="right">Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> 2 minutes </li>
		</ul>
		<hr />
		<p className="no-indent">After running in a straight line for 1 minute, you Leap horizontally. You can Leap up to a distance of 9 miles as an activity that takes 1 minute. This movement is not precise. You land roughly 50 feet from your intended destination for every mile you travel, in a direction determined by the GM.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> Increase the maximum distance of the Leap by 3 mile.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>