import React from 'react';
import action from '../../../../resources/1-action.png';

export const SpellAcidSplash = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Acid Splash&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=3"> </a>
			<div className="spacer" />
			<span className="right">Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Attack</div>
			<div className="trait">Cantrip</div>
			<div className="trait">Evocation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /> (<span className="tweak-color">somatic</span>)</li>
			<li><b>Range</b> 30 feet; <b>Targets:</b> 1 creature <span className="tweak-color">or object</span></li>
		</ul>
		<hr />
		<p className="no-indent">You splash a glob of acid that splatters creatures and objects alike. Make a spell attack roll. If you hit, you deal 1d6 acid damage plus 1 splash acid damage. On a critical success, the target also takes 1 persistent acid damage.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+2)</b> The damage increases by 1d6, the splash damage increases by 1, and the persistent damage on a critical hit increases by 1.</span></p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Its very underwhelming for the casting time, especially when compared to other cantrips, and especially at higher spell levels. Need to at least give wizards a reason to not just use a crossbow. Reducing the attack cantrips generally to 1 action makes them much more flexible. Fortunately, acid splash already has damage low enough to justify not lowering it to compensate.</p>
  		<p>The heightening really does need to be simplified though, while we're here. Better to remove the attribute bonus and give it nice, linear scaling. The splash & persistent damage can be a worthwhile substitute.</p>
	</div>
	<div className="spacer" />
</div>