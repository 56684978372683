import React from 'react';
import { Spell } from '../../extra/linkables';

export const TraitMythic
 = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic</h2>
		<p className="no-indent">A radiance is a mythic spell that gives you a beneficial aura. You can cast only one
		radiance spell each turn, and you can have only one active at a time. If you cast a new radiance spell, any
		ongoing effects from your previous radiance spell end immediately.</p>
		<br />
		<br />
		<h3 className="headerbar sub-header-bg">Spells</h3>
		<Spell name="Invigorating Presence" title={true} />, <Spell name="Quickening Presence" title={true} />,&nbsp;
		<Spell name="Sheltering Presence" title={true} />
	</div>
	<div className="spacer" />
</div>