import React from 'react';
import { Link } from 'react-router-dom'
export const AllMythicFeats = () =>
<div>
	<h2 className="header-bg">Mythic Feats</h2>
	<p className="no-indent">Every time you gain a mythic feat, select one of the following feats. You must satisfy any prerequisites before taking the feat. Some feats are avilable only to characters with specific mythic paths.</p>
	<br />
	<table className="shadow-sm">
		<thead>
			<tr>
				<th className="col-3">Name</th>
				<th className="col-1">Rank</th>
				<th className="col-5">Traits</th>
				<th className="col-5">Prerequisites</th>
				<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/feats/aptitude">Aptitude</Link></td>
				<td className="center">1</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You gain many exceptional skills.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/incredibleclimber">Incredible Climber</Link></td>
				<td className="center">1</td>
				<td>Mythic, Dragon, Elemental, Enlightenment, Fey, Universal</td>
				<td>[Dragon] copper form or white form; [Fey] woodland form</td>
				<td>You gain a climb speed.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/incredibleswimmer">Incredible Swimmer</Link></td>
				<td className="center">1</td>
				<td>Mythic, Dragon, Elemental, Enlightenment, Fey, Universal</td>
				<td>[Dragon] black form, bronze form, green form or gold form; [Fey] aquatic form</td>
				<td>You gain a swim speed.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/infinitevariety">Infinite Variety</Link></td>
				<td className="center">1</td>
				<td>Mythic, Fey, Universal</td>
				<td>—</td>
				<td>Gain perplexing abilities from countless sources.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td className="center">1</td>
				<td>Mythic, Azata, Devil, Dragon, Elemental, Fey, Shadow, Stars, Undead, Universal</td>
				<td>—</td>
				<td>You gain innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/legendaryitem">Legendary Item</Link></td>
				<td className="center">1</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You gain a legendary item.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/manifestpower">Manifest Power</Link></td>
				<td className="center">1</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You can create dramatic effects by expending mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td className="center">1</td>
				<td>Mythic, Angel, Archon, Demon, Dragon, Elemental, Enlightenment, Titan, Shadow, Undead, Universal</td>
				<td>—</td>
				<td>You can survive an attack that would have killed you with 1 hit point.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicbond">Mythic Bond</Link></td>
				<td className="center">1</td>
				<td>Mythic, Universal</td>
				<td>Animal Companion, Familiar or Eidolon</td>
				<td>You grant your animal companion, familiar or eidolon mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythiccraft">Mythic Craft</Link></td>
				<td className="center">1</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You can craft mythic items, and items with a higher item level.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicsustenance">Mythic Sustenance</Link></td>
				<td className="center">1</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You no longer need to eat, drink, sleep or breathe.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/piercethedarkness">Pierce the Darkness</Link></td>
				<td className="center">1</td>
				<td>Mythic, Archon, Angel, Devil, Dragon, Enlightenment, Shadow, Stars, Undead, Universal</td>
				<td>—</td>
				<td>You gain greater darkvision.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/telepathy">Telepathy</Link></td>
				<td className="center">1</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You gain the ability to communicate telepathically.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/throwrock">Throw Rock</Link></td>
				<td className="center">1</td>
				<td>Mythic, Archon, Demon, Dragon, Elemental, Titan</td>
				<td>[Elemental] earth element</td>
				<td>You can throw rocks with great force.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/abundantmagic">Abundant Magic</Link></td>
				<td className="center">2</td>
				<td>Mythic, Azata, Devil, Elemental, Fey, Shadow, Stars, Undead</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td>You gain a wider variety of innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/changeshape">Change Shape</Link></td>
				<td className="center">2</td>
				<td>Concentrate, Mythic, Polymorph, Transmutation, Archon, Angel, Azata, Demon, Devil, Dragon, Fey</td>
				<td>—</td>
				<td>Transform into other creatures.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/communewithpower">Commune with Power</Link></td>
				<td className="center">2</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You can seek answers from the source of your power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/exoticshape">Exotic Shape</Link></td>
				<td className="center">2</td>
				<td>Mythic, Archon, Angel, Azata, Demon, Devil, Dragon, Fey</td>
				<td>—</td>
				<td>Transform into several exotic forms.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mysticsight">Mystic Sight</Link></td>
				<td className="center">2</td>
				<td>Detection, Divination, Mythic, Angel, Enlightenment, Shadow, Stars, Undead, Universal</td>
				<td>—</td>
				<td>You can see magic.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicmetamagic">Mythic Metamagic</Link></td>
				<td className="center">2</td>
				<td>Mythic, Metamagic, Azata, Devil, Fey, Shadow, Stars, Undead, Universal</td>
				<td>Ability to cast spells</td>
				<td>You manipulate your spells in powerful and unexpected ways.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/mythicritualist">Mythic Ritualist</Link></td>
				<td className="center">2</td>
				<td>Mythic, Universal</td>
				<td>expert in Arcana, Nature, Occultism, or Religion</td>
				<td>You perform rituals with great ease.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/perfectself">Perfect Self</Link></td>
				<td className="center">2</td>
				<td>Mythic, Angel, Archon, Azata, Enlightenment, Stars, Titan</td>
				<td>—</td>
				<td>Time and disease have no hold over you and your body will not fail you.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/stomp">Stomp</Link></td>
				<td className="center">2</td>
				<td>Mythic, Demon, Dragon, Elemental, Titan</td>
				<td>[Elemental] earth element</td>
				<td>You can shake the ground, knocking down those around you and creating rough terrain.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/amazingreflexes">Amazing Reflexes</Link></td>
				<td className="center">3</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You gain an additional reaction each round.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/ascendantspell">Ascendant Spell</Link></td>
				<td className="center">3</td>
				<td>Mythic, Metamagic, Universal</td>
				<td>—</td>
				<td>You can infuse a spell with mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/extramythicpower">Extra Mythic Power</Link></td>
				<td className="center">3</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You can access a deep and enduring wellspring of power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/magicsurge">Magic Surge</Link></td>
				<td className="center">3</td>
				<td>Mythic, Fey, Shadow, Undead, Universal</td>
				<td>Ability to cast spells</td>
				<td>You can cast a powerful version of any spell you know.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicanimalcompanion">Mythic Animal Companion</Link></td>
				<td className="center">3</td>
				<td>Mythic, Universal</td>
				<td>Animal Companion, <Link to="/mythic/feats/mythicbond">Mythic Bond</Link></td>
				<td>Your animal companion becomes stronger and gains it's own pool of mythic power points.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythiccohort">Mythic Cohort</Link></td>
				<td className="center">3</td>
				<td>Mythic, Angel, Archon, Devil, Universal</td>
				<td>—</td>
				<td>You grant a trusted individual access to your mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicteamwork">Mythic Teamwork</Link></td>
				<td className="center">3</td>
				<td>Mythic, Universal</td>
				<td><Link to="/mythic/feats/mythicbond">Mythic Bond</Link> or <Link to="/mythic/feats/mythiccohort">Mythic Cohort</Link></td>
				<td>You grant your animal companion, familiar, eidolon or mythic cohort access to your mythic feats.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td className="center">4</td>
				<td>Mythic, Azata, Devil, Fey, Shadow, Stars, Undead, Universal</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td>You gain more innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/advancedlegendaryitem">Advanced Legendary Item</Link></td>
				<td className="center">4</td>
				<td>Mythic, Universal</td>
				<td><Link to="/mythic/feats/legendaryitem">Legendary Item</Link></td>
				<td>Your legendary item becomes an artifact and can have up to 8 abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/advancedmanifestations">Advanced Manifestations</Link></td>
				<td className="center">4</td>
				<td>Mythic, Universal</td>
				<td><Link to="/mythic/feats/manifestpower">Manifest Power</Link></td>
				<td>You can create even more incredible effects by expending mythic power.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/auraofdread">Aura of Dread</Link></td>
				<td className="center">4</td>
				<td>Aura, Emotion, Fear, Mental, Mythic, Angel, Archon, Demon, Devil, Dragon, Fey, Shadow</td>
				<td>—</td>
				<td>Your presence frightens those around you.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/expandedinfluence">Expanded Influence</Link></td>
				<td className="center">4</td>
				<td>Mythic, Angel, Archon, Azata, Devil, Dragon, Enlightenment, Rakshasa, Titan</td>
				<td>—</td>
				<td>Your auras and abilities reach further.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/flawlessstrike">Flawless Strike</Link></td>
				<td className="center">4</td>
				<td>Mythic, Angel, Archon, Devil, Enlightenment, Shadow, Stars, Titan</td>
				<td>—</td>
				<td>When you concentrate your power, you strike without error.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/imbuedarms">Imbued Arms</Link></td>
				<td className="center">4</td>
				<td>Mythic, Angel, Archon, Azata, Demon, Devil, Dragon, Elemental, Enlightenment, Stars</td>
				<td>—</td>
				<td>Imbue your weapons with elemental or alignment damage.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/impossiblespeed">Impossible Speed</Link></td>
				<td className="center">4</td>
				<td>Mythic, Universal</td>
				<td>[Elemental] air element, fire element or water element</td>
				<td>Your Speed increases by 20 feet.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/metamixing">Metamixing</Link></td>
				<td className="center">4</td>
				<td>Mythic, Fey, Shadow, Stars, Undead</td>
				<td>Any two actions with the metamagic trait</td>
				<td>You can apply two metamagics to a spell at once.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicflight">Mythic Flight</Link></td>
				<td className="center">4</td>
				<td>Mythic, Angel, Azata, Demon, Devil, Dragon, Elemental, Fey, Stars</td>
				<td>[Elemental] air element; [Fey] airborne form</td>
				<td>You can sprout wings and fly.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/powerfuldigger">Powerful Digger</Link></td>
				<td className="center">4</td>
				<td>Mythic, Dragon, Elemental, Fey</td>
				<td>[Dragon] blue form or brass form; [Elemental] earth element; [Fey] deep form</td>
				<td>You grow claws suitable for digging.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/shadowdoubling">Shadow Doubling</Link></td>
				<td className="center">4</td>
				<td>Mythic, Demon, Fey, Rakshasa, Shadow</td>
				<td>[Fey] deep form or highland form</td>
				<td>You can create shadowy duplicates of yourself to aid you.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/shadowstep">Shadow Step</Link></td>
				<td className="center">4</td>
				<td>Mythic, Demon, Fey, Rakshasa, Shadow, Undead</td>
				<td>[Fey] deep form or highland form</td>
				<td>You can teleport between shadowy areas.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/signaturespell">Signature Spell</Link></td>
				<td className="center">4</td>
				<td>Mythic, Universal</td>
				<td>Ability to cast a non-cantrip spell</td>
				<td>You can cast a chosen spell regularly.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/spirittouch">Spirit Touch</Link></td>
				<td className="center">4</td>
				<td>Mythic, Enlightenment, Stars, Undead</td>
				<td>—</td>
				<td>Gain the ability to attack the spirit and harm incorporeal creatures.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/titanicrage">Titanic Rage</Link></td>
				<td className="center">4</td>
				<td>Mythic, Archon, Demon, Dragon, Elemental, Fey, Titan</td>
				<td>[Fey] deep form</td>
				<td>You can grow to a terrifying size.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/unscathed">Unscathed</Link></td>
				<td className="center">4</td>
				<td>Mythic, Angel, Archon, Demon, Dragon, Elemental, Enlightenment, Shadow, Titan, Undead</td>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td>You can shrug off blows and ignore effects that would slow you down.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/beyondmorality">Beyond Morality</Link></td>
				<td className="center">5</td>
				<td>Mythic, Universal</td>
				<td>—</td>
				<td>You have no alignment and can ignore alignment-based effects.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/direspellcraft">Dire Spellcraft</Link></td>
				<td className="center">5</td>
				<td>Mythic, Azata, Devil, Fey, Shadow, Stars, Undead</td>
				<td><Link to="/mythic/feats/ascendspell">Ascend Spell</Link></td>
				<td>You can infuse your spells with even more mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/persistentmagic">Persistent Magic</Link></td>
				<td className="center">5</td>
				<td>Mythic, Archon, Devil, Shadow, Stars, Undead, Universal</td>
				<td><Link to="/mythic/feats/mythicmetamagic">Mythic Metamagic</Link>, <Link to="/mythic/spells/mythicextendspell">Mythic Extend Spell</Link></td>
				<td>You can anchor a spell with mythic power to make it last indefinitely.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/accursedspellcraft">Accursed Spellcraft</Link></td>
				<td className="center">6</td>
				<td>Mythic, Devil, Fey, Rakshasa, Shadow</td>
				<td>—</td>
				<td>You understand curses and enchantments like no other.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/impossibleprecision">Impossible Precision</Link></td>
				<td className="center">6</td>
				<td>Mythic, Angel, Archon, Devil, Dragon, Enlightenment, Stars, Titan</td>
				<td>—</td>
				<td>Your follow up attacks are just as deadly as the first.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/truesight">True Sight</Link></td>
				<td className="center">6</td>
				<td>Divination, Mythic, Revelation, Angel, Archon, Azata, Demon, Daemon, Devil, Enlightenment, Universal</td>
				<td><Link to="/mythic/feats/mysticsight">Mystic Sight</Link></td>
				<td>You see things as they actually are.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/yournamehere">Your Name Here</Link></td>
				<td className="center">6</td>
				<td>Mythic, Devil, Fey, Rakshasa, Shadow</td>
				<td>—</td>
				<td>You trade in names, memories, and abilities as easily as coins.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/greaterinnatemagic">Greater Innate Magic</Link></td>
				<td className="center">7</td>
				<td>Mythic, Azata, Devil, Fey, Shadow, Stars, Undead, Universal</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td>You gain even more innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/greaterlegendaryitem">Greater Legendary Item</Link></td>
				<td className="center">7</td>
				<td>Mythic, Universal</td>
				<td><Link to="/mythic/feats/legendaryitem">Legendary Item</Link>, <Link to="/mythic/feats/advancedlegendaryitem">Advanced Legendary Item</Link></td>
				<td>Your legendary item becomes even stronger, and can have up to 9 abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/greatermanifestations">Greater Manifestations</Link></td>
				<td className="center">7</td>
				<td>Mythic, Universal</td>
				<td><Link to="/mythic/feats/manifestpower">Manifest Power</Link>, <Link to="/mythic/feats/advancedmanifestations">Advanced Manifestations</Link></td>
				<td>You can create reality-bending effects by expending mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/indomitable">Indomitable</Link></td>
				<td className="center">8</td>
				<td>Mythic, Archon, Demon, Dragon, Elemental, Titan</td>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link>, <Link to="/mythic/feats/unscathed">Unscathed</Link></td>
				<td>You can continue to activate <Link to="/mythic/feats/unscathed">Unscathed</Link> until your next turn at no additional cost.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/titanicleap">Titanic Leap</Link></td>
				<td className="center">8</td>
				<td>Earth, Evocation, Mythic, Elemental, Titan</td>
				<td><Link to="/mythic/feats/stomp">Stomp</Link>; [Elemental] earth element</td>
				<td>Leap forward and shatter the ground where you land.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/godslayer">Godslayer</Link></td>
				<td className="center">10</td>
				<td>Mythic, Titan</td>
				<td>—</td>
				<td>You can sever creature's connections to the divine.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/irrepressible">Irrepressible</Link></td>
				<td className="center">10</td>
				<td>Mythic, Titan</td>
				<td>—</td>
				<td>Despite your size, nothing can hold you down.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/peerlessinnatemagic">Peerless Innate Magic</Link></td>
				<td className="center">10</td>
				<td>Mythic, Fey, Rakshasa, Universal</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td>You gain even more innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/relentless">Relentless</Link></td>
				<td className="center">10</td>
				<td>Mythic, Titan</td>
				<td>—</td>
				<td>You are ever-moving and indefatiguable.</td>
			</tr>
		</tbody>
	</table>
</div>
