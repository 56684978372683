import React from 'react';
import freetrigger from '../../../resources/free-action.png';
import { Trait } from '../../extra/linkables';

export const SpellFeatofEndurance = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Feat of Endurance
			<div className="spacer" />
			<span className="right">Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="Triggered Free Action" src={freetrigger} /> </li>
			<li><b>Trigger:</b> You make a Constitution-based check that does not have the Attack trait.</li>
		</ul>
		<hr />
		<p className="no-indent">Your body hardens as you manifest your mythic power, allowing you to endure effects that would have killed much hardier creatures. You treat the result of the triggering check as one degree of success higher.</p>
	</div>
	<div className="spacer" />
</div>