import React from 'react';
export const PathofEnlightenment = () =>
<div>
	<h2 className="header-bg">Path of Enlightenment</h2>
	<p>(DESCRIPTION)</p>
	<br />
	<p><b>Hit points:</b> 10 plus your Constitution modifier</p>
	<br />
	<div className="columns">
		<div className="column-md">
			<h2 className="header-bg">Path Abilities</h2>
			<h3 className="sub-header-bg">1st Rank - Focused Mind</h3>
      <p>The path to self perfection begins with the mind. Through your mythic power, yours becomes swift and sturdy. You need only spend 1 minute refocusing to gain the benefits of the Refocus action and can use the Sustain a Spell action once per turn as a free action.</p>
      <p>Additionally, when you roll a saving throw against a spell or effect with the mental trait, you can roll twice and choose the better result.</p>
			<br />
			<h3 className="sub-header-bg">4th Rank - Focused Body</h3>
      <p>(DESCRIPTION)</p>
      <p>You gain the Perfect Self and Mythic Sustenance mythic feats. For each of these feats you already possess, you gain a mythic feat of your choice.</p>
      <p>Additionally, you gain resistance 3 to all damage, regardless of type.</p>
			<br />
			<h3 className="sub-header-bg">7th Rank - (TITLE)</h3>
      <p>(DESCRIPTION)</p>
      <p>Additionally your resistance to damage increases to 5</p>
			<br />
			<h3 className="sub-header-bg">10th Rank - (TITLE)</h3>
      <p>(DESCRIPTION)</p>
      <p>Additionally your resistance to damage increases to 10</p>
			<br />
		</div>
		<div className="column-md">
			<h2 className="header-bg">Path Feats</h2>
			<p>The following additional Mythic Feats are available to those who walk the Path of Enlightenment:</p>
			<div className="sidenav-column">
				<ul>
					<li>feat</li>
					<li>feat</li>
					<li>feat</li>
				</ul>
			</div>
		</div>
	</div>
</div>