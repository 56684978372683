import React from 'react';

export const SW2eSpeciesUgnaught = () => 
<div className="columns">
    <div className="column-md">
        <h2 className="header-bg">Ugnaught</h2>
        <h3 className="sub-header-bg">Physical Description</h3>
        <p className="all-indent">Ugnaughts are porcine humanoids of relatively short statue that are native to the
        planet Gentes. They have skin in varying shades of pink, upturned noses, white hair, and thick layers of jowls.
        Some also have short tusks. They are known for being strong and resilient, and fairly long-lived. They reach
        maturity at a relatively early age of 16, and can live for well beyond 200 standard years. Ugnaughts average 1.3
        meters in height.</p>
        <br />
        <h3 className="sub-header-bg">Society</h3>
        <p className="all-indent">Ugnaught government consists of a relatively complex heirarchy of "terend" councils,
        led by "ufflor" officers who represent them in guild, planetary and galactic parliments. Individuals typically
        live in accordance with their "blood profession;" a trade taught to them by their parents. If the number of new
        Ugnaughts in a given profession exceeded the number needed, a "blood duel" was called. When the Ugnaughts
        reached their twentieth year of age, these fights were held to the death, with the victor winning the right to
        inherit their blood profession. Despite this outdated and violent custom, the Ugnaughts were generally a
        peaceful people with a rich culture.</p>
        <p className="all-indent">Like many races, Ugnaughts find themselves scattered throughout the galaxy due to
        slavery. While capable of war, when faced with overwhelming circumstances, Ugnaughts are prone to accept them
        and begin patiently and stubbornly working their way out of the situation, or simply outliving their enemies.</p>
        <br />
    </div>
    <div className="column-md">
        <h2 className="header-bg">Ugnaught Mechanics</h2>
        <h3 className="sub-header-bg">Hit Points</h3>
        <p>10</p>
        <br />
        <h3 className="sub-header-bg">Size</h3>
        <p>Medium</p>
        <br />
        <h3 className="sub-header-bg">Speed</h3>
        <p>6 meters</p>
        <br />
        <h3 className="sub-header-bg">Ability Boosts</h3>
        <p>Intelligence</p>
        <p>Constitution</p>
        <p>Free</p>
        <br />
        <h3 className="sub-header-bg">Ability Flaws</h3>
        <p>Charisma</p>
        <br />
        <h3 className="sub-header-bg">Languages</h3>
        <p>Basic</p>
        <p>Ugnaught</p>
        <p className="no-indent all-indent">Additional languages equal to your Intelligence (if it's positive).</p>
        <br />
        <h3 className="sub-header-bg">Low-Light Vision</h3>
        <p className="all-indent">You can see in dim light as though it were bright light, so you ignore the concealed
        condition due to dim light.</p>
        <br />
        <h3 className="sub-header-bg">Hardy</h3>
        <p className="all-indent">Ugnaughts are particularly hardy, and are well suited to harsh environments. You gain
        a +2 circumstance bonus on saving throws against afflictions, temperature and weather effects, and any effect
        which would cause fatigue.</p>
        <br />
        <h3 className="sub-header-bg">Dedication</h3>
        <p className="all-indent">Ugnaughts are trained from a young age in their parent's trade and are known for their
        exceptional skill and craftsmanship. You become an expert in one skill of your choice which you were untrained,
        and gain one skill feat that lists the chosen skill as a prerequisite, even if you don't meet the level
        requirement. You must still meet any other requirements. This choice can be made at any point during character
        creation.</p>
        <br />
    </div>
    <div className="column-fit">
        <img className="profile-img" src="/profile/ugnaught.png" alt="" />
    </div>
</div>