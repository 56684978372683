import React from 'react';
import threeaction from '../../../../resources/3-actions.png';

export const SpellBestowCurse = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Bestow Curse&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://www.aonprd.com/SpellDisplay.aspx?ItemName=Bestow%20Curse"> </a>
			<div className="spacer" />
			<span className="right">Spell 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Curse</div>
			<div className="trait">Necromancy</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, divine, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="3 Action" src={threeaction} /> (material, somatic, verbal)</li>
			<li><b>Range</b> 30 feet</li>
			<li><b>Saving Throw</b> varies; <b>Duration</b> varies</li>
		</ul>
		<hr />
		<p className="no-indent">You speak a curse, manifesting it upon the chosen creature. Choose a common curse or another curse that you have access to that has a level of 3 or less. <i>Bestow curse</i> gains the effect and traits of the chosen curse. The duration of the effect is determined by the target's saving throw, or the curse's duration, whichever is shorter. The save is of the same type as the curse, but using your spell DC.</p>
		<p className="no-indent">A creature can only have one curse from this spell at a time. If the target has a curse from this spell already, <i>bestow curse</i> attempts to counteract the previous curse. If it fails to counteract the previous curse, the spell has no effect.</p><br />
		<p className="no-indent"><b>Critical Success</b> The creature is unaffected</p>
		<p className="no-indent"><b>Success</b> The creature is cursed and the duration is 1 round.</p>
		<p className="no-indent"><b>Failure</b> The creature is cursed and the duration is 1 week.</p>
		<p className="no-indent"><b>Critical Failure</b> The creature is cursed and the duration is unlimited.</p>
		<hr />
			<p className="no-indent"><b>Heightened (+1)</b> Increase the maximum level of the curse that you can choose by 2.</p>
	</div>
	<div className="spacer" />
</div>