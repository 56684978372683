import React from 'react';

export const FeatAptitude = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Aptitude
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Add your mythic rank to your level for the purpose of determining the maximum rank you can raise a skill's proficiency to. You gain four skill increases immediately.</p>
		<p className="no-indent">This feat can be taken more than once. Each time you do, you gain four additional skill increases.</p>
	</div>
	<div className="spacer" />
</div>