import React from 'react';
import { FeatInfoLegendaryItem } from './extrainfo/legendaryitem';
import { FeatInfoLegendaryItemSpecial } from './extrainfo/legendaryitemspecial';
export const FeatLegendaryItem = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Legendary Item
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">An item you possess and are able to wear, use, or weild becomes a legendary item. Any runes or magical abilities the item posessed before becoming a legendary item cease functioning. You cannot change an artifact or intelligent item into a legendary item. The item becomes an <a href="https://2e.aonprd.com/Rules.aspx?ID=1084">intelligent item</a> with the abilities detailed below.</p>
        <br />
        <FeatInfoLegendaryItem />

        <br />
        <FeatInfoLegendaryItemSpecial />

	</div>
	<div className="spacer" />
</div>