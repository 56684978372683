import React from 'react';
import { Link, useLocation } from 'react-router-dom'


export interface FeatDetails {
	name: string,
	level: number,
	traits: string[],
	prerequistes: string[],
	description: string[],
	extras:string[]
}

const universalFeats = {

}

export const MythicFeatDetails  = () => {

	const feat = useLocation().pathname.split('/')[3];

	return (
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">{feat}
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Azata</div>
			<div className="trait">Devil</div>
			<div className="trait">Elemental</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/innatemagic">Innate Magic</Link></li>
		</ul>
		<hr />
		<p className="no-indent"></p>
	</div>
	<div className="spacer" />
</div>
	)
}