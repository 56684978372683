import React from 'react';
import { Link } from 'react-router-dom'
import { FeatInfoManifestationsOfPower } from './extrainfo/manifestationsofpower';
import { FeatInfoAdvancedManifestations } from './extrainfo/advancedmanifestations';
export const FeatAdvancedManifestations = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Advanced Manifestations
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/manifestpower">Manifest Power</Link></li>
		</ul>
		<hr />
		<p className="no-indent">Your ability to use your power grows and expands. The number of spells you can choose with Manifest Power increases by 2, and you can choose from the following list of advanced manifestations in addition to those available with manifest power.</p>
        <br />
        <div className="columns">
  <div className="column-md">
    <FeatInfoManifestationsOfPower />
    <FeatInfoAdvancedManifestations />
  </div>
</div>

	</div>
	<div className="spacer" />
</div>