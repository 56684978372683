import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellSpellImmunity = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Spell Immunity&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=296"> </a>
			<div className="spacer" />
			<span className="right">Spell 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Abjuration</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, divine, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Duration</b> 24 hours</li>
		</ul>
		<hr />
		<p className="no-indent">You ward a creature against the effects of a single spell. Choose a spell and name it aloud as part of the verbal component. Spell immunity attempts to <a href="https://2e.aonprd.com/Rules.aspx?ID=371">counteract</a> that spell whenever spell immunity's target is the target of the named spell or in that spell's area. <span className="tweak-color">Spell immunity treats a failure when attempting to counteract a spell as a success, and treats a critical failure as a failure.</span> Successfully counteracting a spell that targets an area or multiple targets with spell immunity negates the effects only for the target affected by spell immunity.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+1)</b> Choose an additional spell when you Cast the Spell. Spell immunity attempts to counteract that spell as well.</span></p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> Instead of choosing one or more spells when you Cast the Spell, choose a spellcasting tradition (arcane, divine, occult or primal). Spell immunity attepts to counteract any spells cast by non-mythic creatures of the chosen tradition.</span></p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Spell immunity really fails to live up to it's name in it's vanilla form, and for a 4th level spell, generally disappoints. By making it fail safe, it at least grants the caster full immunity to the very specific chosen spell, as long as it's a *lower level*, while still offering some obvious counterplay.</p>
  		<p>Even that's not enough though, especially at higher levels, so a useful heightening effect helps round it out and makes a prepared Spell Immunity spell an effective tool for dealing with a magic using creature, provided you know what to expect and they aren't casting spells at a significantly higher level than you. Much more befitting of a 4th level spell.</p>
	</div>
	<div className="spacer" />
</div>