import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Spell } from '../../extra/linkables';
import { Trait } from '../../extra/linkables';

export const SpellGiftofPower = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Gift of Power
			<div className="spacer" />
			<span className="right">Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Range:</b> 30 feet</li>
			<li><b>Duration:</b> 10 minutes</li>
		</ul>
		<hr />
		<p className="no-indent">You infuse an ally with your mythic power. The target gains 3 temporary Mythic Power Points. If the target is not mythic or has a lower mythic rank than you, it gains the <Spell name="mythic surge" /> Mythic Spell, heightened to your mythic rank and is considered mythic for the duration.</p>
		<p className="no-indent">This spell requires 2 Mythic Power Points to cast instead of 1, and cannot be cast with temporary Mythic Power Points.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (7th)</b> The duration increases to 8 hours.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>