import React from 'react';
import { Link } from 'react-router-dom'
export const SW2eArchTableScoundrel = () =>
<div>
	<h2 className="header-bg">Fundamental Scoundrel Feats</h2>
	<p className="all-indent">Each time you gain a level, you can select one or more of the following feats. You must satisfy any prerequisites before taking the feat. Fundamental feats provide essential abilities and improve your proficiencies. They must often be taken to progress in an archetype.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link></td>
				<td>1</td>
				<td>Archetype, Scoundrel, Tech Specialist</td>
				<td><Link to="/sw2e/feats/advancedskilltraining">Advanced Skill Training</Link></td>
				<td>You gain a skill feat and a skill increase at every level.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/fasthands">Fast Hands</Link></td>
				<td>1</td>
				<td>Archetype, Scoundrel, Specialization</td>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Reflex saves, trained in <Link to="sw2e/skills/deception">Deception</Link></td>
				<td></td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/mastermind">Mastermind</Link></td>
				<td>1</td>
				<td>Archetype, Scoundrel, Specialization</td>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Will saves, trained in <Link to="sw2e/skills/society">Society</Link></td>
				<td></td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/roughmethods">Rough Methods</Link></td>
				<td>1</td>
				<td>Archetype, Scoundrel, Specialization</td>
				<td><Link to="/sw2e/feats/improvedskilltraining">Improved Skill Training</Link>, <Link to="/sw2e/feats/toughness">Toughness</Link>, expert in Fortitude saves, trained in <Link to="sw2e/skills/intimidate">Intimidate</Link></td>
				<td></td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/greatfortitude">Great Fortitude</Link></td>
				<td>5</td>
				<td>Archetype, Noble, Scoundrel, Tech Specialist</td>
				<td>—</td>
				<td>Your Fortitude save proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/ironwill">Iron Will</Link></td>
				<td>5</td>
				<td>Archetype, Scoundrel, Scout, Soldier</td>
				<td>—</td>
				<td>Your Will save proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/lightningreflexes">Lightning Reflexes</Link></td>
				<td>5</td>
				<td>Archetype, Noble, Scoundrel, Scout, Soldier, Tech Specialist</td>
				<td>—</td>
				<td>Your Reflex save proficiency increases to expert.</td>
			</tr>
		</tbody>
	</table>
	<br />
	<h2 className="header-bg">Scoundrel Feats</h2>
	<p className="all-indent">Each time you gain a level, you can also select one or more of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/doublestrike">Double Strike</Link></td>
				<td>1</td>
				<td>Archetype, Scoundrel, Soldier</td>
				<td><Link to="/sw2e/feats/fasthands">Fast Hands</Link> or <Link to="/sw2e/feats/mastermind">Mastermind</Link> or <Link to="/sw2e/feats/roughmethods">Rough Methods</Link> or <Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></td>
				<td>You strike quickly with two weapons.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/sneakattack">Sneak Attack</Link></td>
				<td>1</td>
				<td>Archetype, Scoundrel</td>
				<td>any Scoundrel specialization feat</td>
				<td></td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/mobility">Mobility</Link></td>
				<td>2</td>
				<td>Archetype, Scout, Scoundrel</td>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link> or <Link to="/sw2e/feats/favoredterrain">Favored Terrain</Link></td>
				<td>You move in a way that denies your enemies the opportunity to retaliate.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/twinfeint">Twin Feint</Link></td>
				<td>3</td>
				<td>Archetype, Scoundrel</td>
				<td><Link to="/sw2e/feats/doublestrike">Double Strike</Link>, at least 3 total Scoundrel feats</td>
				<td>You use one attack to throw your foe off guard against a second attack at a different angle.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/sneakattackmastery">Sneak Attack Mastery</Link></td>
				<td>5</td>
				<td>Archetype, Scoundrel</td>
				<td><Link to="/sw2e/feats/sneakattack">Sneak Attack</Link></td>
				<td></td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/twindistraction">Twin Distraction</Link></td>
				<td>5</td>
				<td>Archetype, Scoundrel</td>
				<td><Link to="/sw2e/feats/twinfeint">Twin Feint</Link>, at least 4 total Scoundrel feats</td>
				<td>You use one attack to throw your foe off guard against a second attack at a different angle.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/improvedsneakattackmastery">Improved Sneak Attack Mastery</Link></td>
				<td>11</td>
				<td>Archetype, Scoundrel</td>
				<td><Link to="/sw2e/feats/sneakattackmastery">Sneak Attack Mastery</Link></td>
				<td></td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/advancedsneakattackmastery">Advanced Sneak Attack Mastery</Link></td>
				<td>17</td>
				<td>Archetype, Scoundrel</td>
				<td><Link to="/sw2e/feats/improvedsneakattackmastery">Improved Sneak Attack Mastery</Link></td>
				<td></td>
			</tr>
		</tbody>
	</table>
</div>
