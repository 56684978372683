import React from 'react';
import { Link } from 'react-router-dom'

export const RulesLegendaryItem = () =>
<div>
	<h2 className="header-bg shadow-sm">Legendary Items</h2>
	<p>A legendary item is a powerful object and companion brought to life with your mythic power. Your legendary item
		has the <a href="https://2e.aonprd.com/Traits.aspx?ID=109">minion</a> trait, and it gains 2 actions on your turn
		if you use an action to command it. Your legendary item's special abilities and appearance are yours to decide,
		but regardless of it's appearance, it still functions as a normal item of its type.</p>
	<p>You can change any of the decisions related to your legendary item, including its appearance and purpose, with a
		special ritual that requires 8 hours of concentration. This ritual can also be used to change your legendary
		item into a different item (which you must supply) or replace your legendary item if it is lost or destroyed.
		You can only have one legendary item at a time.</p>
	<p>Another creature can use your legendary item if you lend it to them, or if it is lost or stolen. However, if the
		item does not approve of this new partner, it may deny them access to it's magical abilities and legendary item
		special abilites, or even work against them.</p>
	<br />
	<h3 className="sub-header-bg">Proficiencies</h3>
	<p>Your legendary item uses your level to determine its proficiency bonuses. It is trained in unarmored defense, all
		saving throws, Perception, and a number of skills equal to 1 plus its intelligence modifier. Unless your legendary
		item has the <i>animated</i> special ability, it can't typically use actions that require physical activity, such as
		Leap or Treat Wounds, even if trained in the appropriate skill. Even if it has the <i>animated</i> special
		ability, it may still lack the necessary appendages to perform such actions.</p>
	<br />
	<h3 className="sub-header-bg">Ability Modifiers</h3>
	<p>A legendary item begins with base ability modifiers of <b>Int</b> +0, <b>Wis</b> +0, <b>Cha</b> +0. Choose one of
	its mental ability modifiers to increase by 2 and another to increase by 1.</p>
	<br />
	<h3 className="sub-header-bg">Hardness and Hit Points</h3>
	<p>In place of ancestry Hit Points, your legendary item has normal Hardness and Hit Points for an item of its type.
		It gains additional Hit Points equal to 8 plus its Constitution modifier for each mythic rank you have.</p>
	<br />
	<h3 className="sub-header-bg">Senses</h3>
	<p>Your legendary item gains normal vision and hearing within 30 feet.</p>
	<br />
	<h3 className="sub-header-bg">Communication and Languages</h3>
	<p>Your legendary item can communicate with its partner empathically. It understands a number of languages equal to
		1 plus its Intelligence modifier (if it's positive). These must be languages you know.</p>
	<br />
	<h3 className="sub-header-bg">Alignment and Purpose</h3>
	<p>Your legendary item's alignment is the same as your alignment at the time it was created. If your alignment
		changes, you can use the same ritual you use to alter your legendary item to change its alignment to match your
		new alignment.</p>
	<p>You can choose a purpose for the item which it will seek to fulfill regardless of who it's current partner is. It
		may deny its partner access to its abilities or even seek a new partner if it believes they are acting in a way
		which is preventing it from fulfilling its purpose.</p>
	<br />
	<h3 className="sub-header-bg">Special Abilities</h3>
	<p>Legendary items gain a number of special abilities which make them unique. At each mythic rank, your item gains a
		special ability from the list below, up to a maximum of 3.</p>
	<br />

	<h2 className="header-bg shadow-sm">Legendary Item Special Abilities</h2>
	<h3 className="sub-header-bg">Animated</h3>
	<p>A legendary item with this ability can move and act on its own. It gains the <a href="https://2e.aonprd.com/Traits.aspx?ID=35">construct</a> trait
		and the physical ability modifiers <b>Str</b> +1, <b>Dex</b> +1, <b>Con</b> +1. Choose one of its physical
		ability modifiers to increase by 2 and another to increase by 1. The item gains a Speed of 20 feet, becomes trained
		in unarmed attacks, and can perform any activities an item of its type could reasonably be used to accomplish
		(such as a healer's kit treating wounds, or a sword cutting a rope).</p>
	<p> If the item is a weapon, it can make attacks using itself. This is an unarmed attack that deals the same
		damage as a weapon of its type, and has the same traits. The item gains the benefit of any runes or magical properties
		it possesses. If the item is not a weapon, it gains an unarmed strike with the agile, magical and finesse traits
		that deals 1d4 damage of an appropriate type. The item may have other attacks or abilities, similar to
		an <a href="https://2e.aonprd.com/MonsterFamilies.aspx?ID=4">animated object</a>, as determined by the GM.</p>
	<p>If you have the <Link to="/mythic/feats/advancedlegendaryitem">advanced legendary item</Link> feat, choose one of
		its physical ability modifiers to increase by 2 and another to increase by 1. Increase its proficiency rank in
		unarmed attacks to expert.</p>
	<p>If you have the <Link to="/mythic/feats/greaterlegendaryitem">greater legendary item</Link> feat, Choose one of
		its physical ability modifiers to increase by 2 and another to increase by 1. Increase its proficiency rank in
		unarmed attacks to master.</p>
	<br />
	<h3 className="sub-header-bg">Expanded Senses</h3>
	<p>The item's normal vision and hearing have no range limit, as a creature's would. It gains your choice of either
		low-light vision or darkvision.</p>
	<br />
	<h3 className="sub-header-bg">Fundamentals</h3>
	<p>A weapon or suit of armor with this ability gains any fundamental property runes for which it qualifies with an
		item level equal to triple your mythic rank or less. Each time you gain a new mythic rank, the weapon gains any
		new fundamental runes for which it qualifies, and upgrades any old runes to new versions for which it qualifies.
		These runes cannot be transferred to other weapons and armor.</p>
	<br />
	<h3 className="sub-header-bg">Indestructible</h3>
	<p>The item's hardness becomes 30 and it gains 40 additional hit points. It is immune to all damage from
		non-mythic sources.</p>
	<br />
	<h3 className="sub-header-bg">Legendary Spell</h3>
	<p>Choose a spell with a spell level equal to your mythic rank or less. The item gains the following activation:</p>
	<br />
	<p><b>Activate:</b> Cast a Spell; <b>Frequency:</b> varies; <b>Effect:</b> You Cast the Spell at a spell level equal
		to your mythic rank.</p>
	<p>If the spell is a cantrip or the spell's original spell level is at least four levels lower than your mythic rank,
		the frequency is at-will. Otherwise, the frequency is three times per day.</p>
	<p>If the item has the Legendary Surge ability, you or it can spend 1 Mythic Power Point from the item's pool to
		gain the benefits of the <Link to="/mythic/spells/ascendantspell">Ascendant Spell</Link> Mythic Spell when
		casting the spell.</p>
	<br />
	<h3 className="sub-header-bg">Legendary Surge</h3>
	<p>The item gains it's own pool of mythic power with 2 Mythic Power Points. The item or its partner can use these
		points to cast <Link to="/mythic/spells/mythicsurge">Mythic Surge</Link> on checks made involving the item,
		such as attacks with a weapon, skill checks using a tool or saving throws with armor or a worn item. The item
		might also possess abilities that allow it to spend these points in other ways.</p>
	<p>If you have the <Link to="/mythic/feats/advancedlegendaryitem">Advanced Legendary Item</Link> feat, this pool
		increases to 3 Mythic Power Points. If you have
		the <Link to="/mythic/feats/greaterlegendaryitem">Greater Legendary Item</Link> feat, this pool increases to 4
		Mythic Power Points.</p>
	<br />
	<h3 className="sub-header-bg">Magical Ability</h3>
	<p>The item gains the special abilities of a specific magic item of the same type, or of an item of a similar type
		that it could reasonably replicate, as determined by the GM. The chosen item must have an item level equal to
		triple your mythic rank or less. Weapons and armor do not gain any property runes noted in the item's
		description.</p>
	<br />
	<h3 className="sub-header-bg">Magical Properties</h3>
	<p>The item gains a property rune appropriate for an item of its type. The rune must have an item level equal to
		triple your mythic rank or less. This ability can be taken up to three times.</p>
	<br />
	<h3 className="sub-header-bg">Magic Sense</h3>
	<p>The item gains a precise sense with a range of 60 feet that allows it to detect and identify magical effects. It senses
		magical effects that it can see within range as if it had detected them using a <i><a href="https://2e.aonprd.com/Spells.aspx?ID=66">detect magic</a></i> spell of a level
		equal to your mythic rank. This is automatic and does not require an action, but the effect must be within its
		field of vision.</p>
	<p>The item gain a +2 circumstance bonus on checks to <a href="https://2e.aonprd.com/Actions.aspx?ID=24">Identify
		Magic</a> and can grant this bonus to a check its partner makes as a reaction <span className="reaction"></span>.</p>
	<br />
	<h3 className="sub-header-bg">Sense Alignment</h3>
	<p>Choose chaotic, evil, good, or lawful. The item gains a precise sense with a range of 60 feet that allows it to
		detect the chosen alignment. It senses creatures and objects with auras of the chosen alignment that it can see
		within range as if it had detected them using a <a href="https://2e.aonprd.com/Spells.aspx?ID=65">detect
		alignment</a> spell of a level equal to your mythic rank. This is automatic and does not require an action, but
		the effect must be within its field of vision.</p>
	<p>The item gain a +2 circumstance bonus on checks to <a href="https://2e.aonprd.com/Skills.aspx?ID=5&General=true">Recall
		Knowledge</a> about the sources of auras it detects and can grant this bonus to a check its partner makes as a
		reaction <span className="reaction"></span>.</p>
	<br />
	<h3 className="sub-header-bg">Upgradeable</h3>
	<p>Any runes or magical abilities the item possessed before becoming a legendary item begin functioning again. The
		item can have runes inscribed on it and special abilities added to it with Magical Crafting, just as a normal item
		could, but you must be present for the entire crafting process.</p>
	<p>If the item has a dedicated bond, the bonded partner may be present for the crafting process instead.</p>
	<br />
</div>