import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { MythicPathsAbout } from './paths/about';import { PathoftheDragon } from './paths/dragon';
import { PathoftheElemental } from './paths/elemental';
import { PathofEnlightenment } from './paths/enlightenment';
import { PathoftheFey } from './paths/fey';
import { PathoftheShadow } from './paths/shadow';
import { PathoftheTitan } from './paths/titan';
import { PathoftheUndead } from './paths/undead';
import { PathoftheVoid } from './paths/void';
import { PathoftheAngel } from './paths/angel';
import { PathoftheArchon } from './paths/archon';
import { PathoftheAzata } from './paths/azata';
import { PathoftheDemon } from './paths/demon';
import { PathoftheDevil } from './paths/devil';
import { PathoftheStars } from './paths/stars';

export const MythicPaths = () =>
<div className="col-full">
	<h2 className="center">
		<Switch>
			<Route exact path="/mythic/paths/angel">Angel</Route>
			<Route path="/"><Link to="/mythic/paths/angel">Angel</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/archon">Archon</Route>
			<Route path="/"><Link to="/mythic/paths/archon">Archon</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/azata">Azata</Route>
			<Route path="/"><Link to="/mythic/paths/azata">Azata</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/demon">Demon</Route>
			<Route path="/"><Link to="/mythic/paths/demon">Demon</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/devil">Devil</Route>
			<Route path="/"><Link to="/mythic/paths/devil">Devil</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/dragon">Dragon</Route>
			<Route path="/"><Link to="/mythic/paths/dragon">Dragon</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/elemental">Elemental</Route>
			<Route path="/"><Link to="/mythic/paths/elemental">Elemental</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/enlightenment">Enlightenment</Route>
			<Route path="/"><Link to="/mythic/paths/enlightenment">Enlightenment</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/fey">Fey</Route>
			<Route path="/"><Link to="/mythic/paths/fey">Fey</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/shadow">Shadow</Route>
			<Route path="/"><Link to="/mythic/paths/shadow">Shadow</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/stars">Stars</Route>
			<Route path="/"><Link to="/mythic/paths/stars">Stars</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/titan">Titan</Route>
			<Route path="/"><Link to="/mythic/paths/titan">Titan</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/undead">Undead</Route>
			<Route path="/"><Link to="/mythic/paths/undead">Undead</Link></Route>
		</Switch>
	</h2>
	<Switch>
		<Route exact path="/mythic/paths" component={MythicPathsAbout} />
		<Route path="/mythic/paths/angel" component={PathoftheAngel}/>
		<Route path="/mythic/paths/archon" component={PathoftheArchon}/>
		<Route path="/mythic/paths/azata" component={PathoftheAzata}/>
		<Route path="/mythic/paths/demon" component={PathoftheDemon}/>
		<Route path="/mythic/paths/devil" component={PathoftheDevil}/>
		<Route path="/mythic/paths/dragon" component={PathoftheDragon}/>
		<Route path="/mythic/paths/elemental" component={PathoftheElemental}/>
		<Route path="/mythic/paths/enlightenment" component={PathofEnlightenment}/>
		<Route path="/mythic/paths/fey" component={PathoftheFey}/>
		<Route path="/mythic/paths/shadow" component={PathoftheShadow}/>
		<Route path="/mythic/paths/stars" component={PathoftheStars}/>
		<Route path="/mythic/paths/titan" component={PathoftheTitan}/>
		<Route path="/mythic/paths/undead" component={PathoftheUndead}/>
		<Route path="/mythic/paths/void" component={PathoftheVoid}/>
	</Switch>
</div>
