import React from 'react';

export const SW2eSpeciesHuman = () => 
<div className="columns">
    <div className="column-md">
        <h2 className="header-bg">Human</h2>
        <h3 className="sub-header-bg">Physical Description</h3>
        <p className="all-indent">Humans are bipedal mammalian beings with a bilateral symmetry, two arms, two legs and a head. They average
        1.8 meters in height, though they can be significantly shorter or taller. Other sentient species that have a body
        type roughly similar to Humans and walk upright are often referred to as humanoids or anthropoids, since Humans
        are the most common example. Unlike other mammalian species such as Wookiees or Ewoks, they have only a light
        covering of body hair. Most of it is concentrated on the head and, in the case of adult males, the face. As is
        common to many humanoid species, Humans have two sexes: male and female, with the latter being distinguished by
        their pronounced breasts, overall smaller stature and lesser quantity of body hair. Humans have a moderate
        lifespan, reaching maturity around age 15, and rarely living beyond 100.</p>
        <p className="all-indent">The Human genome was considered to be remarkably elastic. In reaction to environmental changes, selection
        pressures only needed few millennia to engage new genes and reshape the Human bodies. A consequence of that
        exceptional adaptability was the existence of many Near-Human subspecies, such as the mysterious Mirialans, the
        blue-skinned Chiss and the gaunt Pau'ans.</p>
        <br />
        <h3 className="sub-header-bg">Society</h3>
        <p className="all-indent">Humans are noted for being extremely adaptable—not only in terms of physiology, but also mentality and in society.
        Their societies are capable of transforming very quickly in response to a wide array of conditions, which explains
        why two Human populations could have next to nothing in common culturally speaking. Some Human peoples have more in
        common with their neighbors of other species than with their own kind.</p>
        <br />
    </div>
    <div className="column-md">
        <h2 className="header-bg">Human Mechanics</h2>
        <h3 className="sub-header-bg">Hit Points</h3>
        <p>8</p>
        <br />
        <h3 className="sub-header-bg">Size</h3>
        <p>Medium</p>
        <br />
        <h3 className="sub-header-bg">Speed</h3>
        <p>8 meters</p>
        <br />
        <h3 className="sub-header-bg">Ability Boosts</h3>
        <p>Two free ability boosts</p>
        <br />
        <h3 className="sub-header-bg">Languages</h3>
        <p>Basic</p>
        <p className="no-indent all-indent">Additional languages equal to 1 + your Intelligence (if it's positive).</p>
        <br />
        <h3 className="sub-header-bg">Varied Ambitions</h3>
        <p className="all-indent">Humans have a wide variety of interests and progress quickly in their chosen fields. You gain a general,
        archetype or skill feat of your choice for which you meet the prerequisites. This feat can be selected at any
        point during character creation.</p>
        <br />
        <h3 className="sub-header-bg">Flexible Skills</h3>
        <p className="all-indent">Human versatility allows them to pick up a wide variety of skills. You become trained in two skills of your
        choice. At 5th level, you become an expert in one of these skills.</p>
        <br />
    </div>
    <div className="column-fit">
        <img className="profile-img" src="/profile/human.png" alt="" />
    </div>
</div>