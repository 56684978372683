import React from 'react';
import { Link } from 'react-router-dom';

export const SpellTweaksAll = () => 
<div>
	<h3 className="header-bg">All Cantrips</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/acidsplash"><b>Acid Splash (evo):</b></Link> Damage creatures with acid.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/divinelance"><b>Divine Lance (evo):</b></Link> Throw divine energy that damages based on your deity's alignment.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/gougingclaw"><b>Gouging Claw (evo):</b></Link> Morph your limb into a claw and attack with it.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/produceflame"><b>Produce Flame (evo):</b></Link> Kindle small flames to attack close or at range.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/rayoffrost"><b>Ray of Frost (evo):</b></Link> Damage a creature with cold.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/revitalize"><b><del>Stabilize</del>&nbsp;<span className="tweak-color">Revitalize</span> (nec):</b></Link> Revive a dying creature.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/telekineticprojectile"><b>Telekinetic Projectile (evo):</b></Link> Fling an object at a creature</li>
	</ul>
	<h3 className="header-bg">All 1st-Level Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/anthaul"><b>Ant Haul (tra):</b></Link> Target can carry more.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/bane"><b>Bane (enc):</b></Link> Weaken enemies' attacks in an aura around you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/bless"><b>Bless (enc):</b></Link> Strengthen allies' attacks in an aura around you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/burninghands"><b>Burning Hands (evo):</b></Link> A small cone of flame rushes from your hands.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/createwater"><b>Create Water (con):</b></Link> Conjure several gallons of water.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/dimensionalhop"><b>Dimensional Hop (con):</b></Link> You teleport a short distance.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/floatingdisk"><b>Floating Disk (con):</b></Link> A disk of energy follows you, carrying objects.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/magicstone"><b>Magic Stone (nec):</b></Link> Make ordinary stones into magical sling bullets that are especially dangerous to undead.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/pestform"><b>Pest Form (tra):</b></Link> Transform into a Tiny animal.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/sleep"><b>Sleep (enc):</b></Link> Cause creatures in a small area to fall asleep.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/sowthought"><span className="tweak-color"><b>Sow Thought (enc):</b></span></Link> Plant an idea in another creature's mind.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/unnaturallust"><span className="tweak-color"><b>Unnatural Lust (enc):</b></span></Link> Suggest a course of action a creature must follow.</li>
	</ul>
	<h3 className="header-bg">All 2nd-Level Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/animatedead"><b>Animate Dead (nec):</b></Link> Animate an undead creature to fight on your behalf.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/restoration"><b>Restoration (nec):</b></Link> Counter the effects of toxins or conditions with restorative magic.</li>
	</ul>
	<h3 className="header-bg">All 3rd-Level Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/fireball"><b>Fireball (evo):</b></Link> An explosion of fire in an area burns creatures.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/lightningbolt"><b>Lightning Bolt (evo):</b></Link> Lightning strikes all creatures in a line.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/nondetection"><b>Nondetection (abj):</b></Link> Protect a creature or object from detection.</li>
	</ul>
	<h3 className="header-bg">All 4th-Level Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/bestowcurse"><span className="tweak-color"><b>Bestow Curse (nec):</b></span></Link> Curse a creature.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/iridescentbeam"><span className="tweak-color"><b>Iridescent Beam (evo):</b></span></Link> Strike creatures with a multicolored beam of light.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/spellimmunity"><b>Spell Immunity (abj):</b></Link> Name a spell to negate its effects on you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/suggestion"><b>Suggestion (enc):</b></Link> Suggest a course of action a creature must follow.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/telepathy"><b>Telepathy (div):</b></Link> Communicate telepathically with any creatures near you.</li>
	</ul>
	<h3 className="header-bg">All 5th-Level Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/lightningstorm"><b>Lightning Storm (evo):</b></Link> Communicate telepathically with any creatures near you.</li>
	</ul>
	<h3 className="header-bg">All 6th-Level Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/trueseeing"><b>True Seeing (div):</b></Link> See through illusions and transmutations.</li>
	</ul>
	<h3 className="header-bg">All 7th-Level Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 8th-Level Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 9th-Level Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 10th-Level Spells</h3>
	<ul className="unstyled">
	</ul>
</div>