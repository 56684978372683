import React from 'react';
import { Link } from 'react-router-dom'

export const FeatGreaterInnateMagic = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Greater Innate Magic
			<div className="spacer" />
			<span className="right">Feat Mythic 7</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Azata</div>
			<div className="trait">Devil</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></li>
		</ul>
		<hr />
		<p className="no-indent">Choose a spell of 7th level or lower from the spell list chosen with Innate Magic. You can cast this spell as an innate spells of the chosen type once per day. It is automatically heightened to a spell level equal to your mythic rank. At 8th mythic rank, you gain a spell of 8th level or lower. At 9th rank, you gain a spell of 9th level or lower.</p>
		<p className="no-indent">Your proficiency ranks for spell attack rolls and spell DCs of the chosen tradition increase to master. Any innate spells you have gained from mythic feats or mythic path abilities with a base spell level of 6th or lower can be cast 3 times per day, unless they could already be cast more often.</p>
	</div>
	<div className="spacer" />
</div>