import React from 'react';

export const SW2eSpeciesWookiee = () => 
<div className="columns">
    <div className="column-md">
        <h2 className="header-bg">Wookiee</h2>
        <h3 className="sub-header-bg">Physical Description</h3>
        <p className="all-indent">Wookiees, are a species of tall, hairy humanoids that inhabit the planet of Kashyyyk.
        A race of arboreal mammals, the Wookiees live in treehouses nestled in the canopy of the towering wroshyr trees.
        Despite their fearsome appearance, they are usually gentle, although they are prone to devastating fits of rage
        when provoked. Wookiees stand around 2 meters in height with a uniform coat of water-shedding hair, the
        color of which ranged from black to brown.  They possess retractable claws which aid in their climbing ability
        and can be used for self defense. Wookies are a fairly long-lived species. A typical wookiee is considered
        mature at age 18, though they are known to live for over 400 years.</p>
        <br />
        <h3 className="sub-header-bg">Society</h3>
        <p className="all-indent">Wookiee society has a complex lineage structure. Small families are regrouped in
        clan-like structures, with a system of inter-clan alliances and enmities. Wookiees greatly valued honor and
        loyalty. Therefore, their notion of "family" encompassed much more than just blood ties. A part of that most
        sacred tradition was the social institution of life debt: when a Wookiee's life was saved by someone else,
        regardless of the species, they would frequently devote themselves to a lifetime of service to their savior
        and their family.</p>
        <p  className="all-indent">Despite their large size and ferocious appearance, Wookiees are known to be gentle creatures unless provoked,
        with a history of skill in art and technology. This combination, along with their ruthless Trandoshan
        neighbors, has found the Wookiee people exploited as slave labor on several occasions in their history.</p>
        <br />
    </div>
    <div className="column-md">
        <h2 className="header-bg">Wookiee Mechanics</h2>
        <h3 className="sub-header-bg">Hit Points</h3>
        <p>10</p>
        <br />
        <h3 className="sub-header-bg">Size</h3>
        <p>Medium</p>
        <br />
        <h3 className="sub-header-bg">Speed</h3>
        <p>10 meters</p>
        <br />
        <h3 className="sub-header-bg">Ability Boosts</h3>
        <p>Strength</p>
        <p>Constitution</p>
        <p>Free</p>
        <br />
        <h3 className="sub-header-bg">Ability Flaws</h3>
        <p>Charisma</p>
        <br />
        <h3 className="sub-header-bg">Languages</h3>
        <p>Basic (cannot speak)</p>
        <p>One of Shyriiwook, Thykarann or Xaczik</p>
        <p className="no-indent all-indent">Additional languages equal to your Intelligence (if it's positive).</p>
        <br />
        <h3 className="sub-header-bg">Limited Vocal Range</h3>
        <p className="all-indent">Wookies have a limited vocal range, and cannot speak most languages. Wookiee
        characters can learn most any language, but can only speak Shyriiwook, Thykarann or Xaczik. Anyone playing a
        Wookiee character should ensure they have a method to communicate, such as a friend or protocol droid who
        understands Shyriiwook, or a datapad with a vocabulator.</p>
        <br />
        <h3 className="sub-header-bg">Tree Climber</h3>
        <p className="all-indent">You are a natural climber, able to move up and along vertical surfaces with surprising
        nimbleness. You gain a climb Speed equal to half your base Speed.</p>
        <br />
        <h3 className="sub-header-bg">Natural Weapons</h3>
        <p className="all-indent">Wookie hands have retractable claws that can be used to make attacks. These deal 1d6
        slashing damage and have the Agile and unarmed traits.</p>
        <br />
        <h3 className="sub-header-bg">Natural Armor</h3>
        <p className="all-indent">Even unarmored, Wookiees are particularly difficult to harm. while not wearing armor,
        you gain a +2 item bonus to AC.</p>
        <br />
        <h3 className="sub-header-bg">Unusual Anatomy</h3>
        <p className="all-indent">Wookiees are unusually large, and consume twice as much food as a normal character.
        Most clothing and armor designed for humanoid species does not fit them. Outside of Wookie settlements, clothing
        and armor for Wookie characters costs twice as much.</p>
        <br />
        <h3 className="sub-header-bg">Wookiee Rage</h3>
        <p className="all-indent">You are capable of short, violent outbursts when provoked. You gain the Wookie Rage
        reaction.</p>
        <div className="all-indent">
            <h3 className="header-bg"><b>Wookiee Rage</b> <div className="reaction" /></h3>
            <div className="traits">
                <div className="trait">Emotion</div>
                <div className="trait">Rage</div>
                <div className="trait">Wookie</div>
            </div>
            <b>Requirements:</b> You aren't fatigued.<br/>
            <b>Trigger:</b> You or an ally which you are at least Friendly towards is attacked, insulted or taunted, or
            an enemy fails an attempt to Demoralize you.
            <div className="hline"></div>
            <p>You begin a rage-fuled outburst that lasts for 1 round. During this time you deal 2 additional damage with
            melee Strikes and take a -1 penalty to AC.</p>
        </div>
        <br />
    </div>
    <div className="column-fit">
        <img className="profile-img" src="/profile/wookiee.png" alt="" />
    </div>
</div>