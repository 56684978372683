import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellRevitalize = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm"><del>Stabilize</del>&nbsp;<span className="tweak-color">Revitalize</span>&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=307"> </a>
			<div className="spacer" />
			<span className="right">Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Cantrip</div>
			<div className="trait">Healing</div>
			<div className="trait">Necromancy</div>
			<div className="trait">Positive</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> divine, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Range</b> 30 feet; <b>Targets:</b> 1 dying creature</li>
		</ul>
		<hr />
		<p className="no-indent">Positive energy shuts death's door. <span className="tweak-color">The target regains 1 hit point and loses the dying condition, but does not gain or increase the wounded condition. The target is then temporarily immune to Revitalize for 24 hours.</span></p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>With the abundance of proper healing in 2e (and the existance of the First Aid action), Stabilize really lost it's place (not that it ever had much of one). A very specific, reasonably powerful, and related purpose (and a new name) makes it a much more interesting choice, while keeping it useful even into higher levels. (Without the need for a heightening effect, even.)</p>
	</div>
	<div className="spacer" />
</div>