import React from 'react';

export const MythicHome = () =>
<div className="columns">
    <div className="spacer" />
    <div className="col-lg">
        <p>Mythic Adventures 2e is an adaptation of the optional Mythic Adventures rules from Pathfinder First Edition. 
        Due to the significant differences between first and second editions, many creative liberties have been taken in
        the attempt to preserve the sense of legendary drama and and fix a number of common issues.</p>
        <h2 className="header-bg">Mythic</h2>
        <h3 className="headerbar sub-header-bg">What is Mythic?</h3>
        <p> Everyone knows the story of the blacksmith’s son who, after taking up arms to defend his village, 
        continues on to become a renowned adventurer. And of the young elf who spends years studying musty tomes and practicing 
        simple spells before she heads out into the world to seek ancient lore. These are the stories of everyday adventurers, 
        risen from the ranks of the common folk to make a name for themselves in places harsh and unforgiving.</p>

        <p> But these are not the only stories of heroism. Some adventurers are beyond exemplary—their stories forge the greatest
        sagas of history, and their every deed births a legend. From the children of gods, blessed with the divine spark, to
        the lucky heroes born under auspicious stars, these characters are destined to greatness. They are mythic: possessed
        of unparalleled heroism and capable of astounding acts.</p>

        <p> To be mythic means to draw upon a power that few even dare to understand, and even fewer hope to wield. An air of
        destiny surrounds mythic characters, and each choice they make shapes the world at large. Their story is intertwined
        with the great events of the day, and their actions are central to the outcomes. Mythic characters are more resilient
        and powerful than others, and as a result are awe-inspiring in ways their non-mythic counterparts could never match.
        Other adventurers might balk at taking on a dragon that plagues a village, but mythic heroes would not only take on
        the dragon, but also clear the entire region of threats. Ultimately, the story of mythic heroes is defined by the
        challenges they face. The GM has a number of new and awe-inspiring tools with which to confront mythic player characters, 
        ranging from immense and deadly mythic monsters to vile and cunning mythic villains. Mythic monsters are unique creatures
        or remnants of a bygone age when such terrifying beasts ruled over the land. They now dwell in the lost places of the 
        world, waiting for their time to bring great terror. Mythic villains have many of the powers of mythic characters, but
        they use their abilities selfishly—to subjugate kingdoms, slaughter the innocent, and bring ruin to the world. Unless
        heroes rise to stop them, mythic villains can cause destruction and chaos on an unimaginable scale. </p>

        <p> Even with such great power, mythic characters are not invulnerable, just more able to deal with the dangers of the
        world around them. If a mythic character dies, her loss is a great tragedy to the world, as the light of one of its true
        champions has been extinguished. This is what makes a mythic story exciting: these heroes might fall, just as non-mythic
        heroes might succumb to lesser threats. And when they do succeed, their victory often comes at a high cost, and usually
        leaves them scarred.</p>

        <p>The rules in this section give players and GMs the tools they need to run mythic adventures. The mythic rules add to
        the base rules of this game, either as part of an adventure in which the PCs attain mythic power for a limited time, or
        as the backbone of an entire campaign charting the legend of a group of mythic characters.</p>

        <h3 className="headerbar sub-header-bg">What Makes Mythic Adventures Different?</h3>
        <p>In a world of might and sorcery, with dragons and elves, what does it mean to be “mythic?” Being mythic means
        possessing a degree of might unusual even in a fantasy world. Scenes turn more dramatic, the enemies are more lethal, and
        the consequences of the heroes’ actions make a far-ranging impact. Being mythic means invoking a sense of wonder and awe
        even in those already accustomed to the strange and unusual.</p>

        <p>The way this section portrays the mythic narrative isn’t solely about stories at 20th level and monsters with high 
        Challenge Ratings—it’s about the surprising and unfamiliar regardless of power and scale. Even 1st-level characters could
        be imbued with mythic power and become forces to be reckoned with. Similarly, lesser monsters such as ogres and skeletons
        that become mythic transform into terrifying foes with unknown powers, changing the nature of the story you’re playing—
        and startling those accustomed to their non-mythic ilk.</p> 

        <p>Not only the characters take on unexpected forms in mythic adventures; the setting does as well. The vistas are more
        dramatic, featuring flying islands and keeps that float in raging volcanoes. The colors are brighter, the sounds are more
        mysterious, and all of the other stimuli are sharper and more vibrant. Where the non-mythic hero would encounter a
        crumbling keep filled with familiar monsters, a mythic hero faces a towering citadel that builds itself from the bones of
        would-be invaders and is inhabited by cruel and malign creatures of nearly god-like power.</p> 

        <p>Besides the setting, the challenges that face mythic characters are far more harrowing than usual. Enhanced abilities
        allow mythic characters to take on threats beyond the reach of those without such power. They can face with ease foes
        both powerful and numerous. The real challenge is when they take on mythic creatures that possess the same resilient
        nature and abilities similar in potency to those they themselves rely on. When a mythic hero comes face to face with a
        mythic monster, the battle is truly legendary. Finally, mythic adventures feature difficult choices and far-reaching
        consequences. As the characters progress through the story, they’re tasked with taking on challenges that seem impossible
        even to them, and might be tempted to wander from their path. As mythic heroes, they’re the first to respond to cataclysmic
        events, just as they’re the last bastion to stem the tide of evil and darkness that threatens to wash over the world.
        Their successes and failures leave marks on the world for centuries to come.</p>

        <h2 className="header-bg">Glossary</h2>
        <p>Mythic Adventures uses several new terms, or that previously existed but were rarely used. These terms are worth reviewing
        before continuing on with the rest of this section.</p>
        <h3 className="headerbar sub-header-bg">Mythic</h3>
        <p>With abilities seemingly beyond the those of ordinary mortals, a mythic character or mythic monster becomes part of a
        story that plays out on a greater scale than ordinary people can understand. An attack, spell, or other effect is
        considered mythic if it originates from a mythic source, such as a character or creature with a mythic tier or rank.</p>
        <br />
        <h3 className="headerbar sub-header-bg">Mythic Ascension</h3>
        <p>The moment of ascension is the moment when a normal character becomes a mythic character. This event is usually a
        critical moment in the story that helps to define the mythic character's origin and the source of her power. Once a
        character has undergone ascension, she gains her first mythic rank and can select a mythic path.</p>
        <br />
        <h3 className="headerbar sub-header-bg">Mythic Boon</h3>
        <p>When a mythic character completes a particularly difficult task, the GM might reward that character with a boon. A boon
        represents having earned the favor of the source of the character's power. Once acquired, a boon allows the character to
        draw upon mythic power one additional time that day. A mythic character might be rewarded with a boon several times in a
        single session, but no more than once per encounter.</p>
        <br />
        <h3 className="headerbar sub-header-bg">Mythic Path</h3>
        <p>(Often referred to just as a "path.") The theme of a character's mythic abilities is determined by her mythic path. 
        Each path grants access to a different set of mythic feats and has a number of special abilities associated with it that
        the character gains as she advances in rank.</p>
        <br />
        <h3 className="headerbar sub-header-bg">Mythic Power</h3>
        <p>Mythic power, represented by your pool of Mythic Power Points, is a resource that can be spent to cast mythic
            spell, which can influence destiny and create dramatic effects.</p>
        <br />
        <h3 className="headerbar sub-header-bg">Mythic Rank</h3>
        <p>Mythic ranks are used to describe the approximate mythic power possessed by a character or creature. All
            creatures with a mythic rank are considered mythic for the purposes of feats, spells, magic items, and other
            abilities. Mythic ranks range from 1 to 10. Beings who achieve the 10th rank are at the height of mythic
            power, and are in some respects akin to minor deities.</p>
        <br />
        <h3 className="headerbar sub-header-bg">Mythic Surge</h3>
        <p>A mythic surge (or just "surge") is a basic ability that each mythic character receives. It allows them to
            roll a die and add the result to a d20 roll, influencing the outcome after the results are revealed.</p>
        <br />
        <h3 className="headerbar sub-header-bg">Mythic Trial</h3>
        <p>A mythic trail (or just a "trial") is a difficult task that awaits mythic heroes. It usually represents the
            culmination of part of the heroes' story, marking it as an important point in their legend. A mythic
            character has to complete one or more trials in order to reach a new mythic rank. Trials and mythic path
            advancement are separate from XP and character level advancement, and are based on grand achievements within
            the story rather than individual encounters.</p>
        <br />
        <h3 className="headerbar sub-header-bg">Non-Mythic</h3>
        <p>Any attack, spell, or effect originating from a character or creature without any mythic abilities is
            on-mythic. This term can also refer to a character without a mythic rank.</p>
    </div>
    <div className="spacer" />
</div>