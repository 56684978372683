import React from 'react';
import { Link } from 'react-router-dom';

export const SpellTweaksDivine = () =>
<div>
	<h3 className="header-bg">Divine  Cantrips</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/divinelance"><b>Divine Lance (evo):</b></Link> Throw divine energy that damages based on your deity's alignment.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/revitalize"><b><del>Stabilize</del>&nbsp;<span className="tweak-color">Revitalize</span> (nec):</b></Link> Revive a dying creature.</li>
	</ul>
	<h3 className="header-bg"> 1st-Level Divine Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/bane"><b>Bane (enc):</b></Link> Weaken enemies' attacks in an aura around you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/bless"><b>Bless (enc):</b></Link> Strengthen allies' attacks in an aura around you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/createwater"><b>Create Water (con):</b></Link> Conjure several gallons of water.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/magicstone"><b>Magic Stone (nec):</b></Link> Make ordinary stones into magical sling bullets that are especially dangerous to undead.</li>
	</ul>
	<h3 className="header-bg"> 2nd-Level Divine Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/animatedead"><b>Animate Dead (nec):</b></Link> Animate an undead creature to fight on your behalf.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/restoration"><b>Restoration (nec):</b></Link> Counter the effects of toxins or conditions with restorative magic.</li>
	</ul>
	<h3 className="header-bg"> 3rd-Level Divine Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 4th-Level Divine Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/bestowcurse"><span className="tweak-color"><b>Bestow Curse (nec):</b></span></Link> Curse a creature.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/spellimmunity"><b>Spell Immunity (abj):</b></Link> Name a spell to negate its effects on you.</li>
	</ul>
	<h3 className="header-bg"> 5th-Level Divine Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 6th-Level Divine Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/trueseeing"><b>True Seeing (div):</b></Link> See through illusions and transmutations.</li>
	</ul>
	<h3 className="header-bg"> 7th-Level Divine Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 8th-Level Divine Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 9th-Level Divine Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 10th-Level Divine Spells</h3>
	<ul className="unstyled">
	</ul>
</div>