import React from 'react';
import { Switch, Route } from 'react-router';
import { SW2eSpeciesBothan } from './species/bothan';
import { SW2eSpeciesCerean } from './species/cerean';
import { SW2eSpeciesDuros } from './species/duros';
import { SW2eSpeciesEwok } from './species/ewok';
import { SW2eSpeciesGamorrean } from './species/gamorrean';
import { SW2eSpeciesGungan } from './species/gungan';
import { SW2eSpeciesHome } from './species/home';
import { SW2eSpeciesHuman } from './species/human';
import { SW2eSpeciesIthorian } from './species/ithorian';
import { SW2eSpeciesJawa } from './species/jawa';
import { SW2eSpeciesKelDor } from './species/keldor';
import { SW2eSpeciesMonCalamari } from './species/moncalamari';
import { SW2eSpeciesQuarren } from './species/quarren';
import { SW2eSpeciesRodian } from './species/rodian';
import { SW2eSpeciesSullustan } from './species/sullustan';
import { SW2eSpeciesTogruta } from './species/togruta';
import { SW2eSpeciesTrandoshan } from './species/trandoshan';
import { SW2eSpeciesTwilek } from './species/twilek';
import { SW2eSpeciesUgnaught } from './species/ugnaught';
import { SW2eSpeciesWookiee } from './species/wookiee';
import { SW2eSpeciesZabrak } from './species/zabrak';

export const SW2eSpecies = () => 
<div className="text-container">
    <Switch>
        <Route exact path="/sw2e/species" component={SW2eSpeciesHome}/>
        <Route path="/sw2e/species/human" component={SW2eSpeciesHuman}/>
        <Route path="/sw2e/species/bothan" component={SW2eSpeciesBothan}/>
        <Route path="/sw2e/species/cerean" component={SW2eSpeciesCerean}/>
        <Route path="/sw2e/species/duros" component={SW2eSpeciesDuros}/>
        <Route path="/sw2e/species/gamorrean" component={SW2eSpeciesGamorrean}/>
        <Route path="/sw2e/species/moncalamari" component={SW2eSpeciesMonCalamari}/>
        <Route path="/sw2e/species/rodian" component={SW2eSpeciesRodian}/>
        <Route path="/sw2e/species/sullustan" component={SW2eSpeciesSullustan}/>
        <Route path="/sw2e/species/twilek" component={SW2eSpeciesTwilek}/>
        <Route path="/sw2e/species/ithorian" component={SW2eSpeciesIthorian}/>
        <Route path="/sw2e/species/quarren" component={SW2eSpeciesQuarren}/>
        <Route path="/sw2e/species/togruta" component={SW2eSpeciesTogruta}/>
        <Route path="/sw2e/species/trandoshan" component={SW2eSpeciesTrandoshan}/>
        <Route path="/sw2e/species/ugnaught" component={SW2eSpeciesUgnaught}/>
        <Route path="/sw2e/species/wookiee" component={SW2eSpeciesWookiee}/>
        <Route path="/sw2e/species/zabrak" component={SW2eSpeciesZabrak}/>
        <Route path="/sw2e/species/ewok" component={SW2eSpeciesEwok}/>
        <Route path="/sw2e/species/gungan" component={SW2eSpeciesGungan}/>
        <Route path="/sw2e/species/jawa" component={SW2eSpeciesJawa}/>
        <Route path="/sw2e/species/keldor" component={SW2eSpeciesKelDor}/>
    </Switch>
</div>