import React from 'react';
import { Link } from 'react-router-dom'

export const FeatSuppressiveFire = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Suppressive Fire
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You know how to cover an area in suppressive fire, making it dangerous for your foes to move about or respond. You gain the Suppressive Fire action.</p><br />
	<br />
<h3 className="sub-header-bg all-indent">Suppressive Fire <div className="threeaction" /></h3> <p className="no-indent all-indent"><b>Requirements:</b> You are weilding a ranged weapon with the autofire trait.</p> <p className="no-indent all-indent"><b>Area:</b> 20-meter cone</p> <p className="no-indent all-indent"><b>Saving Throw:</b> Reflex; <b>Duration:</b> until the beginning of your next turn or sooner (see below)</p> <hr /> <p className="no-indent all-indent">You fire rapidly and precisely, targeting anyone who does not have sufficient cover.</p> <p className="no-indent all-indent">Until the start of your next turn, when a creature does any of the following, they must first make a Reflex save against your ability DC.</p> <ul> <li>Moves into or through the target area and does not have at least greater cover against your attacks.</li> <li>Takes any action other than <a href="https://2e.aonprd.com/Actions.aspx?ID=90">Take Cover</a> without having at least greater cover against your attacks.</li> <li>Takes any action that would cause them to lose greater cover against your attacks, such as a Strike.</li> </ul>
<br />
	<br />
<p>Each creature can only be affected by your Suppressive Fire once per round. You can choose not to affect a creature, but if you do, Supressive Fire ends immediately. Regardless of how it ends, afterwards, you can't use Suppressive Fire again for 1 round.</p>
<br />
	<br />
<p className="no-indent all-indent"><b>Success:</b> The target is unaffected.</p> <p className="no-indent all-indent"><b>Failure:</b> The target takes damage as if you had hit them with a ranged Strike using the weapon you are weilding. Do not add precision damage to this Strike.</p> <p className="no-indent all-indent"><b>Critical Failure:</b> As failure, but the target takes critical damage instead, and if the target was attempting an attack or manipulate action, you disrupt that action.</p>
<br />
</div>