import React from 'react';
import { Link } from 'react-router-dom'

export const FeatRoughMethods = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Rough Methods
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scoundrel</div>
		<div className="trait">Specialization</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/improvedskilltraining">Improved Skill Training</Link>, <Link to="/sw2e/feats/toughness">Toughness</Link>, expert in Fortitude saves, trained in <Link to="sw2e/skills/intimidate">Intimidate</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You prefer to strong-arm or intimidate others rather than rely on finesse or fancy tricks.</p><br />
	<p className="all-indent no-indent justify">You use whatever tools you have at hand to get the job done. When you critically succeed at an attack roll using a melee weapon and the target has the flat-footed condition (unable to focus on defending itself), you also apply the critical specialization effect for the weapon you're wielding.</p><br />
	<p className="all-indent no-indent justify">If you have the <Link to="/sw2e/feats/sneakattack">Sneak Attack</Link> feat, you can deal sneak attack damage with any melee weapon, in addition to the weapons listed in the sneak attack feat description.</p><br />
	<p className="all-indent no-indent justify">Additionally, you can choose Strength as your key ability for determining your ability DC. Once this choice is made, it cannot be changed.</p><br />
	<br />
	<div className="info indent">
		<h3>Key Terms</h3>
		<br />
		<p className="no-indent justify"><b>Specialization:</b> A character may not select more than one specialization feat from a given archetype. For example, a Scoundrel who selects Fast Hands may not also select Rough Methods. They could still select Weapon Focus from the Soldier archetype, however.</p>
	</div><br />
</div>