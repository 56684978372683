import React from 'react';

export const FeatInfoInnateMagic = () =>
<div>
	<h3>Table 2-4: Spellcasting Traditions by Path</h3>
	<div className="columns">
		<div className="column-md">
			<table>
				<thead>
					<tr>
						<th>Path</th>
						<th>Tradition</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><b>Path of the Angel</b></td>
						<td>Divine</td>
					</tr>
					<tr>
						<td><b>Path of the Archon</b></td>
						<td>Arcane or Divine</td>
					</tr>
					<tr>
						<td><b>Path of the Azata</b></td>
						<td>Divine or Primal</td>
					</tr>
					<tr>
						<td><b>Path of the Demon</b></td>
						<td>Arcane or Divine</td>
					</tr>
					<tr>
						<td><b>Path of the Devil</b></td>
						<td>Arcane or Divine</td>
					</tr>
					<tr>
						<td><b>Path of the Dragon</b></td>
						<td>Arcane or Primal</td>
					</tr>
					<tr>
						<td><b>Path of the Elemental</b></td>
						<td>Arcane or Primal</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div className="column-md indent">
			<table>
				<thead>
					<tr>
						<th>Path</th>
						<th>Tradition</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><b>Path of Enlightenment</b></td>
						<td>Divine or Occult</td>
					</tr>
					<tr>
						<td><b>Path of the Fey</b></td>
						<td>Occult or Primal</td>
					</tr>
					<tr>
						<td><b>Path of the Rakshasa</b></td>
						<td>Arcane or Occult</td>
					</tr>
					<tr>
						<td><b>Path of the Shadow</b></td>
						<td>Arcane or Occult</td>
					</tr>
					<tr>
						<td><b>Path of the Stars</b></td>
						<td>Arcane or Occult</td>
					</tr>
					<tr>
						<td><b>Path of the Titan</b></td>
						<td>Divine or Primal</td>
					</tr>
					<tr>
						<td><b>Path of the Undead</b></td>
						<td>Arcane, Divine or Occult</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>