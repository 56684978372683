import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SW2eFeatsAbout } from './feats/about';
import { FeatArmorFocus } from './feats/armorfocus';
import { FeatAttackofOpportunity } from './feats/attackofopportunity';
import { FeatBreadthofExperience } from './feats/breadthofexperience';
import { FeatChemicalEngineering } from './feats/chemicalengineering';
import { FeatChemicalSynthesis } from './feats/chemicalsynthesis';
import { FeatCustomModifications } from './feats/custommodifications';
import { FeatDoubleStrike } from './feats/doublestrike';
import { FeatDroidCompanion } from './feats/droidcompanion';
import { FeatEquipmentManufacturing } from './feats/equipmentmanufacturing';
import { FeatFastHands } from './feats/fasthands';
import { FeatFavoredTerrain } from './feats/favoredterrain';
import { FeatForceSensitivity } from './feats/forcesensitivity';
import { FeatGrenadier } from './feats/grenadier';
import { FeatImprovedSkillTraining } from './feats/improvedskilltraining';
import { FeatAdvancedSkillTraining } from './feats/advancedskilltraining';
import { FeatMastermind } from './feats/mastermind';
import { FeatOvercharge } from './feats/overcharge';
import { FeatQuickRepairs } from './feats/quickrepairs';
import { FeatRoughMethods } from './feats/roughmethods';
import { FeatSkirmish } from './feats/skirmish';
import { FeatSneakAttack } from './feats/sneakattack';
import { FeatSuppressiveFire } from './feats/suppressivefire';
import { FeatToughness } from './feats/toughness';
import { FeatImprovedToughness } from './feats/improvedtoughness';
import { FeatLightArmorTraining } from './feats/lightarmortraining';
import { FeatAdvancedToughness } from './feats/advancedtoughness';
import { FeatWeaponFocus } from './feats/weaponfocus';
import { FeatMobility } from './feats/mobility';
import { FeatSubduingStrikes } from './feats/subduingstrikes';
import { FeatTwinFeint } from './feats/twinfeint';
import { FeatTerrainStride } from './feats/terrainstride';
import { FeatGreatFortitude } from './feats/greatfortitude';
import { FeatImprovedWeaponFocus } from './feats/improvedweaponfocus';
import { FeatIronWill } from './feats/ironwill';
import { FeatLightningReflexes } from './feats/lightningreflexes';
import { FeatSkirmishMastery } from './feats/skirmishmastery';
import { FeatSneakAttackMastery } from './feats/sneakattackmastery';
import { FeatTwinDistraction } from './feats/twindistraction';
import { FeatSoldierWeaponExpertise } from './feats/soldierweaponexpertise';
import { FeatTechSpecialistWeaponExpertise } from './feats/techspecialistweaponexpertise';
import { FeatWeaponSpecialization } from './feats/weaponspecialization';
import { FeatTechSpecialistAbilityExpertise } from './feats/techspecialistabilityexpertise';
import { FeatJuggernaut } from './feats/juggernaut';
import { FeatSoldierArmorExpertise } from './feats/soldierarmorexpertise';
import { FeatImprovedSkirmishMastery } from './feats/improvedskirmishmastery';
import { FeatImprovedSneakAttackMastery } from './feats/improvedsneakattackmastery';
import { FeatAdvancedWeaponFocus } from './feats/advancedweaponfocus';
import { FeatSoldierWeaponMastery } from './feats/soldierweaponmastery';
import { FeatAdvancedSkirmishMastery } from './feats/advancedskirmishmastery';
import { FeatAdvancedSneakAttackMastery } from './feats/advancedsneakattackmastery';

export const SW2eFeats = () =>
<div className="text-container">
	<Switch>
		<Route exact path="/sw2e/feats" component={SW2eFeatsAbout} />
		<Route path="/sw2e/feats/armorfocus" component={FeatArmorFocus}/>
		<Route path="/sw2e/feats/attackofopportunity" component={FeatAttackofOpportunity}/>
		<Route path="/sw2e/feats/breadthofexperience" component={FeatBreadthofExperience}/>
		<Route path="/sw2e/feats/chemicalengineering" component={FeatChemicalEngineering}/>
		<Route path="/sw2e/feats/chemicalsynthesis" component={FeatChemicalSynthesis}/>
		<Route path="/sw2e/feats/custommodifications" component={FeatCustomModifications}/>
		<Route path="/sw2e/feats/doublestrike" component={FeatDoubleStrike}/>
		<Route path="/sw2e/feats/droidcompanion" component={FeatDroidCompanion}/>
		<Route path="/sw2e/feats/equipmentmanufacturing" component={FeatEquipmentManufacturing}/>
		<Route path="/sw2e/feats/fasthands" component={FeatFastHands}/>
		<Route path="/sw2e/feats/favoredterrain" component={FeatFavoredTerrain}/>
		<Route path="/sw2e/feats/forcesensitivity" component={FeatForceSensitivity}/>
		<Route path="/sw2e/feats/grenadier" component={FeatGrenadier}/>
		<Route path="/sw2e/feats/improvedskilltraining" component={FeatImprovedSkillTraining}/>
		<Route path="/sw2e/feats/advancedskilltraining" component={FeatAdvancedSkillTraining}/>
		<Route path="/sw2e/feats/mastermind" component={FeatMastermind}/>
		<Route path="/sw2e/feats/overcharge" component={FeatOvercharge}/>
		<Route path="/sw2e/feats/quickrepairs" component={FeatQuickRepairs}/>
		<Route path="/sw2e/feats/roughmethods" component={FeatRoughMethods}/>
		<Route path="/sw2e/feats/skirmish" component={FeatSkirmish}/>
		<Route path="/sw2e/feats/sneakattack" component={FeatSneakAttack}/>
		<Route path="/sw2e/feats/suppressivefire" component={FeatSuppressiveFire}/>
		<Route path="/sw2e/feats/toughness" component={FeatToughness}/>
		<Route path="/sw2e/feats/improvedtoughness" component={FeatImprovedToughness}/>
		<Route path="/sw2e/feats/lightarmortraining" component={FeatLightArmorTraining}/>
		<Route path="/sw2e/feats/advancedtoughness" component={FeatAdvancedToughness}/>
		<Route path="/sw2e/feats/weaponfocus" component={FeatWeaponFocus}/>
		<Route path="/sw2e/feats/mobility" component={FeatMobility}/>
		<Route path="/sw2e/feats/subduingstrikes" component={FeatSubduingStrikes}/>
		<Route path="/sw2e/feats/twinfeint" component={FeatTwinFeint}/>
		<Route path="/sw2e/feats/terrainstride" component={FeatTerrainStride}/>
		<Route path="/sw2e/feats/greatfortitude" component={FeatGreatFortitude}/>
		<Route path="/sw2e/feats/improvedweaponfocus" component={FeatImprovedWeaponFocus}/>
		<Route path="/sw2e/feats/ironwill" component={FeatIronWill}/>
		<Route path="/sw2e/feats/lightningreflexes" component={FeatLightningReflexes}/>
		<Route path="/sw2e/feats/skirmishmastery" component={FeatSkirmishMastery}/>
		<Route path="/sw2e/feats/sneakattackmastery" component={FeatSneakAttackMastery}/>
		<Route path="/sw2e/feats/twindistraction" component={FeatTwinDistraction}/>
		<Route path="/sw2e/feats/soldierweaponexpertise" component={FeatSoldierWeaponExpertise}/>
		<Route path="/sw2e/feats/techspecialistweaponexpertise" component={FeatTechSpecialistWeaponExpertise}/>
		<Route path="/sw2e/feats/weaponspecialization" component={FeatWeaponSpecialization}/>
		<Route path="/sw2e/feats/techspecialistabilityexpertise" component={FeatTechSpecialistAbilityExpertise}/>
		<Route path="/sw2e/feats/juggernaut" component={FeatJuggernaut}/>
		<Route path="/sw2e/feats/soldierarmorexpertise" component={FeatSoldierArmorExpertise}/>
		<Route path="/sw2e/feats/improvedskirmishmastery" component={FeatImprovedSkirmishMastery}/>
		<Route path="/sw2e/feats/improvedsneakattackmastery" component={FeatImprovedSneakAttackMastery}/>
		<Route path="/sw2e/feats/advancedweaponfocus" component={FeatAdvancedWeaponFocus}/>
		<Route path="/sw2e/feats/soldierweaponmastery" component={FeatSoldierWeaponMastery}/>
		<Route path="/sw2e/feats/advancedskirmishmastery" component={FeatAdvancedSkirmishMastery}/>
		<Route path="/sw2e/feats/advancedsneakattackmastery" component={FeatAdvancedSneakAttackMastery}/>
	</Switch>
</div>
