import React from 'react';
import { FeatInfoManifestationsOfPower } from './extrainfo/manifestationsofpower';
export const FeatManifestPower = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Manifest Power
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You have learned to focus your power to produce dramatic effects. You gain 3 mythic spells chosen from the list below. You can choose any spell with a spell level equal to your mythic rank or lower. Each time you gain a mythic rank, you can swap out one of these manifestations for another from the list for which you qualify.</p>
        <br />
        <div className="columns">
  <div className="column-md">
    <FeatInfoManifestationsOfPower />
  </div>
</div>

	</div>
	<div className="spacer" />
</div>