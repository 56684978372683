import React from 'react';
import threeaction from '../../../resources/3-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellFinalGift = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Final Gift
			<div className="spacer" />
			<span className="right">Mythic 7</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Healing" /></div>
			<div className="trait"><Trait name="Necromancy" /></div>
			<div className="trait"><Trait name="Positive" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="3 Action" src={threeaction} /> </li>
			<li><b>Range:</b> 100 feet; <b>Targets:</b> living and dead creatures of your choice within range</li>
		</ul>
		<hr />
		<p className="no-indent">All dead targets are returned to life with the effect of a critically successful <a href="https://2e.aonprd.com/Rituals.aspx?ID=20">resurrect</a> ritual of a level equal to your mythic rank. Regardless of whether they were alive or dead, all targets are restored to full Hit Points, recover from all harmful conditions and recieve a +1 status bonus to attack rolls, Perception checks, saving throws, and skill checks for 1 week.</p>
		<p className="no-indent">Afterwards, you die. No effect can prevent this death (though you can still be raised from the dead as normal.) You and all targets become immune to <i>final gift</i> for 1 week.</p>
		<p className="no-indent">Casting this spell costs at least 5 Mythic Power Points, instead of 1, and consumes all of your remaining Mythic Power Points.</p>
	</div>
	<div className="spacer" />
</div>