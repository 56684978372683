import React from 'react';

export const FeatExpandedInfluence = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Expanded Influence
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Angel</div>
			<div className="trait">Archon</div>
			<div className="trait">Azata</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Enlightenment</div>
			<div className="trait">Rakshasa</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Your abilities reach farther than some would think possible. Double the radius of any aura you emanate and any emanation centered on you that has a duration of at least 1 round. Double the range of any spell you cast and any ability you use that has a range greater than touch.</p>
	</div>
	<div className="spacer" />
</div>