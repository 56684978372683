import React from 'react';

export const FeatSignatureSpell = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Signature Spell
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> Ability to cast a non-cantrip spell</li>
		</ul>
		<hr />
		<p className="no-indent">Choose a spell from your spell repertoire or a spell you are able to cast as an Innate Spell. You can cast this spell as an Innate spell once per 10 minutes. It is automatically heightened to your mythic rank. If your chosen spell does not have the <a href="https://2e.aonprd.com/Traits.aspx?ID=89">healing</a> trait and does not inflict a harmful condition, you can instead cast the chosen spell at will. If the chosen spell has a duration and you cast the spell again on a second target, the spell on the first target ends.</p>
		<p className="no-indent">Each time you gain a mythic rank, you can change your signature spell to a different spell of your choice.</p>
	</div>
	<div className="spacer" />
</div>