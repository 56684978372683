import React from 'react';
import { Link } from 'react-router-dom'

export const FeatStomp = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Stomp
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Demon</div>
			<div className="trait">Dragon</div>
			<div className="trait">Elemental</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Elemental] earth element</li>
		</ul>
		<hr />
		<p className="no-indent">You can shake the ground, knocking down those around you and creating rough terrain. You gain the <i><Link to="/mythic/spells/stomp">stomp</Link></i> mythic cantrip, which you can cast at no cost.</p>
	</div>
	<div className="spacer" />
</div>