import React from 'react';

export const FeatGreatFortitude = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Great Fortitude
		<div className="spacer" />
		<span className="right">Feat 5</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Noble</div>
		<div className="trait">Scoundrel</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<hr />
	<p className="all-indent no-indent justify">Your physique is incredibly hardy. Your proficiency rank for Fortitude saves increases to expert.</p><br />
</div>