import React from 'react';

export const FeatInfiniteVariety = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Infinite Variety
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Fey</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You gain an ancestry feat, a general feat, and a class or archetype feat. The ancestry feat can be chosen from any ancestry. If you choose a class feat, it can be chosen from any class. If you choose an archetype feat, you do not need to have the prerequisite archetype dedication feat. You must satisfy any other prerequisites.</p>
		<p className="no-indent">This feat can be taken more than once. Each time you do, you gain another ancestry feat, general feat and class or archetype feat, with the same restrictions.</p>
	</div>
	<div className="spacer" />
</div>