import React from 'react';
import { Link } from 'react-router-dom'
export const AngelMythicFeats = () =>
<div>
	<h2 className="header-bg">Angel Mythic Feats</h2>
	<p className="no-indent">Every mythic rank at which you gain an Angel feat, select one of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm full-width">
		<thead>
			<tr>
				<th className="col-3">Name</th>
				<th className="col-1">Rank</th>
				<th className="col-3">Traits</th>
				<th className="col-3">Prerequisites</th>
				<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You can survive an attack that would have killed you with 1 hit point.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/piercethedarkness">Pierce the Darkness</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain greater darkvision.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/changeshape">Change Shape</Link></td>
				<td className="center">2</td>
				<td>Concentrate, Mythic, Polymorph, Transmutation</td>
				<td>—</td>
				<td>Transform into other creatures.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/exoticshape">Exotic Shape</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Transform into several exotic forms.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mysticsight">Mystic Sight</Link></td>
				<td className="center">2</td>
				<td>Detection, Divination, Mythic</td>
				<td>—</td>
				<td>You can see magic.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/perfectself">Perfect Self</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Time and disease have no hold over you and your body will not fail you.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythiccohort">Mythic Cohort</Link></td>
				<td className="center">3</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You grant a trusted individual access to your mythic power.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/auraofdread">Aura of Dread</Link></td>
				<td className="center">4</td>
				<td>Aura, Emotion, Fear, Mental, Mythic</td>
				<td>—</td>
				<td>Your presence frightens those around you.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/expandedinfluence">Expanded Influence</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Your auras and abilities reach further.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/flawlessstrike">Flawless Strike</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>—</td>
				<td>When you concentrate your power, you strike without error.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/imbuedarms">Imbued Arms</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Imbue your weapons with elemental or alignment damage.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicflight">Mythic Flight</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Elemental] air element; [Fey] airborne form</td>
				<td>You can sprout wings and fly.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/unscathed">Unscathed</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td>You can shrug off blows and ignore effects that would slow you down.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/impossibleprecision">Impossible Precision</Link></td>
				<td className="center">6</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Your follow up attacks are just as deadly as the first.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/truesight">True Sight</Link></td>
				<td className="center">6</td>
				<td>Divination, Mythic, Revelation</td>
				<td><Link to="/mythic/feats/mysticsight">Mystic Sight</Link></td>
				<td>You see things as they actually are.</td>
			</tr>
		</tbody>
	</table>
</div>
