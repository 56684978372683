import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MythicSpellsAbout } from './spells/about';
import { SpellHymnofGlory } from './spells/hymnofglory';
import { SpellLightofTruth } from './spells/lightoftruth';
import { SpellShieldofLife } from './spells/shieldoflife';
import { SpellSwordofRetribution } from './spells/swordofretribution';
import { SpellBlowback } from './spells/blowback';
import { SpellFeatofAgility } from './spells/featofagility';
import { SpellFeatofEndurance } from './spells/featofendurance';
import { SpellFeatofStrength } from './spells/featofstrength';
import { SpellMiraculousSurvival } from './spells/miraculoussurvival';
import { SpellMythicSurge } from './spells/mythicsurge';
import { SpellSuddenBrilliance } from './spells/suddenbrilliance';
import { SpellSuddenGranduer } from './spells/suddengranduer';
import { SpellSuddenInsight } from './spells/suddeninsight';
import { SpellDevestatingSmash } from './spells/devestatingsmash';
import { SpellEnergeticSpell } from './spells/energeticspell';
import { SpellMythicReachSpell } from './spells/mythicreachspell';
import { SpellMythicWidenSpell } from './spells/mythicwidenspell';
import { SpellPreciseSpell } from './spells/precisespell';
import { SpellProjectForce } from './spells/projectforce';
import { SpellQuickeningPresence } from './spells/quickeningpresence';
import { SpellShelteringPresence } from './spells/shelteringpresence';
import { SpellStomp } from './spells/stomp';
import { SpellWalktheWorld } from './spells/walktheworld';
import { SpellAscendSpell } from './spells/ascendspell';
import { SpellBorrowTime } from './spells/borrowtime';
import { SpellBurstofSpeed } from './spells/burstofspeed';
import { SpellCommandtheMindless } from './spells/commandthemindless';
import { SpellInvigoratingPresence } from './spells/invigoratingpresence';
import { SpellMagicSurge } from './spells/magicsurge';
import { SpellMythicExtendSpell } from './spells/mythicextendspell';
import { SpellSevenLeaguesLeap } from './spells/sevenleaguesleap';
import { SpellSuddenStrike } from './spells/suddenstrike';
import { SpellFlawlessStrike } from './spells/flawlessstrike';
import { SpellGiftofPower } from './spells/giftofpower';
import { SpellSculptSpell } from './spells/sculptspell';
import { SpellSealPower } from './spells/sealpower';
import { SpellShadowDouble } from './spells/shadowdouble';
import { SpellTitanicRage } from './spells/titanicrage';
import { SpellBlastSpell } from './spells/blastspell';
import { SpellForceofWill } from './spells/forceofwill';
import { SpellMythicQuickenSpell } from './spells/mythicquickenspell';
import { SpellTideofUndeath } from './spells/tideofundeath';
import { SpellUnscathed } from './spells/unscathed';
import { SpellWordofPower } from './spells/wordofpower';
import { SpellFierceRegeneration } from './spells/fierceregeneration';
import { SpellFinalGift } from './spells/finalgift';
import { SpellTouchofUnmaking } from './spells/touchofunmaking';
import { SpellUnstoppableResolve } from './spells/unstoppableresolve';
import { SpellRayofAnnihilation } from './spells/rayofannihilation';
import { SpellRecreation } from './spells/recreation';

export const MythicSpells = () =>
<div>
	<Switch>
		<Route exact path="/mythic/spells" component={MythicSpellsAbout} />
		<Route path="/mythic/spells/hymnofglory" component={SpellHymnofGlory}/>
		<Route path="/mythic/spells/lightoftruth" component={SpellLightofTruth}/>
		<Route path="/mythic/spells/shieldoflife" component={SpellShieldofLife}/>
		<Route path="/mythic/spells/swordofretribution" component={SpellSwordofRetribution}/>
		<Route path="/mythic/spells/blowback" component={SpellBlowback}/>
		<Route path="/mythic/spells/featofagility" component={SpellFeatofAgility}/>
		<Route path="/mythic/spells/featofendurance" component={SpellFeatofEndurance}/>
		<Route path="/mythic/spells/featofstrength" component={SpellFeatofStrength}/>
		<Route path="/mythic/spells/miraculoussurvival" component={SpellMiraculousSurvival}/>
		<Route path="/mythic/spells/mythicsurge" component={SpellMythicSurge}/>
		<Route path="/mythic/spells/suddenbrilliance" component={SpellSuddenBrilliance}/>
		<Route path="/mythic/spells/suddengranduer" component={SpellSuddenGranduer}/>
		<Route path="/mythic/spells/suddeninsight" component={SpellSuddenInsight}/>
		<Route path="/mythic/spells/devestatingsmash" component={SpellDevestatingSmash}/>
		<Route path="/mythic/spells/energeticspell" component={SpellEnergeticSpell}/>
		<Route path="/mythic/spells/mythicreachspell" component={SpellMythicReachSpell}/>
		<Route path="/mythic/spells/mythicwidenspell" component={SpellMythicWidenSpell}/>
		<Route path="/mythic/spells/precisespell" component={SpellPreciseSpell}/>
		<Route path="/mythic/spells/projectforce" component={SpellProjectForce}/>
		<Route path="/mythic/spells/quickeningpresence" component={SpellQuickeningPresence}/>
		<Route path="/mythic/spells/shelteringpresence" component={SpellShelteringPresence}/>
		<Route path="/mythic/spells/stomp" component={SpellStomp}/>
		<Route path="/mythic/spells/walktheworld" component={SpellWalktheWorld}/>
		<Route path="/mythic/spells/ascendspell" component={SpellAscendSpell}/>
		<Route path="/mythic/spells/borrowtime" component={SpellBorrowTime}/>
		<Route path="/mythic/spells/burstofspeed" component={SpellBurstofSpeed}/>
		<Route path="/mythic/spells/commandthemindless" component={SpellCommandtheMindless}/>
		<Route path="/mythic/spells/invigoratingpresence" component={SpellInvigoratingPresence}/>
		<Route path="/mythic/spells/magicsurge" component={SpellMagicSurge}/>
		<Route path="/mythic/spells/mythicextendspell" component={SpellMythicExtendSpell}/>
		<Route path="/mythic/spells/sevenleaguesleap" component={SpellSevenLeaguesLeap}/>
		<Route path="/mythic/spells/suddenstrike" component={SpellSuddenStrike}/>
		<Route path="/mythic/spells/flawlessstrike" component={SpellFlawlessStrike}/>
		<Route path="/mythic/spells/giftofpower" component={SpellGiftofPower}/>
		<Route path="/mythic/spells/sculptspell" component={SpellSculptSpell}/>
		<Route path="/mythic/spells/sealpower" component={SpellSealPower}/>
		<Route path="/mythic/spells/shadowdouble" component={SpellShadowDouble}/>
		<Route path="/mythic/spells/titanicrage" component={SpellTitanicRage}/>
		<Route path="/mythic/spells/blastspell" component={SpellBlastSpell}/>
		<Route path="/mythic/spells/forceofwill" component={SpellForceofWill}/>
		<Route path="/mythic/spells/mythicquickenspell" component={SpellMythicQuickenSpell}/>
		<Route path="/mythic/spells/tideofundeath" component={SpellTideofUndeath}/>
		<Route path="/mythic/spells/unscathed" component={SpellUnscathed}/>
		<Route path="/mythic/spells/wordofpower" component={SpellWordofPower}/>
		<Route path="/mythic/spells/fierceregeneration" component={SpellFierceRegeneration}/>
		<Route path="/mythic/spells/finalgift" component={SpellFinalGift}/>
		<Route path="/mythic/spells/touchofunmaking" component={SpellTouchofUnmaking}/>
		<Route path="/mythic/spells/unstoppableresolve" component={SpellUnstoppableResolve}/>
		<Route path="/mythic/spells/rayofannihilation" component={SpellRayofAnnihilation}/>
		<Route path="/mythic/spells/recreation" component={SpellRecreation}/>
	</Switch>
</div>
