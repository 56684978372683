import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellBurningHands = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Burning Hands&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=30"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Evocation</div>
			<div className="trait">Fire</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Area</b> 15-foot cone</li>
			<li><b>Saving Throw</b> basic Reflex</li>
		</ul>
		<hr />
		<p className="no-indent">Gouts of flame rush from your hands. You deal 2d6 fire damage to creatures in the area.</p>
		<hr />
			<p className="no-indent"><b>Heightened (+1)</b> The damage increases by 2d6.</p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> The area becomes a 30-foot cone and enemies that fail or critically fail their saving throw take persistant fire damage equal to half the damage dealt by the spell.</span></p>
	</div>
	<div className="spacer" />
</div>