import React from 'react';

export const SpellNondetection = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Nondetection&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="http://2e.aonprd.com/Spells.aspx?ID=209"> </a>
			<div className="spacer" />
			<span className="right">Spell 3</span>
		</h2>
		<div className="traits">
			<div className="trait">Uncommon</div>
			<div className="trait">Abjuration</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult, primal</li>
			<li><b>Cast</b> 10 minutes(material, somatic, verbal)</li>
			<li><b>Range</b> touch; <b>Targets:</b> 1 creature or object</li>
			<li><b>Duration</b> 8 hours</li>
		</ul>
		<hr />
		<p className="no-indent">You erect protective wards that make the target difficult to detect via magic. Nondetection attempts to counteract all detection, revelation, and scrying divinations made against the target or the target's gear throughout the duration, counting cantrips as 1st-level spells for this purpose. Successfully counteracting a divination that targets an area or multiple targets negates the effects only for nondetection's target.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> Nondetection treats any attempt to counteract a spell from a non-mythic source as a critical success.</span></p>
	</div>
	<div className="spacer" />
</div>