import React from 'react';

export const SW2ePowersLight = () =>
<div>
	<h3 className="header-bg"> Basic Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 1st-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 2nd-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 3rd-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 4th-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 5th-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 6th-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 7th-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 8th-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 9th-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 10th-Level Light Side Powers</h3>
	<ul className="unstyled">
	</ul>
</div>