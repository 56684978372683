import React from 'react';
import { Link } from 'react-router-dom';
export const FeyForms = () =>
<div className="columns">
	<div className="col-lg">
		<h2 className="header-bg">Fey Forms</h2>
		<p>Your fey abilities manifest as one of the following forms. Your choice of form grants you innate spellcasting,
			enhanced senses and movement, and determines your resistances and the availability of some fey path
			feats.</p>
		<br />
		<h2 className="header-bg">Aquatic Form</h2>
		<p>You aspire to be like the elegant and beautiful aquatic fey, such as nereids and naiads, or maybe more sinister
			water dwelling creatures like sirens and rusalka. Either way, you gain great power over the water and creatures
			who find themselves in or near it.</p>
		<br />
		<h3 className="sub-header-bg">Fey Senses</h3>
		<p>You gain low-light vision. If you already have low-light vision, you gain darkvision instead.</p>
		<br />
		<h3 className="sub-header-bg">Fey Magic</h3>
		<p>You can cast <i><a href="https://2e.aonprd.com/Spells.aspx?ID=34">charm</a></i> and <i><Link to="/contenttweaks/spelltweaks/spells/createwater">create water</Link></i> as
			primal innate spells at will. They are heightened to a spell level equal to your mythic rank. You have the primal
			spellcasting tradition for the <Link to="/mythic/feats/innatemagic">innate magic</Link> mythic feat.</p>
		<br />
		<h3 className="sub-header-bg">Fey Resistance</h3>
		<p>You gain resistance to fire and cold damage.</p>
		<br />
		<h3 className="sub-header-bg">Fey Movement</h3>
		<p>You gain the <Link to="/mythic/feats/incredibleswimmer">incredible swimmer</Link> mythic feat. If you already
			have the <i>incredible swimmer</i> feat, you instead gain a fey path feat of your choice. In addition, you can
			breath underwater.</p>
		<br />

		<h2 className="header-bg">Woodland Form</h2>
		<p>Woodland fey are numerous and varied. From dryads to twigjacks though, they invaribly call the natural world
			their own, and place great value upon it. The might of nature and this reverance appeal to you.</p>
		<br />
		<h3 className="sub-header-bg">Fey Senses</h3>
		<p>You gain low-light vision. If you already have low-light vision, you gain darkvision instead.</p>
		<br />
		<h3 className="sub-header-bg">Fey Magic</h3>
		<p>Choose either the occult or primal spellcasting tradition. You can
			cast <i><a href="https://2e.aonprd.com/Spells.aspx?ID=34">faerie fire</a></i> and <i><a href="https://2e.aonprd.com/Spells.aspx?ID=330">tanglefoot</a></i> as
			innate spells of the chosen tradition at will, and you have the same spellcasting tradition for
			the <Link to="/mythic/feats/innatemagic">innate magic</Link> mythic feat.</p>
		<br />
		<h3 className="sub-header-bg">Fey Resistance</h3>
		<p>You gain resistance to cold and electricity damage.</p>
		<br />
		<h3 className="sub-header-bg">Fey Movement</h3>
		<p>You gain the <Link to="/mythic/feats/impossiblespeed">impossible speed</Link> mythic feat. If you already
			have the <i>impossible speed</i> feat, you instead gain a fey path feat of your choice. In addition, when you
			take a Stride action to move half your Speed or less, that movement does not trigger reactions.</p>
		<br />

		<h2 className="header-bg">Highland Form</h2>
		<p>Many of the most mysterious fey live in and around people, either on the city streets or in the highlands nearby.
			Like them, civilization fascinates you, though you can never truely be a part of it.</p>
		<br />
		<h3 className="sub-header-bg">Fey Senses</h3>
		<p>You gain low-light vision. If you already have low-light vision, you gain darkvision instead.</p>
		<br />
		<h3 className="sub-header-bg">Fey Magic</h3>
		<p>Choose either the occult or primal spellcasting tradition. You can
			cast <i><a href="https://2e.aonprd.com/Spells.aspx?ID=34">charm</a></i> and <i><a href="https://2e.aonprd.com/Spells.aspx?ID=696">ill omen</a></i> as
			occult innate spells at will, and you have the occult spellcasting tradition for
			the <Link to="/mythic/feats/innatemagic">innate magic</Link> mythic feat.</p>
		<br />
		<h3 className="sub-header-bg">Fey Resistance</h3>
		<p>You gain immunity to <a href="https://2e.aonprd.com/Traits.aspx?ID=110">misfortune</a> effects, and resistance to negative damage.</p>
		<br />
		<h3 className="sub-header-bg">Fey Movement</h3>
		<p>You gain the <Link to="/mythic/feats/shadowstep">shadow step</Link> mythic feat. If you already
			have the <i>shadow step</i> feat, you instead gain a fey path feat of your choice. In addition, when using
			shadow step, you can choose to become invisible until the beginning of your next turn or until you are no
			longer in an area of dim light or darkness, whichever comes first. This is an illusion effect with a spell
			level equal to your mythic rank.</p>
		<br />
	</div>
</div>