import React from 'react';

export const FeatAuraofDread = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Aura of Dread
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Aura</div>
			<div className="trait">Emotion</div>
			<div className="trait">Fear</div>
			<div className="trait">Mental</div>
			<div className="trait">Mythic</div>
			<div className="trait">Angel</div>
			<div className="trait">Archon</div>
			<div className="trait">Demon</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Your mere presence can terrify those around you. You gain an aura of fear with a range of 60 feet. An enemy that enters or ends its turn within the aura must attempt a will save against the higher of your spell DC or class DC. A creature's frightened condition (including ones gained from other sources) does not reduce as long as the creature remains in the aura.</p>
        <br />
        <div className='no-indent'>
  <p><b>Success</b> The creature is unaffected and temporarily immune to your Aura of Dread for 1 minute.</p>
  <p><b>Failure</b> The creature is <a href="https://2e.aonprd.com/Conditions.aspx?ID=19">frightened 1</a></p>
  <p><b>Critical Failure</b> The creature is frightened 2.</p>
</div>

	</div>
	<div className="spacer" />
</div>