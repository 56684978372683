import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellFloatingDisk = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Floating Disk&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=124"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Conjuration</div>
			<div className="trait">Force</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Duration</b> 8 hours</li>
		</ul>
		<hr />
		<p className="no-indent">A disk of magical force materializes adjacent to you. This disk is 2 feet in diameter and follows 5 feet behind you <span className="tweak-color">if you move away from it</span>, floating just above the ground. It holds up to 5 Bulk of objects (though they must be able to fit and balance on its surface). Any objects atop the disk fall to the ground when the spell ends.</p>
		<p className="no-indent"><span className="tweak-color">If the disk is overloaded, it falls to the ground and is unable to move. The spell ends if you move more than 30 feet away from the disk.</span></p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+1)</b> The diameter of the disk increases by 1 foot and the amount of bulk the disk can hold increases by 3.</span></p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Whatever problem they were trying to solve with all of those restrictions is mysterious indeed, but they are positively onerous and a bit confusing. Why does the disk care whether a backpack or a bird is riding on it? Why can the ground move it up and down but not a pair of hands? Why does everything <i>cancel the spell</i> instead of just temporarily disrupting it?</p>
  		<p>Removing those restrictions does create some ambiguity though, so a caveat in the description is needed to keep players from arguing that it can now be used as a high-speed hoverboard that only goes in reverse.</p>
  		<p>The heightening effect is mostly for the sake of flavor, thought it does have some uses. Surely a wizard would devise a way to make the spell <i>carry more.</i></p>
	</div>
	<div className="spacer" />
</div>