import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellUnnaturalLust = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Unnatural Lust&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://www.aonprd.com/SpellDisplay.aspx?ItemName=Unnatural%20Lust"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Enchantment</div>
			<div className="trait">Incapacitation</div>
			<div className="trait">Mental</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Range</b> 30 feet</li>
			<li><b>Duration</b> 1 or more rounds (see below)</li>
		</ul>
		<hr />
		<p className="no-indent">You fill the target with lust and desire for a creature or object you designate. The target must attempt a Will save.</p><br />
		<p className="no-indent"><b>Critical Success</b> The target is unaffected.</p>
		<p className="no-indent"><b>Success</b> The target is caught off-guard by its unexpected desire, causing it to be flat-footed for 1 round.</p>
		<p className="no-indent"><b>Failure</b> The target is flat-footed for 1 round, and is fascinated by the subject for 1 minute.</p>
		<p className="no-indent"><b>Critical Failure</b> As failure, but the target also spends all of it's actions on it's next turn to reach the subject of its desire and passionately kiss or caress it, <a href="https://2e.aonprd.com/Actions.aspx?ID=35">grappling</a> the subject if necessary.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Because apparently we're going there again. Wouldn't be Pathfinder if it weren't weird.</p>
	</div>
	<div className="spacer" />
</div>