import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { SpellTweaksAbout } from './about';
import { SpellTweaksRoute } from './spells';

export const SpellTweaks = () =><div>
	<h1>Spell Tweaks</h1>
	<Switch>
		<Route path="/contenttweaks/spelltweaks/spells"></Route>
		<Route path="/contenttweaks/spelltweaks">
			<p>Here you will find a number of tweaks to existing spells and some conversions of spells from 1e with the
				goal of relieving a lot of the frustration caused by finicky restrictions and missing utility. Many
				spells also offer Mythic heightening effects for use with the Sprocket
				Fox <Link to="/mythic">Mythic rules</Link>. Choose a catagory below to see more. Changes to an existing
				spell, along with new spells and spells converted from other rulesets
				will <span className="tweak-color">be highlighted.</span></p>
			<br />
		</Route>
	</Switch>
	
	<h2 className="center">
		<Switch>
			<Route exact path="/contenttweaks/spelltweaks">All Spells</Route>
			<Route path="/"><Link to="/contenttweaks/spelltweaks">All Spells</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/contenttweaks/spelltweaks/focus">Focus</Route>
			<Route path="/"><Link to="/contenttweaks/spelltweaks/focus">Focus</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/contenttweaks/spelltweaks/arcane">Arcane</Route>
			<Route path="/"><Link to="/contenttweaks/spelltweaks/arcane">Arcane</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/contenttweaks/spelltweaks/divine">Divine</Route>
			<Route path="/"><Link to="/contenttweaks/spelltweaks/divine">Divine</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/contenttweaks/spelltweaks/occult">Occult</Route>
			<Route path="/"><Link to="/contenttweaks/spelltweaks/occult">Occult</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/contenttweaks/spelltweaks/primal">Primal</Route>
			<Route path="/"><Link to="/contenttweaks/spelltweaks/primal">Primal</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/contenttweaks/spelltweaks/mythic">Mythic Heightened</Route>
			<Route path="/"><Link to="/contenttweaks/spelltweaks/mythic">Mythic Heightened</Link></Route>
		</Switch>
	</h2>
	<Switch>
		<Route path="/contenttweaks/spelltweaks/spells" component={SpellTweaksRoute} />
		<Route path="/contenttweaks/spelltweaks" component={SpellTweaksAbout} />
	</Switch>
</div>

