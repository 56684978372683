import React from 'react';

export const FeatCommunewithPower = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Commune with Power
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You can tap into your mythic nature to seek answers to the quandaries you encounter in your adventures. Once per day, you can perform the <i><a href="https://2e.aonprd.com/Rituals.aspx?ID=6">commune</a></i> ritual at no cost to contact source of your power. Instead of the normal casting time, you need only spend 1 hour of uninterrupted meditation, you do not  need to meet the skill requirements, and the result is always treated as a critical success. The source of your power may be limited in the knowledge it possesses or can deliver, as decided upon by the GM.</p>
	</div>
	<div className="spacer" />
</div>