import React from 'react';
import { Link } from 'react-router-dom'
import { FeatInfoAdvancedLegendaryItem } from './extrainfo/advancedlegendaryitem';
export const FeatAdvancedLegendaryItem = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Advanced Legendary Item
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/legendaryitem">Legendary Item</Link></li>
		</ul>
		<hr />
		<p className="no-indent">Your legendary item becomes an artifact. Increase its Intelligence, Wisdom, and Charisma by 1. Increase its proficiency rank for Perception, unarmored defense and all saving throws to expert. The item gains two skill increases, allowing it to become trained in a skill in which it was untrained, or become an expert in a skill in which it was already trained.</p>
		<p className="no-indent">The range of your item's senses increases to 60 feet (unless already longer).</p>
		<p className="no-indent">Your item can communicate telepathically with its partner, or any creature that is touching or wearing it.</p>
		<p className="no-indent">Your item gains two new special abilities, and it gains an additional ability at 5th, and 6th mythic rank. You can choose from the abilities listed below, in addition to those described in the Legendary Item feat.</p>
        <br />
        <FeatInfoAdvancedLegendaryItem />

	</div>
	<div className="spacer" />
</div>