import React from 'react';
import { Link } from 'react-router-dom'

export const FeatTerrainStride = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Terrain Stride
		<div className="spacer" />
		<span className="right">Feat 4</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scout</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/favoredterrain">Favored Terrain</Link>, any other Scout feat</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You know the secrets of moving in your favored terrain. You gain a +2 circumstance bonus to skill checks to move within your favored terrain, such as Acrobatics checks to Balance on ice, Athletics checks to Swim in rough water, Acrobatics checks to Fly in windy conditions, and so forth. You also gain a second benefit while in your favored terrain, depending on the type of terrain.</p><br />
	<br />
<ul>
  <li><b>Ice, glaciers snow & tundra:</b>You need to eat and drink only one-tenth as much as
  usual, you arenâ€™t affected by severe or extreme cold, and you can walk across ice and snow at full Speed without
  needing to Balance.</li>
  <li><b>Deserts, wastelands and volcanic areas:</b>you arenâ€™t affected by severe or extreme heat, and you can
  move along sand and shifting ground at full Speed without needing to Balance.</li>
  <li><b>Forests, jungles, hills & mountains, urban and underground:</b>You gain a climb Speed equal to your
  Speed. If you already had a climb Speed, you gain a +4-meter status bonus to your climb Speed.</li>
  <li><b>Plains:</b>You gain a +4-meter status bonus to your land Speed.</li>
</ul>
<br />
	<h3 className="headerbar sub-header-bg shadow-sm">Terrain Stride Leads To...</h3>
	<p className="all-indent no-indent"><Link to="/sw2e/feats/terrainmaster">Terrain Master</Link></p>
</div>