import React from 'react';

export const SW2eHome = () => 
<div className="text-container">
    <p>introductory text here</p>
    <h2 className="header-bg">Star Wars</h2>
    <div className="columns">
        <div className="column-md">
            <h3>header #1</h3>
            <p>some explanation here</p>
        </div>
        <div className="column-md">
            <h3>header #2</h3>
            <p>further explanation here</p>
        </div>
    </div>
    <h2 className="header-bg">Glossary</h2>
    <p>about glossary here</p>
    <div className="columns">
        <div className="column-md">
            <h3>term here</h3>
            <p>explanation here</p>
            <br />
            <h3>term here</h3>
            <p>explanation here</p>
        </div>
        <div className="column-md">
            <h3>term here</h3>
            <p>explanation here</p>
            <br />
            <h3>term here</h3>
            <p>explanation here</p>
        </div>
    </div>
</div>