import React from 'react';
import { Link } from 'react-router-dom'

export const FeatMythicTeamwork = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Teamwork
			<div className="spacer" />
			<span className="right">Feat Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/mythicbond">Mythic Bond</Link> or <Link to="/mythic/feats/mythiccohort">Mythic Cohort</Link></li>
		</ul>
		<hr />
		<p className="no-indent">Choose an animal companion, familiar or eidolon you selected with Mythic Bond, or a creature you selected with Mythic Cohort. The chosen creature gains the benefits of any other mythic feats you possess for which they qualify. For the purpose of mythic feat prerequisites, they are considered to be following the same mythic path as you.</p>
		<p className="no-indent">If any mythic feats allow them to choose a benefit, they are considered to have made the same choices as you. The chosen creature cannot have their own mythic cohort, mythic bond, or legendary item, but can use your legendary item as if they were you, and their animal companion, familiar, or eidolon is considered one of yours for the purpose of mythic bond.</p>
		<p className="no-indent">If any feats or abilities you possess, such as innate spells, have a limited number of uses per day, any uses by your animal companion, familiar, eidolon, or cohort count against your daily limit.</p>
	</div>
	<div className="spacer" />
</div>