import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellLightofTruth = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Light of Truth
			<div className="spacer" />
			<span className="right">Mythic Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Cantrip" /></div>
			<div className="trait"><Trait name="Divination" /></div>
			<div className="trait"><Trait name="Mental" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Revelation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> somatic</li>
			<li><b>Area:</b> 30-foot emanation centered on you</li>
			<li><b>Duration:</b> 1 round</li>
		</ul>
		<hr />
		<p className="no-indent">You shed light as the light spell, with a spell level equal to your mythic rank. This light helps those around you discern truth and lies. Allies within areas of bright light created by this spell recieve a +2 status bonus on perception checks to notice when someone lies, to recognize illusions and on saving throws against illusion effects. Additionally, if creatures within the light choose to honestly express their feelings, the light makes it easier for them to put words to those feelings.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (2nd)</b> Invisible creatures and objects within the areas of bright light become visible, as if the viewers were subject to <i><a href="https://2e.aonprd.com/Spells.aspx?ID=271">see invisibility</a></i>.</li>
			<li><b>Heightened (3rd)</b> Creatures within the areas of bright light are subject to the effects of <i><a href="https://2e.aonprd.com/Spells.aspx?ID=379">zone of truth</a></i>.</li>
			<li><b>Heightened (5th)</b> You can cast this spell as a free action. It retains the somatic component. The duration of the spell becomes 10 minutes and you can Dismiss the spell.</li>
			<li><b>Heightened (6th)</b> Creatures within the areas of bright light can be seen as they actually are, as if viewers were subject to <i><a href="https://2e.aonprd.com/Spells.aspx?ID=344">true seeing</a></i>. The GM rolls only a single counteract check for all creatures each time the spell is cast.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>