import React from 'react';
import threeaction from '../../../../resources/3-actions.png';

export const SpellAnimateDead = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Animate Dead&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=666"> </a>
			<div className="spacer" />
			<span className="right">Spell 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Necromancy</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, divine, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="3 Action" src={threeaction} /> (material, somatic, verbal)</li>
			<li><b>Range</b> 30 feet</li>
			<li><b>Duration</b> sustained up to 1 minute</li>
		</ul>
		<hr />
		<p className="no-indent">Your magic dredges up a corpse or skeleton and fills it with necromantic life, and you force the dead to fight at your command. You summon a common creature that has the undead trait and whose level is -1; this creature gains the summoned trait. Heightening the spell increases the maximum level of creature you can summon.</p>
		<hr />
			<p className="no-indent"><b>Heightened (2nd)</b> Level 1</p>
			<p className="no-indent"><b>Heightened (3rd)</b> Level 2</p>
			<p className="no-indent"><b>Heightened (4th)</b> Level 3</p>
			<p className="no-indent"><b>Heightened (5th)</b> Level 5</p>
			<p className="no-indent"><b>Heightened (6th)</b> Level 7</p>
			<p className="no-indent"><b>Heightened (7th)</b> Level 9</p>
			<p className="no-indent"><b>Heightened (8th)</b> Level 11</p>
			<p className="no-indent"><b>Heightened (9th)</b> Level 13</p>
			<p className="no-indent"><b>Heightened (10th)</b> Level 15</p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> Add your mythic rank to the maximum level of the creature you can summon with Animate Dead. Instead of summoning an undead creature, you can choose to target 1 dead creature. If you do, the spell has no duration and the target becomes an undead creature of a level you could have summoned that could be created with any version of the <a href="https://2e.aonprd.com/Rituals.aspx?ID=10">Create Undead</a> ritual, as if the result of the ritual had been a Success.</span></p>
	</div>
	<div className="spacer" />
</div>