import React from 'react';
import twoaction from '../../../../resources/2-actions.png';
import action from '../../../../resources/1-action.png';

export const SpellPestForm = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Pest Form&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=217"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Polymorph</div>
			<div className="trait">Transmutation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Duration</b> <span className="tweak-color">1 hour</span></li>
		</ul>
		<hr />
		<p className="no-indent">You transform into the alternate form of a Tiny animal, such as a cat, insect, lizard, or rat. You can decide the specific type of animal (such as a field mouse or praying mantis), but this has no effect on the form's Size or statistics. While in this form, you gain the <a href="https://2e.aonprd.com/Traits.aspx?ID=9">animal</a> trait. You can Dismiss the spell.</p>
		<p className="no-indent"><span className="tweak-color">You gain the following statistics and abilities regardless of which alternate form you choose:</span></p>
		<p className="no-indent"><span className="tweak-color"><ul>
  <li>AC = 15 + your level. Ignore your armor's check penalty and Speed reduction.</li>
  <li>Weakness 5 to physical damage. (If you take physical damage in this form, you take 5 additional damage.)</li>
  <li>Low-light vision and imprecise scent 30 feet.</li>
  <li>One or more unarmed melee attacks specific to the alternate form you choose, which are the only attacks you can
    use. You're trained with them. Your attack modifier is 8 + your level, and your damage bonus is +0. If your
    unarmed attack bonus is higher (including any item bonus you had before transforming), you can use it instead.
    These attacks deal 1 die of damage but may gain additional damage dice at higher spell levels. If an effect
    (such as a Striking rune) caused your unarmed attacks to deal more damage dice before transforming, you
    can use that number instead.</li>
  <li>Acrobatics and Stealth modifiers of 8 + your level, unless your own modifier is higher; Athletics modifier of -4.</li>
</ul></span></p>
		<p className="no-indent"><span className="tweak-color">You also gain specific abilities based on the type of animal you choose:</span></p>
		<p className="no-indent"><span className="tweak-color"><div className="indent"><b>Cat or Dog</b>
<ul className="shortmargin">
  <li><b>Speed</b> 25 feet</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> jaws (agile, finesse), <b>Damage</b> 1d4 slashing</li>
</ul>
<b>Rat, Lizard or Insect</b>
<ul className="shortmargin">
  <li><b>Speed</b> 15 feet</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> jaws (agile, finesse), <b>Damage</b> 1d2 slashing</li>
  <li>Stealth modifier of 10 + your level, unless your own modifier is higher.</li>
</ul></div></span></p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (2nd)</b> You can transform into a creature that can swim or climb, such as a fish or monkey. You can choose from the following additional forms:</span></p>
			<p className="no-indent"><span className="tweak-color"><div className="indent"><b>Climbing Lizard</b>
<ul className="shortmargin">
  <li><b>Speed</b> 15 feet, climb 15 feet</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> jaws (agile, finesse), <b>Damage</b> 1d2 slashing</li>
  <li>Stealth modifier of 10 + your level, unless your own modifier is higher.</li>
</ul>
<b>Fish</b>
<ul className="shortmargin">
  <li><b>Speed</b> swim 25 feet</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> jaws (agile, finesse), <b>Damage</b> 1d4 slashing</li>
</ul>
<b>Frog</b>
<ul className="shortmargin">
  <li><b>Speed</b> 15 feet, swim 15 feet</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> tongue (reach, finesse), <b>Damage</b> 1d2 bludgeoning</li>
</ul>
<b>Monkey  or Squirrel</b>
<ul className="shortmargin">
  <li><b>Speed</b> 20 feet, climb 10 feet</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> jaws (agile, finesse), <b>Damage</b> 1d4 slashing</li>
  <li>Thievery modifier of 8 + your level, unless your own modifier is higher.</li>
  <li>Can use manipulate actions.</li>
</ul></div></span></p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (4th)</b> You can transform into a creature that can fly or burrow, such as a bird or mole. You can choose from the following additional forms:</span></p>
			<p className="no-indent"><span className="tweak-color"><div className="indent"><b>Bat</b>
<ul className="shortmargin">
  <li><b>Speed</b> 10 feet, fly 20 feet</li>
  <li>Precise echolocation 30 feet</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> jaws (agile, finesse), <b>Damage</b> 1d3 slashing</li>
</ul>
<b>Bird or Flying Insect</b>
<ul className="shortmargin">
  <li><b>Speed</b> 10 feet, fly 25 feet</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> jaws (agile, finesse), <b>Damage</b> 1d3 slashing</li>
</ul>
<b>Mole</b>
<ul className="shortmargin">
  <li><b>Speed</b> 10 feet, burrow 5 feet</li>
  <li>Darkvision</li>
  <li><b>Melee</b> <img className="small-icon" alt="action" src={action} /> jaws (reach, finesse), <b>Damage</b> 1d3 slashing</li>
</ul></div></span></p>
	</div>
	<div className="spacer" />
</div>