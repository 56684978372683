import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellLightningBolt = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Lightning Bolt&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=172"> </a>
			<div className="spacer" />
			<span className="right">Spell 3</span>
		</h2>
		<div className="traits">
			<div className="trait">Electricity</div>
			<div className="trait">Evocation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Area</b> 120-foot line</li>
			<li><b>Saving Throw</b> basic Reflex</li>
		</ul>
		<hr />
		<p className="no-indent">A bolt of lightning strikes outward from your hand, dealing 4d12 electricity damage.</p>
		<hr />
			<p className="no-indent"><b>Heightened (+1)</b> The damage increases by 1d12.</p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> A storm of lightning pours out from your hand instead. The area becomes a 120-foot cone.</span></p>
	</div>
	<div className="spacer" />
</div>