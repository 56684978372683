import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellAntHaul = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Ant Haul&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=13"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Transmutation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Range</b> touch</li>
			<li><b>Duration</b> 8 hours</li>
		</ul>
		<hr />
		<p className="no-indent">You reinforce the target's musculoskeletal system to bear more weight. The target can carry 3 more Bulk than normal before becoming encumbered and up to a maximum of 6 more Bulk.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+1)</b> The extra Bulk the target can carry before becoming encumbered increases by 1, and the extra maximum Bulk increases by 2.</span></p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Seems like there's a missed opportunity here. If the druid wants to burn a 6th level spell slot so that the fighter can drag a sleeping hill giant off of the road, I say that's a good use of a spell slot.</p>
	</div>
	<div className="spacer" />
</div>