import React from 'react';
import { Link } from 'react-router-dom'

export const FeatMagicSurge = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Magic Surge
			<div className="spacer" />
			<span className="right">Feat Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
			<div className="trait">Undead</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> Ability to cast spells</li>
		</ul>
		<hr />
		<p className="no-indent">You call upon your mythic power to cast a powerful version of a spell you know.</p>
		<p className="no-indent">You gain the <i><Link to="/mythic/spells/magicsurge">magic surge</Link></i> mythic spell, which you can cast for 1 Mythic Power Point.</p>
	</div>
	<div className="spacer" />
</div>