import React from 'react';
import action from '../../../../resources/1-action.png';

export const SpellDivineLance = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Divine Lance&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=84"> </a>
			<div className="spacer" />
			<span className="right">Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Attack</div>
			<div className="trait">Cantrip</div>
			<div className="trait">Evocation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> divine</li>
			<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /> (<span className="tweak-color">somatic</span>)</li>
			<li><b>Range</b> 30 feet; <b>Targets:</b> 1 creature <span className="tweak-color">or object</span></li>
		</ul>
		<hr />
		<p className="no-indent">You unleash a beam of divine energy. Choose an alignment your deity has (chaotic, evil, good, or lawful). You can't cast this spell if you don't have a deity or if your deity is true neutral. Make a ranged spell attack roll against the target's AC. On a hit, the target takes damage of the chosen alignment type equal to <span className="tweak-color">1d6</span> + your spellcasting ability modifier (double damage on a critical hit). The spell gains the trait of the alignment you chose.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+2)</b> The damage increases by 1d6</span>.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>For a spell that doesn't affect two-thirds of the targets you throw it at, there was really no reason to give it both the worst range <i>and</i> the worst damage of all cantrips.</p>
  		<p>Like the other attack cantrips though, reduce the actions to one to make it more flexible and... well the damage didn't really change much on this one. Its big value continues to be its ability to target alignment weaknesses.</p>
	</div>
	<div className="spacer" />
</div>