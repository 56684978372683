import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { PathFeaturesTable, PathTitleDescrip } from '../../extra/pathtemplates';
import {
	AbilityBoosts, AbilityHeader, AmazingSpeed, ForceOfWill, HardToKill, Immortal, LegendaryHero, MythicPower,
	MythicSurge, Recuperation, UniversalMythicFeats, Unstoppable
} from '../../extra/sharedabilities';
import { TitanMythicFeats } from './feats/titan';
import { TitanPathRoute } from './routes/titan';

const titanFeatures: { [index: number]: string[] } = {
	1: ["godly phisique", "impressive display", "powerful blows"],
    2: ["titan feat"],
    3: ["titanic resistance"],
    4: ["titan feat"],
    5: ["legendary strength"],
    6: ["titan feat"],
    7: ["titanic vitality"],
    8: ["titan feat"],
    9: ["titanic proportions"],
    10: ["titan feat"],
}

const description: string = `Often the children of gods, demigods and titans of old, those who walk the Path of the
	Titan exhibit the qualities of the heroes of classical myth. These heroes possess incredible strength, speed and
	skill, and are— quite literally— larger than life.`

export const PathoftheTitan = () =>
<div>
	<TitanPathRoute />
	<Switch>
		<Route exact path="/mythic/paths/titan">
		<PathTitleDescrip name="Path of the Titan" description={description} hp={12} />
	<h2 className="header-bg">Path Features</h2>
	<br />
	<div className="columns">
		<div className="col-md left-col">
			<PathFeaturesTable pathFeatures={titanFeatures}/>
			<br />
			<AbilityHeader name="Godly Physique" level="Rank 1" />
			<p>Your mythic power manifests as greater strength, speed, stamina and stature. You grow to size Medium if
				you were Small or smaller and your reach increases by 5 feet if you were Tiny. You can also lift and
				carry much more than other creatures of your size; You become less encumbered by bulky items, as if you
				were one size larger.</p>
			<p>Your speed increases by 10 feet and you don't become Fatigued from physical exertion, such as when you
				spend long periods of time running or swimming.</p>
			<br />

			<HardToKill />
			<br />

			<MythicPower />
			<br />

			<MythicSurge />
			<br />

			<UniversalMythicFeats />
			<br />

			<AbilityHeader name="Powerful Blows" level="Rank 1" />
			<p>You gain the <a href="https://2e.aonprd.com/Classes.aspx?ID=8">monk</a>'s <i>powerful fist</i> class
				feature, increasing the damage die of your unarmed strikes to 1d6 and allowing you to use them to make
				lethal attacks without penalty. If you already have <i>powerful fist</i>, the damage of your unarmed strikes
				increases to 1d8.</p>
			<br />

			<AbilityHeader name="Titan Feats" level="Rank 2" />
			<p>At 2nd rank and every even rank, you gain a <Link to="/mythic/feats/titan">titan path feat</Link>.</p>
			<br />

			<AbilityBoosts />
			<br />
		</div>
		<div className="col-md right-col">
			<AbilityHeader name="Impressive Display" level="Rank 1" />
			<p>You perform impressive physical feats with great ease. You gain
				the <Link to="/mythic/spells/featofagility">Feat of Agility</Link>, <Link to="/mythic/spells/featofendurance">Feat of Endurance</Link> and <Link to="/mythic/spells/featofstrength">Feat of Strength</Link> mythic
				spells, and can activate them by spending either 1 Focus Point or 1 Mythic Power Point. If you don't
				already have one, you gain a focus pool of 1 Focus Point.</p>
			<br />

			<Recuperation />
			<br />

			<AbilityHeader name="Titanic Resistance" level="Rank 3" />
			<p>Your skin is hard as iron and doesn't cut easily. You gain resistance to all damage equal to your
				mythic rank.</p>
			<br />

			<AmazingSpeed />
			<br />

			<ForceOfWill />
			<br />

			<AbilityHeader name="Legendary Strength" level="Rank 5" />
			<p>You can use weapons built for creatures one size larger than you. You deal 4 additional damage with
				unarmed Strikes, thrown rock Strikes, and melee and thrown weapon Strikes when wielding such a weapon.</p>
			<br />

			<Unstoppable />
			<br />

			<AbilityHeader name="Overwhelming" level="Rank 7" />
			<p>Few creatures can bar your path. You gain the <a href="https://2e.aonprd.com/MonsterAbilities.aspx?ID=39">trample</a> ability, allowing
			you to move through creatures of a smaller size than you. If you are smaller than Large size, you are
			treated as Large for the purpose of this ability. You deal the damage of a fist unarmed Strike to the
			trampled creatures.</p>
			<br />

			<Immortal />
			<br />

			<AbilityHeader name="Titanic Proportions" level="Rank 9" />
			<p>Your physical size reaches it's peak. You grow to size Large and your reach increases by 5 feet if you
				were Medium or smaller. You gain a permanent +2 bonus to Strength and Constitution.</p>
			<br />

			<LegendaryHero />
		</div>
	</div>
		</Route>
		<Route path="/mythic/paths/titan/feats" component={TitanMythicFeats}/>
	</Switch>
</div>