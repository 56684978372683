import React from 'react';

export const FeatShadowStep = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Shadow Step&nbsp;<div className="action" />
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Demon</div>
			<div className="trait">Fey</div>
			<div className="trait">Rakshasa</div>
			<div className="trait">Shadow</div>
			<div className="trait">Undead</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Fey] deep form or highland form</li>
		</ul>
		<hr />
		<p className="no-indent"><b>Requirements</b> You are in an area of dim light or darkness or are standing in the shadow of any object that is your size or larger.</p>
		<p className="no-indent"><b>Effect</b> You instantly teleports to another location within 60 feet that's in dim light or darkness or that's in the shadow of any object that is your size or larger.</p>
	</div>
	<div className="spacer" />
</div>