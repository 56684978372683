import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const PowerQuickRepair = () =>
<div className="text-container">
	<div className="columns">
		<div className="column-full">
			<h2 className="headerbar header-bg shadow-sm">Quick Repair
				<div className="spacer" />
				<span className="right">Power 1</span>
			</h2>
			<div className="traits">
				<div className="trait">Tech Specialist</div>
				<div className="trait">Manipulate</div>
			</div>
			<ul className="proplist">
				<li><b>Tradition</b> focus</li>
				<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /></li>
				<li><b>Range</b> touch; <b>Targets:</b> 1 willing droid or vehicle</li>
			</ul>
			<hr />
			<p className="no-indent all-indent">You make quick repairs to a droid or vehicle, restoring 1d8+8 hit points to the target.</p>
			<hr />
			<ul className="proplist">
				<li><b>Heightened (+1):</b> The amount of hit points restored increases by 1d8+8.</li>
			</ul>
		</div>
	</div>
</div>