import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';

export const MythicFeatsHeader = () =>
<div>
	<h2 className="center">
		<Switch>
			<Route exact path="/mythic/feats">Universal</Route>
			<Route path="/"><Link to="/mythic/feats">Universal</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/all">All</Route>
			<Route path="/"><Link to="/mythic/feats/all">All</Link></Route>
		</Switch>
	</h2>
	<hr />
	<h3 className="center">
		<Switch>
			<Route exact path="/mythic/feats/angel">Angel</Route>
			<Route path="/"><Link to="/mythic/feats/angel">Angel</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/archon">Archon</Route>
			<Route path="/"><Link to="/mythic/feats/archon">Archon</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/azata">Azata</Route>
			<Route path="/"><Link to="/mythic/feats/azata">Azata</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/demon">Demon</Route>
			<Route path="/"><Link to="/mythic/feats/demon">Demon</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/devil">Devil</Route>
			<Route path="/"><Link to="/mythic/feats/devil">Devil</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/dragon">Dragon</Route>
			<Route path="/"><Link to="/mythic/feats/dragon">Dragon</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/elemental">Elemental</Route>
			<Route path="/"><Link to="/mythic/feats/elemental">Elemental</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/enlightenment">Enlightenment</Route>
			<Route path="/"><Link to="/mythic/feats/enlightenment">Enlightenment</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/fey">Fey</Route>
			<Route path="/"><Link to="/mythic/feats/fey">Fey</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/shadow">Shadow</Route>
			<Route path="/"><Link to="/mythic/feats/shadow">Shadow</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/stars">Stars</Route>
			<Route path="/"><Link to="/mythic/feats/stars">Stars</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/titan">Titan</Route>
			<Route path="/"><Link to="/mythic/feats/titan">Titan</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/feats/undead">Undead</Route>
			<Route path="/"><Link to="/mythic/feats/undead">Undead</Link></Route>
		</Switch>
	</h3>
</div>