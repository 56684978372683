import React from 'react';
import { Route } from 'react-router-dom';
import { SW2eArchTableNoble } from './tables/nobletable';

export const SW2eArchetypeNoble = () =>
<div className="text-container all-indent">
    <h2 className="header-bg">Noble</h2>
    <p>.</p>
    <br />
    <Route component={SW2eArchTableNoble} />
    <br />
</div>