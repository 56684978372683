import React from 'react';
import { Link } from 'react-router-dom'

export const FeatDireSpellcraft = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Dire Spellcraft
			<div className="spacer" />
			<span className="right">Feat Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Azata</div>
			<div className="trait">Devil</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/ascendspell">Ascend Spell</Link></li>
		</ul>
		<hr />
		<p className="no-indent">When you cast Ascend Spell, you can choose to expend a second point of Mythic Power to gain both benefits, instead of choosing one.</p>
	</div>
	<div className="spacer" />
</div>