import React from 'react';
import { Link } from 'react-router-dom';

export const SW2eHeroes = () => 
<div className="text-container">
    <h2 className="header-bg">Heroic Character Creation</h2>
    <p> Unless you’re the GM, the first thing you need to do is create your character. It’s up to
    you to imagine your character’s past experiences, personality, and worldview, and this will set the stage for your
    roleplaying during the game. You’ll use the game’s mechanics to determine your character’s ability to perform
    various tasks and use special abilities during the game.</p>
    <br />
    <h2 className="header-bg">Species</h2>
    <p>The Star Wars galaxy contains a bewildering variety of species, each with its own unique outlook and
    civilization. As a player, you will need to choose one for your character. A character's species helps determine
    their abilities and shapes how they see the world around them. Options and more information are detailed in the
    <Link to="/sw2e/species">Species</Link> section.</p>
    <br />
    <h2 className="header-bg">Background</h2>
    <p>A background represents a character's upbringing and history. Where you're from and what you did in the past
    affects who you are and what you can do. When you create your character, you gain a background of your choice.
    Options and more information are detailed in the <Link to="/sw2e/backgrounds">Backgrounds</Link> section.</p>
    <br />
    <hr />
    <div className="columns all-indent">
        <div className="column-md">
            <h2 className="header-bg">The Six Ability Scores</h2>
            <p>One of the most important aspects of your character is their ability scores. These scores
            represent your character’s raw potential and influence nearly every other statistic on your character sheet.
            Determining your ability scores is not done all at once, but instead happens over several steps during character
            creation.</p>
            <p>Ability scores are split into two main groups: physical and mental. Strength, Dexterity,
            and Constitution are physical ability scores, measuring your character’s physical power, agility, and stamina. In
            contrast, Intelligence, Wisdom, and Charisma are mental ability scores and measure your character’s learned prowess,
            awareness, and force of personality.</p>
            <p>Excellence in an ability score improves the checks and statistics related to that ability,
            as described below. When imagining your character, you should also decide what ability scores you want to focus on
            to give you the best chance at success.</p>
            <br />
            <h3 className="sub-header-bg">Strength</h3>
            <p>Strength measures your character’s physical power. Strength is important if your character
            plans to engage in hand-to-hand combat. Your Strength modifier gets added to melee damage rolls and determines how
            much your character can carry.</p>
            <br />
            <h3 className="sub-header-bg">Dexterity</h3>
            <p>Dexterity measures your character’s agility, balance, and reflexes. Dexterity is important
            if your character plans to make attacks with ranged weapons or use stealth to surprise foes. Your Dexterity modifier
            is also added to your character’s AC and Reflex saving throws.</p>
            <br />
            <h3 className="sub-header-bg">Constitution</h3>
            <p>Constitution measures your character’s overall health and stamina. Constitution is an
            important statistic for all characters, especially those who fight in close combat. Your Constitution modifier is
            added to your Hit Points and Fortitude saving throws.</p>
            <br />
            <h3 className="sub-header-bg">Intelligence</h3>
            <p>Intelligence measures how well your character can learn and reason. A high Intelligence
            allows your character to analyze situations and understand patterns, and it means they can become trained in
            additional skills and might be able to master additional languages.</p>
            <br />
            <h3 className="sub-header-bg">Wisdom</h3>
            <p>Wisdom measures your character’s common sense, awareness, and intuition. Your Wisdom
            modifier is added to your Perception and Will saving throws.</p>
            <br />
            <h3 className="sub-header-bg">Charisma</h3>
            <p>Charisma measures your character’s personal magnetism and strength of personality. A high
            Charisma score helps you influence the thoughts and moods of others.</p>
        </div>
        <div className="column-md">
            <h2 className="header-bg">Determining Ability Scores</h2>
            <p>Each ability score starts at 0, representing human average, and can go as high as +5 (at
            1st level) or as low as -5. As you make character choices, you’ll adjust these scores by applying ability boosts,
            which increase a score, and ability flaws, which decrease a score. As you build your character, remember to apply
            ability score adjustments when making the following decisions. </p>
            <br />
            <p className="no-indent"><b>Initial Ability Scores:</b><br />Before applying other ability score boosts, you apply five
            free boosts to the abilities of your choice. You can apply more than one boost to the same ability, but you cannot
            raise an ability score above +2 with these boosts.</p>
            <br />
            <p className="no-indent"><b>Species:</b><br />Each species provides ability boosts, and sometimes an ability flaw. If
            you are taking any voluntary flaws, apply them in this step.</p>
            <br />
            <p className="no-indent"><b>Background:</b><br />Finally, your character’s background provides two ability boosts.</p>
            <br />
            <div className="info shadow-sm indent">
                <h3>Important Terms: Ability Boosts and Flaws</h3>
                <h3 className="sub-header-bg">Ability Boosts</h3>
                <p>An ability boost increases an ability score’s value by 1. At 1st level, a character
                can never have any ability score that’s higher than +5. This limit rises to +6 at 10th level, and +7 at 20th
                level.</p>
                <p>When your character receives an ability boost, the rules indicate whether it must be
                applied to a specific ability score, to one of multiple specific ability scores, or whether it is a "free"
                ability boost that can be applied to any ability score of your choice. Except for your initial 5 ability
                boosts, when you gain multiple ability boosts at the same time, you must apply each one to a different score.
                Bothans, for example, receive an ability boost to their Charisma score and their Dexterity score, as well as
                one free ability boost, which can be applied to any score other than Charisma or Dexterity.</p>
                <br />
                <h3 className="sub-header-bg">Ability Flaws</h3>
                <p>Ability flaws are not nearly as common as ability boosts. If your character has an ability
                flaw—likely from their species—you decrease that ability score by 1. If you think it would be fun to
                play a character with a specific flaw, even if their species does not impose one, you can also elect to
                take an additional ability flaws when applying the ability boosts and ability flaws from your species.
                This flaw cannot be applied to an ability which is already receiving a flaw during this step.</p>
            </div>
        </div>
    </div>
    <br />
    <hr />
    <div className="columns">
        <div className="column-md">
            <h2 className="header-bg">Automatic Character Abilities</h2>
            <p className="all-indent">All heroic characters gain a number of initial abilities, listed below, and can choose a number of
            options to enhance them later. At 1st level, you gain the following hit points and the listed proficiency
            ranks in the following statistics. You are untrained in anything not listed unless you gain a better
            proficiency in some other way.</p>
            <br />
            <h3 className="sub-header-bg">Hit Points</h3>
            <p className="all-indent no-indent">At 1st level and every level thereafter, you increase your maximum
            number of hit points by 6 plus your Constitution.</p>
            <br />
            <h3 className="sub-header-bg">Perception</h3>
            <p>Trained in Perception</p>
            <br />
            <h3 className="sub-header-bg">Saving Throws</h3>
            <p>Trained in Fortitude, Reflex and Will</p>
            <br />
            <h3 className="sub-header-bg">Skills</h3>
            <p>Trained in a number of skills equal to 4 plus your Intelligence.</p>
            <br />
            <h3 className="sub-header-bg">Attacks</h3>
            <p>Trained in small arms</p>
            <p>Trained in melee weapons</p>
            <p>Trained in unarmed attacks</p>
            <br />
            <h3 className="sub-header-bg">Defenses</h3>
            <p>Trained in unarmored defense</p>
            <br />
            <h3 className="sub-header-bg">Ability DC</h3>
            <p>Trained in ability DC</p>
            <br />
        </div>
        <div className="column-md">
            <h2 className="header-bg">Additional Character Options</h2>
            <p className="all-indent">In addition to these benefits, choose 8 additional benefits from
            the list below. You must choose at least one benefit from the Saving Throws section, and at least one from
            the Defenses section. Each benefit may only be taken once.</p>
            <br />
            <h3 className="sub-header-bg">Hit Points</h3>
            <p><Link to="/sw2e/feats/toughness">Toughness</Link> feat</p>
            <p><Link to="/sw2e/feats/improvedtoughness">Improved Toughness</Link> feat</p>
            <p><Link to="/sw2e/feats/advancedtoughness">Advanced Toughness</Link> feat</p>
            <br />
            <h3 className="sub-header-bg">Perception</h3>
            <p>Expert in Perception</p>
            <br />
            <h3 className="sub-header-bg">Saving Throws</h3>
            <p>Expert in Fortitude</p>
            <p>Expert in Reflex</p>
            <p>Expert in Will</p>
            <br />
            <h3 className="sub-header-bg">Skills</h3>
            <p><Link to="/sw2e/feats/advancedskilltraining">Advanced Skill Training</Link> feat</p>
            <p><Link to="/sw2e/feats/greaterskilltraining">Greater Skill Training</Link> feat</p>
            <br />
            <h3 className="sub-header-bg">Attacks</h3>
            <p>Trained in long arms</p>
            <p>Trained in heavy weapons</p>
            <p>Trained in one exotic weapon of your choice</p>
            <br />
            <h3 className="sub-header-bg">Defenses</h3>
            <p>Trained in light armor</p>
            <p>Trained in heavy armor</p>
            <p>Trained in powered armor</p>
            <p>Expert in unarmored defense</p>
            <br />
        </div>
    </div>
    <br />
    <hr />
    <div className="columns" id="progression">
        <div className="column-md">
            <h2 className="header-bg">Character Progression</h2>
            <p className="all-indent">At each level, including first, you gain a number of feats and other special
            features, such as skill increases and ability boosts.</p>
            <br />
            <h3 className="sub-header-bg">Archetype Feats</h3>
            <p>Your character's archetype feats define their training and abilities. They are perhaps the most important
            choices you will make for your character. Archetype feats are grouped into related trees of abilities, such
            as Technician or Bounty Hunter, which describe the various abilities of different character archetypes that
            can be found in the Star Wars galaxy</p>
            <p>At each level, you gain one or more archetype feats, as detailed on the table below. These feats can be
            spent in any archetype, on any archetype feat for which you qualify; you need not constrain yourself to a
            single archetype. While a single archetype often has a wide array of abilities to choose from, it may not
            be sufficient to fully realize your character.</p>
            <p>When choosing feats from multiple archetypes, you will still need to meet any prerequisites for the feats
            you take. Fortunately, many archetypes have feats in common, and similar archetypes, such as Combat and
            Bounty Hunting can be switched between easily. However, you should be careful about investing in too many
            archetypes as you will find that many powerful feats are difficult, or even impossible to obtain without a
            great deal of investment in a single archetype.</p>
            <br />
            <h3 className="sub-header-bg">General Feats</h3>
            <p>At 1st level, and every 2 levels thereafter, you gain a general feat. General feats are
            listed <Link to="/sw2e/feats">here</Link>. Most skill feats are also general feats.</p>
            <br />
            <h3 className="sub-header-bg">Skill Feats</h3>
            <p>At 2nd level and every 2 levels thereafter, you gain a skill feat. Skill feats can be
            found <Link to="/sw2e/feats">here</Link> and have the skill trait. You must be trained or better in the
            corresponding skill to select a skill feat.</p>
            <br />
            <h3 className="sub-header-bg">Ability Boosts</h3>
            <p>At 2nd level and every 2 levels thereafter, you gain a boost to the ability score of your choice.
            Boosting an ability score increases it by 1, but cannot raise an ability score above +5.</p>
            <p>At 10th level, you can use ability boosts to increase ability scores to +6, and at 18th level, you can
            use them to raise ability scores to +7.</p>
            <br />
            <h3 className="sub-header-bg">Skill Increases</h3>
            <p>At 3rd level, and every 2 levels thereafter, you gain a skill increase. You can use this increase either
            to increase your proficiency rank to trained in one skill you’re untrained in, or to increase your
            proficiency rank in one skill in which you’re already trained to expert.</p>
            <p>At 7th level, you can use skill increases to increase your proficiency rank to master in a skill in which
            you’re already an expert, and at 15th level, you can use them to increase your proficiency rank to legendary
            in a skill in which you’re already a master.</p>
        </div>
        <div className="column-md">
            <h3>Table: Character Features</h3>
            <table className="shadow-sm hero-table">
                <tr>
                    <th style={{width:"40px"}}>Your<br />Level</th>
                    <th style={{width:"42px"}}>Arch.<br />Feats</th>
                    <th style={{width:"60px"}}>Other<br />Feats</th>
                    <th style={{width:"107px"}}>Boosts &<br />Increases</th>
                    <th>Special</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>3</td>
                    <td>General</td>
                    <td className="center">*</td>
                    <td>Initial character benefits</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td></td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td></td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td></td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td></td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td></td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td>Master skill proficiency limit</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td></td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td></td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td>+6 base ability score limit</td>
                </tr>
                <tr>
                    <td>11</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td></td>
                </tr>
                <tr>
                    <td>12</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td></td>
                </tr>
                <tr>
                    <td>13</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td></td>
                </tr>
                <tr>
                    <td>14</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td></td>
                </tr>
                <tr>
                    <td>15</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td>Legendary skill proficiency limit</td>
                </tr>
                <tr>
                    <td>16</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td></td>
                </tr>
                <tr>
                    <td>17</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td></td>
                </tr>
                <tr>
                    <td>18</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td>+7 base ability score limit</td>
                </tr>
                <tr>
                    <td>19</td>
                    <td>2</td>
                    <td>General</td>
                    <td>Skill Increase</td>
                    <td></td>
                </tr>
                <tr>
                    <td>20</td>
                    <td>1</td>
                    <td>Skill</td>
                    <td>Ability Boost</td>
                    <td></td>
                </tr>
            </table>
        </div>
    </div>
</div>