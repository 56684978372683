import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

declare global {
    interface String {
        capitalize(): string;
        capitalizeAll(): string;
    }
}

String.prototype.capitalize = function(): string {
    if (this.length <= 1) {
        return this.toUpperCase();
    } else {
        return this[0].toUpperCase() + this.slice(1).toLowerCase();
    }
}

String.prototype.capitalizeAll = function(): string {
    return this.split(" ").map((word) => word.capitalize()).join(" ");
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
