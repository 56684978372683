import React from 'react';
import { Switch, Route } from 'react-router';
import { SpellTweaksAll } from './all';
import { SpellTweaksArcane } from './arcane';
import { SpellTweaksDivine } from './divine';
import { SpellTweaksFocus } from './focus';
import { SpellTweaksMythic } from './mythic';
import { SpellTweaksOccult } from './occult';
import { SpellTweaksPrimal } from './primal';

export const SpellTweaksAbout = () =>
<div>
	<Switch>
		<Route exact path="/contenttweaks/spelltweaks" component={SpellTweaksAll} />
		<Route path="/contenttweaks/spelltweaks/focus" component={SpellTweaksFocus} />
		<Route path="/contenttweaks/spelltweaks/arcane" component={SpellTweaksArcane} />
		<Route path="/contenttweaks/spelltweaks/divine" component={SpellTweaksDivine} />
		<Route path="/contenttweaks/spelltweaks/occult" component={SpellTweaksOccult} />
		<Route path="/contenttweaks/spelltweaks/primal" component={SpellTweaksPrimal} />
		<Route path="/contenttweaks/spelltweaks/mythic" component={SpellTweaksMythic} />
	</Switch>
</div>