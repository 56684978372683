import React from 'react';
import { FeatInfoImbuedArms } from './extrainfo/imbuedarms';
export const FeatImbuedArms = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Imbued Arms&nbsp;<div className="action" />
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Angel</div>
			<div className="trait">Archon</div>
			<div className="trait">Azata</div>
			<div className="trait">Demon</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Elemental</div>
			<div className="trait">Enlightenment</div>
			<div className="trait">Stars</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Your unarmed Strikes, and Strikes with any weapon you are weilding deal 1d6 extra damage of a type determined by your path. If your path allows multiple types of damage, choose one. During your daily preparations, you can change this damage to a different type allowed by your path.</p>
		<p className="no-indent">At 8th rank, you deal an additional 1d6 extra damage. This can be of the same type, or a different type you could choose.</p>
        <br />
        <FeatInfoImbuedArms />

	</div>
	<div className="spacer" />
</div>