import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellStomp = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Stomp
			<div className="spacer" />
			<span className="right">Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Attack" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Area:</b> 15-foot emanation</li>
			<li><b>Duration:</b> 1 round</li>
		</ul>
		<hr />
		<p className="no-indent">You make a powerful stomp, creating a shockwave on the ground. All creatures in the area other than you take 2d10 bludgeoning damage and must attempt a Reflex save.</p><br />
		<p className="no-indent"><b>Critical Success</b> The creature is unaffected</p>
		<p className="no-indent"><b>Success</b> The creature takes half damage.</p>
		<p className="no-indent"><b>Failure</b> The creature takes full damage and is knocked prone.</p>
		<p className="no-indent"><b>Critical Failure</b> The creature takes double damage and is knocked prone.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> The damage increases by 1d10.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>