import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';

export const ArchonPathRoute = () =>
<div>
	<hr />
	<h3 className="center">
		<Switch>
			<Route exact path="/mythic/paths/archon">Archon Details</Route>
			<Route path="/"><Link to="/mythic/paths/archon">Archon Details</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/archon/feats">Archon Feats</Route>
			<Route path="/"><Link to="/mythic/paths/archon/feats">Archon Feats</Link></Route>
		</Switch>
	</h3>
</div>
