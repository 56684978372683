import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellMythicReachSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Reach Spell
			<div className="spacer" />
			<span className="right">Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Metamagic" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> </li>
		</ul>
		<hr />
		<p className="no-indent">You use mythic power to cast your spell far into the distance. If the next action you use is to Cast a Spell that has a range (including a range of touch), That spell's range becomes 1 mile.</p>
	</div>
	<div className="spacer" />
</div>