import React from 'react';
import { Link } from 'react-router-dom'

export const FeatAdvancedSkirmishMastery = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Advanced Skirmish Mastery
		<div className="spacer" />
		<span className="right">Feat 17</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scout</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/improvedskirmishmastery">Improved Skirmish Mastery</Link>, at least 14 total Scout feats</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">The extra damage you deal with Skirmish increases to 4d8.</p><br />
</div>