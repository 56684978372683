import React from 'react';
import { FeatInfoExoticShape } from './extrainfo/exoticshape';
export const FeatExoticShape = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Exotic Shape
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Archon</div>
			<div className="trait">Angel</div>
			<div className="trait">Azata</div>
			<div className="trait">Demon</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Fey</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Your power allows you to transform into exotic creatures. You gain the <a href="https://2e.aonprd.com/MonsterAbilities.aspx?ID=8">change shape</a> monster ability, allowing you take on the form of two or more creature. You can remain in these forms indefinitely. You gain any one form that could be chosen with <i><a href="https://2e.aonprd.com/Spells.aspx?ID=217">pest form</a></i> and any one form chosen from a spell listed in the table below. Some spells are available only to creatures of specific mythic ranks, and you must have a mythic rank at least as high as the spell level to choose a form from it. You gain all the effects of the form you chose from a version of the spell heightened to your mythic rank.</p>
		<p className="no-indent">If you have the <i><a href="https://2e.aonprd.com/Spells.aspx?ID=481">wild shape</a></i> order spell, or a similar ability (at GM's discretion), you also gain every form you are able to change into using that ability, including additional forms added by feats. When you transform, you gain any additional benefits that would apply when using <i>wild shape</i>, such as the status bonus to Athletics checks from <a href="https://2e.aonprd.com/Feats.aspx?ID=330">Ferocious Shape</a></p>
        <br />
        <FeatInfoExoticShape />

	</div>
	<div className="spacer" />
</div>