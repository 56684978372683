import React from 'react';
import threeaction from '../../../../resources/3-actions.png';

export const SpellLightningStorm = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Lightning Storm&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=701"> </a>
			<div className="spacer" />
			<span className="right">Spell 5</span>
		</h2>
		<div className="traits">
			<div className="trait">Electricity</div>
			<div className="trait">Evocation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="3 Action" src={threeaction} /> (material, somatic, verbal)</li>
			<li><b>Range</b> <span className="tweak-color">60 feet</span></li>
			<li><b>Saving Throw</b> basic Reflex; <b>Duration</b> sustained up to 1 minute</li>
		</ul>
		<hr />
		<p className="no-indent">You create a black, rumbling storm cloud <span className="tweak-color">in the sky above (or as high as the ceiling permits, if you are indoors) and call down a bolt of lightning to a point on the ground within range. The bolt deals 4d12 electricity damage to each creature in a vertical line from the storm cloud to the ground below</span> (basic Reflex save). The first time you Sustain the Spell each round, you can call another lightning bolt within range. If you Cast this Spell outdoors, <span className="tweak-color">the range becomes 500 feet. If you Cast this Spell in rain or other precipitation, the damage of each bolt increases by 1d12.</span></p>
		<hr />
			<p className="no-indent"><b>Heightened (+1)</b> The damage of each bolt increases by 1d12.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>I'd have to call that the biggest disappointment of the APG. I know Call Lightning was never a great spell, but that is just brutal. A 5th level spell that costs 4+ actions and deals the damage of a 3rd level lightning bolt to half as many squares, followed by the softest form of area denial in the smallest possible area?</p>
  		<p>Pulling from 1st edition and other tabletops, it's not too hard to get something that really feels like the classic spell though.</p>
	</div>
	<div className="spacer" />
</div>