import React from 'react';

export const SW2eSpeciesTrandoshan = () => 
<div className="columns">
    <div className="column-md">
        <h2 className="header-bg">Trandoshan</h2>
        <h3 className="sub-header-bg">Physical Description</h3>
        <p className="all-indent">Trandoshans are bipedal reptilian humanoids with smooth, scaly skin. Their arms end in
        either three or four clawed digits, and have a pointeds shout filled with pointed teeth. A Trandoshan's skin
        color can vary wildly. Trandoshans can be found with green, red, orange, brown, or yellow skin, and sometimes
        includes red and yellow patterns and stripes. Trandoshans are well known for their ability to regenerate lost
        limbs and recover from any wound which does not prove fatal. Trandoshan height can vary greatly, but they are
        typically very tall and can reach heights of up to 2.1 meters. Trandoshans have an average lifespan, reaching
        maturity at age 15. They are capable of living for over 100 standard years, though their lives are usually much
        shorter due to their violent nature.</p>
        <br />
        <h3 className="sub-header-bg">Society</h3>
        <p className="all-indent">Trandoshans come from the planet Trandosha and have a strong hunting culture. They
        often worshipo a goddess known as the Scorekeeper, from whom they earn points for successful hunts and victory
        in combat. Trandoshans have a particularly violent history with the Wookiees of the neighboring Kashyyyk.
        Trandoshan culture prizes the pelts of Wookiees, especially the rare and dangerous varieties, such a
        silverbacks. They are well known throughout the galaxy for their effectiveness as both traditional hunters and
        bounty hunters and as mercenaries.</p>
        <br />
    </div>
    <div className="column-md">
        <h2 className="header-bg">Trandoshan Mechanics</h2>
        <h3 className="sub-header-bg">Hit Points</h3>
        <p>10</p>
        <br />
        <h3 className="sub-header-bg">Size</h3>
        <p>Medium</p>
        <br />
        <h3 className="sub-header-bg">Speed</h3>
        <p>8 meters</p>
        <br />
        <h3 className="sub-header-bg">Ability Boosts</h3>
        <p>Strength</p>
        <p>Wisdom</p>
        <p>Free</p>
        <br />
        <h3 className="sub-header-bg">Ability Flaws</h3>
        <p>Dexterity</p>
        <br />
        <h3 className="sub-header-bg">Languages</h3>
        <p>Basic</p>
        <p>Dosh</p>
        <p className="no-indent all-indent">Additional languages equal to your Intelligence (if it's positive).</p>
        <br />
        <h3 className="sub-header-bg">Darkvision</h3>
        <p className="all-indent">You can see in darkness and dim light just as well as you can see in bright light,
        though only in in black and white.</p>
        <br />
        <h3 className="sub-header-bg">Light Sensitivity</h3>
        <p className="all-indent">Your eyes are well adapted to the dark and are sensitive to bright lights. You are
        dazzled while within areas of especially bright light, such as spotlights and direct sunlight. You take a -2
        circumstance penalty on saves against light effects.</p>
        <br />
        <h3 className="sub-header-bg">Tree Climber</h3>
        <p className="all-indent">You are a natural climber, able to move up and along vertical surfaces with surprising
        nimbleness. You gain a climb Speed equal to half your base Speed.</p>
        <br />
        <h3 className="sub-header-bg">Natural Weapons</h3>
        <p className="all-indent">Trandoshan hands have large claws that can be used to make attacks. These deal 1d6
        slashing damage and have the Agile and unarmed traits.</p>
        <br />
        <h3 className="sub-header-bg">Natural Armor</h3>
        <p className="all-indent">Trandoshans have a tough hide that protects them even when unarmored. While not
        wearing armor, you gain a +2 item bonus to AC.</p>
        <br />
        <h3 className="sub-header-bg">Regeneration</h3>
        <p className="all-indent">Trandoshans recover quickly from wounds and can even regenerate lost limbs. You gain
        the Fast Recovery feat, even if you do not meet the prerequisites. Additionally, if a body part is ever severed,
        it regenerates over the course of a week.</p>
        <br />
    </div>
    <div className="column-fit">
        <img className="profile-img" src="/profile/trandoshan.png" alt="" />
    </div>
</div>