import React from 'react';
import action from '../../../../resources/1-action.png';
import threeaction from '../../../../resources/3-actions.png';

export const SpellMagicStone = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Magic Stone&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=703"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Divination</div>
			<div className="trait">Linguistic</div>
			<div className="trait">Mental</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> divine, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /> to <img className="small-icon" alt="3 Action" src={threeaction} /> (somatic, verbal)</li>
			<li><b>Range</b> touch; <b>Targets:</b> 1 to 3 non-magical stones or sling bullets</li>
			<li><b>Duration</b> 1 minute</li>
		</ul>
		<hr />
		<p className="no-indent">You pour positive energy into ordinary stones, granting them temporary magical properties. You can target 1 non-magical stone or sling bullet for every action you use Casting this Spell. The stones must be unattended or carried by you or a willing ally. The stones become +1 striking disrupting sling bullets. Each stone can be used only once, after which it crumbles to dust.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (3rd)</b> The stones become +2 striking disrupting sling bullets.</span></p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (4th)</b> The stones become +2 greater striking disrupting sling bullets.</span></p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (5th)</b> The stones become +2 greater striking greater disrupting sling bullets.</span></p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (6th)</b> The stones become +3 greater striking greater disrupting sling bullets.</span></p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (8th)</b> The stones become +3 major striking greater disrupting sling bullets.</span></p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>It's such a great spell, both thematically and mechanically. It's a pity it falls off so hard after about level 3.</p>
	</div>
	<div className="spacer" />
</div>