import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellFireball = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Fireball&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=119"> </a>
			<div className="spacer" />
			<span className="right">Spell 3</span>
		</h2>
		<div className="traits">
			<div className="trait">Evocation</div>
			<div className="trait">Fire</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Range</b> 500 feet; <b>Area:</b> 20-foot burst</li>
			<li><b>Saving Throw</b> basic Reflex</li>
		</ul>
		<hr />
		<p className="no-indent">A roaring blast of fire appears at a spot you designate, dealing 6d6 fire damage.</p>
		<hr />
			<p className="no-indent"><b>Heightened (+1)</b> The damage increases by 2d6.</p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> The area becomes a 40-foot burst and the duration becomes 1 minute. In addition to the damage that occurs when you Cast the Spell, the spell sets the ground and unattended objects in the area ablaze. Any creature that ends it's turn in the area takes 10 fire damage.</span></p>
	</div>
	<div className="spacer" />
</div>