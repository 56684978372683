import React from 'react';
import { Link } from 'react-router-dom'

export const FeatAttackofOpportunity = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Attack of Opportunity
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Ever watchful for weaknesses, you can quickly attack foes that leave an opening in their defenses. You gain the Attack of Opportunity reaction.</p><br />
	<br />
<h3 className="sub-header-bg all-indent">Attack of Opportunity <div className="reaction" /></h3> <p className="no-indent all-indent"><b>Trigger:</b> A creature within your reach uses a manipulate action or a move action, makes a ranged attack, or leaves a square during a move action itâ€™s using.</p> <hr /> <p className="no-indent all-indent">You lash out at a foe that leaves an opening. Make a melee Strike against the triggering creature. If your attack is a critical hit and the trigger was a manipulate action, you disrupt that action. This Strike doesnâ€™t count toward your multiple attack penalty, and your multiple attack penalty doesnâ€™t apply to this Strike.</p>
<br />
</div>