import React from 'react';
import action from '../../../../resources/1-action.png';

export const SpellProduceFlame = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Produce Flame&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=236"> </a>
			<div className="spacer" />
			<span className="right">Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Attack</div>
			<div className="trait">Cantrip</div>
			<div className="trait">Evocation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /> (<span className="tweak-color">somatic</span>)</li>
			<li><b>Range</b> 30 feet; <b>Targets:</b> 1 creature <span className="tweak-color">or object</span></li>
			<li><b>Duration</b> sustained, up to 10 minutes</li>
		</ul>
		<hr />
		<p className="no-indent">A small ball of flame appears in the palm of your hand<span className="tweak-color">, which sheds light as a torch. When you Cast the Spell or Sustain the Spell, you can choose to</span> lash out with it either in melee or at range. <span className="tweak-color">When you do,</span> make a spell attack roll against your target's AC. This is normally a ranged attack, but you can also make a melee attack against a creature in your unarmed reach. On a success, you deal 1d4 fire damage plus your spellcasting ability modifier. On a critical success, the target takes double damage and 1d4 persistent fire damage. <span className="tweak-color">After you attack with it, the spell ends.</span></p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+2)</b></span> The damage increases by 1d4 and the persistent fire damage on a critical increases by 1d4.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Same treatment as the other attack cantrips (1 action cast time, slower damage scaling), but also wanted to codify what it seems most people already use it for/allow it to be used for: a light source.</p>
	</div>
	<div className="spacer" />
</div>