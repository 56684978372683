import React from 'react';
import { Route } from 'react-router-dom';
import { SW2eArchTableTechSpecialist } from './tables/techspecialisttable';

export const SW2eArchetypeTechSpecialist = () => 
<div className="text-container all-indent">
    <h2 className="header-bg">Tech Specialist</h2>
    <p>A tech specialist is an individual that focuses on modifying and enhancing fantastic technology to fit their
    needs. Tech specialists range from brilliant engineers to prodigious outlaw techs, though all share a love for the
    droids, gadgets, gear and guns found throught the galaxy.</p>
    <br />
    <Route component={SW2eArchTableTechSpecialist} />
    <br />
</div>