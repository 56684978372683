import React from 'react';
import { Link } from 'react-router-dom'

export const FeatSoldierArmorExpertise = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Armor Expertise
		<div className="spacer" />
		<span className="right">Feat 11</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/juggernaut">Juggernaut</Link>, at least 8 total Soldier feats</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You have spent so much time in armor that you know how to make the most of its protection. Your proficiency ranks for unarmored defense, as well as all types of armor in which you are trained increase to expert. You gain the armor specialization effects of heavy and powered armor.</p><br />
</div>