import React from 'react';
import { Link } from 'react-router-dom'

export const FeatMythicAnimalCompanion = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Animal Companion
			<div className="spacer" />
			<span className="right">Feat Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> Animal Companion, <Link to="/mythic/feats/mythicbond">Mythic Bond</Link></li>
		</ul>
		<hr />
		<p className="no-indent">Your animal companion gains its own pool of 3 Mythic Power points. Increase your companion's Strength, Dexterity, Constitution, and Wisdom modifiers by 1.</p>
	</div>
	<div className="spacer" />
</div>