import React from 'react';

export const MythicPathsAbout = () =>
<div>
	<h2 className="header-bg">Mythic Paths</h2>
	<p>Every mythic character belongs to a mythic path. Each path represents a journey into legend, and as they gain new mythic ranks,
	that path grants abilities and features related to that pursuit.</p>
	<br />
	<h3 className="sub-header-bg">Selecting a Path</h3>
	<p>Once you gain mythic power, you select a mythic path, which is much like an additional class. It determines the
	majority of your mythic abilities. But instead of gaining levels in a mythic path, you gain ranks that grant additional
	abilities and bonuses. Increasing your mythic rank doesn’t replace gaining experience and character levels. You still receive
	experience points for defeating challenges, but these apply only to your class levels. You increase your mythic rank by
	completing a number of trials; see Increasing Rank; or by other methods as determined by your GM.</p>
</div>