import React from 'react';
import { Link } from 'react-router-dom'

export const FeatTitanicRage = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Titanic Rage
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Archon</div>
			<div className="trait">Demon</div>
			<div className="trait">Dragon</div>
			<div className="trait">Elemental</div>
			<div className="trait">Fey</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Fey] deep form</li>
		</ul>
		<hr />
		<p className="no-indent">You can use your power to grow in size, becoming a terrifying sight to behold. You gain the <i><Link to="/mythic/spells/titanicrage">titanic rage</Link></i> mythic spell.</p>
	</div>
	<div className="spacer" />
</div>