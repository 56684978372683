import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellBlowback = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Blowback
			<div className="spacer" />
			<span className="right">Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Attack" /></div>
			<div className="trait"><Trait name="Flourish" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Targets:</b> One creature no more than one size larger than you.</li>
		</ul>
		<hr />
		<p className="no-indent">Make a melee or ranged Strike against the target</p>
		<p className="no-indent"><b>Critical Success</b> As success, but you push the target up to 20 feet away from you.</p>
		<p className="no-indent"><b>Success</b> In addition to normal damage, you push the target up to 10 feet away from you. If the target strikes a solid object, it takes 1d6 points of bludgeoning damage for every 10 feet it had left to travel</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> Increase the distance you push the target away by 10 feet on a success, or by 20 feet on a critical success.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>