import React from 'react';
import { Link } from 'react-router-dom'

export const FeatOvercharge = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Overcharge
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/custommodifications">Custom Modifications</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Your understanding of energy weapons allows you to squeeze more out of them than is normally possible. You gain the <Link to="/sw2e/powers/overcharge">overcharge</Link> focus power and a focus pool of 1 Focus Point.</p><br />
	<h3 className="headerbar sub-header-bg shadow-sm">Overcharge Leads To...</h3>
	<p className="all-indent no-indent"><Link to="/sw2e/feats/overcharge">Overcharge</Link></p>
</div>