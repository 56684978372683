import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Feat, Ritual, Spell } from '../../extra/linkables';
import { PathFeaturesTable, PathTitleDescrip } from '../../extra/pathtemplates';
import {
	AbilityBoosts, AbilityHeader, AmazingSpeed, ForceOfWill, HardToKill, Immortal, LegendaryHero, MythicPower,
	MythicSurge, Recuperation, UniversalMythicFeats, Unstoppable
} from '../../extra/sharedabilities';
import { AngelMythicFeats } from './feats/angel';
import { AngelPathRoute } from './routes/angel';

const angelFeatures: { [index: number]: string[] } = {
	1: ["angelic light", "incorruptible", "sacred senses"],
    2: ["angelic form (+10 HP, weakness 5)", "angel feat"],
    3: ["angelic gifts"],
    4: ["angel feat"],
    5: ["angelic form (+20 HP, weakness 10)", "angelic wings (30 feet)"],
    6: ["angel feat"],
    7: ["angelic wings (60 feet)", "lifegiving soul"],
    8: ["angelic form (+30 HP, weakness 15)", "angel feat"],
    9: ["angelic wings (100 feet)", "genesis of light"],
    10: ["angelic form (+40 HP, weakness 20)", "angel feat"],
}

const description: string = `Modeling their power after the benevolent celestial hosts, those who follow the Path of the
	Angel value light, life, truth and cooperation, and would put their efforts towards inspiring those values in others.`

export const PathoftheAngel = () =>
<div>
	<AngelPathRoute />
	<Switch>
		<Route exact path="/mythic/paths/angel">
	<PathTitleDescrip name="Path of the Angel" description={description} hp={10} />

	<h2 className="header-bg">Path Features</h2>
	<br />
	<div className="columns">
		<div className="col-md left-col">
			<PathFeaturesTable pathFeatures={angelFeatures}/>
			<br />
			<AbilityHeader name="Angelic Light" level="Rank 1" />
			<p>Your mythic power manifests as a nimbus of holy energy. Add your mythic rank to your level when
				determining the strength of the aura of good you possess which can be detected with Detect Alignment and
				similar effects.</p>
			<p>Choose one of the following mythic cantrips, which you can cast at no cost. It is automatically
				heightened to your mythic rank. This choice can be changed during your daily
				preparations: <Spell name="sword of retribution" />, <Spell name="shield of life" />, <Spell name="hymn of glory" />,
				or <Spell name="light of truth" />.</p>
			<br />
			<HardToKill />
			<br />
			<AbilityHeader name="Incorruptible" level="Rank 1" />
			<p>Your mythic power pushes you to be a steadfast pillar of good. Nothing less than the direct intervention
				of a diety or your willful effort can change your alignment to a non-good alignment.</p>
			<br />
			<AbilityHeader name="Sacred Senses" level="Rank 1" />
			<p>Your eyes are filled with a hint of divine light. You
				gain <a href="https://2e.aonprd.com/Rules.aspx?ID=415">darkvision</a>, and can
				cast <Spell name="detect alignment" /> as an innate divine spell at will, but only to detect evil. It is
				automatically heightened to your mythic rank and loses the somatic and verbal components, but gains the
				concentrate trait.</p>
			<br />
			<MythicPower />
			<br />
			<MythicSurge />
			<br />
			<UniversalMythicFeats />
			<br />
			<AbilityHeader name="Angelic Form" level="Rank 2" />
			<p>Your skin takes on a soft, faintly metalic hue. You gain a +1 status bonus on all saves vs. magic and your
				Hit Points increase by 10, but you also gain weakness 5 to evil. At 5th, 8th and 10th rank, your Hit
				Points increase by an additional 10, and your weakness to evil increases by an additional 5.</p>
			<br />
			<Recuperation />
			<br />
		</div>
		<div className="col-md right-col">
			<AbilityHeader name="Angel Feats" level="Rank 2" />
			<p>At 2nd rank and every even rank, you gain an <Link to="/mythic/feats/angel">angel path feat</Link>.</p>
			<br />
			<AbilityBoosts />
			<br />
			<AmazingSpeed />
			<br />
			<AbilityHeader name="Angelic Gifts" level="Rank 3" />
			<p>You can perform a number of the miracles that angels are known for. You
				gain <Spell name="remove fear" />, <Spell name="remove disease" />, <Spell name="remove curse" />,
				and <Spell name="breath of life" />, as divine innate spells once per day. They are automatically
				heightened to your mythic rank.</p>
			<br />
			<AbilityHeader name="Angelic Wings" level="Rank 5" />
			<p>You gain the <Feat name="mythic flight" /> mythic feat, allowing you to manifest feathered, angelic
				wings. If you already have Mythic Flight, you gain an <Link to="/mythic/feats/angel">angel path
				feat</Link> of your choice. Your base fly Speed with mythic flight increases to 60 feet at rank 7, and
				again to 100 feet at rank 9. You continue to use your Speed instead, if greater.</p>
			<br />
			<ForceOfWill />
			<br />
			<AbilityHeader name="Lifegiving Soul" level="Rank 7" />
			<p>Your connections with life are stronger than ever, and you can grant new life to those who have passed
				before their time. You gain the Gift of Life ability.</p>
			<div className="indent">
				<br />
				<h3 className="headerbar header-bg short-margin">Gift of Life&nbsp;<div className="threeaction" /></h3>
				<div className="traits short-margin">
					<div className="trait">Concentrate</div>
					<div className="trait">Mythic</div>
					<div className="trait">Divine</div>
					<div className="trait">Healing</div>
					<div className="trait">Necromancy</div>
				</div>
				<div className="short-margin">
					<b>Frequency</b> Once per week.<br />
					<b>Range</b> 10 feet; <b>Target(s)</b> 1 dead creature.
				</div>
				<hr />
				<p>You return the target to life with the same effects and limitations as a
					successful <Ritual name="resurrect" /> ritual heightened to your mythic rank. This has no cost and
					does not require secondary casters. If the target is unwilling or unable to return, this ability is
					not expended.</p>
			</div>
			<br />
			<Unstoppable />
			<br />
			<AbilityHeader name="Genesis of Light" level="Rank 9" />
			<p>As your mythic power reaches its height, you are reborn as a being of pure light and your skin takes on
				an appearance of polished metal. You gain the <a href="https://2e.aonprd.com/Traits.aspx?ID=8">Angel
				</a> and <a href="https://2e.aonprd.com/Traits.aspx?ID=23">Celestial</a> traits, and lose whichever race
				traits you possessed previously. You cease aging, gain regeneration 20 (deactivated by evil), and
				the <Feat name="mythic sustenance" /> universal mythic feat. If you already have the Mythic Sustenance
				feat, you gain a universal mythic feat of your choice.</p>
			<br />
			<Immortal />
			<br />
			<LegendaryHero />
		</div>
	</div>
		</Route>
		<Route path="/mythic/paths/angel/feats" component={AngelMythicFeats}/>
	</Switch>
	
</div>