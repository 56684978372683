import React from 'react';
import { Link } from 'react-router-dom'
export const FeyMythicFeats = () =>
<div>
	<h2 className="header-bg">Fey Mythic Feats</h2>
	<p className="no-indent">Every mythic rank at which you gain a Fey feat, select one of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm full-width">
		<thead>
			<tr>
				<th className="col-3">Name</th>
				<th className="col-1">Rank</th>
				<th className="col-3">Traits</th>
				<th className="col-3">Prerequisites</th>
				<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/feats/incredibleclimber">Incredible Climber</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>[Dragon] copper form or white form; [Fey] woodland form</td>
				<td>You gain a climb speed.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/incredibleswimmer">Incredible Swimmer</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>[Dragon] black form, bronze form, green form or gold form; [Fey] aquatic form</td>
				<td>You gain a swim speed.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/infinitevariety">Infinite Variety</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Gain perplexing abilities from countless sources.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/abundantmagic">Abundant Magic</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td>You gain a wider variety of innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/changeshape">Change Shape</Link></td>
				<td className="center">2</td>
				<td>Concentrate, Mythic, Polymorph, Transmutation</td>
				<td>—</td>
				<td>Transform into other creatures.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/exoticshape">Exotic Shape</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Transform into several exotic forms.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicmetamagic">Mythic Metamagic</Link></td>
				<td className="center">2</td>
				<td>Mythic, Metamagic</td>
				<td>Ability to cast spells</td>
				<td>You manipulate your spells in powerful and unexpected ways.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/magicsurge">Magic Surge</Link></td>
				<td className="center">3</td>
				<td>Mythic</td>
				<td>Ability to cast spells</td>
				<td>You can cast a powerful version of any spell you know.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td>You gain more innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/auraofdread">Aura of Dread</Link></td>
				<td className="center">4</td>
				<td>Aura, Emotion, Fear, Mental, Mythic</td>
				<td>—</td>
				<td>Your presence frightens those around you.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/metamixing">Metamixing</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>Any two actions with the metamagic trait</td>
				<td>You can apply two metamagics to a spell at once.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicflight">Mythic Flight</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Elemental] air element; [Fey] airborne form</td>
				<td>You can sprout wings and fly.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/powerfuldigger">Powerful Digger</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Dragon] blue form or brass form; [Elemental] earth element; [Fey] deep form</td>
				<td>You grow claws suitable for digging.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/shadowdoubling">Shadow Doubling</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Fey] deep form or highland form</td>
				<td>You can create shadowy duplicates of yourself to aid you.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/shadowstep">Shadow Step</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Fey] deep form or highland form</td>
				<td>You can teleport between shadowy areas.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/titanicrage">Titanic Rage</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Fey] deep form</td>
				<td>You can grow to a terrifying size.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/direspellcraft">Dire Spellcraft</Link></td>
				<td className="center">5</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/ascendspell">Ascend Spell</Link></td>
				<td>You can infuse your spells with even more mythic power.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/accursedspellcraft">Accursed Spellcraft</Link></td>
				<td className="center">6</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You understand curses and enchantments like no other.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/yournamehere">Your Name Here</Link></td>
				<td className="center">6</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You trade in names, memories, and abilities as easily as coins.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/greaterinnatemagic">Greater Innate Magic</Link></td>
				<td className="center">7</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td>You gain even more innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/peerlessinnatemagic">Peerless Innate Magic</Link></td>
				<td className="center">10</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td>You gain even more innate spellcasting abilities.</td>
			</tr>
		</tbody>
	</table>
</div>
