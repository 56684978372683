import React from 'react';
import { Link } from 'react-router-dom'
export const SW2eArchTableNoble = () =>
<div>
	<h2 className="header-bg">Fundamental Noble Feats</h2>
	<p className="all-indent">Each time you gain a level, you can select one or more of the following feats. You must satisfy any prerequisites before taking the feat. Fundamental feats provide essential abilities and improve your proficiencies. They must often be taken to progress in an archetype.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/greatfortitude">Great Fortitude</Link></td>
				<td>5</td>
				<td>Archetype, Noble, Scoundrel, Tech Specialist</td>
				<td>—</td>
				<td>Your Fortitude save proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/lightningreflexes">Lightning Reflexes</Link></td>
				<td>5</td>
				<td>Archetype, Noble, Scoundrel, Scout, Soldier, Tech Specialist</td>
				<td>—</td>
				<td>Your Reflex save proficiency increases to expert.</td>
			</tr>
		</tbody>
	</table>
	<br />
	<h2 className="header-bg">Noble Feats</h2>
	<p className="all-indent">Each time you gain a level, you can also select one or more of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
		</tbody>
	</table>
</div>
