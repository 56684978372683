import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellUnstoppableResolve = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Unstoppable Resolve
			<div className="spacer" />
			<span className="right">Mythic 7</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Healing" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
		</ul>
		<hr />
		<p className="no-indent">You recover from the blinded, confused, controlled, dazzled, deafened, fascinated, fatigued, fleeing, frightened, immobilized, sickened, slowed and stunned conditions.</p>
		<p className="no-indent">You can cast Unstoppable Resolve at the start of your turn even if a condition would normally prevent you from taking your turn or reduce the number of actions you have available. This does not restore any lost actions.</p>
	</div>
	<div className="spacer" />
</div>