import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PowerAlterFate } from './limited/alterfate';
import { PowerOvercharge } from './focus/overcharge';
import { PowerQuickRepair } from './focus/quickrepair';

export const SW2ePowersRoute = () =>
<div className="text-container">
	<Switch>
		<Route path="/sw2e/powers/limited/alterfate" component={PowerAlterFate}/>
		<Route path="/sw2e/powers/focus/overcharge" component={PowerOvercharge}/>
		<Route path="/sw2e/powers/focus/quickrepair" component={PowerQuickRepair}/>
	</Switch>
</div>
