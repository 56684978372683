import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellSowThought = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Sow Thought&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://aonprd.com/SpellDisplay.aspx?ItemName=Sow%20Thought"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Enchantment</div>
			<div className="trait">Mental</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Range</b> 30 feet; <b>Targets:</b> 1 creature</li>
			<li><b>Saving Throw</b> Will</li>
		</ul>
		<hr />
		<p className="no-indent">You plant a clear idea, concept or suspicion that can be explained in 25 words or fewer in the target's mind. The target is not required to act upon the thought. If the idea is contrary to the target's normal thoughts, such as someone thinking that they should murder their close friends without cause, they may suspect something is wrong with them, or that magic is at play.</p><br />
		<p className="no-indent"><b>Critical Success</b> The target is aware that the idea is not their own and that someone attempted to manipulate them with magic.</p>
		<p className="no-indent"><b>Success</b> As failure, but the idea is only a fleeting one, easily forgotten or dismissed unless it is brought up repeatedly, or is of particular interest to them.</p>
		<p className="no-indent"><b>Failure</b> The target genuinely believes that the idea is their own, and is compelled to contemplate it thoroughly.</p>
		<p className="no-indent"><b>Critical Failure</b> As failure, but the idea is planted so firmly that the target is vividly reminded of it whenever a related subject is brought up.</p>
	</div>
	<div className="spacer" />
</div>