import React from 'react';
import freeaction from '../../../resources/free-action.png';
import { Trait } from '../../extra/linkables';

export const SpellMythicQuickenSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Quicken Spell
			<div className="spacer" />
			<span className="right">Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Metamagic" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Rush" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="Free Action" src={freeaction} /> </li>
			<li><b>Frequency:</b> once per turn</li>
		</ul>
		<hr />
		<p className="no-indent">You use a burst of mythic power to supplement your spellcasting and cast spells faster. If the next action you use is to Cast a Spell, reduce the number of actions to cast it by 1. If this would reduce the actions to zero, it becomes a free action. If the spell you cast is a cantrip, you regain the Mythic Power Point spent to cast this spell.</p>
	</div>
	<div className="spacer" />
</div>