import React from 'react';
import { Condition } from '../../../extra/linkables';

export const FeatInfoGreaterLegendaryItem = () =>
<div className="no-indent all-indent">
	<h2 className="header-bg shadow-sm">Greater Legendary Item Special Abilities</h2>
	<br />
	<p className='hanging-indent'><b><u>Everlasting</u></b>: The item grants its partner limited immortality. While wearing or holding this item, the bonded creature
		doesn't age; doesn't need to eat, drink, or breathe; and is protected from severe cold, severe heat, extreme cold,
		and extreme heat.</p>
	<p>If the item has a <i>dedicated bond</i>, it grants this ability to it's bonded partner regardless of whether or not it
		is worn or held.</p>

	<p className='hanging-indent'><b><u>Greater Magical Boon</u></b>: The spell you choose with magical boon can be up
		to 6th level. Your item must have the <i>magical boon</i> ability to select this.</p>
	<br />

	<p className='hanging-indent'><b><u>Spirit Vessel</u></b>: As a 3 action activity that has the concentrate and
		manipulate traits, you can touch your legendary item and project your spirit fully into it. When you do, your
		body falls unconscious, but you can see through your legendary item's senses, gain access to your item's
		special abilities, and can act, cast spells, and use your other abilities, including your mythic abilities as if
		you were your legendary item. Your item loses the minion trait, and becomes fully controlled by you, gaining
		the normal number of actions you would gain each turn. When making an attack, saving throw, perception check or
		skill check, use your own proficiencies, or your item's, whichever is greater. Your legendary item must have the
		appropriate ability, such as <i>animated</i>, or <i>speech</i> for you to perform a given action while
		inhabiting it. You can return to your own body by using this ability again. Your item must have the <i>share
		senses</i> ability to select this.</p>
	<p>If the item has a <i>dedicated bond</i>, you do not need to touch your legendary item to project your spirit into
		it, and the activity loses the manipulate trait.</p>
	<p>If your legendary item is destroyed while your spirit inhabits it, you are forcibly ripped back to your body, and
		the strain causes you to become <Condition name="clumsy" value={2} />, <Condition name="drained" value={2} />
		, <Condition name="doomed" value={2} />, and <Condition name="enfeebled" value={2} /> for 1 week. These conditions 
		can't be removed or reduced by any means until the week has passed. If your body is not available to return to,
		you die.</p>
	<p>If you would die while you are holding or wearing your legendary item, or any time while it is not destroyed if
		the item has a <i>dedicated bond</i>, You can immediately transfer your spirit into the item. This does not
		prevent your body from dying, but allows you to continue to exist and act through your legendary item. You
		cannot return to your body until it is brought back to life.</p>
	<br />
</div>