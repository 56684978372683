import React from 'react';
import { Link } from 'react-router-dom';

export const SpellTweaksOccult = () =>
<div>
	<h3 className="header-bg">Occult  Cantrips</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/acidsplash"><b>Acid Splash (evo):</b></Link> Damage creatures with acid.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/telekineticprojectile"><b>Telekinetic Projectile (evo):</b></Link> Fling an object at a creature</li>
	</ul>
	<h3 className="header-bg"> 1st-Level Occult Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/bane"><b>Bane (enc):</b></Link> Weaken enemies' attacks in an aura around you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/bless"><b>Bless (enc):</b></Link> Strengthen allies' attacks in an aura around you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/floatingdisk"><b>Floating Disk (con):</b></Link> A disk of energy follows you, carrying objects.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/sleep"><b>Sleep (enc):</b></Link> Cause creatures in a small area to fall asleep.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/sowthought"><span className="tweak-color"><b>Sow Thought (enc):</b></span></Link> Plant an idea in another creature's mind.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/unnaturallust"><span className="tweak-color"><b>Unnatural Lust (enc):</b></span></Link> Suggest a course of action a creature must follow.</li>
	</ul>
	<h3 className="header-bg"> 2nd-Level Occult Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/animatedead"><b>Animate Dead (nec):</b></Link> Animate an undead creature to fight on your behalf.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/restoration"><b>Restoration (nec):</b></Link> Counter the effects of toxins or conditions with restorative magic.</li>
	</ul>
	<h3 className="header-bg"> 3rd-Level Occult Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/nondetection"><b>Nondetection (abj):</b></Link> Protect a creature or object from detection.</li>
	</ul>
	<h3 className="header-bg"> 4th-Level Occult Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/bestowcurse"><span className="tweak-color"><b>Bestow Curse (nec):</b></span></Link> Curse a creature.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/iridescentbeam"><span className="tweak-color"><b>Iridescent Beam (evo):</b></span></Link> Strike creatures with a multicolored beam of light.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/spellimmunity"><b>Spell Immunity (abj):</b></Link> Name a spell to negate its effects on you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/suggestion"><b>Suggestion (enc):</b></Link> Suggest a course of action a creature must follow.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/telepathy"><b>Telepathy (div):</b></Link> Communicate telepathically with any creatures near you.</li>
	</ul>
	<h3 className="header-bg"> 5th-Level Occult Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 6th-Level Occult Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/trueseeing"><b>True Seeing (div):</b></Link> See through illusions and transmutations.</li>
	</ul>
	<h3 className="header-bg"> 7th-Level Occult Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 8th-Level Occult Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 9th-Level Occult Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 10th-Level Occult Spells</h3>
	<ul className="unstyled">
	</ul>
</div>