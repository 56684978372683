import React from 'react';

export const FeatTechSpecialistWeaponExpertise = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Weapon Expertise
		<div className="spacer" />
		<span className="right">Feat 7</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> Expert Will and Fortitude save proficiency, at least 4 total Tech Specialist feats,</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Youâ€™ve dedicated yourself to learning the intricacies of your weapons. Your proficiency ranks for any weapons you are trained in increase to expert.</p><br />
</div>