import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellTrueSeeing = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">True Seeing&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=344"> </a>
			<div className="spacer" />
			<span className="right">Spell 6</span>
		</h2>
		<div className="traits">
			<div className="trait">Divination</div>
			<div className="trait">Revelation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, divine, occult, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Duration</b> <span className="tweak-color">2 hours</span></li>
		</ul>
		<hr />
		<p className="no-indent">You see things within 60 feet as they actually are. The GM rolls a secret counteract check against any illusion or transmutation in the area, but only for the purpose of determining whether you see through it (for instance, if the check succeeds against a polymorph spell, you can see the creature's true form, but you don't end the polymorph spell).</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>True seeing is a weird spell. It's both terrible as part of a standard kit, and <i>amazing</i> to have as an item or constant effect. The effect got a serious (and well deserved) nerf in 2e, but now the spell version is even harder to justify. A 2 hour duration means it's long enough to last for a short to medium dungeon crawl, which makes it much more usable.</p>
	</div>
	<div className="spacer" />
</div>