import React from 'react';

export const SW2ePowersDark = () =>
<div>
	<h3 className="header-bg"> Basic Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 1st-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 2nd-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 3rd-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 4th-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 5th-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 6th-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 7th-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 8th-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 9th-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 10th-Level Dark Side Powers</h3>
	<ul className="unstyled">
	</ul>
</div>