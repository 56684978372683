import React from 'react';
import { FeatInfoMythicMetamagic } from './extrainfo/mythicmetamagic';
export const FeatMythicMetamagic = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Metamagic
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Metamagic</div>
			<div className="trait">Azata</div>
			<div className="trait">Devil</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> Ability to cast spells</li>
		</ul>
		<hr />
		<p className="no-indent">You manipulate your spells in powerful and unexpected ways. You gain 3 mythic metamagic spells from the list below, with a spell level equal to your mythic rank or lower whose prerequisites you satisfy. Each time you gain a mythic rank, you can swap out one of these metamagic spells for another from the list for which you qualify.</p>
        <br />
        <FeatInfoMythicMetamagic />

	</div>
	<div className="spacer" />
</div>