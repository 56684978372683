import React from 'react';

export const FeatAccursedSpellcraft = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Accursed Spellcraft
			<div className="spacer" />
			<span className="right">Feat Mythic 6</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Devil</div>
			<div className="trait">Fey</div>
			<div className="trait">Rakshasa</div>
			<div className="trait">Shadow</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You understand curses and enchantments like no other. You gain a +2 circumstance bonus on saving throws against enchantment and curse effects, and a +2 circumstance bonus on checks made to counteract them.</p>
		<p className="no-indent">Three times per day, as an activity that requires 10 minutes, you can attempt to weave or unravel a curse or enchantment.</p>
		<p className="no-indent">If you choose to weave a new curse, choose a creature which you have seen in the last 10 minutes and a curse or enchantment. The curse can be any common <a href="https://2e.aonprd.com/Curses.aspx">curse</a> with a level that is no more than your level, or twice your mythic rank, whichever's greater. Curses which require specific dieties, locations or items which you do not possess (at GM's discretion) cannot be chosen. The GM might allow other curses which you have access to. Alternatively, you can choose any curse or enchantment spell which you have access to that has a spell level equal to or less than you mythic rank or half your level rounded up. The spell does not need to be in your spell book or spell repertiore. After completing the weaving, the target must succeed on a Will save or be afflicted by the curse or enchantment regardless of distance. The spell level for this effect is equal to the greater of your mythic rank or half your level rounded up, and the DC of the saving throw is equal to the greater of your spell DC or class DC.</p>
		<p className="no-indent">If you choose to unravel a curse or enchantment, choose a creature or object with an ongoing curse or enchantment effect and attempt to counteract it. The target must be present for the entire activity. Your counteract level is equal to the greater of your mythic rank or half your level rounded up, and your counteract bonus is equal to the greater of your spellcasting ability modifier plus your spellcasting proficiency bonus or your class DC - 10.</p>
	</div>
	<div className="spacer" />
</div>