import React from 'react';
import reaction from '../../../resources/reaction.png';
import { Trait } from '../../extra/linkables';

export const SpellBorrowTime = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Borrow Time
			<div className="spacer" />
			<span className="right">Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="Reaction" src={reaction} /> </li>
			<li><b>Trigger:</b> Another creature is about to begin it's turn</li>
		</ul>
		<hr />
		<p className="no-indent">Borrowing from the future, you act immediately and pay it back afterwards</p>
		<p className="no-indent">Take your next turn immediately, before the triggering creature takes its turn. Skip your normal turn this round, or your turn in the following round if you have already acted this round. This does not alter your position in the turn order. You cannot use Borrow Time again for 1 round.</p>
	</div>
	<div className="spacer" />
</div>