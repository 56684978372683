import React from 'react';
import { Switch, Route } from 'react-router';
import { Link } from 'react-router-dom';
import { FeatTweaks } from './contenttweaks/feattweaks/feattweaks';
import { SpellTweaks } from './contenttweaks/spelltweaks/spelltweaks';

export const ContentTweaks = () =>
<div className="content">
	<Switch>
		<Route exact path="/contenttweaks">
			<div className="text-container">
				<h1>Spell Tweaks</h1>
				<p>Here you will find a number of tweaks to existing spells and some conversions of spells from 1e with the
					goal of relieving a lot of the frustration caused by finicky restrictions and missing utility. Many
					spells also offer Mythic heightening effects for use with the Sprocket
					Fox <Link to="/mythic">Mythic rules</Link>. Choose a catagory below to see more. Changes to an existing
					spell, along with new spells and spells converted from other rulesets
					will <span className="tweak-color">be highlighted.</span></p>
				<br />

				<h2 className="center">
					<Link to="/contenttweaks/archtweaks">Archetypes</Link>
					<span className="spacerbar">|</span>
					<Link to="/contenttweaks/feattweaks">Feats</Link>
					<span className="spacerbar">|</span>
					<Link to="/contenttweaks/spelltweaks">Spells</Link>
				</h2>
			</div>
		</Route>
		<Route path="/contenttweaks/spelltweaks" component={SpellTweaks} />
		<Route path="/contenttweaks/feattweaks" component={FeatTweaks} />
	</Switch>
</div>
