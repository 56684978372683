import React from 'react';
import { Link } from 'react-router-dom'

export const FeatSubduingStrikes = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Subduing Strikes
		<div className="spacer" />
		<span className="right">Feat 2</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You have trained in techniques to use when subduing opponents without killing them. When you Strike a creature with a one-handed weapon and deal nonlethal damage, you deal an extra 1d6 nonlethal precision damage.</p><br />
</div>