import React from 'react';

export const FeatIrrepressible = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Irrepressible
			<div className="spacer" />
			<span className="right">Feat Mythic 10</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Despite your size, nothing can hold you&mdash; or your friends&mdash; down. You gain <a href="https://2e.aonprd.com/Spells.aspx?ID=128"><i>freedom of movement</i></a> as a 10th level constant divine Innate spell and a 10th level at will divine Innate spell. As a 3-action activity, you can perform an 8th level <a href="https://2e.aonprd.com/Rituals.aspx?ID=11"><i>freedom</i></a> ritual with no secondary casters and at no cost. The DC of the primary check is equal to the highest DC of an effect that imprisoned, petrified or put the creature into stasis.</p>
	</div>
	<div className="spacer" />
</div>