import React from 'react';
import { Link } from 'react-router-dom'
export const ElementalMythicFeats = () =>
<div>
	<h2 className="header-bg">Elemental Mythic Feats</h2>
	<p className="no-indent">Every mythic rank at which you gain an Elemental feat, select one of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm full-width">
		<thead>
			<tr>
				<th className="col-3">Name</th>
				<th className="col-1">Rank</th>
				<th className="col-3">Traits</th>
				<th className="col-3">Prerequisites</th>
				<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/feats/incredibleclimber">Incredible Climber</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>[Dragon] copper form or white form; [Fey] woodland form</td>
				<td>You gain a climb speed.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/incredibleswimmer">Incredible Swimmer</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>[Dragon] black form, bronze form, green form or gold form; [Fey] aquatic form</td>
				<td>You gain a swim speed.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You can survive an attack that would have killed you with 1 hit point.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/throwrock">Throw Rock</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>[Elemental] earth element</td>
				<td>You can throw rocks with great force.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/abundantmagic">Abundant Magic</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td>You gain a wider variety of innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/stomp">Stomp</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>[Elemental] earth element</td>
				<td>You can shake the ground, knocking down those around you and creating rough terrain.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/imbuedarms">Imbued Arms</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Imbue your weapons with elemental or alignment damage.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicflight">Mythic Flight</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Elemental] air element; [Fey] airborne form</td>
				<td>You can sprout wings and fly.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/powerfuldigger">Powerful Digger</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Dragon] blue form or brass form; [Elemental] earth element; [Fey] deep form</td>
				<td>You grow claws suitable for digging.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/titanicrage">Titanic Rage</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Fey] deep form</td>
				<td>You can grow to a terrifying size.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/unscathed">Unscathed</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link></td>
				<td>You can shrug off blows and ignore effects that would slow you down.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/indomitable">Indomitable</Link></td>
				<td className="center">8</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/miraculoussurvival">Miraculous Survival</Link>, <Link to="/mythic/feats/unscathed">Unscathed</Link></td>
				<td>You can continue to activate <Link to="/mythic/feats/unscathed">Unscathed</Link> until your next turn at no additional cost.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/titanicleap">Titanic Leap</Link></td>
				<td className="center">8</td>
				<td>Earth, Evocation, Mythic</td>
				<td><Link to="/mythic/feats/stomp">Stomp</Link>; [Elemental] earth element</td>
				<td>Leap forward and shatter the ground where you land.</td>
			</tr>
		</tbody>
	</table>
</div>
