import React from 'react';
import action from '../../../../resources/1-action.png';

export const SpellDimensionalHop = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Dimensional Hop&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=1037"> </a>
			<div className="spacer" />
			<span className="right">Focus 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Uncommon</div>
			<div className="trait">Conjuration</div>
			<div className="trait">Magus</div>
			<div className="trait">Teleportation</div>
		</div>
		<ul className="proplist">
			<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /> (somatic)</li>
			<li><b>Range</b> 10 feet</li>
		</ul>
		<hr />
		<p className="no-indent">You teleport to a location up to 10 feet away within your line of sight.</p>
		<hr />
			<p className="no-indent"><b>Heightened (+2)</b> The distance you can teleport increases by 10 feet.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Changing Dimensional Assault to be an ability that triggers when you teleport leaves the Magus without an actual way to <i>use it</i> without multiclassing. Free teleporting at level 1 is a little strong, but Dimensional Assault was <i>already a way to do that</i>, with some (admittedly silly) setup.</p>
  		<p>A +2 heightening delays it's growth, and keeps it even with the wizard <a href="https://2e.aonprd.com/Spells.aspx?ID=524">Dimensional Steps</a> spell, most of the time.</p>
	</div>
	<div className="spacer" />
</div>