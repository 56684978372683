import React from 'react';
import threeaction from '../../../resources/3-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellTideofUndeath = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Tide of Undeath
			<div className="spacer" />
			<span className="right">Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Evocation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="3 Action" src={threeaction} /> </li>
			<li><b>Range:</b> 100 feet; <b>Targets:</b> dead creatures of your choice within range</li>
			<li><b>Duration:</b> 1 hour</li>
		</ul>
		<hr />
		<p className="no-indent">You cast out your power in a wave, capturing the lingering framents of departed souls and binding them to their former bodies. All targets within range are transformed into zombies or skeletons (your choice), as if you had critically succeeded with a <a href="https://2e.aonprd.com/Rituals.aspx?ID=10">create undead</a> ritual of a level equal to your mythic rank. Once <i>tide of undeath</i>'s dureation ends, all undead created by it are destroyed.</p>
		<p className="no-indent">This spell requires 3 Mythic Power Points to cast, instead of 1.</p>
	</div>
	<div className="spacer" />
</div>