import React from 'react';

export const FeatGodslayer = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Godslayer&nbsp;<div className="freeaction" />
			<div className="spacer" />
			<span className="right">Feat Mythic 10</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
			<li><b>Trigger:</b> You damage a creature capable of using divine spells or abilities.</li>
		</ul>
		<hr />
		<p className="no-indent">You attempt to sever the creature's connections to the divine. It must attempt a Will save against your class or spell DC, whichever's higher.</p>
        <br />
        <div className='no-indent'>
  <p><b>Critical Success</b> The creature is unaffected.</p>
  <p><b>Success</b> The creature can't use divine spells or abilities for 1 round and is frightened 2. Only
    powerful non-divine magic, such as wish, can undo this effect.</p>
  <p><b>Failure</b> As success, but the duration is 1 minute.</p>
  <p><b>Critical Failure</b> As success, but the duration is unlimited.</p>
</div>

	</div>
	<div className="spacer" />
</div>