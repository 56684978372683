import React from 'react';

export const FeatPiercetheDarkness = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Pierce the Darkness
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Archon</div>
			<div className="trait">Angel</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Enlightenment</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You gain <a href='https://2e.aonprd.com/MonsterAbilities.aspx?ID=12'>greater darkvision</a>, allowing you to see through even magical darkness.</p>
	</div>
	<div className="spacer" />
</div>