import React from 'react';
import { Switch, Route } from 'react-router';
import { SW2eHome } from './sw2e/home';
import { SW2eRules } from './sw2e/rules';
import { SW2eSpecies } from './sw2e/species';
import { SW2eBackgrounds } from './sw2e/backgrounds';
import { SW2eHeroes } from './sw2e/heroes';
import { SW2eFeats } from './sw2e/feats';
import { SW2eSkills } from './sw2e/skills';
import { SW2eEquipment } from './sw2e/equipment';
import { SW2ePowers } from './sw2e/powers';
import { SW2eCreatures } from './sw2e/creatures';
import { SW2eArchetypes } from './sw2e/archetypes';

export const SW2e = () =>
<div className="content sw-body">
    <h1 className="sw-header">Star Wars 2e OGL</h1> 
    <Switch>
        <Route exact path="/sw2e" component={SW2eHome}/>
        <Route path="/sw2e/rules" component={SW2eRules}/>
        <Route path="/sw2e/species" component={SW2eSpecies}/>
        <Route path="/sw2e/backgrounds" component={SW2eBackgrounds}/>
        <Route path="/sw2e/archetypes" component={SW2eArchetypes}/>
        <Route path="/sw2e/heroes" component={SW2eHeroes}/>
        <Route path="/sw2e/feats" component={SW2eFeats}/>
        <Route path="/sw2e/skills" component={SW2eSkills}/>
        <Route path="/sw2e/equipment" component={SW2eEquipment}/>
        <Route path="/sw2e/powers" component={SW2ePowers}/>
        <Route path="/sw2e/creatures" component={SW2eCreatures}/>
    </Switch>
    <div className="spacer" />
</div>;