import React from 'react';

export const FeatPowerfulDigger = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Powerful Digger
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Dragon</div>
			<div className="trait">Elemental</div>
			<div className="trait">Fey</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Dragon] blue form or brass form; [Elemental] earth element; [Fey] deep form</li>
		</ul>
		<hr />
		<p className="no-indent">You grow a powerful set of claws or other appendages that can be used for digging. You gain a burrow Speed of 20 feet, and can deal lethal slashing damage with your unarmed strikes.</p>
	</div>
	<div className="spacer" />
</div>