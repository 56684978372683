import React from 'react';

export const FeatTechSpecialistAbilityExpertise = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Ability Expertise
		<div className="spacer" />
		<span className="right">Feat 8</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> Expert proficiency with any type of weapon, at least 5 total Tech Specialist feats,</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Extended practice improves your abilities. Your proficiency ranks for your ability DC increases to expert.</p><br />
</div>