import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellEnergeticSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Energetic Spell
			<div className="spacer" />
			<span className="right">Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Metamagic" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> </li>
		</ul>
		<hr />
		<p className="no-indent">If your next action is to Cast a Spell that deals <i>acid</i>, <i>cold</i>, <i>electricity</i>, <i>fire</i>, <i>force</i>, <i>negative</i>, <i>positive</i> or <i>sonic</i> damage, you can change the type of damage dealt to any of the other listed damage types. Spells that deal positive damage instead heal living creatures, while spells that deal negative damage heal undead creatures. Spells that deal any other type of damage affect all types of creatures, but no longer restore Hit Points.</p>
	</div>
	<div className="spacer" />
</div>