import React from 'react';
import { Link } from 'react-router-dom'

const conditions: {[index: string]: number } = {
    "clumsy": 3,
    "drained": 10,
    "doomed": 9,
    "enfeebled": 13,
}

const feats: {[index: string]: { type: string, section?: string, id?: number }} = {
    "changeshape": { type: "local", section: "mythic" },
    "innatemagic": { type: "local", section: "mythic" },
    "mythicsustenance": { type: "local", section: "mythic" },
    "mythicflight": { type: "local", section: "mythic" },
    "telepathy": { type: "local", section: "mythic" },

    "diehard": { type: "archive", id: 774 },
}

const rituals: {[index: string]: { type: string, section?: string, id?: number }} = {
    "astralprojection": { type: "archive", id: 29 },
}

const spells: {[index: string]: { type: string, section?: string, id?: number }} = {
    "burstofspeed": { type: "local", section: "mythic" },
    "mythicquickenspell": { type: "local", section: "mythic" },
    "mythicsurge": { type: "local", section: "mythic" },
    "hymnofglory": { type: "local", section: "mythic" },
    "invigoratingpresence": { type: "local", section: "mythic" },
    "lightoftruth": { type: "local", section: "mythic" },
    "quickeningpresence": { type: "local", section: "mythic" },
    "shelteringpresence": { type: "local", section: "mythic" },
    "shieldoflife": { type: "local", section: "mythic" },
    "suddenstrike": { type: "local", section: "mythic" },
    "swordofretribution": { type: "local", section: "mythic" },
    "unstoppableresolve": { type: "local", section: "mythic" },

    "breathoflife": { type: "archive", id: 29 },
    "dancinglights": { type: "archive", id: 58 },
    "detectalignment": { type: "archive", id: 65 },
    "disruptundead": { type: "archive", id: 79 },
    "heal": { type: "archive", id: 148 },
    "planeshift": { type: "archive", id: 222 },
    "invisibility": { type: "archive", id: 164 },
    "removecurse": { type: "archive", id: 250 },
    "removedisease": { type: "archive", id: 251 },
    "removefear": { type: "archive", id: 252 },
    "restoration": { type: "archive", id: 258 },

    "rayoffrost": { type: "local", section: "tweaks" },
    "telekineticprojectile": { type: "local", section: "tweaks" },
    "nondetection": { type: "local", section: "tweaks" },
}

const traits: {[index: string]: { type: string, id?: number }} = {
    "mythic": { type: "local" },
    "radiance": { type: "local" },
    "rush": { type: "local" },

    "flourish": { type: "archive", id: 73 },
    "manipulate": { type: "archive", id: 104 },
    "metamagic": { type: "archive", id: 107 },
    "move": {type: "archive", id: 114 },
}


export const Condition: React.FC<{name: string, value: number}> = ({name, value}) =>{
    const flatName = name.replace(/\s+/g, '').toLowerCase();

    if (!!conditions[flatName]) {
        return (<a href={"https://2e.aonprd.com/Conditions.aspx?ID=" + conditions[flatName]}>{name.toLowerCase() + " " + value}</a>);
    }

    return (<a href="">{name.capitalizeAll()}</a>);
}

export const Feat: React.FC<{name: string}> = ({name}) =>{
    const flatName = name.replace(/\s+/g, '').toLowerCase();

    if (!!feats[flatName]) {
        if (feats[flatName].type === "archive") {
            return (<a href={"https://2e.aonprd.com/Feats.aspx?ID=" + feats[flatName].id}>{name.capitalizeAll()}</a>);
        }
        else if (feats[flatName].type === "local") {
            if (feats[flatName].section === "mythic") {
                return (<Link to={"/mythic/feats/" + flatName}>{name.capitalizeAll()}</Link>);
            }
            else if (feats[flatName].section === "tweaks") {
                return (<Link to={"/contenttweaks/feattweaks/feats/" + flatName}>{name.capitalizeAll()}</Link>);
            }
        }
    }

    return (<a href="">{name.capitalizeAll()}</a>);
}

export const Ritual: React.FC<{name: string}> = ({name}) =>{
    const flatName = name.replace(/\s+/g, '').toLowerCase();

    if (!!rituals[flatName]) {
        if (rituals[flatName].type === "archive") {
            return (<i><a href={"https://2e.aonprd.com/Rituals.aspx?ID=" + rituals[flatName].id}>{name.toLowerCase()}</a></i>);
        }
        else if (rituals[flatName].type === "local") {
            if (rituals[flatName].section === "mythic") {
                return (<i><Link to={"/mythic/spells/" + flatName}>{name.toLowerCase()}</Link></i>);
            }
            else if (rituals[flatName].section === "tweaks") {
                return (<i><Link to={"/contenttweaks/spelltweaks/spells/" + flatName}>{name.toLowerCase()}</Link></i>);
            }
        }
    }

    return (<i><a href="">{name.toLowerCase()}</a></i>);
}

export const Spell: React.FC<{name: string, title?: boolean}> = ({name, title = false}) =>{
    const flatName = name.replace(/\s+/g, '').toLowerCase();

    if (!!spells[flatName]) {
        if (title) {
            if (spells[flatName].type === "archive") {
                return (<a href={"https://2e.aonprd.com/Spells.aspx?ID=" + spells[flatName].id}>{name}</a>);
            }
            else if (spells[flatName].type === "local") {
                if (spells[flatName].section === "mythic") {
                    return (<Link to={"/mythic/spells/" + flatName}>{name}</Link>);
                }
                else if (spells[flatName].section === "tweaks") {
                    return (<Link to={"/contenttweaks/spelltweaks/spells/" + flatName}>{name}</Link>);
                }
            }
        }

        if (spells[flatName].type === "archive") {
            return (<i><a href={"https://2e.aonprd.com/Spells.aspx?ID=" + spells[flatName].id}>{name.toLowerCase()}</a></i>);
        }
        else if (spells[flatName].type === "local") {
            if (spells[flatName].section === "mythic") {
                return (<i><Link to={"/mythic/spells/" + flatName}>{name.toLowerCase()}</Link></i>);
            }
            else if (spells[flatName].section === "tweaks") {
                return (<i><Link to={"/contenttweaks/spelltweaks/spells/" + flatName}>{name.toLowerCase()}</Link></i>);
            }
        }
    }

    return (<i><a href="">{name.toLowerCase()}</a></i>);
}

export const Trait: React.FC<{name: string}> = ({name}) =>{
    const flatName = name.replace(/\s+/g, '').toLowerCase();

    if (!!traits[flatName] && traits[flatName].type === "archive") {
        return (<a href={"https://2e.aonprd.com/Traits.aspx?ID=" + traits[flatName].id}>{name}</a>);
    }
    else {
        return (<Link to={"/rules/traits/" + flatName}>{name}</Link>);
    }
}