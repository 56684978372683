import React from 'react';

const universalFeatures: { [index: number]: string[] } = {
    1: ["hard to kill", "mythic power", "mythic surge", "universal mythic feat"],
    2: ["recuperation", "universal mythic feat"],
    3: ["ability boosts", "universal mythic feat"],
    4: ["amazing speed", "universal mythic feat"],
    5: ["force of will", "universal mythic feat"],
    6: ["ability boosts", "universal mythic feat"],
    7: ["universal mythic feat", "unstoppable"],
    8: ["immortal", "universal mythic feat"],
    9: ["ability boosts", "universal mythic feat"],
    10: ["legendary hero", "universal mythic feat"],
}

interface PathFeatureDef {
    pathFeatures?: { [index: number]: string[] };
    baseTable?: boolean;
}

export const PathFeaturesTable: React.FC<PathFeatureDef> = ({pathFeatures, baseTable = false}) => {

    const features: { [index: number]: string } = {}
    
    for (let i = 1; i <= 10; i++) {
        features[i] = pathFeatures ?
            universalFeatures[i].concat(pathFeatures[i]).sort().join(", ").capitalize() :
            universalFeatures[i].join(", ").capitalize();
    }

    return (<table>
    <thead>
        <tr>
            <th className="col-1">{baseTable ? 'Mythic Rank' : 'Your Rank'}</th>
            <th className="col-4">Mythic Power Points</th>
            <th>{baseTable ? 'Mythic Features' : 'Path Features'}</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td className="center">1</td>
            <td className="center">5</td>
            <td>{features[1]}</td>
        </tr>
        <tr>
            <td className="center">2</td>
            <td className="center">7</td>
            <td>{features[2]}</td>
        </tr>
        <tr>
            <td className="center">3</td>
            <td className="center">9</td>
            <td>{features[3]}</td>
        </tr>
        <tr>
            <td className="center">4</td>
            <td className="center">11</td>
            <td>{features[4]}</td>
        </tr>
        <tr>
            <td className="center">5</td>
            <td className="center">13</td>
            <td>{features[5]}</td>
        </tr>
        <tr>
            <td className="center">6</td>
            <td className="center">15</td>
            <td>{features[6]}</td>
        </tr>
        <tr>
            <td className="center">7</td>
            <td className="center">17</td>
            <td>{features[7]}</td>
        </tr>
        <tr>
            <td className="center">8</td>
            <td className="center">19</td>
            <td>{features[8]}</td>
        </tr>
        <tr>
            <td className="center">9</td>
            <td className="center">21</td>
            <td>{features[9]}</td>
        </tr>
        <tr>
            <td className="center">10</td>
            <td className="center">23</td>
            <td>{features[10]}</td>
        </tr>
    </tbody>
</table>);
}


export const PathTitleDescrip: React.FC<{name: string, description: string, hp: number}> = ({name, description, hp}) => 
<div className="columns">
		<div className="spacer" />
		<div className="col-lg">
			<h2 className="header-bg">{name}</h2>
			<p>{description}</p>
				<br />
			<p className='no-indent'><b>Hit points: {hp} plus your Constitution modifier</b></p>
			<p className='no-indent'>You increase your maximum number of HP by this number at 1st rank and every rank thereafter.</p>
			<br />
		</div>
		<div className="spacer" />
</div>