import React from 'react';
import { Link, Route, Switch } from 'react-router-dom'
import { ArchonMythicFeats } from './feats/archon';
import { ArchonPathRoute } from './routes/archon';
export const PathoftheArchon = () =>
<div>
	<ArchonPathRoute />
	<Switch>
		<Route exact path="/mythic/paths/archon">
	<h2 className="header-bg">Path of the Archon</h2>
	<p>[path description here]</p>
	<br />
	<p><b>Hit points: [HP] plus your Constitution modifier</b></p>
	<p>You increase your maximum number of HP by this number at 1st rank and every rank thereafter.</p>
	<br />
	<h2 className="header-bg">Path Features</h2>
	<br />
	<div className="columns">
		<div className="col-md left-col">
			<table>
				<thead>
					<tr>
						<th className="col-1">Your Rank</th>
						<th className="col-4">Mythic Power Points</th>
						<th>Path Features</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="center">1</td>
						<td className="center">5</td>
						<td>[Level 1 Ability], [path] feat, hard to kill, [second ability], mythic power, mythic surge +1d6, universal mythic feat</td>
					</tr>
					<tr>
						<td className="center">2</td>
						<td className="center">7</td>
						<td>[Level 2 ability], Ability boosts, recuperation, universal mythic feat</td>
					</tr>
					<tr>
						<td className="center">3</td>
						<td className="center">9</td>
						<td>[path] feat, mythic Surge +1d8, universal mythic feat</td>
					</tr>
					<tr>
						<td className="center">4</td>
						<td className="center">11</td>
						<td>Amazing speed, [level 4 ability], universal mythic feat</td>
					</tr>
					<tr>
						<td className="center">5</td>
						<td className="center">13</td>
						<td>Ability boosts, [path] feat, force of will, universal mythic feat</td>
					</tr>
					<tr>
						<td className="center">6</td>
						<td className="center">15</td>
						<td>Mythic surge +1d10, universal mythic feat</td>
					</tr>
					<tr>
						<td className="center">7</td>
						<td className="center">17</td>
						<td>[path] feat, universal mythic feat, unstoppable</td>
					</tr>
					<tr>
						<td className="center">8</td>
						<td className="center">19</td>
						<td>Ability boosts, universal mythic feat</td>
					</tr>
					<tr>
						<td className="center">9</td>
						<td className="center">21</td>
						<td>[path] feat, immortal, mythic surge +1d12, universal mythic feat</td>
					</tr>
					<tr>
						<td className="center">10</td>
						<td className="center">23</td>
						<td>Ability boosts, [capstone ability], legendary hero, universal mythic feat</td>
					</tr>
				</tbody>
			</table>
			<br />
			<h3 className="sub-header-bg">[Level 1 ability]</h3>
			<p></p>
			<br />
			<h3 className="sub-header-bg">Hard to Kill</h3>
			<p>You become particularly hard to kill. When your hit points reach 0, you fall unconscious, but do not
				immediately gain the dying condition. If you take damage while unconscious, you gain the dying condition
				as normal. In addition, you do not die until your condition reaches dying 6</p>
			<br />
			<h3 className="sub-header-bg">[Level 1 second ability]</h3>
			<p></p>
			<br />
			<h3 className="sub-header-bg">Mythic Power</h3>
			<p>A mythic character has a wellspring of power that they can draw upon to accomplish amazing deeds and cheat
				fate. You gain a pool of 5 Mythic Power Points, which can be used to activate mythic spells in the same
				way Focus Points are used to activate focus spells. This pool increases by 2 points at each mythic rank
				beyond 1st. You replenish all the Mythic Power Points in your pool during your daily preparations.</p>
			<br />
			<h3 className="sub-header-bg">Mythic Surge</h3>
			<p>You can call upon your mythic power to overcome difficult challenges. You gain
				the <Link to="/mythic/spells/mythicsurge">mythic surge</Link> mythic spell.</p>
			<br />

			<h3 className="sub-header-bg">Universal Mythic Feats</h3>
			<p>Your mythic power manifests in a variety of ways. Each time you gain a mythic rank, you gain
				a <Link to="/mythic/feats/universal">universal mythic feat</Link>.</p>
			<br />
		</div>
		<div className="col-md right-col">
			<h3 className="sub-header-bg">[Level 2 Ability]</h3>
			<p></p>
			<br />
			<h3 className="sub-header-bg">Recuperation</h3>
			<p>At 2nd rank, when you get a full-night's rest, you regain all of your Hit Points, and if a condition, such
				as doomed, would decrease in value, it decreases by an additional 1. In addition, by expending 1 Mythic
				Power Point and resting for 1 hour, you regain all of your Hit Points and refresh your spells, Focus
				Points and other abilities as if you had just finished your daily preparations for the first time. You
				do not regain Mythic Power Points or uses of any mythic abilities that are limited to a number of times
				per day.</p>
			<br />
			<h3 className="sub-header-bg">[Path] Feats</h3>
			<p>At 1st rank and every odd rank, you gain an <Link to="/mythic/feats/angel">angel path feat</Link>.</p>
			<br />
			<h3 className="sub-header-bg">Amazing Speed</h3>
			<p>At 4th rank, you can move and act supernatually fast. You gain 1 additional action at the start of your
				turn each round. This action stacks with any gained from the quickened condition.</p>
			<br />
			<h3 className="sub-header-bg">[Level 4 Ability]</h3>
			<p></p>
			<br />
			<h3 className="sub-header-bg">Force of Will</h3>
			<p>At 5th rank, you can exert your will to force events to unfold as you would like. You gain
				the <Link to="/mythic/spells/forceofwill">force of will</Link> Mythic Spell.</p>
			<br />
			<h3 className="sub-header-bg">Unstoppable</h3>
			<p>At 8th rank, what can stop mere mortals in their tracks is only a mild inconvenience to you. You gain
				the <Link to="/mythic/spells/unstoppableresolve">unstoppable resolve</Link> Mythic Spell.</p>
			<br />
			<h3 className="sub-header-bg">Immortal</h3>
			<p>At 9th rank, if you die, you return to life 24 hours later, regardless of the condition of your body,
				unless you are killed by a mythic creature or effect, or by an artifact. When you return to life, you
				have full Hit Points and the same spells prepared and points in your pools as you had when you died. At
				10th rank, you can be permanently killed only by a diety, or an artifact of at least 20th level.</p>
			<br />
			<h3 className="sub-header-bg">[Capstone ability]</h3>
			<p></p>
			<br />
			<h3 className="sub-header-bg">Legendary Hero</h3>
			<p>At 10th rank, you have reached the height of mythic power. You can spend Focus Points to activate mythic
				spells and mythic abilities instead of Mythic Power Points. If you don't already have one, you gain a
				focus pool of 1 Focus Point.</p>
		</div>
	</div>
		</Route>
		<Route path="/mythic/paths/archon/feats" component={ArchonMythicFeats}/>
	</Switch>
	
</div>