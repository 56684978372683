import React from 'react';

export const FeatPerfectSelf = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Perfect Self
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Angel</div>
			<div className="trait">Archon</div>
			<div className="trait">Azata</div>
			<div className="trait">Enlightenment</div>
			<div className="trait">Stars</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You use your mythic power to sculpt your body into an ideal version of yourself.</p>
		<p className="no-indent">You cease aging. In addition, you gain immunity to poisons, diseases, blindness, deafness, petrification and poison damage from non-mythic sources. You gain a +2 mythic bonus on saving throws against poisons, diseases, blindness, deafness and petrification from mythic sources and resistance to poison damage from mythic sources equal to your mythic rank.</p>
	</div>
	<div className="spacer" />
</div>