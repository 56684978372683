import React from 'react';
import { Link } from 'react-router-dom'
export const SW2eArchTableSoldier = () =>
<div>
	<h2 className="header-bg">Fundamental Soldier Feats</h2>
	<p className="all-indent">Each time you gain a level, you can select one or more of the following feats. You must satisfy any prerequisites before taking the feat. Fundamental feats provide essential abilities and improve your proficiencies. They must often be taken to progress in an archetype.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/armorfocus">Armor Focus</Link></td>
				<td>1</td>
				<td>Archetype, Soldier</td>
				<td>Trained in powered armor, expert in Fortitude saves, <Link to="/sw2e/feats/improvedtoughness">Improved Toughness</Link>, trained in Athletics or Acrobatics</td>
				<td>Your armor proficiency with one type of armor increases by one step.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link></td>
				<td>1</td>
				<td>Archetype, Soldier, Specialization</td>
				<td>Trained in heavy weapons, expert in Fortitude saves, <Link to="/sw2e/feats/improvedtoughness">Improved Toughness</Link>, trained in Athletics or Acrobatics</td>
				<td>Your weapon proficiency with one group of weapons increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/improvedweaponfocus">Improved Weapon Focus</Link></td>
				<td>5</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link>, Weapon Expertise (any), at least 3 total Soldier feats</td>
				<td>Your weapon proficiency with two groups of weapons increases to master.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/ironwill">Iron Will</Link></td>
				<td>5</td>
				<td>Archetype, Scoundrel, Scout, Soldier</td>
				<td>—</td>
				<td>Your Will save proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/lightningreflexes">Lightning Reflexes</Link></td>
				<td>5</td>
				<td>Archetype, Noble, Scoundrel, Scout, Soldier, Tech Specialist</td>
				<td>—</td>
				<td>Your Reflex save proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/soldierweaponexpertise">Weapon Expertise</Link></td>
				<td>5</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link>, at least 4 total Soldier feats</td>
				<td>Your weapon proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/weaponspecialization">Weapon Specialization</Link></td>
				<td>7</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/weaponexpertise">Weapon Expertise</Link>, at least 5 total Soldier feats</td>
				<td>You deal more damage with weapons.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/juggernaut">Juggernaut</Link></td>
				<td>9</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/weaponspecialization">Weapon Specialization</Link>, at least 7 total Soldier feats</td>
				<td>Your proficiency with Fortitude saves increases to master.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/soldierarmorexpertise">Armor Expertise</Link></td>
				<td>11</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/juggernaut">Juggernaut</Link>, at least 8 total Soldier feats</td>
				<td>Your armor proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/advancedweaponfocus">Advanced Weapon Focus</Link></td>
				<td>13</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/improvedweaponfocus">Improved Weapon Focus</Link>, Weapon Mastery (any), at least 11 total Soldier feats</td>
				<td>Your weapon proficiency with three groups of weapons increases to legendary.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/soldierweaponmastery">Weapon Mastery</Link></td>
				<td>13</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/armorexpertise">Armor Expertise</Link>, at least 11 total Soldier feats</td>
				<td>Your weapon proficiency increases to expert.</td>
			</tr>
		</tbody>
	</table>
	<br />
	<h2 className="header-bg">Soldier Feats</h2>
	<p className="all-indent">Each time you gain a level, you can also select one or more of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/attackofopportunity">Attack of Opportunity</Link></td>
				<td>1</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></td>
				<td>You can quickly attack foes that leave an opening in their defenses.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/doublestrike">Double Strike</Link></td>
				<td>1</td>
				<td>Archetype, Scoundrel, Soldier</td>
				<td><Link to="/sw2e/feats/fasthands">Fast Hands</Link> or <Link to="/sw2e/feats/mastermind">Mastermind</Link> or <Link to="/sw2e/feats/roughmethods">Rough Methods</Link> or <Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></td>
				<td>You strike quickly with two weapons.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/suppressivefire">Suppressive Fire</Link></td>
				<td>1</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></td>
				<td>You know how to cover an area in suppressive fire.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/subduingstrikes">Subduing Strikes</Link></td>
				<td>2</td>
				<td>Archetype, Soldier</td>
				<td><Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></td>
				<td>Your proficiency with Fortitude saves increases to master.</td>
			</tr>
		</tbody>
	</table>
</div>
