import React from 'react';
import { Switch, Route } from 'react-router';
import { MythicHeroes } from './mythic/heroes';
import { MythicPaths } from './mythic/paths';
import { MythicFeats } from './mythic/feats';
import { GestaltHome } from './gestalt/home';

export const Gestalt = () =>
<div className="content">
	<h1>Gestalt</h1>
	<div className="columns">
		<Switch>
			<Route exact path="/gestalt" component={GestaltHome}/>
			<Route path="/gestalt/backgrounds" component={MythicHeroes}/>
			<Route path="/gestalt/running" component={MythicPaths}/>
			<Route path="/gestalt/tips" component={MythicFeats}/>
		</Switch>
	</div>
</div>