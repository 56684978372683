import React from 'react';
import { Link, Route, Switch } from 'react-router-dom'
import { PathFeaturesTable, PathTitleDescrip } from '../../extra/pathtemplates';
import { AbilityBoosts, AbilityHeader, AmazingSpeed, ForceOfWill, HardToKill, Immortal, LegendaryHero, MythicPower, MythicSurge, Recuperation, UniversalMythicFeats, Unstoppable } from '../../extra/sharedabilities';
import { FeyMythicFeats } from './feats/fey';
import { FeyForms } from './forms/fey';
import { FeyPathRoute } from './routes/fey';

const feyFeatures: { [index: number]: string[] } = {
	1: ["fey form", "fey magic", "fey senses"],
    2: ["fey feat", "fey resistance (resistance & weakness 5)"],
    3: ["fey feat"],
    4: ["fey feat"],
    5: ["fey feat", "fey resistance (resistance & weakness 10)"],
    6: ["fey feat"],
    7: ["fey feat"],
    8: ["fey feat", "fey resistance (resistance & weakness 15)"],
    9: ["fey feat"],
    10: ["fey feat", "fey resistance (resistance & weakness 20)"],
}

const description: string = `Mysterious, curious, whimsical, beautiful and terrifying, the fey have been the focus of
	legends among mortal races since before the world properly began. Those who follow the path of the fey, whether they
	walk, fly or swim, seek to emulate them as they develop their power.`

export const PathoftheFey = () =>
<div>
	<FeyPathRoute />
	<Switch>
		<Route exact path="/mythic/paths/fey">
	<PathTitleDescrip name="Path of the Fey" description={description} hp={6} />
	<h2 className="header-bg">Path Features</h2>
	<br />
	<div className="columns">
		<div className="col-md left-col">
			<PathFeaturesTable pathFeatures={feyFeatures}/>
			<br />

			<AbilityHeader name="Fey Form" level="Rank 1" />
			<p>Upon taking the Path of the Fey, you choose a specific form of fey to emulate. Your choice of form grants
				you innate spellcasting, enhanced senses and movement, and determines your resistances and the
				availability of some fey path feats.</p>
			<br />

			<AbilityHeader name="Fey Magic" level="Rank 1" />
			<p>Your mythic power expresses itself as a variety of innate spells. You gain a number of innate spells
				based on your fey form, and you gain the <Link to="/mythic/feats/innatemagic">innate magic</Link> mythic
				feat. Your spellcasting tradition for <i>innate magic</i> feat is determined by your form.</p>
			<br />

			<AbilityHeader name="Fey Senses" level="Rank 1" />
			<p>You develop enhanced, or even additional senses, based on the form you chose.
				See <Link to="/mythic/paths/fey/forms">fey forms</Link> for more information</p>
			<br />

			<HardToKill />
			<br />

			<MythicPower />
			<br />

			<MythicSurge />
			<br />

			<UniversalMythicFeats />
			<br />
		</div>
		<div className="col-md right-col">
			<AbilityHeader name="Fey Feats" level="Rank 2" />
			<p>Fey abilities are as varied and strange as the countless corners of the universe they inhabit. At 2nd
				rank and every rank thereafter, you gain a <Link to="/mythic/feats/fey">fey path feat</Link>.</p>
			<br />
			
			<AbilityHeader name="Fey Resistance" level="Rank 2" />
			<p>Your <Link to="/mythic/paths/fey/forms">form</Link> grants you special resistances to the elements, or
				other defenses. You gain resistance 5 to the listed damage types, as well as weakness 5 to cold iron. At
				5th, 8th and 10th rank, these weaknesses and resistances increase by an additional 5.</p>
			<br />

			<Recuperation />
			<br />

			<AbilityBoosts />
			<br />

			<AmazingSpeed />
			<br />

			<ForceOfWill />
			<br />

			<Unstoppable />
			<br />

			<Immortal />
			<br />

			<LegendaryHero />
		</div>
	</div>
		</Route>
		<Route path="/mythic/paths/fey/feats" component={FeyMythicFeats}/>
		<Route path="/mythic/paths/fey/forms" component={FeyForms}/>
	</Switch>
</div>