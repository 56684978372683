import React from 'react';

export const FeatRelentless = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Relentless
			<div className="spacer" />
			<span className="right">Feat Mythic 10</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You are ever-moving and indefatiguable. You are immune to fatigue and are permanently quickened. You can use the extra action to Escape, Force Open, Grapple a creature which you already have grabbed or restrained, Sustain a Spell, or as one of the actions necessary to either use an action or Cast a Spell that requires two or more actions and has the <a href="https://2e.aonprd.com/Traits.aspx?ID=15">attack</a> trait.</p>
	</div>
	<div className="spacer" />
</div>