import React from 'react';
import { Link } from 'react-router-dom'

export const FeatFavoredTerrain = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Favored Terrain
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scout</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/improvedskilltraining">Improved Skill Training</Link>, <Link to="/sw2e/feats/toughness">Toughness</Link>, trained in Survival</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You have studied a specific terrain to overcome its challenges. Choose a terrain from the table below as your favored terrain. When in that terrain, you can ignore the effects of difficult terrain and gain a +4-meter circumstance bonus to your travel Speed. When other creatures <a href="https://2e.aonprd.com/Actions.aspx?ID=514">Follow the Expert</a> with you as a guide in your favored terrain, they gain both of these benefits as well.</p><br />
	<p className="all-indent no-indent justify">You can spend a week of downtime to change your favored terrain to another from the table below.</p><br />
	<p className="all-indent no-indent justify">Additionally, you can choose Dexterity or Wisdom as your key ability for determining your ability DC. Once this choice is made, it cannot be changed.</p><br />
	<br />
<table>
  <tr><td>Cold (ice, glaciers, snow, tundra, hills & mountains)</td></tr>
  <tr><td>Hot (deserts, wastelands, and volcanic areas)</td></tr>
  <tr><td>Lush (forests, jungles, plains and swamps)</td></tr>
  <tr><td>Aquatic (above and below the surface of water, swamps & bogs)</td></tr>
  <tr><td>Urban & Underground (buildings, streets, ships & stations, sewers, and caves)</td></tr>
</table>
<br />
	<h3 className="headerbar sub-header-bg shadow-sm">Favored Terrain Leads To...</h3>
	<p className="all-indent no-indent"><Link to="/sw2e/feats/skirmish">Skirmish</Link>, <Link to="/sw2e/feats/terrainstride">Terrain Stride</Link>, <Link to="/sw2e/feats/terrainmaster">Terrain Master</Link></p>
</div>