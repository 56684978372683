import React from 'react';
import { FeatInfoInnateMagic } from './extrainfo/innatemagic';
export const FeatInnateMagic = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Innate Magic
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Azata</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Elemental</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Choose two cantrips and one 1st-level spell from the magical tradition determined by your mythic path, as shown on Table 2-4. If your path allows multiple spellcasting traditions, choose one that your innate magic will come from. You can cast these spells as innate spells of the chosen type. You gain additional spells as your mythic rank increases. Your chosen cantrips can be cast at will, and your other spells can be cast once per day. These spells are automatically heightened  to your mythic rank.</p>
		<p className="no-indent">At 2nd mythic rank, you gain a spell of 2nd-level or lower. At 3rd rank, you gain a spell of 3rd-level or lower.</p>
		<p className="no-indent">You become trained in spell attack rolls and spell DCs for the chosen tradition.</p>
        <br />
        <FeatInfoInnateMagic />

	</div>
	<div className="spacer" />
</div>