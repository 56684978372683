import React from 'react';

export const FeatMetamixing = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Metamixing&nbsp;<div className="action" />
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Fey</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> Any two actions with the metamagic trait</li>
		</ul>
		<hr />
		<p className="no-indent">You can combine two metamagics, applying both of their effects to the same spell. Choose two actions that you can use with the metamagic trait. If your next action is to Cast a Spell, it gains the benefits of both metamagics. You do not need to spend any actions to activate these metamagics, but must still pay any additional costs, such as Focus Points or Mythic Power Points.</p>
	</div>
	<div className="spacer" />
</div>