import React from 'react';

export const SpellRestoration = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Restoration&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=258"> </a>
			<div className="spacer" />
			<span className="right">Spell 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Healing</div>
			<div className="trait">Necromancy</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> divine, occult, primal</li>
			<li><b>Cast</b> 1 minutesomatic, verbal</li>
			<li><b>Range</b> touch; <b>Targets:</b> 1 creature</li>
		</ul>
		<hr />
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b></span> If another effect would prevent restoration from reducing a condition, its effects are instead suppressed for 1 day. During that time, the target isn't affected by the chosen conditions, unless another effect would impose them again.</p>
	</div>
	<div className="spacer" />
</div>