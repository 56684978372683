import React from 'react';
import { Link } from 'react-router-dom'

export const FeatMastermind = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Mastermind
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scoundrel</div>
		<div className="trait">Specialization</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Will saves, trained in <Link to="sw2e/skills/society">Society</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Where others might use sleight of hand or a silver tongue to achieve their objectives, you rely on your intellect to craft intricate schemes.</p><br />
	<p className="all-indent no-indent justify">If you successfully identify a creature using Recall Knowledge, that creature is flat-footed against your attacks until the start of your next turn; if you critically succeed, it's flat-footed against your attacks for 1 minute.</p><br />
	<br />
	<div className="info indent">
		<h3>Key Terms</h3>
		<br />
		<p className="no-indent justify"><b>Specialization:</b> A character may not select more than one specialization feat from a given archetype. For example, a Scoundrel who selects Fast Hands may not also select Mastermind. They could still select Weapon Focus from the Soldier archetype, however.</p>
	</div><br />
</div>