import React from 'react';
import action from '../../../../resources/1-action.png';

export const PowerOvercharge = () =>
<div className="text-container">
	<div className="columns">
		<div className="column-full">
			<h2 className="headerbar header-bg shadow-sm">Overcharge
				<div className="spacer" />
				<span className="right">Power 1</span>
			</h2>
			<div className="traits">
				<div className="trait">Tech Specialist</div>
				<div className="trait">Manipulate</div>
			</div>
			<ul className="proplist">
				<li><b>Tradition</b> focus</li>
				<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /></li>
				<li><b>Duration</b> 1 minute</li>
			</ul>
			<hr />
			<p className="no-indent all-indent">You overcharge the power cell on your weapon, causing it to deal more damage for a short time. Choose a weapon that does not have the low&#8209;tech trait. On your first weapon Strike with that weapon each round, you gain a status bonus to damage equal to twice the number of weapon damage dice.</p>
		</div>
	</div>
</div>