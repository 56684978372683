import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { TraitMythic } from './traits/mythic';
import { TraitRadiance } from './traits/radiance';
import { TraitRush } from './traits/rush';

export const RulesTraits = () =>
<div>
	<Switch>
		<Route path="/rules/traits/mythic" component={TraitMythic}/>
		<Route path="/rules/traits/radiance" component={TraitRadiance}/>
		<Route path="/rules/traits/rush" component={TraitRush}/>
	</Switch>
</div>
