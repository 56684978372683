import React from 'react';

export const SW2eEquipmentArmor = () =>
<div>
	<h2 className="header-bg">Armor</h2>

	<table>
		<tr>
			<th>Name</th>
			<th>Category</th>
			<th>Level</th>
			<th>Price</th>
			<th>AC Bonus</th>
			<th>Dex Cap</th>
			<th>Check Penalty</th>
			<th>Speed Penalty</th>
			<th>Strength</th>
			<th>Bulk</th>
			<th>Armor Traits</th>
		</tr>
		<tr>
			<td>Unarmored</td>
			<td>Unarmored</td>
			<td>—</td>
			<td>—</td>
			<td>+0</td>
			<td>—</td>
			<td>—</td>
			<td>—</td>
			<td>—</td>
			<td>—</td>
			<td>—</td>
		</tr>
		<tr>
			<td>Durable Clothing</td>
			<td>Unarmored</td>
			<td>—</td>
			<td>10 <div className="credits" /></td>
			<td>+0</td>
			<td>+5</td>
			<td>—</td>
			<td>—</td>
			<td>—</td>
			<td>L</td>
			<td>Comfort</td>
		</tr>
	</table>
</div>