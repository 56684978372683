import React from 'react';

export const FeatChangeShape = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Change Shape
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Concentrate</div>
			<div className="trait">Mythic</div>
			<div className="trait">Polymorph</div>
			<div className="trait">Transmutation</div>
			<div className="trait">Archon</div>
			<div className="trait">Angel</div>
			<div className="trait">Azata</div>
			<div className="trait">Demon</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Fey</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You can transform easily into different humanoid forms. You gain the <a href="https://2e.aonprd.com/MonsterAbilities.aspx?ID=8">change shape</a> monster ability, allowing you to take on the appearance of any Small or Medium humanoid. This doesn't change your Speed or the attack and damage modifiers with your Strikes, but it might change the damage type your unarmed Strikes deal (typically to bludgeoning).</p>
	</div>
	<div className="spacer" />
</div>