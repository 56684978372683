import React from 'react';
import { Link } from 'react-router-dom'
export const StarsMythicFeats = () =>
<div>
	<h2 className="header-bg">Stars Mythic Feats</h2>
	<p className="no-indent">Every mythic rank at which you gain a Stars feat, select one of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm full-width">
		<thead>
			<tr>
				<th className="col-3">Name</th>
				<th className="col-1">Rank</th>
				<th className="col-3">Traits</th>
				<th className="col-3">Prerequisites</th>
				<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/piercethedarkness">Pierce the Darkness</Link></td>
				<td className="center">1</td>
				<td>Mythic</td>
				<td>—</td>
				<td>You gain greater darkvision.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/abundantmagic">Abundant Magic</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td>You gain a wider variety of innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mysticsight">Mystic Sight</Link></td>
				<td className="center">2</td>
				<td>Detection, Divination, Mythic</td>
				<td>—</td>
				<td>You can see magic.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicmetamagic">Mythic Metamagic</Link></td>
				<td className="center">2</td>
				<td>Mythic, Metamagic</td>
				<td>Ability to cast spells</td>
				<td>You manipulate your spells in powerful and unexpected ways.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/perfectself">Perfect Self</Link></td>
				<td className="center">2</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Time and disease have no hold over you and your body will not fail you.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link></td>
				<td>You gain more innate spellcasting abilities.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/flawlessstrike">Flawless Strike</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>—</td>
				<td>When you concentrate your power, you strike without error.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/imbuedarms">Imbued Arms</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Imbue your weapons with elemental or alignment damage.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/metamixing">Metamixing</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>Any two actions with the metamagic trait</td>
				<td>You can apply two metamagics to a spell at once.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/mythicflight">Mythic Flight</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>[Elemental] air element; [Fey] airborne form</td>
				<td>You can sprout wings and fly.</td>
			</tr>
			<tr>
				<td className="new-item"><Link to="/mythic/feats/spirittouch">Spirit Touch</Link></td>
				<td className="center">4</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Gain the ability to attack the spirit and harm incorporeal creatures.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/direspellcraft">Dire Spellcraft</Link></td>
				<td className="center">5</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/ascendspell">Ascend Spell</Link></td>
				<td>You can infuse your spells with even more mythic power.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/persistentmagic">Persistent Magic</Link></td>
				<td className="center">5</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/mythicmetamagic">Mythic Metamagic</Link>, <Link to="/mythic/spells/mythicextendspell">Mythic Extend Spell</Link></td>
				<td>You can anchor a spell with mythic power to make it last indefinitely.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/impossibleprecision">Impossible Precision</Link></td>
				<td className="center">6</td>
				<td>Mythic</td>
				<td>—</td>
				<td>Your follow up attacks are just as deadly as the first.</td>
			</tr>
			<tr>
				<td><Link to="/mythic/feats/greaterinnatemagic">Greater Innate Magic</Link></td>
				<td className="center">7</td>
				<td>Mythic</td>
				<td><Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></td>
				<td>You gain even more innate spellcasting abilities.</td>
			</tr>
		</tbody>
	</table>
</div>
