import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellAscendSpell = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Ascend Spell
			<div className="spacer" />
			<span className="right">Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Concentrate" /></div>
			<div className="trait"><Trait name="Metamagic" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> verbal</li>
		</ul>
		<hr />
		<p className="no-indent">If the next action you use is to Cast a Spell, choose one of the following benefits: <ul><li>That spell gains its mythic heightening benefit (if any).</li> <li>The spell is heightened two levels higher. It does not consume a higher level spell slot.</li></ul></p>
	</div>
	<div className="spacer" />
</div>