import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellSwordofRetribution = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Sword of Retribution
			<div className="spacer" />
			<span className="right">Mythic Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Cantrip" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> somatic</li>
			<li><b>Area:</b> 30-foot emanation centered on you</li>
			<li><b>Duration:</b> 1 round</li>
		</ul>
		<hr />
		<p className="no-indent">An aura of retribution surrounds you, protecting you and your allies. Enemies that damage you or an ally within the area are slowed 1 for 1 round. This effect occurs even if the damage is prevented by resistances, a Champion's Reaction, or a similar ability. Enemies who are slowed by this spell recieve a -2 status penalty to AC and saves while within the spell's area.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (5th)</b> You can cast this spell as a free action. It retains the verbal component.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>