import React from 'react';

export const FeatMysticSight = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mystic Sight
			<div className="spacer" />
			<span className="right">Feat Mythic 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Detection</div>
			<div className="trait">Divination</div>
			<div className="trait">Mythic</div>
			<div className="trait">Angel</div>
			<div className="trait">Enlightenment</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You can see magic clearly and understand it immediately. You gain a precise sense with a range of 60 feet that allows you to detect and identify magical effects. You sense magical effects within range that you can see as if you had detected them using a <i><a href="https://2e.aonprd.com/Spells.aspx?ID=66">detect magic</a></i> spell of a level one higher than your mythic rank. This is automatic and does not require an action, but the effect must be within your field of vision. You can turn this sense off and on with a free action at the start or the end of your turn.</p>
	</div>
	<div className="spacer" />
</div>