import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellMagicSurge = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Magic Surge
			<div className="spacer" />
			<span className="right">Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Concentrate" /></div>
			<div className="trait"><Trait name="Manipulate" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
		</ul>
		<hr />
		<p className="no-indent">Choose one of your innate spells, focus spells, or a spell from the same tradition as your spell repertoire with a casting time of 3 actions or less that has a spell level equal to or less than your mythic rank. You do not need to have the spell prepared, and it does not need to be a spell that is in your repertoire. You cast that spell at a spell level equal to your mythic rank without expending a daily use, a Focus Point or a prepared spell or spell slot. If a spell requires a variable number of actions to cast, you may choose to treat the spell as if you had cast it with any number of actions, up to 3.</p>
		<p className="no-indent">Magic Surge counts as the Cast a Spell activity for the purpose of actions with the Metamagic trait.</p>
	</div>
	<div className="spacer" />
</div>