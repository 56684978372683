import React from 'react';
import { HashRouter as Router, Route, Link, Switch } from 'react-router-dom'
import logo from './resources/logo.png';
import './App.css';
import { Home } from './content/home';
import { Mythic2e } from './content/mythic';
import { Gestalt } from './content/gestalt';
import { ContentTweaks } from './content/contenttweaks';
import { SW2e } from './content/sw2e';
import { Rules } from './content/rules';

const App: React.FC = () => {
	return (
		<div className="App">
			<Router>
				<input type="checkbox" id="sidebar-toggle" className="sidebar-toggle fas fa-angle-left" />
				<div className="sidebar">
					<div className="logo">
						<img src={logo} alt="logo" />
					</div>
					<nav>
						<h3>Sprocket Fox System Reference Document</h3>
						<ul>
							<li><Link to="/">Main Page</Link></li>
							<li><Link to="/">Contents</Link></li>
							<li><Link to="/">Help</Link></li>
						</ul>
						<br/>
						<h3 className="sb-header"><Link to="/contenttweaks/">Content Tweaks</Link></h3>
						<ul>
							<li><Link to="/contenttweaks/spelltweaks">Spell Tweaks</Link></li>
							<li><Link to="/contenttweaks/feattweaks">Feat Tweaks</Link></li>
							<li><Link to="/rules">Rule Tweaks</Link></li>
							<li><Link to="/contenttweaks/itemtweaks">Item Tweaks</Link></li>
						</ul>
						<br/>
						<h3 className="sb-header"><Link to="/mythic">Mythic 2e</Link></h3>
						<ul>
							<li><Link to="/mythic/heroes">Mythic Heroes</Link></li>
							<li><Link to="/rules">Mythic Rules</Link></li>
							<li><Link to="/mythic/spells">Mythic Spells</Link></li>
							<li><Link to="/mythic/paths">Mythic Paths</Link></li>
							<li><Link to="/mythic/feats">Mythic Feats</Link></li>
							<li><Link to="/contenttweaks/spelltweaks">Mythic Heightening</Link></li>
						</ul>
						<br/>
						<h3 className="sb-header"><Link to="/gestalt">Gestalt</Link></h3>
						<ul>
							<li><Link to="/gestalt">Creating a Gestalt</Link></li>
						</ul>
						<br/>
						<h3 className="sb-header sw-header size-1"><Link to="/sw2e">Star Wars 2e OGL</Link></h3>
						<ul className="sw-body">
							<li><Link to="/sw2e/rules">Rules</Link></li>
							<li><Link to="/sw2e/species">Species</Link></li>
							<li><Link to="/sw2e/backgrounds">Backgrounds</Link></li>
							<li><Link to="/sw2e/archetypes">Archetypes</Link></li>
							<li><Link to="/sw2e/heroes">Heroes</Link></li>
							<li><Link to="/sw2e/feats">Feats</Link></li>
							<li><Link to="/sw2e/skills">Skills</Link></li>
							<li><Link to="/sw2e/equipment">Equipment</Link></li>
							<li><Link to="/sw2e/powers">Powers</Link></li>
							<li><Link to="/sw2e/creatures">Creatures</Link></li>
						</ul>
					</nav>
				</div>
				<div className="main">
					<div className="title-bar">Sprocket Fox 2e SRD</div>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route path="/rules" component={Rules} />
						<Route path="/mythic" component={Mythic2e} />
						<Route path="/gestalt" component={Gestalt} />
						<Route path="/contenttweaks" component={ContentTweaks} />
						<Route path="/sw2e" component={SW2e} />
					</Switch>
				</div>
			</Router>
		</div>
	);
}

export default App;
