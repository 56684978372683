import React from 'react';
import action from '../../../resources/1-action.png';
import threeaction from '../../../resources/3-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellShadowDouble = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Shadow Double
			<div className="spacer" />
			<span className="right">Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Illusion" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Shadow" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 to 3 Actions" src={action} /> to <img className="small-icon" alt="1 to 3 Actions" src={threeaction} /> </li>
			<li><b>Range:</b> 60 feet</li>
			<li><b>Duration:</b> sustained</li>
		</ul>
		<hr />
		<p className="no-indent">For each action spent to Cast the Spell, you creates one shadowy duplicate of yourself anywhere within range. Shadow doubles have the same statistics and equipment as you, but have the summoned trait, have 40 Hit Points, can't cast spells, activate items or use mythic power, and have a -4 penalty on attack rolls. A shadow double that attempts a saving throw against a light effect can't get a result better than failure. If a double is reduced to 0 hitpoints or moves more than 120 feet from you, it and any equipment it was created with are destroyed. Each round when you Sustain the Spell, you can issue commands to all of your shadow doubles with the same action. You can see through the eyes of all of the shadow doubles at once. A character who Seeks can identify the real you from among the shadow doubles with a successful Perception check against your deception DC.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+1)</b> The Hit Points of each double increase by 10.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>