import React from 'react';

export const FeatLightningReflexes = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Lightning Reflexes
		<div className="spacer" />
		<span className="right">Feat 5</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Noble</div>
		<div className="trait">Scoundrel</div>
		<div className="trait">Scout</div>
		<div className="trait">Soldier</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<hr />
	<p className="all-indent no-indent justify">Your reflexes are lightning fast. Your proficiency rank for Reflex saves increases to expert.</p><br />
</div>