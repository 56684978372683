import React from 'react';
import reaction from '../../../resources/reaction.png';
import { Trait } from '../../extra/linkables';

export const SpellForceofWill = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Force of Will
			<div className="spacer" />
			<span className="right">Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="Reaction" src={reaction} /> </li>
			<li><b>Trigger:</b> the target makes an attack roll, skill or perception check, saving throw or flat check that is not an initiative roll</li>
			<li><b>Range:</b> 30 feet; <b>Targets:</b> 1 creature</li>
		</ul>
		<hr />
		<p className="no-indent">If the creature is an ally, you and any allies within range who are rolling for the same effect can roll again and use the better result. If <i>force of will</i> is used on an ally it gains the <a href="https://2e.aonprd.com/Traits.aspx?ID=76">fortune</a> trait.</p>
		<p className="no-indent">If the creature is an enemy, all enemies within range who are rolling for the same effect and whose mythic rank is no more than 1 higher than the spell level of <i>force of will</i> must reroll that roll and use the worse result. If <i>force of will</i> is used on an enemy, it gains the <a href="https://2e.aonprd.com/Traits.aspx?ID=110">misfortune</a> trait.</p>
		<p className="no-indent">If this spell is cast after the outcome of a roll is revealed, it can change the outcome of the roll.</p>
	</div>
	<div className="spacer" />
</div>