import React from 'react';
import { Switch, Route } from 'react-router';
import { RulesHome } from './rules/home';
import { RulesMythic } from './rules/mythic';
import { RulesTraits } from './rules/traits';

export const Rules = () =>
<div className="content">
    <h1>Rules, Index & Glossary</h1>
	<div className="columns">
		<div className="spacer" />
		<div className="col-lg">
			<Switch>
				<Route exact path="/rules" component={RulesHome}/>
				<Route path="/rules/traits" component={RulesTraits}/>
				<Route path="/rules/mythic" component={RulesMythic}/>
			</Switch>
		</div>
		<div className="spacer" />
	</div>
</div>