import React from 'react';
import { Link } from 'react-router-dom'

export const FeatImpossiblePrecision = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Impossible Precision
			<div className="spacer" />
			<span className="right">Feat Mythic 6</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Angel</div>
			<div className="trait">Archon</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Enlightenment</div>
			<div className="trait">Stars</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You strike with relentless and unparalleled precision. Your first attack each turn does not count when determining your multiple attack penalty. If you are mythic rank 10, your first two attacks each turn do not count when determining your multiple attack penalty.</p>
		<p className="no-indent">You gain the <i><Link to="/mythic/spells/suddenstrike">sudden strike</Link></i> mythic spell, which you can cast for 1 Mythic Power Point.</p>
	</div>
	<div className="spacer" />
</div>