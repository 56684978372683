import React from 'react';
import { Route } from 'react-router-dom';
import { SW2eArchTableSoldier } from './tables/soldiertable';

export const SW2eArchetypeSoldier = () =>
<div className="text-container all-indent">
    <h2 className="header-bg">Soldier</h2>
    <p>.</p>
    <br />
    <Route component={SW2eArchTableSoldier} />
    <br />
</div>