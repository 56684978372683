import React from 'react';

export const FeatThrowRock = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Throw Rock
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Archon</div>
			<div className="trait">Demon</div>
			<div className="trait">Dragon</div>
			<div className="trait">Elemental</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Elemental] earth element</li>
		</ul>
		<hr />
		<p className="no-indent">You gain the <a href="https://2e.aonprd.com/MonsterAbilities.aspx?ID=38"><b>throw rock</b></a> <div className="action" /> action. Rocks are martial ranged weapons for you and deal 2d10 damage; are in the brawling group and have a range of 100 feet. At mythic rank 4, this damage increases to 3d10. At mythic rank 8, this damage increases to 4d10.</p>
		<p className="no-indent">Any object you can carry without penalty can be thrown as a rock, provided it is sufficiently hard and at least 1 bulk. The object thrown gains the benefits of any special materials it is made of, and any magical properties it possesses, excluding <i>striking</i> runes.</p>
	</div>
	<div className="spacer" />
</div>