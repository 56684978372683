import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellFlawlessStrike = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Flawless Strike
			<div className="spacer" />
			<span className="right">Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Divination" /></div>
			<div className="trait"><Trait name="Fortune" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> </li>
		</ul>
		<hr />
		<p className="no-indent">You use your power to guide your strikes. The next time you make an attack roll before the end of your turn, roll the attack three times and use the best result. The attack deals 2d8 additional precision damage, and ignores the following:</p>
		<p className="no-indent"><ul><li>Circumstance penalties to the attack roll.</li> <li>Penalties due to range.</li> <li>Any flat check required due to the target being concealed or hidden.</li> <li>Any flat check or random chance from an effect, such as mirror image, which would cause the attack to miss, or strike a different target.</li></ul></p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+2)</b> Increase the extra precision damage by 1d8.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>