import React from 'react';
import { Link } from 'react-router-dom'

export const FeatMobility = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Mobility
		<div className="spacer" />
		<span className="right">Feat 2</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scout</div>
		<div className="trait">Scoundrel</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link> or <Link to="/sw2e/feats/favoredterrain">Favored Terrain</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You move in a way that denies your enemies the opportunity to retaliate. When you take a Stride action to move half your Speed or less, that movement does not trigger reactions. You can use Mobility when Climbing, Flying, or Swimming instead of Striding if you have the corresponding movement type.</p><br />
</div>