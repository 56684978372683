import React from 'react';
import { Link } from 'react-router-dom'

export const FeatQuickRepairs = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Quick Repairs
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> At least 2 total Tech Specialist feats.</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You know how to make quick repairs to a droid or vehicle to make it function again in the field. You gain the <Link to="/sw2e/powers/quickrepair">quick repair</Link> focus power and a focus pool of 1 Focus Point.</p><br />
</div>