import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { SW2ePowersGeneral } from './powers/general';
import { SW2ePowersAll } from './powers/all';
import { SW2ePowersUniversal } from './powers/universal';
import { SW2ePowersLight } from './powers/light';
import { SW2ePowersDark } from './powers/dark';
import { SW2ePowersLimited } from './powers/limited';
import { SW2ePowersRoute } from './powers/route';

export const SW2ePowers = () =>
<div className="text-container">
	<h2 className="center">
		<Switch>
			<Route exact path="/sw2e/powers/all">All Powers</Route>
			<Route path="/"><Link to="/sw2e/powers/all">All Powers</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/powers/">General</Route>
			<Route exact path="/sw2e/powers/general">General</Route>
			<Route path="/"><Link to="/sw2e/powers/general">General</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/powers/limited">Limited</Route>
			<Route path="/"><Link to="/sw2e/powers/limited">Limited</Link></Route>
		</Switch>
	</h2>
	<h2 className="center">
		<Switch>
			<Route exact path="/sw2e/powers/universal">Universal</Route>
			<Route path="/"><Link to="/sw2e/powers/universal">Universal</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/powers/light">Light Side</Route>
			<Route path="/"><Link to="/sw2e/powers/light">Light Side</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/powers/dark">Dark Side</Route>
			<Route path="/"><Link to="/sw2e/powers/dark">Dark Side</Link></Route>
		</Switch>
	</h2>
	<Switch>
		<Route exact path="/sw2e/powers/" component={SW2ePowersGeneral}/>
		<Route exact path="/sw2e/powers/general" component={SW2ePowersGeneral}/>
		<Route exact path="/sw2e/powers/all" component={SW2ePowersAll}/>
		<Route exact path="/sw2e/powers/universal" component={SW2ePowersUniversal}/>
		<Route exact path="/sw2e/powers/light" component={SW2ePowersLight}/>
		<Route exact path="/sw2e/powers/dark" component={SW2ePowersDark}/>
		<Route exact path="/sw2e/powers/limited" component={SW2ePowersLimited}/>
		<Route path="/" component={SW2ePowersRoute}/>
	</Switch>
</div>
