import React from 'react';

export const FeatFamiliarCompanion = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Familiar-Companion
			<div className="spacer" />
			<span className="right">Feat 2</span>
		</h2>
		<div className="traits">
			<div className="trait">Druid</div>
			<div className="trait">Magus</div>
			<div className="trait">Ranger</div>
			<div className="trait">Witch</div>
			<div className="trait">Wizard</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites</b> an <a href="https://2e.aonprd.com/AnimalCompanions.aspx">animal companion</a> and a <a href="https://2e.aonprd.com/Familiars.aspx">familiar</a></li>
		</ul>
		<hr />
		<p className="no-indent">You can choose your animal companion to be your familiar. Treat your animal companion
			as a specific familiar that requires 1 ability. Your familiar-companion keeps its size, senses, movement
			and other abilities it had as an animal companion. Your familiar-companion uses its animal companion
			modifiers and DCs instead of gaining those of a familiar. It use its animal companion Hit Points and does
			not gain additional hit points from becoming a familiar, or the bonus on other skills equal to your level.</p>

		<p className="no-indent">A familiar-companion's Intelligence modifier increases by 2, and it can use abilities
			which require greater intelligence than an animal, such as Coerce or Decipher Writing, even if it does not
			have a specialization that specifically allows it.</p>
		<br />
		<p className="no-indent">Beyond those required to choose your animal companion as a familiar, you can use any
			remaining abilities to select familiar and master abilities as with a specific familiar. Certain
			familiar abilities function differently for familiar-companions, as detailed below.</p>
		<br />
		<h3 className="headerbar sub-header-bg shadow-sm">Familiar Ability Changes</h3>
		<p className="no-indent"><b><u>Burrower</u></b>: It gains a burrow Speed of 5 feet, allowing it to burrow
			through loose dirt.</p>
		<p className="no-indent"><b><u>Damage Avoidance</u></b>: A familiar-companion gains no benefit from this ability.</p>
		<p className="no-indent"><b><u>Fast Movement</u></b>: Increase each of the familiar-companion's speeds by 5 feet.</p>
		<p className="no-indent"><b><u>Independent</u></b>: A familiar-companion gains no benefit from this ability, but
			might still gain a similar benefit from a different ability such as <a href="https://2e.aonprd.com/Feats.aspx?ID=322">mature animal companion</a>.</p>
		<p className="no-indent"><b><u>Master's Form</u></b>: Your familiar-companion can change shape as a single action,
			transforming into a humanoid of your ancestry with the same age, gender, and build of its true form, though
			it always maintains a clearly unnatural remnant of its nature, such as a cat's eyes or a serpent's tongue.
			This form is always the same each time it uses this ability. This doesn't change its Speed or its attack and
			damage modifiers with its Strikes, but it might change the damage type its Strikes deal (typically to
			bludgeoning). Your familiar must have the manual dexterity and speech abilities to select this.</p>
		<p className="no-indent"><b><u>Plant Form</u></b>: Your plant familiar-companion can change shape as a single
			action, transforming into a plant of the same size as it, of a type roughly similar to the familiar's nature.
			This otherwise uses the effects of <a href="https://2e.aonprd.com/Spells.aspx?ID=342">tree shape</a>. You
			must have a familiar-companion with the plant trait, such as an arboreal sapling, to select this ability.</p>
		<p className="no-indent"><b><u>Skilled</u></b>: Chose a skill in which your familiar-companion is untrained. It
			becomes trained in the chosen skill. You can select this ability repeatedly, choosing a different skill each
			time.</p>
		<p className="no-indent"><b><u>Tough</u></b>: A familiar-companion gains no benefit from this ability.</p>
	</div>
	<div className="col-md info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
		<p></p>
	</div>
	<div className="spacer" />
</div>