import React from 'react';
import { Link } from 'react-router-dom';

export const SpellTweaksPrimal = () =>
<div>
	<h3 className="header-bg">Primal  Cantrips</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/gougingclaw"><b>Gouging Claw (evo):</b></Link> Morph your limb into a claw and attack with it.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/produceflame"><b>Produce Flame (evo):</b></Link> Kindle small flames to attack close or at range.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/rayoffrost"><b>Ray of Frost (evo):</b></Link> Damage a creature with cold.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/revitalize"><b><del>Stabilize</del>&nbsp;<span className="tweak-color">Revitalize</span> (nec):</b></Link> Revive a dying creature.</li>
	</ul>
	<h3 className="header-bg"> 1st-Level Primal Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/anthaul"><b>Ant Haul (tra):</b></Link> Target can carry more.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/burninghands"><b>Burning Hands (evo):</b></Link> A small cone of flame rushes from your hands.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/createwater"><b>Create Water (con):</b></Link> Conjure several gallons of water.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/magicstone"><b>Magic Stone (nec):</b></Link> Make ordinary stones into magical sling bullets that are especially dangerous to undead.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/pestform"><b>Pest Form (tra):</b></Link> Transform into a Tiny animal.</li>
	</ul>
	<h3 className="header-bg"> 2nd-Level Primal Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/restoration"><b>Restoration (nec):</b></Link> Counter the effects of toxins or conditions with restorative magic.</li>
	</ul>
	<h3 className="header-bg"> 3rd-Level Primal Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/fireball"><b>Fireball (evo):</b></Link> An explosion of fire in an area burns creatures.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/lightningbolt"><b>Lightning Bolt (evo):</b></Link> Lightning strikes all creatures in a line.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/nondetection"><b>Nondetection (abj):</b></Link> Protect a creature or object from detection.</li>
	</ul>
	<h3 className="header-bg"> 4th-Level Primal Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 5th-Level Primal Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/lightningstorm"><b>Lightning Storm (evo):</b></Link> Communicate telepathically with any creatures near you.</li>
	</ul>
	<h3 className="header-bg"> 6th-Level Primal Spells</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/trueseeing"><b>True Seeing (div):</b></Link> See through illusions and transmutations.</li>
	</ul>
	<h3 className="header-bg"> 7th-Level Primal Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 8th-Level Primal Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 9th-Level Primal Spells</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 10th-Level Primal Spells</h3>
	<ul className="unstyled">
	</ul>
</div>