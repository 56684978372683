import React from 'react';
import { Link } from 'react-router-dom'

export const FeatImpossibleSpeed = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Impossible Speed
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Elemental] air element, fire element or water element</li>
		</ul>
		<hr />
		<p className="no-indent">You can move at speeds that defy logic. Each of your Speeds increases by 20 feet and you gain the <i><Link to="/mythic/spells/walktheworld">walk the world</Link></i> mythic spell.</p>
	</div>
	<div className="spacer" />
</div>