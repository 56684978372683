import React from 'react';

export const FeatInfoExoticShape = () =>
<div className="col-20">
	<h3>Table: Exotic Shape Spells and Mythic Paths</h3>
	<table>
		<thead>
			<tr>
				<th className="col-1">Rank</th>
				<th className="col-6">Spell</th>
				<th className="col-15">Paths</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td className="center">2</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=10">Animal form</a></i></td>
				<td>Any</td>
			</tr>
			<tr>
				<td className="center">2</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=342">Tree shape</a></i></td>
				<td>Azata, Fey, Elemental, Enlightenment</td>
			</tr>
			<tr>
				<td className="center">3</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=163">Insect form</a></i></td>
				<td>Azata, Demon, Elemental, Fey, Undead</td>
			</tr>
			<tr>
				<td className="center">3</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=962">Ooze form</a></i></td>
				<td>Azata, Demon, Elemental, Fey, Shadow, Undead</td>
			</tr>
			<tr>
				<td className="center">4</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=4">Aerial form</a></i></td>
				<td>Any</td>
			</tr>
			<tr>
				<td className="center">4</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=72">Dinosaur form</a></i></td>
				<td>Archon, Demon, Elemental, Titan</td>
			</tr>
			<tr>
				<td className="center">5</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=861">Abberant form</a></i></td>
				<td>Fey, Demon, Elemental, Shadow, Undead</td>
			</tr>
			<tr>
				<td className="center">5</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=98">Elemental form</a></i></td>
				<td>Fey, Elemental, Enlightenment, Titan</td>
			</tr>
			<tr>
				<td className="center">5</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=223">Plant form</a></i></td>
				<td>Azata, Fey, Elemental</td>
			</tr>
			<tr>
				<td className="center">6</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=893">Demon form</a></i></td>
				<td>Demon</td>
			</tr>
			<tr>
				<td className="center">6</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=894">Devil form</a></i></td>
				<td>Devil</td>
			</tr>
			<tr>
				<td className="center">6</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=88">Dragon form</a></i></td>
				<td>Demon, Dragon, Elemental, Enlightenment, Titan</td>
			</tr>
			<tr>
				<td className="center">7</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=862">Angel form</a></i></td>
				<td>Angel, Archon, Azata</td>
			</tr>
			<tr>
				<td className="center">7</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=889">Cosmic form</a></i></td>
				<td>Elemental, Enlightenment, Titan</td>
			</tr>
			<tr>
				<td className="center">8</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=202">Monstrosity form</a></i></td>
				<td>Azata, Dragon, Elemental, Enlightenment, Fey, Titan</td>
			</tr>
			<tr>
				<td className="center">10</td>
				<td><i><a href="https://2e.aonprd.com/Spells.aspx?ID=202">Nature incarnate</a></i></td>
				<td>Azata, Elemental, Fey, Titan</td>
			</tr>
		</tbody>
	</table>
	<br />
</div>