import React from 'react';
import { Link } from 'react-router-dom'

export const FeatPeerlessInnateMagic = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Peerless Innate Magic
			<div className="spacer" />
			<span className="right">Feat Mythic 10</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Fey</div>
			<div className="trait">Rakshasa</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/innatemagic">Innate Magic</Link>, <Link to="/mythic/feats/advancedinnatemagic">Advanced Innate Magic</Link></li>
		</ul>
		<hr />
		<p className="no-indent">Choose a spell of 10th level or lower from the spell list chosen with Innate Magic. You can cast this spell as a 10th level innate spells of the chosen type once per day.</p>
		<p className="no-indent">Your proficiency ranks for spell attack rolls and spell DCs of the chosen tradition increase to legendary. Any innate spells you have gained from mythic feats or mythic path abilities with a base spell level of 9th or lower can be cast 3 times per day, unless they could already be cast more often.</p>
	</div>
	<div className="spacer" />
</div>