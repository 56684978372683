import React from 'react';

export const FeatMythicFlight = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Flight
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Angel</div>
			<div className="trait">Azata</div>
			<div className="trait">Demon</div>
			<div className="trait">Devil</div>
			<div className="trait">Dragon</div>
			<div className="trait">Elemental</div>
			<div className="trait">Fey</div>
			<div className="trait">Stars</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Elemental] air element; [Fey] airborne form</li>
		</ul>
		<hr />
		<p className="no-indent">Your mythic power manifests as an impressive set of wings or other feature relevant to your path, granting you a fly Speed equal to your Speed or 30 feet, whichever is greater. These wings can be dismissed or resummoned as a free action at the start or the end of your turn, or at any time if you would fall.</p>
	</div>
	<div className="spacer" />
</div>