import React from 'react';
import { Switch, Route } from 'react-router-dom';import { SpellAcidSplash } from './spells/acidsplash';
import { SpellAnimateDead } from './spells/animatedead';
import { SpellAntHaul } from './spells/anthaul';
import { SpellBane } from './spells/bane';
import { SpellBestowCurse } from './spells/bestowcurse';
import { SpellBless } from './spells/bless';
import { SpellBurningHands } from './spells/burninghands';
import { SpellCreateWater } from './spells/createwater';
import { SpellDimensionalHop } from './spells/dimensionalhop';
import { SpellDivineLance } from './spells/divinelance';
import { SpellFireball } from './spells/fireball';
import { SpellFloatingDisk } from './spells/floatingdisk';
import { SpellGougingClaw } from './spells/gougingclaw';
import { SpellIridescentBeam } from './spells/iridescentbeam';
import { SpellLightningBolt } from './spells/lightningbolt';
import { SpellLightningStorm } from './spells/lightningstorm';
import { SpellMagicStone } from './spells/magicstone';
import { SpellNondetection } from './spells/nondetection';
import { SpellProduceFlame } from './spells/produceflame';
import { SpellPestForm } from './spells/pestform';
import { SpellRayofFrost } from './spells/rayoffrost';
import { SpellRestoration } from './spells/restoration';
import { SpellSleep } from './spells/sleep';
import { SpellSowThought } from './spells/sowthought';
import { SpellSpellImmunity } from './spells/spellimmunity';
import { SpellRevitalize } from './spells/revitalize';
import { SpellSuggestion } from './spells/suggestion';
import { SpellTelekineticProjectile } from './spells/telekineticprojectile';
import { SpellTelepathy } from './spells/telepathy';
import { SpellTrueSeeing } from './spells/trueseeing';
import { SpellUnnaturalLust } from './spells/unnaturallust';

export const SpellTweaksRoute = () =>
<Switch>
	<Route path="/contenttweaks/spelltweaks/spells/acidsplash" component={SpellAcidSplash}/>
	<Route path="/contenttweaks/spelltweaks/spells/animatedead" component={SpellAnimateDead}/>
	<Route path="/contenttweaks/spelltweaks/spells/anthaul" component={SpellAntHaul}/>
	<Route path="/contenttweaks/spelltweaks/spells/bane" component={SpellBane}/>
	<Route path="/contenttweaks/spelltweaks/spells/bestowcurse" component={SpellBestowCurse}/>
	<Route path="/contenttweaks/spelltweaks/spells/bless" component={SpellBless}/>
	<Route path="/contenttweaks/spelltweaks/spells/burninghands" component={SpellBurningHands}/>
	<Route path="/contenttweaks/spelltweaks/spells/createwater" component={SpellCreateWater}/>
	<Route path="/contenttweaks/spelltweaks/spells/dimensionalhop" component={SpellDimensionalHop}/>
	<Route path="/contenttweaks/spelltweaks/spells/divinelance" component={SpellDivineLance}/>
	<Route path="/contenttweaks/spelltweaks/spells/fireball" component={SpellFireball}/>
	<Route path="/contenttweaks/spelltweaks/spells/floatingdisk" component={SpellFloatingDisk}/>
	<Route path="/contenttweaks/spelltweaks/spells/gougingclaw" component={SpellGougingClaw}/>
	<Route path="/contenttweaks/spelltweaks/spells/iridescentbeam" component={SpellIridescentBeam}/>
	<Route path="/contenttweaks/spelltweaks/spells/lightningbolt" component={SpellLightningBolt}/>
	<Route path="/contenttweaks/spelltweaks/spells/lightningstorm" component={SpellLightningStorm}/>
	<Route path="/contenttweaks/spelltweaks/spells/magicstone" component={SpellMagicStone}/>
	<Route path="/contenttweaks/spelltweaks/spells/nondetection" component={SpellNondetection}/>
	<Route path="/contenttweaks/spelltweaks/spells/produceflame" component={SpellProduceFlame}/>
	<Route path="/contenttweaks/spelltweaks/spells/pestform" component={SpellPestForm}/>
	<Route path="/contenttweaks/spelltweaks/spells/rayoffrost" component={SpellRayofFrost}/>
	<Route path="/contenttweaks/spelltweaks/spells/restoration" component={SpellRestoration}/>
	<Route path="/contenttweaks/spelltweaks/spells/sleep" component={SpellSleep}/>
	<Route path="/contenttweaks/spelltweaks/spells/sowthought" component={SpellSowThought}/>
	<Route path="/contenttweaks/spelltweaks/spells/spellimmunity" component={SpellSpellImmunity}/>
	<Route path="/contenttweaks/spelltweaks/spells/revitalize" component={SpellRevitalize}/>
	<Route path="/contenttweaks/spelltweaks/spells/suggestion" component={SpellSuggestion}/>
	<Route path="/contenttweaks/spelltweaks/spells/telekineticprojectile" component={SpellTelekineticProjectile}/>
	<Route path="/contenttweaks/spelltweaks/spells/telepathy" component={SpellTelepathy}/>
	<Route path="/contenttweaks/spelltweaks/spells/trueseeing" component={SpellTrueSeeing}/>
	<Route path="/contenttweaks/spelltweaks/spells/unnaturallust" component={SpellUnnaturalLust}/>
</Switch>
