import React from 'react';
import { Link } from 'react-router-dom'

export const FeatAdvancedToughness = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Advanced Toughness
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">General</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/swe2/feats/improvedtoughness">Improved Toughness</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You can withstand impressive amounts of  punishment. Increase your hitpoints per level to 12 plus your Consitution. If this feat is taken after 1st level, you gain these hitpoints retroactively. Additionally, the DC of <a href="https://2e.aonprd.com/Rules.aspx?ID=375">recovery checks</a> is equal to 8 + your dying condition value.</p><br />
</div>