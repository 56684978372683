import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MythicFeatsHeader } from './feats/header';
import { AllMythicFeats } from './feats/all';
import { UniversalMythicFeats } from './paths/feats/universal';
import { AngelMythicFeats } from './paths/feats/angel';
import { ArchonMythicFeats } from './paths/feats/archon';
import { AzataMythicFeats } from './paths/feats/azata';
import { DemonMythicFeats } from './paths/feats/demon';
import { DevilMythicFeats } from './paths/feats/devil';
import { DragonMythicFeats } from './paths/feats/dragon';
import { ElementalMythicFeats } from './paths/feats/elemental';
import { EnlightenmentMythicFeats } from './paths/feats/enlightenment';
import { FeyMythicFeats } from './paths/feats/fey';
import { ShadowMythicFeats } from './paths/feats/shadow';
import { StarsMythicFeats } from './paths/feats/stars';
import { TitanMythicFeats } from './paths/feats/titan';
import { UndeadMythicFeats } from './paths/feats/undead';
import { FeatAptitude } from './feats/aptitude';
import { FeatIncredibleClimber } from './feats/incredibleclimber';
import { FeatIncredibleSwimmer } from './feats/incredibleswimmer';
import { FeatInfiniteVariety } from './feats/infinitevariety';
import { FeatInnateMagic } from './feats/innatemagic';
import { FeatLegendaryItem } from './feats/legendaryitem';
import { FeatManifestPower } from './feats/manifestpower';
import { FeatMiraculousSurvival } from './feats/miraculoussurvival';
import { FeatMythicBond } from './feats/mythicbond';
import { FeatMythicCraft } from './feats/mythiccraft';
import { FeatMythicSustenance } from './feats/mythicsustenance';
import { FeatPiercetheDarkness } from './feats/piercethedarkness';
import { FeatTelepathy } from './feats/telepathy';
import { FeatThrowRock } from './feats/throwrock';
import { FeatAbundantMagic } from './feats/abundantmagic';
import { FeatChangeShape } from './feats/changeshape';
import { FeatCommunewithPower } from './feats/communewithpower';
import { FeatExoticShape } from './feats/exoticshape';
import { FeatMysticSight } from './feats/mysticsight';
import { FeatMythicMetamagic } from './feats/mythicmetamagic';
import { FeatMythicRitualist } from './feats/mythicritualist';
import { FeatPerfectSelf } from './feats/perfectself';
import { FeatStomp } from './feats/stomp';
import { FeatAmazingReflexes } from './feats/amazingreflexes';
import { FeatAscendantSpell } from './feats/ascendantspell';
import { FeatExtraMythicPower } from './feats/extramythicpower';
import { FeatMagicSurge } from './feats/magicsurge';
import { FeatMythicAnimalCompanion } from './feats/mythicanimalcompanion';
import { FeatMythicCohort } from './feats/mythiccohort';
import { FeatMythicTeamwork } from './feats/mythicteamwork';
import { FeatAdvancedInnateMagic } from './feats/advancedinnatemagic';
import { FeatAdvancedLegendaryItem } from './feats/advancedlegendaryitem';
import { FeatAdvancedManifestations } from './feats/advancedmanifestations';
import { FeatAuraofDread } from './feats/auraofdread';
import { FeatExpandedInfluence } from './feats/expandedinfluence';
import { FeatFlawlessStrike } from './feats/flawlessstrike';
import { FeatImbuedArms } from './feats/imbuedarms';
import { FeatImpossibleSpeed } from './feats/impossiblespeed';
import { FeatMetamixing } from './feats/metamixing';
import { FeatMythicFlight } from './feats/mythicflight';
import { FeatPowerfulDigger } from './feats/powerfuldigger';
import { FeatShadowDoubling } from './feats/shadowdoubling';
import { FeatShadowStep } from './feats/shadowstep';
import { FeatSignatureSpell } from './feats/signaturespell';
import { FeatSpiritTouch } from './feats/spirittouch';
import { FeatTitanicRage } from './feats/titanicrage';
import { FeatUnscathed } from './feats/unscathed';
import { FeatBeyondMorality } from './feats/beyondmorality';
import { FeatDireSpellcraft } from './feats/direspellcraft';
import { FeatPersistentMagic } from './feats/persistentmagic';
import { FeatAccursedSpellcraft } from './feats/accursedspellcraft';
import { FeatImpossiblePrecision } from './feats/impossibleprecision';
import { FeatTrueSight } from './feats/truesight';
import { FeatYourNameHere } from './feats/yournamehere';
import { FeatGreaterInnateMagic } from './feats/greaterinnatemagic';
import { FeatGreaterLegendaryItem } from './feats/greaterlegendaryitem';
import { FeatGreaterManifestations } from './feats/greatermanifestations';
import { FeatIndomitable } from './feats/indomitable';
import { FeatTitanicLeap } from './feats/titanicleap';
import { FeatGodslayer } from './feats/godslayer';
import { FeatIrrepressible } from './feats/irrepressible';
import { FeatPeerlessInnateMagic } from './feats/peerlessinnatemagic';
import { FeatRelentless } from './feats/relentless';
import { MythicFeatDetails } from './feats/details';

export const MythicFeats = () =>
<div>
	<MythicFeatsHeader />
	<Switch>
		<Route exact path="/mythic/feats" component={UniversalMythicFeats} />
		<Route path="/mythic/feats/details" component={MythicFeatDetails} />
		<Route path="/mythic/feats/all" component={AllMythicFeats} />
		<Route path="/mythic/feats/angel" component={AngelMythicFeats} />
		<Route path="/mythic/feats/archon" component={ArchonMythicFeats} />
		<Route path="/mythic/feats/azata" component={AzataMythicFeats} />
		<Route path="/mythic/feats/demon" component={DemonMythicFeats} />
		<Route path="/mythic/feats/devil" component={DevilMythicFeats} />
		<Route path="/mythic/feats/dragon" component={DragonMythicFeats} />
		<Route path="/mythic/feats/elemental" component={ElementalMythicFeats} />
		<Route path="/mythic/feats/enlightenment" component={EnlightenmentMythicFeats} />
		<Route path="/mythic/feats/fey" component={FeyMythicFeats} />
		<Route path="/mythic/feats/shadow" component={ShadowMythicFeats} />
		<Route path="/mythic/feats/stars" component={StarsMythicFeats} />
		<Route path="/mythic/feats/titan" component={TitanMythicFeats} />
		<Route path="/mythic/feats/undead" component={UndeadMythicFeats} />
		<Route path="/mythic/feats/aptitude" component={FeatAptitude}/>
		<Route path="/mythic/feats/aptitude" component={FeatAptitude}/>
		<Route path="/mythic/feats/incredibleclimber" component={FeatIncredibleClimber}/>
		<Route path="/mythic/feats/incredibleswimmer" component={FeatIncredibleSwimmer}/>
		<Route path="/mythic/feats/infinitevariety" component={FeatInfiniteVariety}/>
		<Route path="/mythic/feats/innatemagic" component={FeatInnateMagic}/>
		<Route path="/mythic/feats/legendaryitem" component={FeatLegendaryItem}/>
		<Route path="/mythic/feats/manifestpower" component={FeatManifestPower}/>
		<Route path="/mythic/feats/miraculoussurvival" component={FeatMiraculousSurvival}/>
		<Route path="/mythic/feats/mythicbond" component={FeatMythicBond}/>
		<Route path="/mythic/feats/mythiccraft" component={FeatMythicCraft}/>
		<Route path="/mythic/feats/mythicsustenance" component={FeatMythicSustenance}/>
		<Route path="/mythic/feats/piercethedarkness" component={FeatPiercetheDarkness}/>
		<Route path="/mythic/feats/telepathy" component={FeatTelepathy}/>
		<Route path="/mythic/feats/throwrock" component={FeatThrowRock}/>
		<Route path="/mythic/feats/abundantmagic" component={FeatAbundantMagic}/>
		<Route path="/mythic/feats/changeshape" component={FeatChangeShape}/>
		<Route path="/mythic/feats/communewithpower" component={FeatCommunewithPower}/>
		<Route path="/mythic/feats/exoticshape" component={FeatExoticShape}/>
		<Route path="/mythic/feats/mysticsight" component={FeatMysticSight}/>
		<Route path="/mythic/feats/mythicmetamagic" component={FeatMythicMetamagic}/>
		<Route path="/mythic/feats/mythicritualist" component={FeatMythicRitualist}/>
		<Route path="/mythic/feats/perfectself" component={FeatPerfectSelf}/>
		<Route path="/mythic/feats/stomp" component={FeatStomp}/>
		<Route path="/mythic/feats/amazingreflexes" component={FeatAmazingReflexes}/>
		<Route path="/mythic/feats/ascendantspell" component={FeatAscendantSpell}/>
		<Route path="/mythic/feats/extramythicpower" component={FeatExtraMythicPower}/>
		<Route path="/mythic/feats/magicsurge" component={FeatMagicSurge}/>
		<Route path="/mythic/feats/mythicanimalcompanion" component={FeatMythicAnimalCompanion}/>
		<Route path="/mythic/feats/mythiccohort" component={FeatMythicCohort}/>
		<Route path="/mythic/feats/mythicteamwork" component={FeatMythicTeamwork}/>
		<Route path="/mythic/feats/advancedinnatemagic" component={FeatAdvancedInnateMagic}/>
		<Route path="/mythic/feats/advancedlegendaryitem" component={FeatAdvancedLegendaryItem}/>
		<Route path="/mythic/feats/advancedmanifestations" component={FeatAdvancedManifestations}/>
		<Route path="/mythic/feats/auraofdread" component={FeatAuraofDread}/>
		<Route path="/mythic/feats/expandedinfluence" component={FeatExpandedInfluence}/>
		<Route path="/mythic/feats/flawlessstrike" component={FeatFlawlessStrike}/>
		<Route path="/mythic/feats/imbuedarms" component={FeatImbuedArms}/>
		<Route path="/mythic/feats/impossiblespeed" component={FeatImpossibleSpeed}/>
		<Route path="/mythic/feats/metamixing" component={FeatMetamixing}/>
		<Route path="/mythic/feats/mythicflight" component={FeatMythicFlight}/>
		<Route path="/mythic/feats/powerfuldigger" component={FeatPowerfulDigger}/>
		<Route path="/mythic/feats/shadowdoubling" component={FeatShadowDoubling}/>
		<Route path="/mythic/feats/shadowstep" component={FeatShadowStep}/>
		<Route path="/mythic/feats/signaturespell" component={FeatSignatureSpell}/>
		<Route path="/mythic/feats/spirittouch" component={FeatSpiritTouch}/>
		<Route path="/mythic/feats/titanicrage" component={FeatTitanicRage}/>
		<Route path="/mythic/feats/unscathed" component={FeatUnscathed}/>
		<Route path="/mythic/feats/beyondmorality" component={FeatBeyondMorality}/>
		<Route path="/mythic/feats/direspellcraft" component={FeatDireSpellcraft}/>
		<Route path="/mythic/feats/persistentmagic" component={FeatPersistentMagic}/>
		<Route path="/mythic/feats/accursedspellcraft" component={FeatAccursedSpellcraft}/>
		<Route path="/mythic/feats/impossibleprecision" component={FeatImpossiblePrecision}/>
		<Route path="/mythic/feats/truesight" component={FeatTrueSight}/>
		<Route path="/mythic/feats/yournamehere" component={FeatYourNameHere}/>
		<Route path="/mythic/feats/greaterinnatemagic" component={FeatGreaterInnateMagic}/>
		<Route path="/mythic/feats/greaterlegendaryitem" component={FeatGreaterLegendaryItem}/>
		<Route path="/mythic/feats/greatermanifestations" component={FeatGreaterManifestations}/>
		<Route path="/mythic/feats/indomitable" component={FeatIndomitable}/>
		<Route path="/mythic/feats/titanicleap" component={FeatTitanicLeap}/>
		<Route path="/mythic/feats/godslayer" component={FeatGodslayer}/>
		<Route path="/mythic/feats/irrepressible" component={FeatIrrepressible}/>
		<Route path="/mythic/feats/peerlessinnatemagic" component={FeatPeerlessInnateMagic}/>
		<Route path="/mythic/feats/relentless" component={FeatRelentless}/>
	</Switch>
</div>
