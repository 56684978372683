import React from 'react';
import { Link } from 'react-router-dom'
export const SW2eFeatsAbout = () =>
<div>
	<h2 className="header-bg">General Feats</h2>
	<p className="all-indent">All kinds of experiences and training can shape your character beyond what you learn by advancing in an archetype. Abilities that require a degree of training but can be learned by anyone—not only members of certain species, and without investing in an archetype—are called general feats.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
				<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/chemicalsynthesis">Chemical Synthesis</Link></td>
				<td>1</td>
				<td>General, Skill</td>
				<td>trained in <Link to="/sw2e/skills/crafting">Crafting</Link></td>
				<td>Craft explosives, chemicals and medical supplies.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/equipmentmanufacturing">Equipment Manufacturing</Link></td>
				<td>1</td>
				<td>General, Skill</td>
				<td>trained in <Link to="/sw2e/skills/crafting">Crafting</Link></td>
				<td>Craft droids, vehicles and equipment.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/forcesensitivity">Force Sensitivity</Link></td>
				<td>1</td>
				<td>Force, General</td>
				<td>Non-droid</td>
				<td>You are Force-sensitive, and use this connection unconsciously to your benefit.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/improvedskilltraining">Improved Skill Training</Link></td>
				<td>1</td>
				<td>General</td>
				<td>—</td>
				<td>You become trained in 2 additional skills.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/advancedskilltraining">Advanced Skill Training</Link></td>
				<td>1</td>
				<td>General</td>
				<td><Link to="/swe2/feats/improvedskilltraining">Improved Skill Training</Link></td>
				<td>You become trained in 2 additional skills.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/toughness">Toughness</Link></td>
				<td>1</td>
				<td>General</td>
				<td>—</td>
				<td>You gain 2 additional hit points per level.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/improvedtoughness">Improved Toughness</Link></td>
				<td>1</td>
				<td>General</td>
				<td><Link to="/swe2/feats/toughness">Toughness</Link></td>
				<td>You gain 2 additional hit points per level.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/lightarmortraining">Light Armor Training</Link></td>
				<td>1</td>
				<td>General</td>
				<td><Link to="/swe2/feats/toughness">Toughness</Link></td>
				<td>You gain 2 additional hit points per level.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/advancedtoughness">Advanced Toughness</Link></td>
				<td>1</td>
				<td>General</td>
				<td><Link to="/swe2/feats/improvedtoughness">Improved Toughness</Link></td>
				<td>You gain 2 additional hit points per level.</td>
			</tr>
		</tbody>
	</table>
</div>
