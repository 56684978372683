import React from 'react';
import { Link } from 'react-router-dom'

export const FeatSkirmishMastery = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Skirmish Mastery
		<div className="spacer" />
		<span className="right">Feat 5</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scout</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/skirmish">Skirmish</Link>, at least 3 total Scout feats</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">The extra damage you deal with Skirmish increases to 2d8.</p><br />
</div>