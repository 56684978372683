import React from 'react';
import { Link } from 'react-router-dom'

export const FeatDroidCompanion = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Droid Companion
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Will saves, trained in <Link to="/sw2e/skills/crafting">Crafting</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You gain a basic droid companion that travels with you on your adventures and obeys any simple commands you give it to the best of its abilities. See <Link to="/sw2e/rules/droidcompanions">Droid Companions</Link> for more information.</p><br />
	<p className="all-indent no-indent justify"><b>Special:</b> If you have the <Link to="/sw2e/feats/custommodifications">Custom Modifications</Link> feat, your droid companion can use your custom equipment without the normal penalty.</p><br />
	<h3 className="headerbar sub-header-bg shadow-sm">Droid Companion Leads To...</h3>
	<p className="all-indent no-indent"><Link to="/sw2e/feats/improveddroidcompanion">Improved Droid Companion</Link>, <Link to="/sw2e/feats/advanceddroidcompanion">Advanced Droid Companion</Link>, <Link to="/sw2e/feats/sidebyside">Side By Side</Link></p>
</div>