import React from 'react';
import { Link } from 'react-router-dom'

export const SW2ePowersLimited = () =>
<div>
	<h3 className="header-bg">Limited Basic Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 1st-Level Powers</h3>
	<ul className="unstyled">
		<li><Link to="/sw2e/powers/limited/alterfate"><b>Alter Fate:</b></Link> Reroll any check.</li>
	</ul>
	<h3 className="header-bg">Limited 2nd-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 3rd-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 4th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 5th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 6th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 7th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 8th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 9th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">Limited 10th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
</div>