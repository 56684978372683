import React from 'react';

export const FeatIncredibleClimber = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Incredible Climber
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Dragon</div>
			<div className="trait">Elemental</div>
			<div className="trait">Enlightenment</div>
			<div className="trait">Fey</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Dragon] copper form or white form; [Fey] woodland form</li>
		</ul>
		<hr />
		<p className="no-indent">You gain a climb Speed equal to your Speed.</p>
	</div>
	<div className="spacer" />
</div>