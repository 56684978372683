import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { SW2eArchetypeNoble } from './archetypes/noble';
import { SW2eArchetypeScoundrel } from './archetypes/scoundrel';
import { SW2eArchetypeScout } from './archetypes/scout';
import { SW2eArchetypeSoldier } from './archetypes/soldier';
import { SW2eArchetypeTechSpecialist } from './archetypes/techspecialist';

export const SW2eArchetypes = () =>
<Switch>
    <Route exact path="/sw2e/archetypes">
        <div className="text-container all-indent">
            <h2 className="header-bg">Archetypes</h2>
            <p>Your character's archetype feats define their training and abilities. Each of the archetypes listed below
            describes a different character archetype that can often be found in the Star Wars galaxy.</p>
            <p>When you gain an archetype feat, you can spend it in any archetype. You can select any feat from that archetype
            as long as you meet its prerequisites. Many archetypes also share feats, such as the Technician and Scoundrel's
            Breadth of Experience. If you take a feat in one archetype, you do not need to take the same feat again in the other
            archetype in order to meet prerequisites.</p>
            <br />
            <h2 className="header-bg">Major Archetypes</h2>
            <p>Major archetypes are robust and contain a large number of feats to choose from. They represent a broad category
            of individuals rather than a specific job or way of doing things. Many players will find everything they need for a
            character in a single major archetype. It is recommended (though not required) that multi-archetype characters begin
            with a major archetype.</p>
            <br />
            <h3 className="sub-header-bg"><Link to="/sw2e/archetypes/noble">Noble</Link></h3>
            <p>From true royalty to elected officials, military commanders, crime lords, merchants, holovid stars and
            influential corportate magnates, nobles come in varied and numerous types, but they all use their
            intelligence and natural charisma to make their way in the galaxy. With a winning smile, a golden tongue, a
            powerful message or a knack for making compromises, a noble uses their talents to influence <i>people.</i></p>
            <br />
            <h3 className="sub-header-bg"><Link to="/sw2e/archetypes/scoundrel">Scoundrel</Link></h3>
            <p>Scoundrels, for good or ill, live outside the law, or at least outside common decency. They get by with
            bravado, duplicity, cunning, and trickery. They live by their wits, and are rarely above lying, cheating or
            stealing to get what they need.</p>
            <br />
            <h3 className="sub-header-bg"><Link to="/sw2e/archetypes/scout">Scout</Link></h3>
            <p>Scouts are natural explorers. Travel and survival are what they do best. With the skills and training to
            handle out of the way locations and hostile environments, they tend to go where the money is good and their
            skills can be utilized and tested.</p>
            <br />
            <h3 className="sub-header-bg"><Link to="/sw2e/archetypes/soldier">Soldier</Link></h3>
            <p>Disciplined and skilled, soldiers are made to fight. They are trained in a wide variety of weapons and
            tactics and no one else is more at home on a battlefield. The fight for glory, honor, to right wrongs, to
            gain power and wealth, or simply for the thrill of combat.</p>
            <br />
            <h3 className="sub-header-bg"><Link to="/sw2e/archetypes/techspecialist">Tech Specialist</Link></h3>
            <p>A tech specialist is an individual that focuses on modifying and enhancing fantastic technology to fit
            their needs. Tech specialists range from brilliant engineers to prodigious outlaw techs, though all share a
            love for the droids, gadgets, gear and guns found throught the galaxy.</p>
            <br />
            <h2 className="header-bg">Minor Archetypes</h2>
            <p>Minor archetypes represent a much more specific focus, and are intended to supplement a major archetype. While a
            character can start with a minor archetype, there are not generally enough feats for an entire character.</p>
            <br />
            <h3 className="sub-header-bg">Assassin</h3>
            <p></p>
            <br />
            <h3 className="sub-header-bg">Bounty Hunter</h3>
            <p></p>
            <br />
            <h3 className="sub-header-bg">Martial Artist</h3>
            <p></p>
            <br />
            <h3 className="sub-header-bg">Performer</h3>
            <p></p>
            <br />
            <h2 className="header-bg">Force Archetypes</h2>
            <p>Not right now.</p>
            <br />
            <h3 className="sub-header-bg"><del>Jedi Consular</del></h3>
            <p></p>
            <br />
            <h3 className="sub-header-bg"><del>Jedi Guardian</del></h3>
            <p></p>
            <br />
            <h3 className="sub-header-bg"><del>Jedi Sentinel</del></h3>
            <p></p>
            <br />
            <h3 className="sub-header-bg"><del>Sith Assassin</del></h3>
            <p></p>
            <br />
            <h3 className="sub-header-bg"><del>Sith Maurauder</del></h3>
            <p></p>
            <br />
            <h3 className="sub-header-bg"><del>Sith Sorcerer</del></h3>
            <p></p>
            <br />
        </div>
    </Route>
    <Route path="/sw2e/archetypes/noble" component={SW2eArchetypeNoble}/>
    <Route path="/sw2e/archetypes/scoundrel" component={SW2eArchetypeScoundrel}/>
    <Route path="/sw2e/archetypes/scout" component={SW2eArchetypeScout}/>
    <Route path="/sw2e/archetypes/soldier" component={SW2eArchetypeSoldier}/>
    <Route path="/sw2e/archetypes/techspecialist" component={SW2eArchetypeTechSpecialist}/>
</Switch> 
