import React from 'react';
import { Link } from 'react-router-dom'

export const FeatSkirmish = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Skirmish
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scout</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/favoredterrain">Favored Terrain</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You have trained to outmaneuver your opponents and strike their weak points. After you Stride, you deal 1d8 additional precision damage the next time you hit an opponent in that round. (Precision damage increases the damage you already deal, using the same type, but is ineffective against targets that lack vital organs or other weak points.)</p><br />
</div>