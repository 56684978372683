import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellTelepathy = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Telepathy&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=337"> </a>
			<div className="spacer" />
			<span className="right">Spell 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Divination</div>
			<div className="trait">Linguistic</div>
			<div className="trait">Mental</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Duration</b> <span className="tweak-color">2 hours</span></li>
		</ul>
		<hr />
		<p className="no-indent">You can communicate telepathically with creatures within 30 feet. Once you establish a connection by communicating with a creature, the communication is two-way. You can communicate only with creatures that share a language with you.</p>
		<hr />
			<p className="no-indent"><b>Heightened (6th)</b> <span className="tweak-color">Telepathy's range becomes 120 feet.</span> It loses the linguistic trait. You can communicate telepathically with creatures using shared mental imagery even if you don't share a language.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>Telepathy is fun. It has some strong uses in social situations, but just like in 1e, social situations usually last longer than 10 minutes, so this spell sees virtually no use. Also no reason it can't have it's old range back (or closest standard range in 2e). Medium range is strong, but it's appropriate for a 6th level slot.</p>
	</div>
	<div className="spacer" />
</div>