import React from 'react';
import { Link } from 'react-router-dom';

export const RulesHome = () =>
<div>
    <h2 className="header-bg">Core</h2>
    <h3 className="sub-header-bg">Traits</h3>
    <Link to="rules/traits/mythic">Mythic</Link>
    <br />
    <Link to="rules/traits/radiance">Radiance</Link>
    <br />
    <Link to="rules/traits/rush">Rush</Link>
    <br />
    <br />
    <h2 className="header-bg">Mythic</h2>
    <h3 className="sub-header-bg">Legendary Items</h3>
    <Link to="rules/mythic/legendaryitems">Legendary Items</Link>
    <br />
    <Link to="rules/mythic/legendaryitems/specialabilities">Legendary Item Special Abilities</Link>
</div>