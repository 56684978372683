import React from 'react';
import { Link } from 'react-router-dom'

export const FeatCustomModifications = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Custom Modifications
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Tech Specialist</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Will saves, trained in <Link to="/sw2e/skills/crafting">Crafting</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You use the spare parts you have on hand, along with your expertise to upgrade your equipment to your liking.</p><br />
	<p className="all-indent no-indent justify">As a downtime activity that requires 1 day, you can create a custom modified item. Choose a weapon, suit of armor, or vehicle and apply an upgrade of your choice of your level or lower at no cost. This upgrade does not count against the normal limit of upgrades on an item.</p><br />
	<p className="all-indent no-indent justify">This upgrade is clearly makeshift in nature and does not increase the value of the item. You can only have one custom modified item at a time, and if the item is not maintained at least once every 24 hours, the upgrade ceases functioning until maintenance is performed. You can maintain a custom modified item as part of your daily preparations, or as an activity that requires 10 minutes.</p><br />
	<h3 className="headerbar sub-header-bg shadow-sm">Custom Modifications Leads To...</h3>
	<p className="all-indent no-indent"><Link to="/sw2e/feats/overcharge">Overcharge</Link></p>
</div>