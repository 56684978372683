import React from 'react';
import { Link } from 'react-router-dom';

export const SpellTweaksMythic = () =>
<div>
	<h3 className="header-bg"> Cantrips with Mythic heightening effects</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 1st-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/bane"><b>Bane (enc):</b></Link> Weaken enemies' attacks in an aura around you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/bless"><b>Bless (enc):</b></Link> Strengthen allies' attacks in an aura around you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/burninghands"><b>Burning Hands (evo):</b></Link> A small cone of flame rushes from your hands.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/sleep"><b>Sleep (enc):</b></Link> Cause creatures in a small area to fall asleep.</li>
	</ul>
	<h3 className="header-bg"> 2nd-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/animatedead"><b>Animate Dead (nec):</b></Link> Animate an undead creature to fight on your behalf.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/restoration"><b>Restoration (nec):</b></Link> Counter the effects of toxins or conditions with restorative magic.</li>
	</ul>
	<h3 className="header-bg"> 3rd-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/fireball"><b>Fireball (evo):</b></Link> An explosion of fire in an area burns creatures.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/lightningbolt"><b>Lightning Bolt (evo):</b></Link> Lightning strikes all creatures in a line.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/nondetection"><b>Nondetection (abj):</b></Link> Protect a creature or object from detection.</li>
	</ul>
	<h3 className="header-bg"> 4th-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
		<li><Link to="/contenttweaks/spelltweaks/spells/spellimmunity"><b>Spell Immunity (abj):</b></Link> Name a spell to negate its effects on you.</li>
		<li><Link to="/contenttweaks/spelltweaks/spells/suggestion"><b>Suggestion (enc):</b></Link> Suggest a course of action a creature must follow.</li>
	</ul>
	<h3 className="header-bg"> 5th-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 6th-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 7th-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 8th-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 9th-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg"> 10th-Level Spells with Mythic heightening effects</h3>
	<ul className="unstyled">
	</ul>
</div>