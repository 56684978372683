import React from 'react';
export const PathoftheDragon = () =>
<div>
	<h2 className="header-bg">Path of the Dragon</h2>
	<p>(DESCRIPTION)</p>
	<br />
	<p><b>Hit points:</b> 10 plus your Constitution modifier</p>
	<br />
	<div className="columns">
		<div className="column-md">
			<h2 className="header-bg">Path Abilities</h2>
			<h3 className="sub-header-bg">1st Rank - (TITLE)</h3>
      <p>(Natural Weapons)</p>
      <p>(Energy Resistance 10, based on color)</p>
      <p>(advantage on saves vs. paralysis & sleep)</p>
			<br />
			<h3 className="sub-header-bg">4th Rank - (TITLE)</h3>
      <p>(Frightful Presence, 30 ft, use class DC)</p>
      <p>(Breath weapon, 30 ft cone/80 ft line, based on color)</p>
      <p>(+1 natural armor bonus to AC, +1 status to saves against magic)</p>
			<br />
			<h3 className="sub-header-bg">7th Rank - (TITLE)</h3>
      <p>(Flight, other movement ability based on color)</p>
      <p>(Energy immunity, based on color)</p>
      <p>(Special ability based on color (corrupt water & create water, desert thirst & create water, desert wind, vortex & water mastery, mass laughter, golden luck, trackless step & woodland stride & camoflage, manipulate flames & redirect fire & smoke sight, cloud walk & fog vision, snow vision & blizzard))</p>
			<br />
			<h3 className="sub-header-bg">10th Rank - (TITLE)</h3>
      <p>(Shapechange, 1 action, Humanoid Form or Dragon Form, replacement mythic feat if you have Shapechanger)</p>
      <p>(Immunity to Paralysis & Sleep)</p>
      <p>(other?)</p>
			<br />
		</div>
		<div className="column-md">
			<h2 className="header-bg">Path Feats</h2>
			<p>The following additional Mythic Feats are available to those who walk the Path of the Dragon:</p>
			<div className="sidenav-column">
				<ul>
					<li>feat</li>
					<li>feat</li>
					<li>feat</li>
				</ul>
			</div>
		</div>
	</div>
</div>