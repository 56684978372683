import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { SW2eEquipmentArmor } from './equipment/armor';

export const SW2eEquipment = () => 
<div className="text-container">
	<h2 className="center">
		<Switch>
			<Route exact path="/sw2e/equipment/armor">Armor</Route>
			<Route path="/"><Link to="/sw2e/equipment/armor">Armor</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/equipment/weapons">Weapons</Route>
			<Route path="/"><Link to="/sw2e/equipment/weapons">Weapons</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/equipment/consumables">Consumables</Route>
			<Route path="/"><Link to="/sw2e/equipment/consumables">Consumables</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/equipment/upgrades">Upgrades</Route>
			<Route path="/"><Link to="/sw2e/equipment/upgrades">Upgrades</Link></Route>
		</Switch>
	</h2>
	<h2 className="center">
		<Switch>
			<Route exact path="/sw2e/equipment/survivalgear">Survival Gear</Route>
			<Route path="/"><Link to="/sw2e/equipment/survivalgear">Survival Gear</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/equipment/droids">Droids</Route>
			<Route path="/"><Link to="/sw2e/equipment/droids">Droids</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/equipment/vehicles">Vehicles</Route>
			<Route path="/"><Link to="/sw2e/equipment/vehicles">Vehicles</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/sw2e/equipment/cybernetics">Cybernetics</Route>
			<Route path="/"><Link to="/sw2e/equipment/cybernetics">Cybernetics</Link></Route>
		</Switch>
	</h2>
	<Switch>
		<Route exact path="/sw2e/equipment/armor" component={SW2eEquipmentArmor}/>
		<Route exact path="/sw2e/equipment/weapons" component={SW2eEquipmentArmor}/>
		<Route exact path="/sw2e/equipment/consumables" component={SW2eEquipmentArmor}/>
		<Route exact path="/sw2e/equipment/upgrades" component={SW2eEquipmentArmor}/>
		<Route exact path="/sw2e/equipment/survivalgear" component={SW2eEquipmentArmor}/>
		<Route exact path="/sw2e/equipment/droids" component={SW2eEquipmentArmor}/>
		<Route exact path="/sw2e/equipment/vehicles" component={SW2eEquipmentArmor}/>
		<Route exact path="/sw2e/equipment/cybernetics" component={SW2eEquipmentArmor}/>
		<Route path="/" component={SW2eEquipmentArmor}/>
	</Switch>
</div>