import React from 'react';
import { Link } from 'react-router-dom'
export const SW2eArchTableScout = () =>
<div>
	<h2 className="header-bg">Fundamental Scout Feats</h2>
	<p className="all-indent">Each time you gain a level, you can select one or more of the following feats. You must satisfy any prerequisites before taking the feat. Fundamental feats provide essential abilities and improve your proficiencies. They must often be taken to progress in an archetype.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/ironwill">Iron Will</Link></td>
				<td>5</td>
				<td>Archetype, Scoundrel, Scout, Soldier</td>
				<td>—</td>
				<td>Your Will save proficiency increases to expert.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/lightningreflexes">Lightning Reflexes</Link></td>
				<td>5</td>
				<td>Archetype, Noble, Scoundrel, Scout, Soldier, Tech Specialist</td>
				<td>—</td>
				<td>Your Reflex save proficiency increases to expert.</td>
			</tr>
		</tbody>
	</table>
	<br />
	<h2 className="header-bg">Scout Feats</h2>
	<p className="all-indent">Each time you gain a level, you can also select one or more of the following feats. You must satisfy any prerequisites before taking the feat.</p>
	<br />
	<table className="shadow-sm arch-table">
		<thead>
			<tr>
				<th className="table-col-md-lg">Name</th>
				<th className="table-col-sm">Level</th>
				<th className="table-col-md-lg">Traits</th>
				<th className="table-col-md-lg">Prerequisites</th>
			<th>Benefit</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/sw2e/feats/favoredterrain">Favored Terrain</Link></td>
				<td>1</td>
				<td>Archetype, Scout</td>
				<td><Link to="/sw2e/feats/improvedskilltraining">Improved Skill Training</Link>, <Link to="/sw2e/feats/toughness">Toughness</Link>, trained in Survival</td>
				<td>You have studied a specific terrain to overcome its challenges.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/skirmish">Skirmish</Link></td>
				<td>1</td>
				<td>Archetype, Scout</td>
				<td><Link to="/sw2e/feats/favoredterrain">Favored Terrain</Link></td>
				<td>Moving helps you find weaknesses in your opponents' defenses.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/mobility">Mobility</Link></td>
				<td>2</td>
				<td>Archetype, Scout, Scoundrel</td>
				<td><Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link> or <Link to="/sw2e/feats/favoredterrain">Favored Terrain</Link></td>
				<td>You move in a way that denies your enemies the opportunity to retaliate.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/terrainstride">Terrain Stride</Link></td>
				<td>4</td>
				<td>Archetype, Scout</td>
				<td><Link to="/sw2e/feats/favoredterrain">Favored Terrain</Link>, any other Scout feat</td>
				<td>You move well in your favored terrain.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/skirmishmastery">Skirmish Mastery</Link></td>
				<td>5</td>
				<td>Archetype, Scout</td>
				<td><Link to="/sw2e/feats/skirmish">Skirmish</Link>, at least 3 total Scout feats</td>
				<td>Deal 2d8 extra damage with Skirmish.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/improvedskirmishmastery">Improved Skirmish Mastery</Link></td>
				<td>11</td>
				<td>Archetype, Scout</td>
				<td><Link to="/sw2e/feats/skirmishmastery">Skirmish Mastery</Link>, at least 8 total Scout feats</td>
				<td>Deal 3d8 extra damage with Skirmish.</td>
			</tr>
			<tr>
				<td><Link to="/sw2e/feats/advancedskirmishmastery">Advanced Skirmish Mastery</Link></td>
				<td>17</td>
				<td>Archetype, Scout</td>
				<td><Link to="/sw2e/feats/improvedskirmishmastery">Improved Skirmish Mastery</Link>, at least 14 total Scout feats</td>
				<td>Deal 4d8 extra damage with Skirmish.</td>
			</tr>
		</tbody>
	</table>
</div>
