import React from 'react';
import { Link } from 'react-router-dom';
import { PathFeaturesTable } from '../extra/pathtemplates';
import { AbilityBoosts, AbilityHeader, AmazingSpeed, ForceOfWill, HardToKill, Immortal, LegendaryHero, MythicSurge, Recuperation, Unstoppable } from '../extra/sharedabilities';

export const MythicHeroes = () => 
<div>
    <p>--== introduction to the abilities of a mythic character here ==--</p>
    <h2 className='header-bg'>Mythic Heroes</h2>
	<br />
	<div className='columns'>
		<div className='col-md left-col'>
			<p>Mythic ranks are similar in many ways to character levels, and all mythic characters gain a number of base abilities as they
				increase in mythic rank, in addition to their path abilities.
			</p>
			<h2 className='header-bg'>Abilities of Mythic Characters</h2>
			<br />
			<AbilityHeader name='Hit Points' />
			<p>Similarly to your character level, each mythic rank grants additional Hit Points. The number gained is determined by your mythic path.</p>
			<br />

			<AbilityHeader name='Mythic Power' />
			<p>Mythic characters can draw upon a wellspring of power to accomplish amazing deeds and cheat fate. This power is used to
				activate Mythic Spells much in the same way Focus Points are used to activate Focus Spells. Each mythic character
				gains a pool of mythic power with 5 Mythic Power Points upon obtaining their first mythic rank. This pool increases by
				2 points at each mythic rank beyond first, to a maximum of 23 points at mythic rank 10. You replenish all the Mythic
				Power Points in your pool during your daily preparations.</p>
			<br />
			<AbilityHeader name='Mythic Path' />
			<p>Choose a <Link to='/mythic/paths'>Mythic Path</Link>, which will shape your journey into legend.
				Your choice of path grants access to a list of special mythic feats, along with granting new abilities
				and features as a character increases their mythic rank.</p>
			<br />
			<MythicSurge />
			<br />
			<AbilityHeader name='Mythic Feats' level='Rank 1'/>
			<p>At each new rank, mythic characters recieve their choice of a <Link to='/mythic/feats'>mythic feat</Link> for which they
				qualify. Mythic feats are powerful options available only to characters who posess mythic power, and often just to
				those who follow specific mythic paths.</p>
			<br />
			<HardToKill />
			<br />
			<Recuperation />
			<br />
			<AbilityBoosts />
			<br />
		</div>
		<div className='col-md right-col'>
			<p className='no-indent'>Below are the class features gained at each level by all mythic characters</p>
			<h3>Table 2-1: Mythic Abilities by Rank</h3>
			<PathFeaturesTable baseTable={true}/>
			<br />
			<AmazingSpeed />
			<br />
			<ForceOfWill />
			<br />
			<Unstoppable />
			<br />
			<Immortal />
			<br />
			<LegendaryHero />
		</div>
	</div>
</div>