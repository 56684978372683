import React from 'react';
import { Link } from 'react-router-dom'

export const FeatInfoImbuedArms = () =>
<div className="columns">
	<div className="col-md">
		<h3>Table: Mythic Paths and Damage Types</h3>
		<table>
			<thead>
				<tr>
					<th>Path</th>
					<th>Damage Types</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td><Link to="/mythic/paths/angel">Angel</Link></td>
					<td>fire, force, good, or positive</td>
				</tr>
				<tr>
					<td><Link to="/mythic/paths/archon">Archon</Link></td>
					<td>fire, good, lawful, positive, or sonic</td>
				</tr>
				<tr>
					<td><Link to="/mythic/paths/azata">Azata</Link></td>
					<td>acid, chaotic, cold, electricity, fire, good, or positive</td>
				</tr>
				<tr>
					<td><Link to="/mythic/paths/demon">Demon</Link></td>
					<td>acid, chaotic, electricity, fire, evil, negative, or poison</td>
				</tr>
				<tr>
					<td><Link to="/mythic/paths/devil">Devil</Link></td>
					<td>cold, fire, evil, lawful, negative, or poison</td>
				</tr>
				<tr>
					<td><Link to="/mythic/paths/dragon">Dragon</Link></td>
					<td>acid, cold, electricity, fire, or poison (must match your dragon type)</td>
				</tr>
				<tr>
					<td><Link to="/mythic/paths/elemental">Elemental</Link></td>
					<td>acid, cold, electricity, or fire (must match your elemental type)</td>
				</tr>
				<tr>
					<td><Link to="/mythic/paths/enlightenment">Enlightenment</Link></td>
					<td>force, lawful (only if you're lawful), negative, or positive</td>
				</tr>
				<tr>
					<td><Link to="/mythic/paths/enlightenment">Stars</Link></td>
					<td>force, cold, negative, or positive</td>
				</tr>
			</tbody>
		</table>
		<br />
	</div>
</div>