import React from 'react';
import { Link } from 'react-router-dom'

export const FeatDoubleStrike = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Double Strike&nbsp;<div className="twoaction" />
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scoundrel</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/fasthands">Fast Hands</Link> or <Link to="/sw2e/feats/mastermind">Mastermind</Link> or <Link to="/sw2e/feats/roughmethods">Rough Methods</Link> or <Link to="/sw2e/feats/weaponfocus">Weapon Focus</Link> or <Link to="/sw2e/feats/armorfocus">Armor Focus</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You lash out at your foes with both weapons. Make two Strikes, one with each of your two weapons, or each end of a double weapon. If the second Strike is made with a weapon that doesn't have the agile or double trait, it takes a -2 penalty.</p><br />
	<h3 className="headerbar sub-header-bg shadow-sm">Double Strike Leads To...</h3>
	<p className="all-indent no-indent"><Link to="/sw2e/feats/twinfeint">Twin Feint</Link></p>
</div>