import React from 'react';

export const SW2eSpeciesZabrak = () => 
<div className="columns">
    <div className="column-md">
        <h2 className="header-bg">Zabrak</h2>
        <h3 className="sub-header-bg">Physical Description</h3>
        <p className="all-indent">Zabraks are a species of near-humans that has evolved to be tough due to the nature of
        their homeworld Iridonia. They are a carnivorous species and have two hearts, which allow them to pump
        oxygenated blood around their systems more quickly than other species, meaning they can go faster for longer.
        Some Zabraks possess a ring of small, vestigial horns that run from high on their brow round to the back of
        their head. The horns of males are generally more developed than those of females, although horn placement,
        length, and thickness varies enormously across the species. Zabraks are often Force-sensitive.</p>
        <br />
        <h3 className="sub-header-bg">Society</h3>
        <p className="all-indent">Zabraks are considered to be proud, self-determined, and even arrogant to some, and
        are one of the most independent species in the galaxy. Zabrak societies on Iridonia and Dathomir are insular and
        almost tribal in nature. They tend to revolve around force-sensitive individuals, such as the Nightsisters;
        witches who practiced powerful dark-side magick.</p>
        <p className="all-indent">Because of their drive for independence, Zabraks can be found all across the galaxy,
        seeking to make a name for themselves, test their abilities, or just escape the restrictions imposed on them by
        society and tradition.</p>
        <br />
    </div>
    <div className="column-md">
        <h2 className="header-bg">Zabrak Mechanics</h2>
        <h3 className="sub-header-bg">Hit Points</h3>
        <p>10</p>
        <br />
        <h3 className="sub-header-bg">Size</h3>
        <p>Medium</p>
        <br />
        <h3 className="sub-header-bg">Speed</h3>
        <p>8 meters</p>
        <br />
        <h3 className="sub-header-bg">Ability Boosts</h3>
        <p>Constitution</p>
        <p>Two free ability boosts</p>
        <h3 className="sub-header-bg">Ability Flaws</h3>
        <p>Charisma</p>
        <br />
        <h3 className="sub-header-bg">Languages</h3>
        <p>Basic</p>
        <p>Zabrak</p>
        <p className="no-indent all-indent">Additional languages equal to your Intelligence (if it's positive).</p>
        <br />
        <h3 className="sub-header-bg">Tireless</h3>
        <p className="all-indent">Zabrak physiology allows them to go farther, faster and longer, and withstand harsh
        conditions. You gain a +4-meter bonus to your travel Speed, and double the time it takes for you to become
        fatigued from overland travel. You treat hot weather as one step less severe (extreme to severe, severe to mild)
        and gain a +2 circumstance bonus on Fortitude saves against effects that would cause fatigue.</p>
        <br />
        <h3 className="sub-header-bg">Hard to Kill</h3>
        <p className="all-indent">Zabrak are able to bounce back from hits that would kill most other species. Once per
        day, when you take a critical hit, you can instead treat the attack as a hit.</p>
        <br />
    </div>
    <div className="column-fit">
        <img className="profile-img" src="/profile/zabrak.png" alt="" />
    </div>
</div>