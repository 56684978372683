import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellCommandtheMindless = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Command the Mindless
			<div className="spacer" />
			<span className="right">Mythic 3</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Concentrate" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Area:</b> 30-foot emanation</li>
			<li><b>Duration:</b> 1 minute</li>
		</ul>
		<hr />
		<p className="no-indent">You take control of the mindless creatures around you. Mindless creature and creatures with an Intelligence modifier of -5 in the area become your minions if they are at least 3 levels lower than you. During the spell's duration, these do not count against your normal limit of four minions. Afterwards, you can choose to keep the mindless creatures as minions. You can have a maximum of four minions under your control. Any non-mindless creatures, and any mindless creature that don't become your minion leave you alone unless you attack them, but otherwise returns to their previous activities.</p>
		<p className="no-indent">If a creature is already under someone else's command, the controlling creature can roll a Will saving throw to maintain control of the creature.</p><br />
		<p className="no-indent"><b>Critical Success</b> The creature is unaffected and is temporarily immune for 24 hours.</p>
		<p className="no-indent"><b>Success</b> The creature is unaffected.</p>
		<p className="no-indent"><b>Failure</b> The creature becomes a minion under your control, but reverts to its original owner after the spell ends, or if you or an ally attacks the creature.</p>
		<p className="no-indent"><b>Critical Failure</b> The creature becomes a minion under your control as normal. After the spell ends, it becomes an uncontrolled creature unless you choose to make it a minion.</p>
	</div>
	<div className="spacer" />
</div>