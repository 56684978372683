import React from 'react';
import { Link } from 'react-router-dom'

export const FeatArmorFocus = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Armor Focus
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> Trained in powered armor, expert in Fortitude saves, <Link to="/sw2e/feats/improvedtoughness">Improved Toughness</Link>, trained in Athletics or Acrobatics</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Hours spent training in your preferred type of armor have made you wear it like a second skin. Choose unarmored defense, light armor, heavy armor or powered armor. Your proficiency rank with your chosen defense type increases to Expert.</p><br />
	<p className="all-indent no-indent justify">Additionally, you can choose Strength or Dexterity as your key ability for determining your ability DC. Once this choice is made, it cannot be changed.</p><br />
	<br />
	<div className="info indent">
		<h3>Key Terms</h3>
		<br />
		<p className="no-indent justify"><b>Specialization:</b> A character may not select more than one specialization feat from a given archetype. For example, a Soldier who selects Weapon Focus may not also select Armor Focus. They could still select Fast Hands from the Scoundrel archetype, however.</p>
	</div><br />
</div>