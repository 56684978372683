import React from 'react';

export const SW2eSpeciesBothan = () => 
<div className="columns">
    <div className="column-md">
        <h2 className="header-bg">Bothan</h2>
        <h3 className="sub-header-bg">Physical Description</h3>
        <p className="all-indent">Bothans are furry mammalian anthropoids that average 1.5 meters in height. They are covered in fur that
        ripples in response to their emotional state. This reait, and teh language that developed from it, are known as
        Wrendui. They are sexually dimorphic, though the differences between male and female members of the species are
        somewhat subtle. They have a similar stature, and both are known to sport furry manes and beards. Bothans have
        an average lifespan, reaching maturity around age 15 and having a maximum age of around 100.</p>
        <br />
        <h3 className="sub-header-bg">Society</h3>
        <p className="all-indent">Bothan culture is guided by the philosophy and principles set forth in the ancient text known as The Way. In
        this "Bothan Way", the pursuit of power and influence is paramount. Thus, individual Bothans put their own
        political and economic success above all other concerns, and as a species, Bothans put their own advancement
        ahead of other intergalactic interests. Bothans tend to remain neutral in most conflicts, attempting to play
        both sides for their own gain. The volume of backstabbing, subtle character assassination and political
        maneuvering in Bothan society is dizzying, and resulted in many species stereotyping Bothans as untrustworthy.
        Many Bothans are habitually paranoid, believing that anyone who's not working with them, is working against
        them.</p>
        <br />
    </div>
    <div className="column-md">
        <h2 className="header-bg">Bothan Mechanics</h2>
        <h3 className="sub-header-bg">Hit Points</h3>
        <p>6</p>
        <br />
        <h3 className="sub-header-bg">Size</h3>
        <p>Medium</p>
        <br />
        <h3 className="sub-header-bg">Speed</h3>
        <p>8 meters</p>
        <br />
        <h3 className="sub-header-bg">Ability Boosts</h3>
        <p>Charisma</p>
        <p>Dexterity</p>
        <p>Free</p>
        <br />
        <h3 className="sub-header-bg">Ability Flaws</h3>
        <p>Constitution</p>
        <br />
        <h3 className="sub-header-bg">Languages</h3>
        <p>Basic</p>
        <p>Bothese and it's written form, Botha</p>
        <p className="no-indent all-indent">Additional languages equal to 2 + your Intelligence (if it's positive).</p>
        <p className="all-indent">Additionally, you can understand and perform Wrendui, the Bothan body language involving gentle rippling
        patterns in the fur. Any observer who does not understand Wrendui and does not know of it (a DC 20 Society
        check) is unaware that the performer has done anything of significance when performing Wrendui. Any species can
        learn to understand Wrendui, but only Bothans (and specifically designed droids) can perform it. Wrendui is a
        rare language that only Bothans can teach.</p>
        <br />
        <h3 className="sub-header-bg">Low-Light Vision</h3>
        <p className="all-indent">You can see in dim light as though it were bright light, so you ignore the concealed condition due to dim light.</p>
        <br />
        <h3 className="sub-header-bg">Social Skills</h3>
        <p className="all-indent">You are trained in your choice of Diplomacy, Deception or Society and gain a skill
        feat for which you qualify. At 5th level, you become an expert in your chosen skill and gain an additional skill
        feat for which you qualify. These feats can be chosen at any time during the character creation or level up
        process.</p>
        <br />
    </div>
    <div className="column-fit">
        <img className="profile-img" src="/profile/bothan.jpg" alt="" />
    </div>
</div>