import React from 'react';
import { Link } from 'react-router-dom'

export const FeatFastHands = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Fast Hands
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scoundrel</div>
		<div className="trait">Specialization</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/breadthofexperience">Breadth of Experience</Link>, expert in Reflex saves, trained in <Link to="sw2e/skills/deception">Deception</Link></li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">You use fast-talking, flattery, and a silver tongue to avoid danger and escape tricky situations.</p><br />
	<p className="all-indent no-indent justify">When you successfully Feint, the target is flat-footed against attacks you attempt against it until the end of your next turn. On a critical success, the target is flat-footed against all attacks until the end of your next turn, not just yours.</p><br />
	<p className="all-indent no-indent justify">Additionally, you can choose Charisma as your key ability for determining your ability DC. Once this choice is made, it cannot be changed.</p><br />
	<br />
	<div className="info indent">
		<h3>Key Terms</h3>
		<br />
		<p className="no-indent justify"><b>Specialization:</b> A character may not select more than one specialization feat from a given archetype. For example, a Scoundrel who selects Fast Hands may not also select Rough Methods. They could still select Weapon Focus from the Soldier archetype, however.</p>
	</div><br />
</div>