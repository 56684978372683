import React from 'react';
import { Link } from 'react-router-dom'

export const FeatTwinFeint = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Twin Feint
		<div className="spacer" />
		<span className="right">Feat 3</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Scoundrel</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> <Link to="/sw2e/feats/doublestrike">Double Strike</Link>, at least 3 total Scoundrel feats</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">When you attack with both weapons, you use the first attack to throw your foe offguard against a second attack at a different angle. When Using Double Strike, the target is automatically flat-footed against the second attack.</p><br />
	<h3 className="headerbar sub-header-bg shadow-sm">Twin Feint Leads To...</h3>
	<p className="all-indent no-indent"><Link to="/sw2e/feats/twinfeint">Twin Distraction</Link></p>
</div>