import React from 'react';
import { Link } from 'react-router-dom'

export const FeatTitanicLeap = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Titanic Leap&nbsp;<div className="threeaction" />
			<div className="spacer" />
			<span className="right">Feat Mythic 8</span>
		</h2>
		<div className="traits">
			<div className="trait">Earth</div>
			<div className="trait">Evocation</div>
			<div className="trait">Mythic</div>
			<div className="trait">Elemental</div>
			<div className="trait">Titan</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/stomp">Stomp</Link>; [Elemental] earth element</li>
		</ul>
		<hr />
		<p className="no-indent">You Stride, then Leap. When you land, you can cast <i><a href="https://2e.aonprd.com/Spells.aspx?ID=95">earthquake</a></i> centered on yourself as a free action.</p>
	</div>
	<div className="spacer" />
</div>