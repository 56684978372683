import React from 'react';
import { Link } from 'react-router-dom'

export const FeatPersistentMagic = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Persistent Magic
			<div className="spacer" />
			<span className="right">Feat Mythic 5</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Archon</div>
			<div className="trait">Devil</div>
			<div className="trait">Shadow</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> <Link to="/mythic/feats/mythicmetamagic">Mythic Metamagic</Link>, <Link to="/mythic/spells/mythicextendspell">Mythic Extend Spell</Link></li>
		</ul>
		<hr />
		<p className="no-indent">When you cast <i>mythic extend spell</i>, followed by a spell with a normal level at least 2 below your mythic rank, and a duration of at least 1 hour, you can choose to expend 4 additional points of Mythic Power. If you do, the duration of the spell becomes permanent. If you cast another spell with a permanent duration using <i>mythic extend spell</i>, the previous permanent spell ends.</p>
		<p className="no-indent">If you are at least mythic rank 7, you can make a spell with a duration of 10 minutes or more permanent.</p>
		<p className="no-indent">If you are at least mythic rank 9, you can make a spell with a duration of 1 minute or more permanent.</p>
	</div>
	<div className="spacer" />
</div>