import React from 'react';
import { Link } from 'react-router-dom'

export const SW2ePowersAll = () => 
<div>
	<h3 className="header-bg">All Basic Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 1st-Level Powers</h3>
	<ul className="unstyled">
		<li><Link to="/sw2e/powers/limited/alterfate"><b>Alter Fate:</b></Link> Reroll any check.</li>
		<li><Link to="/sw2e/powers/focus/overcharge"><b>Overcharge:</b></Link> Overcharge your weapons to deal more damage for a short time.</li>
		<li><Link to="/sw2e/powers/focus/quickrepair"><b>Quick Repair:</b></Link> Overcharge your weapons to deal more damage for a short time.</li>
	</ul>
	<h3 className="header-bg">All 2nd-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 3rd-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 4th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 5th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 6th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 7th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 8th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 9th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
	<h3 className="header-bg">All 10th-Level Powers</h3>
	<ul className="unstyled">
	</ul>
</div>