import React from 'react';

export const FeatSpiritTouch = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Spirit Touch&nbsp;<div className="action" />
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Enlightenment</div>
			<div className="trait">Stars</div>
			<div className="trait">Undead</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">Your Strikes affect incorporeal creatures with the effects of a <i>ghost touch</i> property rune and deal an extra 1d6 negative damage to living creatures and 1d6 positive damage to undead.</p>
		<p className="no-indent">At 8th rank, this increases to 2d6 extra damage.</p>
	</div>
	<div className="spacer" />
</div>