import React from 'react';
import freetrigger from '../../../resources/free-action.png';
import { Trait } from '../../extra/linkables';

export const SpellMythicSurge = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Surge
			<div className="spacer" />
			<span className="right">Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Fortune" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="Triggered Free Action" src={freetrigger} /> </li>
			<li><b>Trigger:</b> You make an attack roll, skill or perception check, saving throw or flat check</li>
		</ul>
		<hr />
		<p className="no-indent">Reroll the triggering roll and use the better result. If this spell is cast after the outcome of the roll is revealed, it can change the outcome of the roll.</p>
	</div>
	<div className="spacer" />
</div>