import React from 'react';
import { Link } from 'react-router-dom'

export const FeatShadowDoubling = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Shadow Doubling
			<div className="spacer" />
			<span className="right">Feat Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Demon</div>
			<div className="trait">Fey</div>
			<div className="trait">Rakshasa</div>
			<div className="trait">Shadow</div>
		</div>
		<ul className="proplist">
			<li><b>Prerequisites:</b> [Fey] deep form or highland form</li>
		</ul>
		<hr />
		<p className="no-indent">You can create duplicates of yourself formed from shadow stuff to confuse and harass your opponents. You gain the <i><Link to="/mythic/spells/shadowdouble">shadow double</Link></i> mythic spell.</p>
	</div>
	<div className="spacer" />
</div>