import React from 'react';
import { Link } from 'react-router-dom'

export const FeatWeaponFocus = () =>
<div className="text-container">
	<h2 className="headerbar header-bg shadow-sm">Weapon Focus
		<div className="spacer" />
		<span className="right">Feat 1</span>
	</h2>
	<div className="traits">
		<div className="trait">Archetype</div>
		<div className="trait">Soldier</div>
		<div className="trait">Specialization</div>
	</div>
	<ul className="proplist">
		<li className="all-indent"><b>Prerequisites:</b> Trained in heavy weapons, expert in Fortitude saves, <Link to="/sw2e/feats/improvedtoughness">Improved Toughness</Link>, trained in Athletics or Acrobatics</li>
	</ul>
	<hr />
	<p className="all-indent no-indent justify">Hours spent training with your preferred weapons, learning and developing new combat techniques, have made you particularly effective with your weapons of choice. Choose unarmed attacks, melee weapons, small arms, long arms, heavy weapons or one type of exotic weapon in which you are trained, such as lightsabers. Your proficiency rank with your chosen weapon type increases to Expert.</p><br />
	<p className="all-indent no-indent justify">Additionally, you can choose Strength or Dexterity as your key ability for determining your ability DC. Once this choice is made, it cannot be changed.</p><br />
	<br />
	<div className="info indent">
		<h3>Key Terms</h3>
		<br />
		<p className="no-indent justify"><b>Specialization:</b> A character may not select more than one specialization feat from a given archetype. For example, a Soldier who selects Weapon Focus may not also select Armor Focus. They could still select Fast Hands from the Scoundrel archetype, however.</p>
	</div><br />
</div>