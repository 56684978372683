import React from 'react';
import { Link } from 'react-router-dom'

export const FeatInfoAdvancedManifestations = () =>
<div>
	<h3>Table: Advanced Manifestations</h3>
	<table>
		<thead>
			<tr>
				<th>Spell</th>
				<th>Level</th>
				<th>Power Points</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td><Link to="/mythic/spells/borrowtime">Borrow Time</Link></td>
				<td>4</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/burstofspeed">Burst of Speed</Link></td>
				<td>4</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/giftofpower">Gift of Power</Link></td>
				<td>4</td>
				<td>2</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/sealpower">Seal Power</Link></td>
				<td>4</td>
				<td>1</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/tideofundeath">Tide of Undeath</Link></td>
				<td>5</td>
				<td>3</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/wordofpower">Word of Power</Link></td>
				<td>5</td>
				<td>2</td>
			</tr>
			<tr>
				<td><Link to="/mythic/spells/fierceregeneration">Fierce Regeneration</Link></td>
				<td>6</td>
				<td>1</td>
			</tr>
		</tbody>
	</table>
	<br />
</div>