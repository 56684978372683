import React from 'react';
import action from '../../../resources/1-action.png';
import { Trait } from '../../extra/linkables';

export const SpellTitanicRage = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Titanic Rage
			<div className="spacer" />
			<span className="right">Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Polymorph" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="1 Action" src={action} /> </li>
			<li><b>Area:</b> 10-foot emanation</li>
			<li><b>Duration:</b> 1 minute</li>
		</ul>
		<hr />
		<p className="no-indent">In a flash of power, you grow to a terrifying size. Creatures and objects in the area must succeed on a Fortitude save or be pushed out of the area. If an object, such as a wall, is unable to be moved about freely, it instead takes 30 damage which ignores any hardness. If this damage is enough to break an object, it doesn't prevent you from expanding.</p>
		<p className="no-indent">Afterwards, if you were Small or smaller, you grow to Large size. Otherwise, your size increases by one. Your reach increases by 5 feet (or by 10 feet if you were Tiny), your Speed increases by 10 feet, and you gain a +4 status bonus to melee damage.</p>
		<hr />
		<ul className="proplist">
			<li><b>Heightened (+2)</b> The initial damage increases by 20 and the status bonus to melee damage increases by 2.</li>
		</ul>
	</div>
	<div className="spacer" />
</div>