import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellBane = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Bane&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=18"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Enchantment</div>
			<div className="trait">Mental</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> divine, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Area</b> 5-foot emanation</li>
			<li><b>Saving Throw</b> Will; <b>Duration</b> 8 hours</li>
		</ul>
		<hr />
		<p className="no-indent">You fill the minds of your enemies with doubt. Enemies in the area must succeed at a Will save or take a â€“1 status penalty to attack rolls as long as they are in the area. Once per turn, starting the turn after you cast bane, you can use a single action, which has the <a href="https://2e.aonprd.com/Traits.aspx?ID=32">concentrate</a> trait, to increase the emanation's radius by 5 feet and force enemies in the area that weren't yet affected to attempt another saving throw. Bane can <a href="https://2e.aonprd.com/Rules.aspx?ID=371">counteract</a>&nbsp; <a href="https://2e.aonprd.com/Spells.aspx?ID=25">bless</a>.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> Each time an enemy fails or critically fails at a Will save against the effect, it must roll the next attack roll it makes while within the area twice and take the worse result.</span></p>
	</div>
	<div className="spacer" />
</div>