import React from 'react';
import { Link } from 'react-router-dom'

export const FeatInfoLegendaryItemSpecial = () =>
<div className='no-indent all-indent'>
	<h2 className="header-bg shadow-sm">Legendary Item Special Abilities</h2>
	<br />
	<p className='hanging-indent'><b><u>Amphibious</u></b>: Your item gains a swim Speed of 25 feet (or Speed of 25 feet
	if it already has a swim Speed). Your item must have the animated ability to select this.</p>

	<p className='hanging-indent'><b><u>Animated</u></b>: A legendary item with this ability can move and act on its
		own. It gains the <a href="https://2e.aonprd.com/Traits.aspx?ID=35">construct
		</a> and <a href="https://2e.aonprd.com/Traits.aspx?ID=109">minion</a> traits, and it gains 2 actions during
		your turn if you use an action to Command it. Despite being a minion, your item gains 1 reaction at the start of
		your turn.</p>
	<p>Your legendary item gains the physical ability modifiers <b>Str</b> +1, <b>Dex</b> +1, <b>Con</b> +1. Choose one
		of its physical ability modifiers to increase by 2 and another to increase by 1. The item gains a Speed of 25
		feet (or optionally a swim Speed of 25 feet, if appropriate), becomes trained in unarmed attacks, and can
		perform any activities an item of its type could reasonably be used to accomplish (such as a healer's kit
		treating wounds, or a sword cutting a rope).</p>
	<p>If the item is a weapon, it can make attacks using itself. This is an unarmed attack that deals the same
		damage as a weapon of its type and has the same traits. The item gains the benefit of any runes or magical
		properties it possesses. If the item is not a weapon, it gains an unarmed strike with the agile, magical and
		finesse traits that deals 1d4 damage of an appropriate type. The item may have other attacks or abilities,
		similar to an <a href="https://2e.aonprd.com/MonsterFamilies.aspx?ID=4">animated object</a>, as determined by
		the GM.</p>
	<p>If you have the <Link to="/mythic/feats/advancedlegendaryitem">Advanced Legendary Item</Link> feat, increase
		its Strength, Dexterity, and Constitution by 1, and increase its proficiency rank in unarmed attacks to expert.
		If you have the <Link to="/mythic/feats/greaterlegendaryitem">Greater Legendary Item</Link> feat, increase
		its Strength, Dexterity, and Constitution by 1, and increase its proficiency rank in unarmed attacks to
		master.</p>

	<p className='hanging-indent'><b><u>Climbing</u></b>: Your item gains a climb Speed of 25 feet. Your item must have
		the animated ability to select this.</p>

	<p className='hanging-indent'><b><u>Expanded Senses</u></b>: The item's normal vision and hearing have no range
		limit, as a creature's would. It gains your choice of either low-light vision or darkvision.</p>

	<p className='hanging-indent'><b><u>Fast Movement</u></b>: Increase one of the item's Speeds from 25 feet to 40
		feet. This ability can be selected more than once. Each time, choose a different Speed to increase. Your item
		must have the animated ability to select this.</p>

	<p className='hanging-indent'><b><u>Indestructible</u></b>: The item's hardness increases by 10. It is immune to all
		damage from non-mythic sources.</p>

	<p className='hanging-indent'><b><u>Legendary Surge</u></b>: The item gains it's own pool of mythic power with 2
		Mythic Power Points. The item or its partner can use these points to
		cast <Link to="/mythic/spells/mythicsurge">Mythic Surge</Link> on checks made involving the item, such as
		attacks with a weapon, skill checks using a tool or saving throws with armor or a worn item. The item might also
		possess abilities that allow it to spend these points in other ways.</p>
	<p>If you have the <Link to="/mythic/feats/advancedlegendaryitem">Advanced Legendary Item</Link> feat, this pool
		increases to 3 Mythic Power Points. If you have the <Link to="/mythic/feats/greaterlegendaryitem">Greater
		Legendary Item</Link> feat, this pool increases to 4 Mythic Power Points.</p>

	<p className='hanging-indent'><b><u>Magical Ability</u></b>: The item gains the special abilities of a specific
		magic item of the same type, or of an item of a similar type that it could reasonably replicate, as determined
		by the GM. The chosen item must have an item level equal to twice your mythic rank or less. Weapons and armor do
		not gain any property runes noted in the item's description.</p>

	<p className='hanging-indent'><b><u>Magical Property</u></b>: The item gains a property rune appropriate for an item
		of its type (or any time it is capable of changing into if it has the <i>shapechange</i> ability). The rune must
		have an item level equal to twice your mythic rank or less. This ability can be taken up to three times.</p>

	<p className='hanging-indent'><b><u>Magic Sense</u></b>: The item gains a precise sense with a range of 60 feet that
		allows it to detect and identify magical effects. It senses magical effects that it can see within range as if
		it had detected them using a <i><a href="https://2e.aonprd.com/Spells.aspx?ID=66">detect magic</a></i> spell of
		a level equal to your mythic rank. This is automatic and does not require an action, but the effect must be
		within its field of vision.</p>
	<p>The item gain a +2 circumstance bonus on checks to <a href="https://2e.aonprd.com/Actions.aspx?ID=24">Identify
		Magic</a> and can grant this bonus to a check its partner makes as a reaction.</p>

	<p className='hanging-indent'><b><u>Potency</u></b>: The item grants a +1 item bonus to attack rolls when used as a
		weapon, as if it had a <a href="https://2e.aonprd.com/Equipment.aspx?ID=280">weapon potency</a> rune inscribed,
		and a +1 bonus to AC when worn, as if it had a +1 <a href="https://2e.aonprd.com/Equipment.aspx?ID=278">armor
		potency</a> rune inscribed. These bonuses increase as your mythic rank increases.</p>
	<p>At mythic rank 4, the item bonus to attack rolls increases to +2</p>
	<p>At mythic rank 5, the item bonus to AC increases to +2</p>
	<p>At mythic rank 7, the item bonus to attack rolls increases to +3</p>
	<p>At mythic rank 8, the item bonus to AC increases to +3</p>

	<p className='hanging-indent'><b><u>Self-Repairing</u></b>: When you refocus, your item taps into your mythic power
		to repair itself, regaining 10 Hit Points per mythic rank.</p>

	<p className='hanging-indent'><b><u>Speech</u></b>: Your item can speak, allowing it to communicate with creatures
		other than its partner and those touching it.</p>

	<p className='hanging-indent'><b><u>Striking</u></b>: The item deals an additional die of weapon damage, as if it
		had a <a href="https://2e.aonprd.com/Equipment.aspx?ID=280">striking</a> rune inscribed. If you're at least 5th
		mythic rank, increase this to two extra dice, and if you're at least 8th mythic rank, increase this to 3 extra
		dice.</p>

	<p className='hanging-indent'><b><u>Resilient</u></b>: The item grants a +1 item bonus to saving throws, as if it
		had a <a href="https://2e.aonprd.com/Equipment.aspx?ID=279">resilient</a> rune inscribed. If you're at least 6th
		mythic rank, the item bonus increases to +2, and if you're at least 9th mythic rank, the item bonus increases
		to +3.</p>

	<p className='hanging-indent'><b><u>Sense Alignment</u></b>: Choose chaotic, evil, good, or lawful. The item gains a
		precise sense with a range of 60 feet that allows it to detect the chosen alignment. It senses creatures and
		objects with auras of the chosen alignment that it can see within range as if it had detected them using
		a <a href="https://2e.aonprd.com/Spells.aspx?ID=65">detect alignment</a> spell of a level equal to your mythic
		rank. This is automatic and does not require an action, but the effect must be within its field of vision.</p>
	<p>The item gain a +2 circumstance bonus on checks
		to <a href="https://2e.aonprd.com/Skills.aspx?ID=5&General=true">Recall Knowledge</a> about the sources of auras
		it detects and can grant this bonus to a check its partner makes as a reaction.</p>

	<p className='hanging-indent'><b><u>Upgradeable</u></b>: Any runes or magical abilities the item possessed before
		becoming a legendary item begin functioning again. The item can have runes inscribed on it and special abilities
		added to it with Magical Crafting, just as a normal item could, but you must be present for the entire crafting
		process.</p>
	<p>If the item has a dedicated bond, the bonded partner may be present for the crafting process instead.</p>
</div>