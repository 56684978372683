import React from 'react';
import freeaction from '../../../../resources/free-action.png';

export const FeatDimensionalAssault = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Dimensional Assault&nbsp;<img className="small-icon" alt="Triggered Free Action" src={freeaction} />&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=1037"> </a>
			<div className="spacer" />
			<span className="right">Magus 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Magus</div>
		</div>
		<ul className="proplist">
			<li><b>Trigger</b> You cast a spell or use an action with the teleportation trait and teleport within reach of a creature that was within your line of sight.</li>
		</ul>
		<hr />
		<p className="no-indent">After teleporting, make a melee Strike against one creature within your reach.</p>
	</div>
	<div className="col-md info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
		<p>Easily my biggest disappointment with Secrets of Magic was the reintroduction of the Dimensional Dervish
			line. Specifically that it can only be used with a very specific teleportation ability that can only be
			viably used a few times per day, and can only move a character a disappointing 5 - 30 feet.</p>
		<p>To remedy the problem, we've separated the teleportation and attacking effect into two seaparate
			abilities, one given in place of dimenstional assault to laughing shadows and the other available to any
			magus as a feat. As compensation for the extra cost, we've opened up the use of dimensional assault to
			any teleportation effect, and improved the teleportation focus spell to keep pace with the level 4
			wizard focus spell <a href="https://2e.aonprd.com/Spells.aspx?ID=524">Dimensional Steps</a></p>
	</div>
	<div className="spacer" />
</div>