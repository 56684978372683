import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';

export const TitanPathRoute = () =>
<div>
	<hr />
	<h3 className="center">
		<Switch>
			<Route exact path="/mythic/paths/titan">Titan Details</Route>
			<Route path="/"><Link to="/mythic/paths/titan">Titan Details</Link></Route>
		</Switch>
		<span className="spacerbar">|</span>
		<Switch>
			<Route exact path="/mythic/paths/titan/feats">Titan Feats</Route>
			<Route path="/"><Link to="/mythic/paths/titan/feats">Titan Feats</Link></Route>
		</Switch>
	</h3>
</div>
