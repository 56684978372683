import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellSuggestion = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Suggestion&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=315"> </a>
			<div className="spacer" />
			<span className="right">Spell 4</span>
		</h2>
		<div className="traits">
			<div className="trait">Enchantment</div>
			<div className="trait">Incapacitation</div>
			<div className="trait">Linguistic</div>
			<div className="trait">Mental</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Range</b> 30 feet</li>
			<li><b>Duration</b> varies</li>
		</ul>
		<hr />
		<p className="no-indent">Your honeyed words are difficult for creatures to resist. You suggest a course of action to the target, which must be phrased in such a way as to seem like a logical course of action to the target and can't be self-destructive or obviously against the target's self-interest. The target must attempt a Will save.</p><br />
		<p className="no-indent"><b>Critical Success</b> The target is unaffected and knows you tried to control it.</p>
		<p className="no-indent"><b>Success</b> The target is unaffected and thinks you were talking to them normally, not casting a spell on them.</p>
		<p className="no-indent"><b>Failure</b> The target immediately follows your suggestion. The spell has a duration of 1 minute, or until the target has completed a finite suggestion or the suggestion becomes self-destructive or has other obvious negative effects.</p>
		<p className="no-indent"><b>Critical Failure</b> As failure, but the base duration is 1 hour.</p>
		<hr />
			<p className="no-indent"><b>Heightened (8th)</b> You can target up to 10 creatures.</p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> Suggestion loses the vocal component, and if the target's save is not a critical success, the target believes the suggestion was one of their own thoughts, rather than something told to them by someone else.</span></p>
	</div>
	<div className="spacer" />
</div>