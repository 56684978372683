import React from 'react';
import twoaction from '../../../resources/2-actions.png';
import { Trait } from '../../extra/linkables';

export const SpellSealPower = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Seal Power
			<div className="spacer" />
			<span className="right">Mythic 4</span>
		</h2>
		<div className="traits">
			<div className="trait"><Trait name="Attack" /></div>
			<div className="trait"><Trait name="Flourish" /></div>
			<div className="trait"><Trait name="Mythic" /></div>
			<div className="trait"><Trait name="Transmutation" /></div>
		</div>
		<ul className="proplist">
			<li><b>Cast:</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> </li>
			<li><b>Range:</b> touch</li>
			<li><b>Duration:</b> 1 round</li>
		</ul>
		<hr />
		<p className="no-indent">You channel your mythic power into your opponent, breifly sealing their access to any supernatural abilities. Make an unarmed Strike or melee spell attack roll. On a hit, the target becomes unable to Cast a Spell (including focus spells and mythic spells) and cannot use actions or abilities that have a limited number of uses per day, except for actions to Activate an item. On a critical hit, the duration becomes 1 minute.</p>
	</div>
	<div className="spacer" />
</div>