import React from 'react';
import { Link } from 'react-router-dom';

export const SW2eSpeciesHome = () => 
<div className="all-indent">
    <h2 className="header-bg">Species</h2>
    <p>The Star Wars galaxy contains a bewildering variety of species, each with its own unique outlook and
    civilization. Although Humans dominate the known galaxy, there are many intelligent alien species that can be
    encountered wherever you travel. A character's species helps determine their abilities and shapes how they see the
    world around them.</p>
    <p>Most species have their own homeworlds and colonies, but individuals of any species can be found just about
    anywhere, thanks to the prevalence of hyperspace travel.</p>
    <br />
	<h2 className="header-bg">Common Species</h2>
    <p>Certain species are more common than others, and enjoy certain privaleges because of it.
    Their languages are often spoken, facilities are built to accomodate them, and they are typically well represented
    politically.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/human">Human</Link></h3>
    <p>Humans are the galaxy's most numerous and politically dominant sentient species with
    millions of major and minor colonies galaxywide. Often considered to be a standard or average to which the biology,
    psychology, and culture of other species were compared, they can be found anywhere, engaging in all manner of
    different pursuits.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/bothan">Bothan</Link></h3>
    <p>Bothans are furry mammalian anthropoids with a facial appearance and body structure that
    has canine, feline, and equine features. Hailing from Bothawui and several colonies, they are known for their
    desires for intrigue and subterfuge, and for remaining (officially) neutral in most conflicts.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/cerean">Cerean</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/duros">Duros</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/moncalamari">Mon Calamari</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/rodian">Rodian</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/sullustan">Sullustan</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/twilek">Twi'lek</Link></h3>
    <p>Twi'leks are an omnivorous humanoid species originating from the planet Ryloth. Their
    distinctive features include a complete lack of body hair, colorful skin and a pair of shapely prehensile tentacles
    that grow from the base of their skulls. They can be found easily throughout the galaxy in large part due to being a
    popular target for slavers.</p>
    <br />
    <h2 className="header-bg">Uncommon Species</h2>
    <p>While they don't make up the bulk of the galactic population, uncommon races are at least
    well known. The can often find accomodations specific to their species, and their language is spoken by almost every
    protocol droid.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/gamorrean">Gamorrean</Link></h3>
    <p>Gamorreans are large, green-skinned, porcine humanoids that hail from the Outer Rim planet
    of Gamorr. While seemingly primative and isolationist, they can be found all around the galaxy in the employ of
    crime lords, and anyone who needs hired muscle without a hint of squeemishness.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/ithorian">Ithorian</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/quarren">Quarren</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/togruta">Togruta</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/trandoshan">Trandoshan</Link></h3>
    <p>Trandoshans are tall, reptillian humanoids from the planet Trandosha. They are renowed as
    great hunters and are known to embark across the galaxy on hunting expeditions. They are known for their ability to
    regenerate lost limbs and heal from most wounds that are not immediately fatal.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/ugnaught">Ugnaught</Link></h3>
    <p>Ugnaughts are short, porcine humanoids native to Gentes. They are prized for their skill
    and dedication along with their generally docile nature which makes them, like many races, a target of slavers
    throughout the galaxy.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/wookiee">Wookiee</Link></h3>
    <p>The Wookiees, whose name for themselves translates to the "People of the Trees", are a
    species of tall, hairy humanoids that inhabit the treetops and plains of the planet Kashyyyk. Their strength and
    skill makes them a target for slavers, and Wookiee clan customs will occasionally find them travelling across the
    galaxy to aid a friend.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/zabrak">Zabrak</Link></h3>
    <p>Zabraks are a carnivorous near-human species native to Iridonia. They are known for their distinctive vestigial
    horns and incedible tenacity. A fiercly independent species, Zabraks can be found all across the galaxy, seeking to
    make a name for themselves, test their abilities, or just escape the restrictions imposed on them by society and
    tradition.</p>
    <br />
    <h2 className="header-bg">Rare Species</h2>
    <p>Certain species can be quite rare. Either because of a dwindling population, because they
    do not often leave their homeworld, or because they are particularly xenophobic and keep almost exclusively to their
    own kind. While this can prove challenging at times, especially if they do not resemble more common races
    physically, there are benefits to not being well known.</p>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/ewok">Ewok</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/gungan">Gungan</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/jawa">Jawa</Link></h3>
    <br />
    <h3 className="sub-header-bg"><Link to="/sw2e/species/keldor">Kel Dor</Link></h3>
</div>