import React from 'react';
import action from '../../../../resources/1-action.png';

export const SpellGougingClaw = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Gouging Claw&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://2e.aonprd.com/Spells.aspx?ID=920"> </a>
			<div className="spacer" />
			<span className="right">Cantrip 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Attack</div>
			<div className="trait">Cantrip</div>
			<div className="trait">Morph</div>
			<div className="trait">Transmutation</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, primal</li>
			<li><b>Cast</b> <img className="small-icon" alt="1 Action" src={action} /> (<span className="tweak-color">somatic</span>)</li>
			<li><b>Range</b> touch; <b>Targets:</b> 1 creature <span className="tweak-color">or object</span></li>
		</ul>
		<hr />
		<p className="no-indent">You temporarily morph your limb into a clawed appendage. Make a melee spell attack roll against your target's AC. If you hit, you deal your choice of slashing or piercing damage equal to 1d6 plus your spellcasting ability modifier. On a critical success, you deal double damage plus 1d4 persistent bleed damage.</p>
		<hr />
			<p className="no-indent"><span className="tweak-color"><b>Heightened (+2)</b></span> The damage increases by 1d6 and the persistent bleed damage on a critical increases by 1d4.</p>
	</div>
	<div className="col-md right-col info shadow-sm">
		<h3>Design Insights...</h3>
		<hr />
  		<p>This one wasn't bad for an attack cantrip (other than being touch-range), but we wanted to give it the same flexibility as the others, and so 1 action, but we cut the scaling in half.Ends up a little stronger than the others, but it's also melee.</p>
	</div>
	<div className="spacer" />
</div>