import React from 'react';

export const FeatMythicSustenance = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Sustenance
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You no longer need to eat, drink, sleep or breathe. You gain immunity to sleep effects, inhaled poisons and diseases and other airborne hazards which require breathing unless you choose to allow them to affect you.</p>
	</div>
	<div className="spacer" />
</div>