import React from 'react';
import twoaction from '../../../../resources/2-actions.png';

export const SpellSleep = () =>
<div className="columns">
	<div className="spacer" />
	<div className="col-lg left-col">
		<h2 className="headerbar header-bg shadow-sm">Sleep&nbsp;<a target="_blank"  rel="noopener noreferrer" title="View the official item on the Archives of Nethys" className="aon-link" href="https://aonprd.com/SpellDisplay.aspx?ItemName=Sow%20Thought"> </a>
			<div className="spacer" />
			<span className="right">Spell 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Enchantment</div>
			<div className="trait">Incapacitation</div>
			<div className="trait">Mental</div>
			<div className="trait">Sleep</div>
		</div>
		<ul className="proplist">
			<li><b>Traditions</b> arcane, occult</li>
			<li><b>Cast</b> <img className="small-icon" alt="2 Actions" src={twoaction} /> (somatic, verbal)</li>
			<li><b>Range</b> 30 feet</li>
			<li><b>Saving Throw</b> Will</li>
		</ul>
		<hr />
		<p className="no-indent">Each creature in the area becomes drowsy and might fall asleep. A creature that falls unconscious from this spell doesn't fall prone or release what it's holding. This spell doesn't prevent creatures from waking up due to a successful Perception check, limiting its utility in combat.</p><br />
		<p className="no-indent"><b>Critical Success</b> The creature is unaffected</p>
		<p className="no-indent"><b>Success</b> The creature takes a -1 status penalty to Perception checks for 1 round.</p>
		<p className="no-indent"><b>Failure</b> The creature falls unconscious. If it's still unconscious after 1 minute, it wakes up automatically.</p>
		<p className="no-indent"><b>Critical Failure</b> The creature falls unconscious. If it's still unconscious after 1 hour, it wakes up automatically.</p>
		<hr />
			<p className="no-indent"><b>Heightened (4th)</b> The creatures fall unconscious for 1 round on a failure or 1 minute on a critical failure. They fall prone and release what they're holding, and they can't attempt Perception checks to wake up. When the duration ends, the creature is sleeping normally instead of automatically waking up.</p>
			<p className="no-indent"><span className="tweak-color"><b>Heightened (Mythic)</b> You can choose to target a single creature instead of an area. If you do, the spell gains the <i>curse</i> trait, and the creatures fall unconscious for 1 day on a failure or permanently on a critical failure and can't wake up by any means while the curse remains. The curse can only be removed by a <i>mythic</i> <a href="https://2e.aonprd.com/Spells.aspx?ID=250">remove curse</a> spell, or by a kiss from a creature who sincerely loves the target.</span></p>
	</div>
	<div className="spacer" />
</div>