import React from 'react';

export const FeatMythicCraft = () =>
<div className="columns full-width">
	<div className="spacer" />
	<div className="col-lg">
		<h2 className="headerbar header-bg shadow-sm">Mythic Craft
			<div className="spacer" />
			<span className="right">Feat Mythic 1</span>
		</h2>
		<div className="traits">
			<div className="trait">Mythic</div>
			<div className="trait">Universal</div>
		</div>
		<ul className="proplist">
		</ul>
		<hr />
		<p className="no-indent">You can Craft mythic magic items, provided you meet their other requirements. When you select this feat, you gain formulas for four common magic items, or four uncommon magic items with the mythic trait.</p>
		<p className="no-indent">Add your mythic rank to your level when determining the highest level formula you can learn for any type of crafted item.</p>
	</div>
	<div className="spacer" />
</div>